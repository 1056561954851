import {
  REQUEST_FOLLOWED_MAGAZINES,
  REQUEST_FOLLOWED_MAGAZINES_SUCCESS,
  FOLLOW_MAGAZINE,
  UNFOLLOW_MAGAZINE
} from "constants/actionTypes"

export const onRequestFollowedMagazines = (userId, forceUpdate) => {
  return {
    type: REQUEST_FOLLOWED_MAGAZINES,
    payload: { userId, forceUpdate }
  }
}

export const onRequestFollowedMagazinesSuccess = followedIssues => {
  return {
    type: REQUEST_FOLLOWED_MAGAZINES_SUCCESS,
    payload: { followedIssues }
  }
}

export const onFollowMagazine = (userId, magazineId, options = {}) => {
  return {
    type: FOLLOW_MAGAZINE,
    payload: { userId, magazineId, options }
  }
}

export const onUnfollowMagazine = (userId, magazineId, options = {}) => {
  return {
    type: UNFOLLOW_MAGAZINE,
    payload: { userId, magazineId, options }
  }
}
