import KonoApi from "../KonoApi"

class FetchIssueThumbnailsApi extends KonoApi {
  constructor(data) {
    super(`/magazines/${data.issueId}/thumbnails`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchIssueThumbnailsApi
