import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import {
  REQUEST_BOOKMARKS_SUCCESS,
  REQUEST_BOOKMARKS_STATISTICS_SUCCESS,
  CREATE_BOOKMARK,
  CREATE_BOOKMARK_SUCCESS,
  CREATE_BOOKMARK_ERROR,
  DELETE_BOOKMARK,
  DELETE_BOOKMARK_SUCCESS,
  DELETE_BOOKMARK_ERROR,
  CLEAN_BOOKMARK_ARTICLES
} from "constants/actionTypes"

const initialState = fromJS({
  bookmarkedArticles: [],
  statistics: { totalCount: 0 },
  firstFetched: false
})

let deletionBuffer = {}

const reducerMap = new Map([
  [
    CLEAN_BOOKMARK_ARTICLES,
    state =>
      state.set("firstFetched", false).set("bookmarkedArticles", fromJS([]))
  ],
  [
    REQUEST_BOOKMARKS_SUCCESS,
    (state, action) => {
      const lastBookmark = state.get("bookmarkedArticles").last()
      const lastId = lastBookmark
        ? lastBookmark.get("bookmarkId")
        : Number.MAX_SAFE_INTEGER
      const bookmarks = fromJS(
        action.payload.bookmarks
          .filter(bookmark => bookmark.id < lastId)
          .map(bookmark => ({
            bookmarkId: bookmark.id,
            articleId: bookmark.article.article_id
          }))
      )
      return state
        .set("firstFetched", true)
        .set(
          "bookmarkedArticles",
          state.get("bookmarkedArticles").concat(bookmarks)
        )
    }
  ],
  [
    REQUEST_BOOKMARKS_STATISTICS_SUCCESS,
    (state, action) => {
      const { count } = action.payload.statistics
      return state.setIn(["statistics", "totalCount"], count)
    }
  ],
  [
    CREATE_BOOKMARK,
    (state, action) => {
      const { articleId } = action.payload
      const bookmark = fromJS({ bookmarkId: null, articleId: articleId })
      const totalCount = state.getIn(["statistics", "totalCount"]) + 1
      return state.setIn(["statistics", "totalCount"], totalCount).set(
        "bookmarkedArticles",
        state
          .get("bookmarkedArticles")
          .filter(b => b.get("articleId") !== articleId)
          .unshift(bookmark)
      )
    }
  ],
  [
    CREATE_BOOKMARK_SUCCESS,
    (state, action) => {
      const { id: bookmarkId, article } = action.payload.bookmark
      const articleId = article.article_id
      const index = state
        .get("bookmarkedArticles")
        .findIndex(b => b.get("articleId") === articleId)
      return index < 0
        ? state
        : state.setIn(["bookmarkedArticles", index, "bookmarkId"], bookmarkId)
    }
  ],
  [
    CREATE_BOOKMARK_ERROR,
    (state, action) => {
      const totalCount = state.getIn(["statistics", "totalCount"]) - 1
      return state.setIn(["statistics", "totalCount"], totalCount).set(
        "bookmarkedArticles",
        state
          .get("bookmarkedArticles")
          .filter(b => b.get("articleId") !== action.payload.articleId)
      )
    }
  ],
  [
    DELETE_BOOKMARK,
    (state, action) => {
      const { articleId } = action.payload
      const deletedBookmark = state
        .get("bookmarkedArticles")
        .find(b => b.get("articleId") === articleId)
      if (!deletedBookmark) {
        return state
      }
      deletionBuffer[articleId] = deletedBookmark
      const totalCount = state.getIn(["statistics", "totalCount"]) - 1
      return state.setIn(["statistics", "totalCount"], totalCount).set(
        "bookmarkedArticles",
        state
          .get("bookmarkedArticles")
          .filter(b => b.get("articleId") !== articleId)
      )
    }
  ],
  [
    DELETE_BOOKMARK_SUCCESS,
    (state, action) => {
      delete deletionBuffer[action.payload.articleId]
      return state
    }
  ],
  [
    DELETE_BOOKMARK_ERROR,
    (state, action) => {
      const bookmark = deletionBuffer[action.payload.articleId]
      delete deletionBuffer[action.payload.articleId]
      const index = state
        .get("bookmarkedArticles")
        .findIndex(b => b.get("bookmarkId") < bookmark.get("bookmarkId"))
      const totalCount = state.getIn(["statistics", "totalCount"]) + 1
      return state
        .setIn(["statistics", "totalCount"], totalCount)
        .set(
          "bookmarkedArticles",
          state.get("bookmarkedArticles").insert(index, bookmark)
        )
    }
  ]
])

export default handleActions(reducerMap, initialState)
