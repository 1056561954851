import {
  REQUEST_BRAINTREE_PAYMENT_METHODS_SUCCESS,
  CHANGE_BRAINTREE_PAYMENT_METHODS_SUCCESS,
  CHANGE_BRAINTREE_PAYMENT_METHODS_ERROR
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const formPaymentMethod = method => {
  return fromJS({
    bin: method.bin,
    cardType: method.card_type,
    expired: method.expired,
    last4: method.last_4,
    maskedNumber: method.masked_number,
    expirationYear: method.expiration_year,
    expirationMonth: method.expiration_month,
    imageUrl: method.image_url
  })
}

const reduceMap = new Map([
  [
    REQUEST_BRAINTREE_PAYMENT_METHODS_SUCCESS,
    (state, action) => {
      const method = action.payload.paymentMethods[0]
      if (!method) {
        return fromJS({})
      }
      return formPaymentMethod(method)
    }
  ],
  [
    CHANGE_BRAINTREE_PAYMENT_METHODS_SUCCESS,
    (state, action) => {
      const method = action.payload.paymentMethod
      return formPaymentMethod(method)
    }
  ],
  [
    CHANGE_BRAINTREE_PAYMENT_METHODS_ERROR,
    (state, action) => {
      const code = action.payload.error.code
      return fromJS({
        code: code
      })
    }
  ]
])

export default handleActions(reduceMap, initialState)
