import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import {
  REQUEST_READING_PROGRESSES_SUCCESS,
  DELETE_READING_PROGRESS,
  DELETE_READING_PROGRESS_SUCCESS,
  DELETE_READING_PROGRESS_ERROR,
  DELETE_READING_PROGRESSES,
  DELETE_READING_PROGRESSES_SUCCESS,
  DELETE_READING_PROGRESSES_ERROR,
  REQUEST_ISSUE_READING_PROGRESSES_SUCCESS,
  RESET_READING_PROGRESSES_FETCHING_STATUS
} from "constants/actionTypes"

const initialState = fromJS({
  readingProgresses: [],
  fetched: false
})

let deletionBuffer = {}
let deletionBuffers = []

const readingProgressFormat = rp => {
  return {
    readingProgressId: rp.id,
    readFormat: rp.read_format,
    pageNumber: rp.page_number,
    articleId: rp.article_id,
    issueId: rp.issue_id,
    deviceId: rp.device_id,
    readAt: rp.read_at
  }
}

const reducerMap = new Map([
  [
    REQUEST_READING_PROGRESSES_SUCCESS,
    (state, action) => {
      const readingProgresses = action.payload.readingProgresses.map(
        readingProgressFormat
      )
      return state
        .set("fetched", true)
        .set("readingProgresses", fromJS(readingProgresses))
    }
  ],
  [
    DELETE_READING_PROGRESS,
    (state, action) => {
      const { issueId } = action.payload
      const deletedReadingProgress = state
        .get("readingProgresses")
        .find(rp => rp.get("issueId") === issueId)
      if (!deletedReadingProgress) {
        return state
      }
      deletionBuffer[issueId] = deletedReadingProgress
      return state.set(
        "readingProgresses",
        state
          .get("readingProgresses")
          .filter(rp => rp.get("issueId") !== issueId)
      )
    }
  ],
  [
    DELETE_READING_PROGRESS_SUCCESS,
    (state, action) => {
      delete deletionBuffer[action.payload.issueId]
      return state
    }
  ],
  [
    DELETE_READING_PROGRESS_ERROR,
    (state, action) => {
      const { issueId } = action.payload
      const readingProgress = deletionBuffer[issueId]
      delete deletionBuffer[issueId]
      const index = state
        .get("readingProgresses")
        .findIndex(
          r =>
            r.get("readingProgressId") ===
            readingProgress.get("readingProgressId")
        )

      if (index < 0) {
        return state.set(
          "readingProgresses",
          state.get("readingProgresses").push(readingProgress)
        )
      } else if (
        state.get("readingProgresses").getIn([index, "readAt"]) <
        readingProgress.get("readAt")
      ) {
        return state.set(
          "readingProgresses",
          state.get("readingProgresses").setIn([index], readingProgress)
        )
      }

      return state
    }
  ],
  [
    DELETE_READING_PROGRESSES,
    state => {
      deletionBuffers = state
      return state.set("readingProgresses", fromJS([]))
    }
  ],
  [
    DELETE_READING_PROGRESSES_SUCCESS,
    state => {
      deletionBuffers = []
      return state
    }
  ],
  [
    DELETE_READING_PROGRESSES_ERROR,
    state => {
      const readingProgresses = deletionBuffers
      deletionBuffers = []
      return state.set("readingProgresses", fromJS(readingProgresses))
    }
  ],
  [
    REQUEST_ISSUE_READING_PROGRESSES_SUCCESS,
    (state, action) => {
      const issueId = action.payload.issueId
      if (action.payload.readingProgresses.length === 0) {
        return state.set(
          "readingProgresses",
          state
            .get("readingProgresses")
            .filter(rp => rp.get("issueId") !== issueId)
        )
      }
      const newIssueReadingProgresses = action.payload.readingProgresses
        .map(readingProgressFormat)
        .sort((a, b) => b.readAt > a.readAt)
      const newIssueReadingProgress = newIssueReadingProgresses[0]
      const index = state
        .get("readingProgresses")
        .findIndex(rp => rp.get("issueId") === issueId)

      if (index < 0) {
        return state.set(
          "readingProgresses",
          state.get("readingProgresses").push(fromJS(newIssueReadingProgress))
        )
      } else if (
        state.get("readingProgresses").getIn([index, "readAt"]) <
        newIssueReadingProgress.readAt
      ) {
        return state.set(
          "readingProgresses",
          state
            .get("readingProgresses")
            .setIn([index], fromJS(newIssueReadingProgress))
        )
      }

      return state
    }
  ],
  [
    RESET_READING_PROGRESSES_FETCHING_STATUS,
    state => {
      return state.set("fetched", false)
    }
  ]
])

export default handleActions(reducerMap, initialState)
