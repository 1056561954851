import {
  DOWNLOAD_APP_LINK,
  DOWNLOAD_APP_DYNAMIC_LINK
} from "constants/appLinks"
import queryString from "query-string"

export const branchDownloadAppLink = () => {
  const { search } = window.location

  const {
    utm_source: utmSource,
    utm_campaign: utmCampaign,
    utm_medium: utmMedium
  } = queryString.parse(search)

  const channelQuery = `~channel=${utmSource}`
  const campaignQuery = `~campaign=${utmCampaign}`
  const featureQuery = `~feature=${utmMedium}`

  const query = [channelQuery, campaignQuery, featureQuery]
    .filter(string => !string.includes("undefined"))
    .join("&")

  return query ? DOWNLOAD_APP_DYNAMIC_LINK + "?" + query : DOWNLOAD_APP_LINK
}
