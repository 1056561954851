import { REQUEST_BANNER_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS([])

const formatBanner = banner => {
  return {
    id: banner.id,
    mainPosition: banner.main_position,
    actionType: banner.action_type,
    actionArg: banner.action_arg,
    utmCampaign: banner.utm_campaign,
    utmSource: banner.utm_source,
    utmMedium: banner.utm_medium,
    images: {
      desktop: banner.images.desktop,
      mobile: banner.images.mobile
    }
  }
}

const reducerMap = new Map([
  [
    REQUEST_BANNER_SUCCESS,
    (state, action) => {
      const { banners } = action.payload
      return fromJS(banners.map(formatBanner))
    }
  ]
])

export default handleActions(reducerMap, initialState)
