import {
  REQUEST_CATEGORY,
  REQUEST_CATEGORY_SUCCESS,
  REQUEST_CATEGORIES,
  REQUEST_CATEGORIES_SUCCESS
} from "constants/actionTypes"

export const onRequestCategory = (bookListId, categoryId, onError) => {
  return {
    type: REQUEST_CATEGORY,
    payload: { bookListId, categoryId, onError }
  }
}

export const onRequestCategorySuccess = category => {
  return {
    type: REQUEST_CATEGORY_SUCCESS,
    payload: { category }
  }
}

export const onRequestCategories = (bookListId, language) => {
  return {
    type: REQUEST_CATEGORIES,
    payload: { bookListId, language }
  }
}

export const onRequestCategoriesSuccess = categoryGroups => {
  return {
    type: REQUEST_CATEGORIES_SUCCESS,
    payload: { categoryGroups }
  }
}
