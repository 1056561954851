import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestCategoryLatestIssuesSuccess } from "actions/issues"
import { REQUEST_CATEGORY_LATEST_ISSUES } from "constants/actionTypes"
import FetchCategoryLatestIssuesApi from "apis/FetchCategoryLatestIssuesApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { CATEGORY_MAGAZINES_TTL } from "constants/ttl"

function* fetchCategoryLatestIssues(action) {
  try {
    const { bookListId, categoryId } = action.payload
    const isExisted = yield* isStoreExisted("categoryMagazines", categoryId)
    const isExpired = yield* isStoreExpired(
      CATEGORY_MAGAZINES_TTL,
      "categoryMagazines",
      categoryId
    )
    if (isExisted && !isExpired) {
      return
    }

    const fetchCategoryLatestIssuesApi = new FetchCategoryLatestIssuesApi({
      categoryId: categoryId
    })
    const response = yield call(fetchCategoryLatestIssuesApi.call, {
      bookListId
    })
    const issues = response.data
    yield put(onRequestCategoryLatestIssuesSuccess(categoryId, issues))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestCategoryLatestIssues() {
  yield takeEvery(REQUEST_CATEGORY_LATEST_ISSUES, fetchCategoryLatestIssues)
}
