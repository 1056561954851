import KonoApi from "../KonoApi"

class FetchBraintreePlansApi extends KonoApi {
  constructor(data) {
    const userId = data.userId ? `user_id=${data.userId}` : ""
    const promotionCode = data.promotionCode
      ? `promotion_code=${data.promotionCode}`
      : ""
    const params = promotionCode
      ? userId
        ? `${userId}&${promotionCode}`
        : promotionCode
      : userId
    const url = `/braintree_plans${params ? "?" + params : ""}`
    super(url)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchBraintreePlansApi
