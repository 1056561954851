import dictionary from "./assets/dictionary"

export function limitWords(string, limit = 75) {
  return typeof string === "string" ? string.slice(0, limit) : ""
}

export function concatTitle(string) {
  const suffix =
    process.env.REACT_APP_PRODUCT === "k4l"
      ? dictionary["K4L_SUFFIX"]
      : dictionary["SUFFIX"]
  return string ? string.concat(" - ", suffix) : suffix
}
