import {
  SET_VERSION,
  FORCE_UPDATE_VERSION_SUCCESS
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import Immutable from "immutable"

const initialState = Immutable.fromJS({ current: null })

const reducerMap = new Map([
  [
    SET_VERSION,
    (state, action) => {
      console.log("set version")
      return state.set("current", action.payload.version)
    }
  ],
  [
    FORCE_UPDATE_VERSION_SUCCESS,
    (state, action) => {
      console.log("force update version success")
      return state.set("current", action.payload.version)
    }
  ]
])

export default handleActions(reducerMap, initialState)
