import { put, takeEvery, call } from "redux-saga/effects"
import {
  REQUEST_TSTAR_SMS,
  BIND_TSTAR_SMS,
  EXIST_TSTAR_TOKEN,
  BIND_TSTAR_TOKEN
} from "constants/actionTypes"
import SendTstarSmsApi from "apis/SendTstarSmsApi"
import {
  onRequestTstarSmsSuccess,
  onRequestTstarSmsError,
  onBindTstarSmsSuccess,
  onBindTstarSmsError,
  onExistTstarTokenSuccess,
  onExistTstarTokenError,
  onBindTstarTokenSuccess,
  onBindTstarTokenError
} from "actions/tstar"
import { onLogTelecomBinding } from "actions/trackingEvents"
import BindTstarSmsApi from "apis/BindTstarSmsApi"
import TstarExistTokenApi from "apis/TstarExistTokenApi"
import TstarBindTokenApi from "apis/TstarBindTokenApi"
import { onStartLoading, onEndLoading } from "actions/loading"
import {
  BIND_TSTAR_SMS_LOADING,
  BIND_TSTAR_TOKEN_LOADING
} from "constants/loadingTypes"

function* sendTstarSms(action) {
  try {
    const sendTstarSmsApi = new SendTstarSmsApi()
    yield call(sendTstarSmsApi.call, {
      phoneNumber: action.payload.phoneNumber
    })
    yield put(onRequestTstarSmsSuccess())
  } catch (e) {
    yield put(
      onRequestTstarSmsError(e.response.status, e.response.data.remaining_time)
    )
  }
}

export function* watchRequestTstarSms() {
  yield takeEvery(REQUEST_TSTAR_SMS, sendTstarSms)
}

function* bindTstarSms(action) {
  try {
    yield put(onStartLoading(BIND_TSTAR_SMS_LOADING))
    const bindTstarSmsApi = new BindTstarSmsApi()
    yield call(bindTstarSmsApi.call, {
      confirmationCode: action.payload.confirmationCode
    })
    yield put(onEndLoading(BIND_TSTAR_SMS_LOADING))
    yield put(onBindTstarSmsSuccess())
    yield put(
      onLogTelecomBinding({ telecom: "tstar", via: "smsConfirmationCode" })
    )
  } catch (e) {
    yield put(onEndLoading(BIND_TSTAR_SMS_LOADING))
    yield put(onBindTstarSmsError(e.response.status))
  }
}

export function* watchBindTstarSms() {
  yield takeEvery(BIND_TSTAR_SMS, bindTstarSms)
}

function* existTstarToken(action) {
  try {
    const tstarExistTokenApi = new TstarExistTokenApi()
    yield call(tstarExistTokenApi.call, {
      token: action.payload.token
    })
    yield put(onExistTstarTokenSuccess())
  } catch (e) {
    yield put(onExistTstarTokenError(e.response))
  }
}

export function* watchExistTstarToken() {
  yield takeEvery(EXIST_TSTAR_TOKEN, existTstarToken)
}

function* bindTstarToken(action) {
  try {
    yield put(onStartLoading(BIND_TSTAR_TOKEN_LOADING))
    const tstarBindTokenApi = new TstarBindTokenApi()
    yield call(tstarBindTokenApi.call, {
      userId: action.payload.userId,
      token: action.payload.token
    })
    yield put(onEndLoading(BIND_TSTAR_TOKEN_LOADING))
    yield put(onBindTstarTokenSuccess())
    yield put(onLogTelecomBinding({ telecom: "tstar", via: "smsLinkToken" }))
  } catch (e) {
    yield put(onEndLoading(BIND_TSTAR_TOKEN_LOADING))
    yield put(onBindTstarTokenError(e.response))
  }
}

export function* watchBindTstarToken() {
  yield takeEvery(BIND_TSTAR_TOKEN, bindTstarToken)
}
