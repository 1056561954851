import React from "react"
import styled from "styled-components/macro"
import media from "utilities/media"
import icon from "./assets/images/icon.png"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`

const Icon = styled.div`
  background-image: url(${icon});
  background-repeat: no-repeat;
  background-size: cover;

  ${media.desktop`
    width: 22px;
    height: 22px;
    margin-right: 10px;
  `};
  ${media.tablet`
    width: 22px;
    height: 22px;
    margin-right: 10px;
  `};
  ${media.mobile`
    width: 20px;
    height: 20px;
    margin-right: 12px;
  `};
`

const Text = styled.div`
  color: #ff721a;
  ${media.desktop`
    font-size: 14px;
  `};
  ${media.tablet`
    font-size: 14px;
  `};
  ${media.mobile`
    font-size: 13px;
  `};
`

export default function ErrorMessage(props) {
  return (
    <Wrapper>
      {props.noIcon ? null : <Icon />}
      <Text>{props.children}</Text>
    </Wrapper>
  )
}
