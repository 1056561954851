import { COMPLETE_REGISTRATION, VIEW_ITEM, PURCHASE } from "constants/branch"
import { getMainImageSrc } from "utilities/articleText"

export function logBranchSignUp() {
  window.branch.logEvent(COMPLETE_REGISTRATION)
}

export function logBranchViewItem() {
  window.branch.logEvent(VIEW_ITEM)
}

export function logBranchPurchase(product) {
  const { currency, avgPrice } = product
  const discountCode = product.hasPromotionCodes
    ? product._braintreePlan
        .get("promotionCodes")
        .toJS()
        .map(code => code.id)
        .join()
    : ""

  const eventAndCustomData = {
    currency,
    revenue: avgPrice,
    coupon: discountCode
  }
  window.branch.logEvent(PURCHASE, eventAndCustomData)
}

export function isBranchInit(callback) {
  if (!window.branch) return
  window.branch.data(callback)
}

export function generateBranchLink(href, article, callback) {
  window.branch.link(
    {
      data: {
        $desktop_url: href,
        $og_title: article.get("title"),
        $og_description: article.get("intro"),
        $og_image_url: getMainImageSrc(article.get("id"), "large"),
        payload: "article",
        data: article.get("id")
      }
    },
    callback
  )
}

export function hasBranchKey() {
  return Boolean(process.env.REACT_APP_BRANCH_KEY)
}
