import React from "react"
import HeaderUI from "components/presentational/HeaderUI"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { withTranslation } from "react-i18next"
import styled from "styled-components/macro"
import { k4lSummitHref } from "utilities/customByApp"

const SplitLine = styled.div`
  height: 20px;
  width: 0px;
  border-left: 1px solid;
  border-color: ${props => (props.dark ? "#656565" : "#c7c7c7")};
  margin: ${props => props.margin || ""};
`

function CategoryLink(props) {
  const location = useLocation()
  const href =
    parseInt(props.categoryId) === 249
      ? k4lSummitHref
      : `/categories/${props.categoryId}`
  const isFocus = location.pathname === href ? 1 : 0

  const categories = useSelector(state => state.get("categories"))
  const category =
    categories &&
    categories.find(
      category => category.get("id") === parseInt(props.categoryId)
    )

  return category ? (
    <React.Fragment>
      {!props.onlyWorldwide ? (
        <SplitLine dark={props.dark} margin="0 10.5px" />
      ) : null}
      <HeaderUI.LinkWrapper
        dark={props.dark}
        to={href}
        focus={isFocus}
        wide="true"
        onClick={props.onClick}
      >
        <HeaderUI.NavigationsText dark={props.dark}>
          {props.t(`category:${category.get("dictKey")}`)}
        </HeaderUI.NavigationsText>
      </HeaderUI.LinkWrapper>
    </React.Fragment>
  ) : null
}

export default withTranslation("category")(CategoryLink)
