import KonoApi from "../KonoApi"

class TstarExistTokenApi extends KonoApi {
  constructor() {
    super(`/tstar_orders/find`)
  }

  call(params) {
    return this.axios.get(this.url, {
      params: {
        tstar_token: params.token
      }
    })
  }
}

export default TstarExistTokenApi
