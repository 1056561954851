import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestCurrentFreeIssueSuccess } from "actions/freeIssues"
import { REQUEST_CURRENT_FREE_ISSUE } from "constants/actionTypes"
import FetchCurrentFreeIssueApi from "apis/FetchCurrentFreeIssueApi"
import FetchIssueApi from "apis/FetchIssueApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { FREE_ISSUES_TTL } from "constants/ttl"

function* fetchCurrentFreeIssue(action) {
  try {
    const isExisted = yield* isStoreExisted("freeIssues", "current")
    const isExpired = yield* isStoreExpired(
      FREE_ISSUES_TTL,
      "freeIssues",
      "current"
    )
    if (isExisted && !isExpired) {
      return
    }
    const fetchCurrentFreeIssueApi = new FetchCurrentFreeIssueApi()
    const response = yield call(fetchCurrentFreeIssueApi.call)
    const freeIssue = response.data
    const fetchIssueApi = new FetchIssueApi({
      issueId: freeIssue.magazine.bid
    })
    yield call(fetchIssueApi.call)
    yield put(onRequestCurrentFreeIssueSuccess(freeIssue))
  } catch (e) {
    const { onError } = action.payload
    onError ? onError(e) : console.log(e)
  }
}

export function* watchRequestCurrentFreeIssue() {
  yield takeEvery(REQUEST_CURRENT_FREE_ISSUE, fetchCurrentFreeIssue)
}
