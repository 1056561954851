import { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { onRequestClientInfo } from "actions/clientInfo"
import { recordUserActivity } from "actions/userActivity"
import { onRequestTranslations } from "actions/translations"
import { detectWebP } from "actions/webP"
import { onForceUpdateVersion, onSetVersion } from "actions/version"
import { onSetBookListId } from "actions/apiSettings"
import version from "version.json"
import { compareVersion } from "utilities/version"
import { trySetApiBase } from "utilities/sandbox"
import { onAmpInitUserProperties } from "actions/trackingEventsV2"

class InitialApis extends Component {
  constructor() {
    super()
    this.minVersion = process.env.REACT_APP_MINIMUM_VERSION
  }

  componentDidMount() {
    trySetApiBase()
    this.checkVersion()
    this.props.onRequestClientInfo()
    this.props.onRequestTranslations()
    if (this.props.currentUser)
      this.props.recordUserActivity(this.props.currentUser.get("id"))

    if (!this.props.detectedWebP) this.props.detectWebP()

    if (
      this.props.k4lCurrentLibrary &&
      this.props.currentBookListId !==
        this.props.k4lCurrentLibrary.get("bookListId")
    )
      this.props.onSetBookListId(this.props.k4lCurrentLibrary.get("bookListId"))

    this.initUserProperties()
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentState !== prevProps.currentState) {
      this.checkVersion()
    }
    if (
      this.props.k4lCurrentLibrary &&
      this.props.k4lCurrentLibrary !== prevProps.k4lCurrentLibrary
    )
      this.props.onSetBookListId(this.props.k4lCurrentLibrary.get("bookListId"))
  }

  initUserProperties() {
    if (!this.props.currentUser) return

    this.props.onAmpInitUserProperties()
  }

  checkVersion() {
    if (this.props.currentVersion) {
      if (compareVersion(this.props.currentVersion, this.minVersion) < 0) {
        console.log("version lower than minVersion")
        this.props.onForceUpdateVersion(this.minVersion)
      }
    } else {
      console.log("no current version")
      this.props.onSetVersion(version.version)
    }
  }

  render() {
    return null
  }
}

const mapStateToProps = state => ({
  currentUser: state.getIn(["user", "current"]),
  detectedWebP: state.getIn(["webP", "detected"]),
  currentVersion: state.getIn(["version", "current"]),
  currentState: state.getIn(["state", "current"]),
  currentBookListId: state.getIn(["apiSettings", "bookListId"]),
  k4lCurrentLibrary: state.getIn(["k4lLibraries", "current"])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onRequestClientInfo,
      detectWebP,
      recordUserActivity,
      onForceUpdateVersion,
      onSetVersion,
      onSetBookListId,
      onRequestTranslations,
      onAmpInitUserProperties
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(InitialApis)
