import React from "react"
import styled from "styled-components/macro"
import loading from "./assets/images/kono-loading.gif"

const LoadingAnime = styled.img`
  width: 60px;
  height: 60px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  background: rgba(256, 256, 256, 0.8);
  z-index: 5566;
`

export default function Loading(props) {
  return props.display ? (
    <LoadingWrapper>
      <LoadingAnime src={loading} alt="loading" />
    </LoadingWrapper>
  ) : null
}
