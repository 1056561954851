import axios from "axios"
import KonoApi from "../KonoApi"

class RemindPasswordApi extends KonoApi {
  constructor() {
    super(`/users/remind_password`)
  }

  call(params) {
    return axios.post(this.url, { account: params.account })
  }
}

export default RemindPasswordApi
