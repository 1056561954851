import axios from "axios"
import KonoApi from "../KonoApi"

class SearchPlatformsApi extends KonoApi {
  constructor() {
    super(`/users/search_platforms`)
  }

  call(email) {
    return axios.post(this.url, {
      email
    })
  }
}

export default SearchPlatformsApi
