import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { onRequestCategories } from "actions/categories"
import { connect } from "react-redux"
import { withBookListId } from "utilities/withBookListId"
import SideTabComponent from "components/container/SideTabComponent"
import DropDownButton from "components/presentational/DropDownButton"
import { Link } from "react-router-dom"
import SmoothCollapse from "react-smooth-collapse"
import { withTranslation } from "react-i18next"
import styled from "styled-components/macro"

const SideTabRowStaticItem = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  color: #222222;
  height: 48px;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 16px;
`

class SideTabLibraryLink extends Component {
  constructor() {
    super()
    this.state = {
      isToggle: false
    }
    this.handleToggle = this.handleToggle.bind(this)
  }

  componentDidMount() {
    this.props.onRequestCategories(this.props.currentBookListId)
  }

  handleToggle() {
    this.setState(prevState => ({
      isToggle: !prevState.isToggle
    }))
  }

  get libraryHref() {
    return `/libraries/${this.props.libraryName}`
  }

  categoryHref(category) {
    return `/categories/${category.get("id")}`
  }

  get libraryName() {
    return this.props.t(`library:${this.props.libraryName}`)
  }

  renderCategories() {
    const categories =
      this.props.libraries
        .find(
          library =>
            typeof library.get === "function" &&
            library.get("id") === this.props.libraryName
        )
        ?.get("categories") || []
    return categories.map(category => (
      <Link key={category.get("id")} to={this.categoryHref(category)}>
        <SideTabComponent.SideTabSubMenuItem>
          {this.props.t(`category:${category.get("dictKey")}`)}
        </SideTabComponent.SideTabSubMenuItem>
      </Link>
    ))
  }

  render() {
    return (
      <div>
        <SideTabComponent.SideTabRow onClick={this.handleToggle}>
          <SideTabRowStaticItem>{this.libraryName}</SideTabRowStaticItem>
          <DropDownButton
            size="48px"
            mode="normal"
            isToggle={this.state.isToggle}
          />
        </SideTabComponent.SideTabRow>
        <SmoothCollapse expanded={this.state.isToggle}>
          <SideTabComponent.SideTabSubMenuWrapper>
            <Link to={this.libraryHref}>
              <SideTabComponent.SideTabSubMenuItem>
                {this.props.t("ALL")}
              </SideTabComponent.SideTabSubMenuItem>
            </Link>
            {this.renderCategories()}
          </SideTabComponent.SideTabSubMenuWrapper>
        </SmoothCollapse>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  libraries: state.get("libraries")
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onRequestCategories }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation(["container", "category", "library"])(
    withBookListId(SideTabLibraryLink)
  )
)
