export const HEADER_POPULAR_MAGAZINE = "HEADER_POPULAR_MAGAZINE"
export const HEADER_HK_MAGAZINE = "HEADER_HK_MAGAZINE"
export const HEADER_REDEEM = "HEADER_REDEEM"
export const HEADER_SUBSCRIBE = "HEADER_SUBSCRIBE"
export const HEADER_GIFTING = "HEADER_GIFTING"
export const HEADER_PROFILE_MY_FOLLOW = "HEADER_PROFILE_MY_FOLLOW"
export const HEADER_PROFILE_BOOKMARK = "HEADER_PROFILE_BOOKMARK"
export const HEADER_PROFILE_RECENT_READ = "HEADER_PROFILE_RECENT_READ"
export const HEADER_PROFILE_SUBSCRIBE = "HEADER_PROFILE_SUBSCRIBE"
export const HEADER_PROFILE_GIFTING = "HEADER_PROFILE_GIFTING"
export const HEADER_PROFILE_MEMBERSHIP = "HEADER_PROFILE_MEMBERSHIP"
export const HEADER_PROFILE_LOGOUT = "HEADER_PROFILE_LOGOUT"
