import { handleActions } from "redux-actions"
import {
  START_LOADING,
  END_LOADING,
  SHOW_LOADING,
  HIDE_LOADING,
  CHANGE_LOADING_SUCCESS
} from "constants/actionTypes"
import Immutable from "immutable"

const initialState = Immutable.fromJS({
  loading: false,
  changeLoading: false
})

const reducerMap = new Map([
  [
    START_LOADING,
    (state, action) => {
      const name = action.payload.name
      name.forEach(n => {
        state = state.set(n, true)
      })
      return state
    }
  ],
  [
    END_LOADING,
    (state, action) => {
      const name = action.payload.name
      name.forEach(n => {
        state = state.set(n, false)
      })
      return state
    }
  ],
  [
    SHOW_LOADING,
    state => {
      return state.set("loading", true).set("changeLoading", true)
    }
  ],
  [
    HIDE_LOADING,
    state => state.set("loading", false).set("changeLoading", true)
  ],
  [CHANGE_LOADING_SUCCESS, state => state.set("changeLoading", false)]
])

export default handleActions(reducerMap, initialState)
