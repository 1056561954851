import {
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  INIT_RESET_PASSWORD
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({
  success: false,
  error: null
})

const reducerMap = new Map([
  [
    RESET_PASSWORD_SUCCESS,
    state => {
      return state.set("success", true).set("error", null)
    }
  ],
  [
    RESET_PASSWORD_ERROR,
    (state, action) => {
      return state.set("success", false).set("error", action.payload.reason)
    }
  ],
  [
    INIT_RESET_PASSWORD,
    () => {
      return initialState
    }
  ]
])

const resetPassword = handleActions(reducerMap, initialState)
export default resetPassword
