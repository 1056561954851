import KonoApi from "../KonoApi"

class FetchBookmarkedArticlesApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/bookmarks`)
  }

  call(before, limit) {
    let params = { limit }
    if (before) {
      params.before = before
    }
    return this.axios.get(this.url, {
      params
    })
  }
}

export default FetchBookmarkedArticlesApi
