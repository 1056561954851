import {
  REQUEST_CURATION_POST_SUCCESS,
  REQUEST_CURATION_CHANNEL_POSTS_SUCCESS
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const formatCurationPost = curationPost => {
  return {
    id: curationPost.id,
    curationChannelId: curationPost.curation_channel_id,
    title: curationPost.title,
    body: curationPost.body,
    content: curationPost.content,
    dominantColor: curationPost.dominant_color,
    mainImage: curationPost.main_image,
    createdAt: curationPost.available_from || curationPost.created_at
  }
}

const arrayReducer = (acc, cur) => {
  acc[cur.id] = formatCurationPost(cur)
  return acc
}

const reducerMap = new Map([
  [
    REQUEST_CURATION_POST_SUCCESS,
    (state, action) => {
      const curationPost = formatCurationPost(action.payload.curationPost)
      return state.setIn([curationPost.id], fromJS(curationPost))
    }
  ],
  [
    REQUEST_CURATION_CHANNEL_POSTS_SUCCESS,
    (state, action) => {
      return state.merge(
        fromJS(action.payload.curationChannelPosts.reduce(arrayReducer, {}))
      )
    }
  ]
])

export default handleActions(reducerMap, initialState)
