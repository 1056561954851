import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import { REQUEST_ISSUE_THUMBNAILS_SUCCESS } from "constants/actionTypes"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    REQUEST_ISSUE_THUMBNAILS_SUCCESS,
    (state, action) => {
      const issueId = action.payload.issueId
      const thumbnails = fromJS(
        action.payload.thumbnails.map(thumbnail => {
          return { url: thumbnail.url, pageNumber: thumbnail.page_number }
        })
      )
      return state.set(issueId, thumbnails)
    }
  ]
])

export default handleActions(reducerMap, initialState)
