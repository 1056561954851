import KonoApi from "../KonoApi"

class FetchMagazineYearIssuesApi extends KonoApi {
  constructor(data) {
    super(`/titles/${data.magazineId}/years/${data.year}/magazines`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchMagazineYearIssuesApi
