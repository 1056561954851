export const HOME_STATE = "HOME_STATE"
export const WEEKLY_ISSUE_STATE = "WEEKLY_ISSUE_STATE"
export const MY_FOLLOW_STATE = "MY_FOLLOW_STATE"
export const MY_COLLECT_STATE = "MY_COLLECT_STATE"
export const LIBRARY_STATE = "LIBRARY_STATE"
export const DOWNLOAD_APP_STATE = "DOWNLOAD_APP_STATE"
export const REDEEM_STATE = "REDEEM_STATE"
export const GIFTING_WELCOME_STATE = "GIFTING_WELCOME_STATE"
export const GIFTING_PLANS_STATE = "GIFTING_PLANS_STATE"
export const LOGIN_STATE = "LOGIN_STATE"
export const MEMBERSHIP_STATE = "MEMBERSHIP_STATE"
export const AUTHENTICATION_STATE = "AUTHENTICATION_STATE"
export const SINGLE_MAGAZINE = "SINGLE_MAGAZINE"
export const SINGLE_ISSUE = "SINGLE_ISSUE"
export const SINGLE_ARTICLE = "SINGLE_ARTICLE"
export const ABOUT = "ABOUT"
export const JOBS = "JOBS"
export const NEWS = "NEWS"
export const CONTACT_US = "CONTACT_US"
export const KONO_MAGAZINE_INTRO = "KONO_MAGAZINE_INTRO"
export const KONO_BUSINESS_SOLUTIONS_INTRO = "KONO_BUSINESS_SOLUTIONS_INTRO"
export const SINGLE_CATEGORY = "SINGLE_CATEGORY"
export const SEARCH = "SEARCH"
export const LANDING_STATE = "LANDING_STATE"
export const CURATION_STATE = "CURATION_STATE"
export const CURATION_POST_STATE = "CURATION_POST_STATE"
export const SIGN_IN_STATE = "SIGN_IN_STATE"
export const SIGN_UP_STATE = "SIGN_UP_STATE"
export const DASHBOARD_STATE = "DASHBOARD_STATE"
export const OOBE_STATE = "OOBE_STATE"
export const REMIND_PASSWORD_STATE = "REMIND_PASSWORD_STATE"
export const RESET_PASSWORD_STATE = "RESET_PASSWORD_STATE"
export const CHANGE_PASSWORD_STATE = "CHANGE_PASSWORD_STATE"
export const T_STAR_INTRO_STATE = "T_STAR_INTRO_STATE"
export const T_STAR_BINDING_STATE = "T_STAR_BINDING_STATE"
export const TELECOM_INTRO_STATE = "TELECOM_INTRO_STATE"
export const TELECOM_BINDING_STATE = "TELECOM_BINDING_STATE"
export const PAGE = "PAGE"
export const THANK_YOU_STATE = "THANK_YOU_STATE"
export const CREDIT_CARD_PLANS_STATE = "CREDIT_CARD_PLANS_STATE"
export const COUPON_CODE_STATE = "COUPON_CODE_STATE"
export const SMARTONE_SIGN_UP_STATE = "SMARTONE_SIGN_UP_STATE"
export const SMS_APTG_BINDING = "SMS_APTG_BINDING"
export const SMS_TSTAR_BINDING = "SMS_TSTAR_BINDING"
export const CANCEL_VIP_STATE = "CANCEL_VIP_STATE"
export const SUBSCRIPTION_MANAGEMENT_STATE = "SUBSCRIPTION_MANAGEMENT_STATE"
export const RECOMMEND_STATE = "RECOMMEND_STATE"
export const NOT_FOUND_STATE = "NOT_FOUND_STATE"
export const PRIVACY_STATE = "PRIVACY_STATE"
export const POLICY_STATE = "POLICY_STATE"
export const K4L_LIBRARY_LIST_STATE = "K4L_LIBRARY_LIST_STATE"
export const FAQ_STATE = "FAQ_STATE"
export const READING_PROGRESS_STATE = "READING_PROGRESS_STATE"
export const MAGAZINE_LIST_STATE = "MAGAZINE_LIST_STATE"
export const K4L_LIBRARY_REGION_STATE = "K4L_LIBRARY_REGION_STATE"
