export const CATEGORIES_TTL = 3600
export const CATEGORY_MAGAZINES_TTL = 300
export const LATEST_ISSUES_TTL = 300
export const BANNERS_TTL = 120
export const FOLLOWED_MAGAZINES_TTL = 300
export const MAGAZINE_TTL = 300
export const MAGAZINE_YEARS_TTL = 300
export const MAGAZINE_YEAR_ISSUES_TTL = 300
export const ISSUE_TTL = 300
export const MAGAZINE_ISSUES_TTL = 300
export const ISSUE_THUMBNAILS_TTL = 86400
export const ISSUE_ARTICLES_TTL = 86400
export const ARTICLE_TTL = 300
export const ALL_MAGAZINES_TTL = 3600
export const POPULAR_ARTICLES_TTL = 86400
export const FREE_ISSUES_TTL = 300
export const CURATION_POST_TTL = 300
export const CURATION_CHANNEL_TTL = 300
export const CURATION_CHANNEL_POST_TTL = 300
export const RECOMMEND_ARTICLES_TTL = 86400
export const POPULAR_MAGAZINES_TTL = 3600
export const IBON_PLANS_TTL = 3600
export const GIFTING_PLANS_TTL = 300
export const SENT_GIFTS_TTL = 120
export const FREE_ARTICLE_QUOTA_TTL = 86400
export const BRAINTREE_PAYMENT_METHODS_TTL = 300
export const CLIENT_INFO_TTL = 10800
export const USER_ACTIVITY_TTL = 3600
export const BRAINTREE_TTL = 86400
export const VIP_PERIODS_TTL = 86400
export const ARTICLE_ACCESSS_TOKEN_TTL = 5
export const K4L_ALL_LIBRARIES_TTL = 60
export const READING_PROGRESS_PROMPTS_TTL = 79200
export const TRANSLATIONS_TTL = 86400
export const SUBSCRIPTION_INFO_TTL = 86400
export const NEWS_TTL = 300
export const ABOUT_TTL = 3600
export const MAGAZINE_LIST_TTL = 86400
export const INTRO_TTL = 300
