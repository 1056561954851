import styled from "styled-components/macro"
import media from "utilities/media"

const TableContent = styled.div`
  width: 100%;
  line-height: 1.5em;
  color: #222222;
  ${media.desktop`
    font-size: 14px;
  `};
  ${media.tablet`
    font-size: 12px;
  `};
  ${media.mobile`
    font-size: 13px;
  `};
`

export default TableContent
