import {
  REQUEST_ARTICLE,
  REQUEST_ARTICLE_SUCCESS,
  REQUEST_SEARCH_ARTICLES,
  REQUEST_SEARCH_ARTICLES_SUCCESS,
  REQUEST_BOOKMARKED_ARTICLES_SUCCESS,
  REQUEST_POPULAR_ARTICLES,
  REQUEST_POPULAR_ARTICLES_SUCCESS,
  REQUEST_RECOMMEND_ARTICLES,
  REQUEST_RECOMMEND_ARTICLES_SUCCESS,
  REQUEST_ARTICLE_ACCESS_TOKEN,
  REQUEST_ARTICLE_ACCESS_TOKEN_SUCCESS,
  REQUEST_ARTICLE_ACCESS_TOKEN_ERROR,
  REQUEST_ARTICLE_TEXT,
  REQUEST_ARTICLE_TEXT_SUCCESS,
  REQUEST_ARTICLE_TEXT_V2_SUCCESS,
  REQUEST_ARTICLE_TEXT_ERROR,
  REQUEST_ARTICLE_RELEVANT_ARTICLES,
  REQUEST_ARTICLE_RELEVANT_ARTICLES_SUCCESS
} from "constants/actionTypes"

export const onRequestArticle = (articleId, onError) => {
  return {
    type: REQUEST_ARTICLE,
    payload: { articleId, onError }
  }
}

export const onRequestArticleSuccess = article => {
  return {
    type: REQUEST_ARTICLE_SUCCESS,
    payload: { article }
  }
}

export const onRequestSearchArticles = (bookListId, q) => {
  return {
    type: REQUEST_SEARCH_ARTICLES,
    payload: { bookListId, q }
  }
}

export const onRequestSearchArticlesSuccess = (result, sortType) => {
  return {
    type: REQUEST_SEARCH_ARTICLES_SUCCESS,
    payload: { result, sortType }
  }
}

export const onRequestBookmarkedArticlesSuccess = bookmarks => {
  return {
    type: REQUEST_BOOKMARKED_ARTICLES_SUCCESS,
    payload: { bookmarks }
  }
}

export const onRequestPopularArticles = () => {
  return {
    type: REQUEST_POPULAR_ARTICLES
  }
}

export const onRequestPopularArticlesSuccess = popularArticles => {
  return {
    type: REQUEST_POPULAR_ARTICLES_SUCCESS,
    payload: { popularArticles }
  }
}

export const onRequestRecommendArticles = () => {
  return {
    type: REQUEST_RECOMMEND_ARTICLES
  }
}

export const onRequestRecommendArticlesSuccess = recommendArticles => {
  return {
    type: REQUEST_RECOMMEND_ARTICLES_SUCCESS,
    payload: { recommendArticles }
  }
}

export const onRequestArticleAccessToken = articleId => {
  return {
    type: REQUEST_ARTICLE_ACCESS_TOKEN,
    payload: { articleId }
  }
}

export const onRequestArticleAccessTokenSuccess = (
  articleId,
  accessToken,
  expiredAt
) => {
  return {
    type: REQUEST_ARTICLE_ACCESS_TOKEN_SUCCESS,
    payload: { articleId, accessToken, expiredAt }
  }
}

export const onRequestArticleAccessTokenError = articleId => {
  return {
    type: REQUEST_ARTICLE_ACCESS_TOKEN_ERROR,
    payload: { articleId }
  }
}

export const onRequestArticleText = (articleId, accessToken) => {
  return {
    type: REQUEST_ARTICLE_TEXT,
    payload: { articleId, accessToken }
  }
}

export const onRequestArticleTextSuccess = (articleId, text) => {
  return {
    type: REQUEST_ARTICLE_TEXT_SUCCESS,
    payload: { articleId, text }
  }
}

export const onRequestArticleTextV2Success = (articleId, text) => {
  return {
    type: REQUEST_ARTICLE_TEXT_V2_SUCCESS,
    payload: { articleId, text }
  }
}

export const onRequestArticleTextError = articleId => {
  return {
    type: REQUEST_ARTICLE_TEXT_ERROR,
    payload: { articleId }
  }
}

export const onRequestArticleRelevantArticles = (articleId, userId) => {
  return {
    type: REQUEST_ARTICLE_RELEVANT_ARTICLES,
    payload: { articleId, userId }
  }
}

export const onRequestArticleRelevantArticlesSuccess = (
  articleId,
  magazineIds,
  relevantArticles
) => {
  return {
    type: REQUEST_ARTICLE_RELEVANT_ARTICLES_SUCCESS,
    payload: { articleId, magazineIds, relevantArticles }
  }
}
