import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { onRequestCategories } from "actions/categories"
import HeaderUI from "components/presentational/HeaderUI"
import DownloadAppLink from "../DownloadAppLink"
import CategoryLink from "../CategoryLink"
import LibraryLink from "../LibraryLink"
import { withMql } from "utilities/decorators"
import { withBookListId } from "utilities/withBookListId"
import styled from "styled-components/macro"
import media from "utilities/media"

const BottomRowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;

  ${media.desktop`
    margin: 0 30px 10px;
  `};

  ${media.tablet`
    margin: 0 4px 5px;
  `};

  ${media.mobile`
    margin: 0 4px 5px;
  `};
`

const SplitLine = styled.div`
  height: 20px;
  width: 0px;
  border-left: 1px solid;
  border-color: ${props => (props.dark ? "#656565" : "#c7c7c7")};
  margin: ${props => props.margin || ""};
`

class BottomHeader extends Component {
  componentDidMount() {
    this.props.onRequestCategories(this.props.currentBookListId)
  }

  renderHighlightCategory() {
    const highlightCategory = ["249"]

    return highlightCategory.map(id => (
      <CategoryLink
        key={id}
        dark={this.props.dark}
        categoryId={id}
        onlyWorldwide={this.onlyWorldwide}
      />
    ))
  }

  renderDownloadAppLink() {
    return (
      <React.Fragment>
        {!this.props.isMobile() ? (
          <SplitLine dark={this.props.dark} margin="0 10.5px" />
        ) : null}
        <DownloadAppLink dark={this.props.dark} />
      </React.Fragment>
    )
  }

  get onlyWorldwide() {
    return (
      this.props.libraries.size === 1 &&
      this.props.libraries.find(library => library.get("id") === "worldwide")
    )
  }

  render() {
    return (
      <HeaderUI.NavigationsWrapper>
        <BottomRowWrapper>
          <HeaderUI.NavigationsRow>
            {!this.onlyWorldwide &&
            (this.props.isDesktop() || this.props.isTablet())
              ? this.props.libraries.map(library =>
                  typeof library.get === "function" ? (
                    <LibraryLink
                      dark={this.props.dark}
                      libraryName={library.get("id")}
                      key={library.get("id")}
                    />
                  ) : null
                )
              : null}
            {(this.onlyWorldwide && !this.props.isMobile()) ||
            this.props.isDesktop() ||
            this.props.isTablet()
              ? this.renderHighlightCategory()
              : null}
            {this.props.isDesktop() || this.props.isMobile()
              ? this.renderDownloadAppLink()
              : null}
          </HeaderUI.NavigationsRow>
        </BottomRowWrapper>
      </HeaderUI.NavigationsWrapper>
    )
  }
}

const mapStateToProps = state => ({
  libraries: state.get("libraries")
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onRequestCategories }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMql(withBookListId(BottomHeader)))
