import React, { useState, useCallback } from "react"
import SignUI from "components/presentational/SignUI"
import eyeIcon from "./assets/images/eye.svg"
import slashEyeIcon from "./assets/images/slash-eye.svg"
import styled, { css } from "styled-components/macro"
import { withTranslation } from "react-i18next"

export const SquareInputCss = css`
  position: relative;
  width: 100%;
  height: 48px;
  font-size: 14px;
  color: ${props => (props.disabled ? "#919191" : "#222222")};
  border: ${props => (props.error ? "solid 1px #fd7121" : "solid 1px #c7c7c7")};
  border-radius: 8px;
  padding-left: 20px;

  &::placeholder {
    color: #919191;
    padding-top: 1px;
  }

  &:focus {
    border: ${props =>
      props.error ? "solid 1px #fd7121" : "solid 1px #00a270"};
  }
`

export const SquareInputNameCss = css`
  position: absolute;
  background-color: ${props => (props.blank ? "" : "#ffffff")};
  font-size: 12px;
  line-height: 1.42;
  padding: 0 10px;
  top: -8.5px;
  left: 10px;
  height: 9.5px;

  font-weight: ${props =>
    props.error ? "normal" : props.focus ? "600" : "normal"};
  color: ${props =>
    props.error ? "#f15700" : props.focus ? "#008159" : "#222222"};
`

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`

const Input = styled.input`
  ${SquareInputCss};
`

const InputName = styled.div`
  ${SquareInputNameCss};
`

const Eye = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
  background-size: 48px 48px;
  background-image: url(${props => props.src});
  right: 5px;
  top: 0;
  bottom: 0;
  cursor: pointer;
`

const FunctionText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 12px;
  color: #008159;
  font-weight: 500;
  text-decoration: underline;
  right: 20px;
  top: 0;
  bottom: 0;
  cursor: pointer;
`

const bool = param => (param ? 1 : 0)

function SquareInput(props) {
  const {
    regretClick,
    error,
    word,
    eye,
    regret,
    type,
    autoFocus,
    errorMessage,
    showErrorMessage,
    wordFocus = true,
    ...rest
  } = props

  const [focus, setFocus] = useState(false)
  const [showInput, setShowInput] = useState(false)

  const onFocus = useCallback(() => {
    setFocus(true)
  }, [setFocus])

  const onBlur = useCallback(() => {
    setFocus(false)
  }, [setFocus])

  const onChangeDisplay = useCallback(() => {
    setShowInput(showInput => !showInput)
  }, [setShowInput])

  const inputEyeType = showInput ? "text" : "password"

  const onClickRegret = useCallback(() => {
    if (typeof regretClick === "function") regretClick()
  }, [regretClick])

  return (
    <React.Fragment>
      <InputWrapper>
        <Input
          {...rest}
          error={error}
          onFocus={onFocus}
          onBlur={onBlur}
          autoFocus={bool(autoFocus)}
          type={eye ? inputEyeType : type}
        />
        {word ? (
          <InputName
            focus={bool(focus) && wordFocus}
            error={bool(error) && wordFocus}
          >
            {word}
          </InputName>
        ) : null}
        {eye ? (
          <Eye
            src={showInput ? slashEyeIcon : eyeIcon}
            onClick={onChangeDisplay}
          />
        ) : null}
        {regret ? (
          <FunctionText onClick={onClickRegret}>
            {props.t("MODIFY")}
          </FunctionText>
        ) : null}
      </InputWrapper>
      <SignUI.ErrorMessage
        errorMessage={errorMessage}
        error={showErrorMessage}
      />
    </React.Fragment>
  )
}

export default withTranslation("presentational")(SquareInput)
