import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestGiftingPlansSuccess } from "actions/giftingPlans"
import { REQUEST_GIFTING_PLAN } from "constants/actionTypes"
import FetchGiftingPlansApi from "apis/FetchGiftingPlansApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { GIFTING_PLANS_TTL } from "constants/ttl"

function* fetchGiftingPlans(action) {
  try {
    const isExisted = yield* isStoreExisted("giftingPlans")
    const isExpired = yield* isStoreExpired(GIFTING_PLANS_TTL, "giftingPlans")
    if (isExisted && !isExpired) {
      return
    }
    const fetchGiftingPlansApi = new FetchGiftingPlansApi()
    const response = yield call(fetchGiftingPlansApi.call)
    const giftingPlans = response.data
    yield put(onRequestGiftingPlansSuccess(giftingPlans))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestGiftingPlans() {
  yield takeEvery(REQUEST_GIFTING_PLAN, fetchGiftingPlans)
}
