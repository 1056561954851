import { put, takeEvery, call } from "redux-saga/effects"
import {
  REQUEST_APTG_SMS,
  BIND_APTG_SMS,
  EXIST_APTG_TOKEN,
  BIND_APTG_TOKEN
} from "constants/actionTypes"
import {
  onRequestAptgSmsSuccess,
  onRequestAptgSmsError,
  onBindAptgSmsSuccess,
  onBindAptgSmsError,
  onExistAptgTokenSuccess,
  onExistAptgTokenError,
  onBindAptgTokenSuccess,
  onBindAptgTokenError
} from "actions/aptg"
import { onLogTelecomBinding } from "actions/trackingEvents"
import AptgSendSmsApi from "apis/AptgSendSmsApi"
import AptgBindSmsApi from "apis/AptgBindSmsApi"
import AptgExistTokenApi from "apis/AptgExistTokenApi"
import AptgBindTokenApi from "apis/AptgBindTokenApi"
import { onStartLoading, onEndLoading } from "actions/loading"
import {
  BIND_APTG_SMS_LOADING,
  BIND_APTG_TOKEN_LOADING
} from "constants/loadingTypes"

function* sendAptgSms(action) {
  try {
    const aptgSendSmsApi = new AptgSendSmsApi()
    yield call(aptgSendSmsApi.call, {
      phoneNumber: action.payload.phoneNumber
    })
    yield put(onRequestAptgSmsSuccess())
  } catch (e) {
    yield put(
      onRequestAptgSmsError(e.response.status, e.response.data.remaining_time)
    )
  }
}

export function* watchRequestAptgSms() {
  yield takeEvery(REQUEST_APTG_SMS, sendAptgSms)
}

function* bindAptgSms(action) {
  try {
    yield put(onStartLoading(BIND_APTG_SMS_LOADING))
    const aptgBindSmsApi = new AptgBindSmsApi()
    yield call(aptgBindSmsApi.call, {
      confirmationCode: action.payload.confirmationCode
    })
    yield put(onEndLoading(BIND_APTG_SMS_LOADING))
    yield put(onBindAptgSmsSuccess())
    yield put(
      onLogTelecomBinding({ telecom: "aptg", via: "smsConfirmationCode" })
    )
  } catch (e) {
    yield put(onEndLoading(BIND_APTG_SMS_LOADING))
    yield put(onBindAptgSmsError(e.response.status))
  }
}

export function* watchBindAptgSms() {
  yield takeEvery(BIND_APTG_SMS, bindAptgSms)
}

function* existAptgToken(action) {
  try {
    const aptgExistTokenApi = new AptgExistTokenApi()
    yield call(aptgExistTokenApi.call, {
      token: action.payload.token
    })
    yield put(onExistAptgTokenSuccess())
  } catch (e) {
    yield put(onExistAptgTokenError(e.response))
  }
}

export function* watchExistAptgToken() {
  yield takeEvery(EXIST_APTG_TOKEN, existAptgToken)
}

function* bindAptgToken(action) {
  try {
    yield put(onStartLoading(BIND_APTG_TOKEN_LOADING))
    const aptgBindTokenApi = new AptgBindTokenApi()
    yield call(aptgBindTokenApi.call, {
      userId: action.payload.userId,
      token: action.payload.token
    })
    yield put(onEndLoading(BIND_APTG_TOKEN_LOADING))
    yield put(onBindAptgTokenSuccess())
    yield put(onLogTelecomBinding({ telecom: "aptg", via: "smsLinkToken" }))
  } catch (e) {
    yield put(onEndLoading(BIND_APTG_TOKEN_LOADING))
    yield put(onBindAptgTokenError(e.response))
  }
}

export function* watchBindAptgToken() {
  yield takeEvery(BIND_APTG_TOKEN, bindAptgToken)
}
