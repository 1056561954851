import axios from "axios"
import KonoApi from "../KonoApi"

class SignUpSmartoneApi extends KonoApi {
  constructor() {
    super(`/users`)
  }

  call(params) {
    return axios.post(this.url, {
      platform: params.platform,
      account: params.account,
      validator: params.validator,
      smartone_id: params.smartone_id,
      ...params.utmParams
    })
  }
}

export default SignUpSmartoneApi
