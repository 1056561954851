import {
  REQUEST_FREE_ARTICLE_QUOTA,
  REQUEST_FREE_ARTICLE_QUOTA_SUCCESS
} from "constants/actionTypes"

export const onRequestFreeArticleQuota = userId => {
  return {
    type: REQUEST_FREE_ARTICLE_QUOTA,
    payload: { userId }
  }
}

export const onRequestFreeArticleQuotaSuccess = quota => {
  return {
    type: REQUEST_FREE_ARTICLE_QUOTA_SUCCESS,
    payload: { quota }
  }
}
