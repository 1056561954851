import KonoApi from "../KonoApi"

class TemporaryLoginApi extends KonoApi {
  constructor() {
    super(`/login_tokens/login`)
  }

  call(params) {
    return this.axios.post(this.url, {
      login_token: params.loginToken
    })
  }
}

export default TemporaryLoginApi
