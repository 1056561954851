import KonoApi from "../KonoApi"

class FetchHamiReadCountApi extends KonoApi {
  constructor(data) {
    super(`/hami_members/article_reads/count?user_id=${data.userId}`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchHamiReadCountApi
