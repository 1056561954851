import { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { onLogOut } from "actions/authentication"
import { onSetCurrentLibraryById } from "actions/k4lLibraries"

class InformationInUrl extends Component {
  componentDidMount() {
    this.init()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.init()
    }
  }

  init() {
    const k4lLibraryId = this.props.match.params.k4lLibraryId
    if (k4lLibraryId) {
      if (!this.isConsistLibraryId()) {
        this.props.k4lCurrentLibrary
          ? this.props.onLogOut()
          : this.props.onSetCurrentLibraryById(k4lLibraryId)
      }
    } else {
      if (this.props.k4lCurrentLibrary) {
        const id = this.props.k4lCurrentLibrary.get("id")
        this.props.history.replace(`/${id}${this.props.location.pathname}`)
      } else {
        this.props.history.replace("/")
      }
    }
  }

  isConsistLibraryId() {
    return (
      this.props.k4lCurrentLibrary &&
      this.props.k4lCurrentLibrary.get("id") ===
        this.props.match.params.k4lLibraryId
    )
  }

  render() {
    return this.isConsistLibraryId()
      ? this.props.render({ ...this.props })
      : null
  }
}

const mapStateToProps = state => ({
  k4lCurrentLibrary: state.getIn(["k4lLibraries", "current"])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onSetCurrentLibraryById, onLogOut }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InformationInUrl))
