import axios from "axios"
import KonoApi from "../KonoApi"

class SignUpApi extends KonoApi {
  constructor() {
    super(`/users`)
  }

  call(params) {
    return axios.post(this.url, {
      platform: params.platform,
      account: params.account,
      validator: params.validator,
      referrer_token: params.referrerToken,
      ...params.utmParams
    })
  }
}

export default SignUpApi
