import Mutex from "utilities/Mutex"

let mutexes = {}

function getMutex(key) {
  mutexes[key] = mutexes[key] || Mutex.create()
  return mutexes[key]
}

function removeMutex(key) {
  delete mutexes[key]
}

const ApiLock = {
  getLock: key => getMutex(key),
  acquireLock: (key, timeout) => {
    return getMutex(key)
      .lock(timeout)
      .then(lock => {
        return {
          release: () => {
            lock.release()
            if (!getMutex(key).isLocked()) removeMutex(key)
          }
        }
      })
  }
}

export default ApiLock

export function withLock(func, returnIfLocked = false, timeout = 5000) {
  return function*(action) {
    const lockKey = `${func.name}-${JSON.stringify(action.payload)}`
    if (returnIfLocked && ApiLock.getLock(lockKey).isLocked()) {
      return
    }
    const lock = yield ApiLock.acquireLock(lockKey, timeout)
    yield* func(action)
    lock.release()
  }
}
