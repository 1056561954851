import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({
  pastDueChecked: false
})

const reducerMap = new Map([

])

const generalBlacklist = handleActions(reducerMap, initialState)
export default generalBlacklist
