import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import styled from "styled-components/macro"
import { onChangeStateVia } from "actions/state"
import { VIA_BREADCRUMB, VIA_HEADER } from "constants/stateVia"
import { onOpenHeaderMenu, onCloseHeaderMenu } from "actions/header"
import media from "utilities/media"
import DeviceSearchBar from "components/container/DeviceSearchBar"
import TopHeader from "./components/TopHeader"
import BottomHeader from "./components/BottomHeader"
import OneRowHeader from "./components/OneRowHeader"
import Breadcrumb from "components/presentational/Breadcrumb"
import LogoRow from "./components/LogoRow"

const HeaderWrapper = styled.div`
  width: 100%;
  position: ${props => (props.dark ? "fixed" : "sticky")};
  top: 0px;
  left: 0px;
  z-index: 8888;
  border-bottom: ${props =>
    props.dark ? "1px solid #3d3d3d" : "1px solid #e6e6e6"};
  ${media.desktop`
    top: ${props => (props.dark && !props.isOpen ? "-144px" : "0px")};
    transition: top 0.5s ease-in-out;
  `};
`

const HeadWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: ${props => (props.dark ? "#2c2c2c" : "#ffffff")};

  ${media.desktop`
    height: ${props => (props.oneRow ? "70px" : "114px")};
  `}

  ${media.tablet`
    height: ${props => (props.oneRow ? "70px" : "90px")};
  `}

  ${media.mobile`
    height: ${props => (props.oneRow ? "70px" : "90px")};
  `}
`

const AppendWrapper = styled.div`
  position: absolute;
  overflow: visible;
  bottom: -42px;
  margin: 10px 0 0 10px;
  width: 128px;
`

class K4lHeader extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.currentState !== prevProps.currentState ||
      this.props.currentParams !== prevProps.currentParams
    )
      this.props.onCloseHeaderMenu()
  }

  renderBreadcrumb() {
    return this.props.breadcrumb ? (
      <Breadcrumb
        maplist={this.props.breadcrumb}
        dark={this.props.dark}
        onLogClick={() => this.props.onChangeStateVia(VIA_BREADCRUMB)}
      />
    ) : null
  }

  renderAppend() {
    return this.props.append ? (
      <AppendWrapper> {this.props.append}</AppendWrapper>
    ) : null
  }

  render() {
    return this.props.appViewShowHeader ? (
      <HeaderWrapper dark={this.props.dark} isOpen={this.props.isOpen}>
        <HeadWrapper
          dark={this.props.dark}
          onClick={() => this.props.onChangeStateVia(VIA_HEADER)}
          oneRow={!this.props.k4lCurrentLibrary}
        >
          {this.props.k4lCurrentLibrary ? (
            <React.Fragment>
              <TopHeader dark={this.props.dark} />
              <BottomHeader dark={this.props.dark} />
            </React.Fragment>
          ) : (
            <OneRowHeader isLanding={this.props.isLanding} />
          )}
          <DeviceSearchBar />
        </HeadWrapper>
        {this.renderBreadcrumb()}
        {this.renderAppend()}
      </HeaderWrapper>
    ) : (
      <HeaderWrapper dark={this.props.dark} isOpen={this.props.isOpen}>
        <HeadWrapper
          dark={this.props.dark}
          oneRow={!this.props.k4lCurrentLibrary}
        >
          <LogoRow />
        </HeadWrapper>
      </HeaderWrapper>
    )
  }
}

const mapStateToProps = state => ({
  currentState: state.getIn(["state", "current"]),
  currentParams: state.getIn(["state", "currentParams"]),
  isOpen: state.getIn(["header", "isOpen"]),
  isLoggedIn: state.getIn(["authentication", "isLoggedIn"]),
  k4lCurrentLibrary: state.getIn(["k4lLibraries", "current"]),
  appViewShowHeader: state.getIn(["general", "appView", "showHeader"])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { onChangeStateVia, onOpenHeaderMenu, onCloseHeaderMenu },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(K4lHeader)
