import { createStore, applyMiddleware } from "redux"
import { autoRehydrate } from "redux-persist-immutable"
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction"
import createSagaMiddleware from "redux-saga"
import reducer from "../reducers"
import rootSaga from "../sagas"

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = composeWithDevTools(
  applyMiddleware(sagaMiddleware),
  autoRehydrate()
)
const store = createStore(reducer, composeEnhancers)

sagaMiddleware.run(rootSaga)

export default store
