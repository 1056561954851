import KonoApi from "../KonoApi"

class FetchCurationChannelPostsApi extends KonoApi {
  constructor(data) {
    super(
      `/curation_channels/${data.curationChannelId}/curation_posts?limit=20`
    )
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchCurationChannelPostsApi
