import styled from "styled-components/macro"
import { Link } from "react-router-dom"

const InternalLink = styled(Link)`
  text-decoration: ${props => (props.underline ? "underline" : "none")};
  color: inherit;

  &:visited {
    text-decoration: none;
    color: inherit;
  }
`

export default InternalLink
