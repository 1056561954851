import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import { REQUEST_ISSUE_ARTICLES_SUCCESS } from "constants/actionTypes"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    REQUEST_ISSUE_ARTICLES_SUCCESS,
    (state, action) => {
      const issueId = action.payload.issueId
      const articles = fromJS(
        action.payload.articles.map(article => {
          return {
            id: article.article_id,
            sequence: article.sequence,
            isHighlight: article.is_highlight,
            startPageNumber: article.page_number,
            endPageNumber: article.end_page,
            hasFitReading: article.has_fit_reading,
            hasPdf: article.has_pdf
          }
        })
      )
      return state.set(issueId, articles)
    }
  ]
])

export default handleActions(reducerMap, initialState)
