import KonoApi from "../KonoApi"

class FetchReadingProgressesApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/reading_progresses`)
  }

  call(params) {
    return this.axios.get(this.url, { params })
  }
}

export default FetchReadingProgressesApi
