import { REQUEST_MAGAZINE_LIST_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import camelcaseKeys from "camelcase-keys"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    REQUEST_MAGAZINE_LIST_SUCCESS,
    (state, action) => {
      const { magazineList } = action.payload
      return fromJS(camelcaseKeys(magazineList, { deep: true }))
    }
  ]
])

export default handleActions(reducerMap, initialState)
