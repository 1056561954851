import axios from "axios"

class FetchTranslationsApi {
  call() {
    const translationsUrl =
      "https://d3xwm9x3ez74b.cloudfront.net/locales/web/translations.json"

    return axios.get(translationsUrl)
  }
}

export default FetchTranslationsApi
