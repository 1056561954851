import { put, throttle, call } from "redux-saga/effects"
import { onRequestClientInfoSuccess } from "actions/clientInfo"
import { REQUEST_CLIENT_INFO } from "constants/actionTypes"
import FetchClientInfoApi from "apis/FetchClientInfoApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { CLIENT_INFO_TTL } from "constants/ttl"

function* fetchClientInfo() {
  try {
    const isExisted = yield* isStoreExisted("clientInfo")
    const isExpired = yield* isStoreExpired(CLIENT_INFO_TTL, "clientInfo")
    if (isExisted && !isExpired) {
      return
    }

    const fetchClientInfoApi = new FetchClientInfoApi()
    const response = yield call(fetchClientInfoApi.call)
    const clientInfo = response.data
    yield put(onRequestClientInfoSuccess(clientInfo))
  } catch (e) {
    console.log(e)
  }
}

export function* watchClientInfo() {
  yield throttle(1000, REQUEST_CLIENT_INFO, fetchClientInfo)
}
