import {
  LOG_IN,
  LOG_OUT,
  CLEAR_STATE,
  LOG_IN_SUCCESS,
  LOG_IN_FAIL,
  CLEAR_AUTHENTICATION_ERROR,
  SIGN_UP,
  SIGN_UP_SMARTONE,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,
  REQUEST_USER_PLATFORMS,
  REQUEST_USER_PLATFORMS_SUCCESS,
  CLEAR_USER_PLATFORMS,
  REMIND_PASSWORD,
  REMIND_PASSWORD_SUCCESS,
  REMIND_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  INIT_REMIND_PASSWORD,
  INIT_RESET_PASSWORD,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  REQUEST_USER_INFO,
  REQUEST_USER_INFO_SUCCESS,
  REQUEST_WECHAT_ACCESS_TOKEN,
  REQUEST_WECHAT_ACCESS_TOKEN_FINISH,
  WECHAT_LOG_IN,
  K4L_LOG_IN,
  K4L_SIGN_UP,
  REQUEST_USER_PICTURE,
  REQUEST_USER_PICTURE_SUCCESS,
  TEMPORARY_LOGIN,
  TEMPORARY_LOGIN_SUCCESS,
  TEMPORARY_LOGIN_FAIL
} from "constants/actionTypes"

export const onLogIn = params => ({
  type: LOG_IN,
  payload: params
})

export const onLogOut = () => ({
  type: LOG_OUT
})

export const onClearState = () => ({
  type: CLEAR_STATE
})

export const onLogInSuccess = user => ({
  type: LOG_IN_SUCCESS,
  payload: user
})

export const onLogInFail = reason => ({
  type: LOG_IN_FAIL,
  payload: { reason }
})

export const onClearAuthenticationError = () => ({
  type: CLEAR_AUTHENTICATION_ERROR
})

export const onSignUp = params => ({
  type: SIGN_UP,
  payload: params
})

export const onSignUpSmartone = params => ({
  type: SIGN_UP_SMARTONE,
  payload: params
})

export const onSignUpSuccess = (user, goSignIn = true) => ({
  type: SIGN_UP_SUCCESS,
  payload: { ...user, goSignIn }
})

export const onSignUpFail = reason => ({
  type: SIGN_UP_FAIL,
  payload: { reason }
})

export const onSearchPlatforms = email => ({
  type: REQUEST_USER_PLATFORMS,
  payload: { email }
})

export const onSearchPlatformsSuccess = platforms => ({
  type: REQUEST_USER_PLATFORMS_SUCCESS,
  payload: { platforms }
})

export const onClearUserPlatforms = () => ({
  type: CLEAR_USER_PLATFORMS
})

export const onRemindPassword = (account, platform = "") => ({
  type: REMIND_PASSWORD,
  payload: { account, platform }
})

export const onRemindPasswordSuccess = () => ({
  type: REMIND_PASSWORD_SUCCESS
})

export const onRemindPasswordError = reason => ({
  type: REMIND_PASSWORD_ERROR,
  payload: { reason }
})

export const onResetPassword = (token, password) => ({
  type: RESET_PASSWORD,
  payload: { token, password }
})

export const onResetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS
})

export const onResetPasswordError = reason => ({
  type: RESET_PASSWORD_ERROR,
  payload: { reason }
})

export const onInitRemindPassword = () => ({
  type: INIT_REMIND_PASSWORD
})

export const onInitResetPassword = () => ({
  type: INIT_RESET_PASSWORD
})

export const onUpdateUser = params => ({
  type: UPDATE_USER,
  payload: params
})

export const onUpdateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user
})

export const onRequestUserInfo = userId => ({
  type: REQUEST_USER_INFO,
  payload: { userId }
})

export const onRequestUserInfoSuccess = user => ({
  type: REQUEST_USER_INFO_SUCCESS,
  payload: user
})

export const onRequestWechatAccessToken = params => ({
  type: REQUEST_WECHAT_ACCESS_TOKEN,
  payload: params
})

export const onRequestWechatAccessTokenFinish = params => ({
  type: REQUEST_WECHAT_ACCESS_TOKEN_FINISH,
  payload: params
})

export const onWechatLogIn = params => ({
  type: WECHAT_LOG_IN,
  payload: params
})

export const onK4lLogIn = params => ({
  type: K4L_LOG_IN,
  payload: params
})

export const onK4lSignUp = (params, goSignIn = true) => ({
  type: K4L_SIGN_UP,
  payload: { ...params, goSignIn }
})

export const onRequestUserPicture = (userId, accessToken) => ({
  type: REQUEST_USER_PICTURE,
  payload: { userId, accessToken }
})

export const onRequestUserPictureSuccess = url => ({
  type: REQUEST_USER_PICTURE_SUCCESS,
  payload: { url }
})

export const onTemporaryLogin = loginToken => ({
  type: TEMPORARY_LOGIN,
  payload: { loginToken }
})

export const onTemporaryLoginSuccess = user => ({
  type: TEMPORARY_LOGIN_SUCCESS,
  payload: { user }
})

export const onTemporaryLoginFail = errorCode => ({
  type: TEMPORARY_LOGIN_FAIL,
  payload: { errorCode }
})
