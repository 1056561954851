import styled from "styled-components/macro"
import media from "utilities/media"

const Checkbox = styled.div`
  width: 18px;
  height: 18px;
  position: relative;
  border: 1px solid #222222;
  cursor: pointer;

  ${media.tablet`
      width: 16px;
      height: 16px;
  `};

  ${media.mobile`
      width: 16px;
      height: 16px;
  `};

  &::before {
    content: "✔";
    display: ${props => (props.selected ? "block" : "none")};
    width: 12px;
    height: 12px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -30%);
  }
`

export default Checkbox
