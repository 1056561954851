const Mutex = {
  create: () => {
    let currentLocking = Promise.resolve()
    let count = 0

    return {
      isLocked: () => count > 0,
      lock: timeout => {
        let release, timeoutId, willLock, willRelease
        count += 1

        willLock = new Promise(resolve => {
          release = () => {
            count -= 1
            resolve()
            if (timeoutId) clearTimeout(timeoutId)
          }
        })

        willRelease = currentLocking.then(() => ({ release }))

        currentLocking = currentLocking.then(() => {
          if (timeout) timeoutId = setTimeout(() => release(), timeout)
          return willLock
        })

        return willRelease
      }
    }
  }
}

export default Mutex
