import { UPDATE_REFER_TOKEN } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import Immutable from "immutable"

const initialState = Immutable.fromJS({})

const reducerMap = new Map([
  [
    UPDATE_REFER_TOKEN,
    (state, action) => {
      const referrerToken = action.payload.token
      return state.set("referrerToken", referrerToken)
    }
  ]
])

export default handleActions(reducerMap, initialState)
