import React, { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { onChangeStateVia } from "actions/state"
import { onCloseSideTabMenu } from "actions/sideTabMenu"
import { VIA_SIDEBAR } from "constants/stateVia"
import { signInOnlyType } from "constants/k4l"
import SideTabAuthDiv from "./components/SideTabAuthDiv"
import SideTabProfileDiv from "./components/SideTabProfileDiv"
import SideTabNavigationsDiv from "./components/SideTabNavigationsDiv"
import SideTabLanguageSelector from "./components/SideTabLanguageSelector"
import SideTabComponent from "components/container/SideTabComponent"
import SideTabSummitLink from "./components/SideTabSummitLink"

export default function K4lSideTabMenu(props) {
  const isLoggedIn = useSelector(state =>
    state.getIn(["authentication", "isLoggedIn"])
  )

  const isSideTabMenuOpen = useSelector(state =>
    state.getIn(["general", "sideTabMenu", "isOpen"])
  )

  const validatorType = useSelector(state =>
    state.getIn(["k4lLibraries", "current", "validatorType"])
  )
  const hasSignUp = !signInOnlyType.includes(validatorType)

  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    dispatch(onChangeStateVia(VIA_SIDEBAR))
  }, [dispatch])

  return (
    <React.Fragment>
      <SideTabComponent.SideTabMenuWrapper
        onClick={onClick}
        isOpen={isSideTabMenuOpen}
        s={SideTabComponent.second}
      >
        <SideTabComponent.SideTabCloseButton />
        {isLoggedIn ? <SideTabProfileDiv /> : null}
        <SideTabNavigationsDiv />
        <SideTabSummitLink />
        {isLoggedIn ? null : <SideTabAuthDiv hasSignUp={hasSignUp} />}
        <SideTabLanguageSelector />
      </SideTabComponent.SideTabMenuWrapper>
      <SideTabComponent.Overlay
        show={isSideTabMenuOpen}
        onClick={() => dispatch(onCloseSideTabMenu())}
        s={SideTabComponent.second}
      />
    </React.Fragment>
  )
}
