import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestFreeArticleQuotaSuccess } from "actions/freeArticleQuota"
import { onStartLoading, onEndLoading } from "actions/loading"
import { REQUEST_FREE_ARTICLE_QUOTA } from "constants/actionTypes"
import { FREE_ARTICLE_QUOTA_TTL } from "constants/ttl"
import { REQUEST_FREE_ARTICLE_QUOTA_LOADING } from "constants/loadingTypes"
import {
  isStoreExisted,
  isStoreExpired,
  selectStore
} from "utilities/storeCheck"
import FetchFreeArticleQuotaApi from "apis/FetchFreeArticleQuotaApi"

function* fetchFreeArticleQuota(action) {
  try {
    yield put(onStartLoading(REQUEST_FREE_ARTICLE_QUOTA_LOADING))
    const userId = action.payload.userId
    const fetchFreeArticleQuotaApi = new FetchFreeArticleQuotaApi({ userId })
    const response = yield call(fetchFreeArticleQuotaApi.call)
    const quota = response.data
    yield put(onRequestFreeArticleQuotaSuccess(quota))
    yield put(onEndLoading(REQUEST_FREE_ARTICLE_QUOTA_LOADING))
  } catch (e) {
    console.log(e)
    yield put(onEndLoading(REQUEST_FREE_ARTICLE_QUOTA_LOADING))
  }
}

export function* watchRequestFreeArticleQuota() {
  yield takeEvery(REQUEST_FREE_ARTICLE_QUOTA, fetchFreeArticleQuota)
}

export function* fetchAndReturnFreeArticleQuota() {
  try {
    let freeArticleQuota = {}
    const isExisted = yield* isStoreExisted("freeArticleQuota")
    const isExpired = yield* isStoreExpired(
      FREE_ARTICLE_QUOTA_TTL,
      "freeArticleQuota"
    )
    if (isExisted && !isExpired) {
      freeArticleQuota = yield selectStore("freeArticleQuota")
      freeArticleQuota = freeArticleQuota.toJS()
    } else {
      const userId = yield selectStore("user", "current", "id")
      if (!userId) return
      const fetchFreeArticleQuotaApi = new FetchFreeArticleQuotaApi({ userId })
      const response = yield call(fetchFreeArticleQuotaApi.call)
      freeArticleQuota = response.data
      yield put(onRequestFreeArticleQuotaSuccess(freeArticleQuota))
    }
    return freeArticleQuota
  } catch (e) {
    console.log(e)
  }
}
