import KonoApi from "../KonoApi"

class FetchLatestIssuesApi extends KonoApi {
  constructor() {
    super(`/magazines/current_issues`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchLatestIssuesApi
