import { REQUEST_RECOMMENDED_MAGAZINES_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS([])

const reducerMap = new Map([
  [
    REQUEST_RECOMMENDED_MAGAZINES_SUCCESS,
    (_state, action) =>
      fromJS(
        action.payload.magazines
          .filter(magazine => magazine.available)
          .map(magazine => magazine.title)
      )
  ]
])

export default handleActions(reducerMap, initialState)
