import KonoApi from "../KonoApi"

class FetchAllMagazinesApi extends KonoApi {
  constructor(data) {
    super(`/book_lists/${data.bookListId}/titles`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchAllMagazinesApi
