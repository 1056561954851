import {
  REQUEST_IBON_PLANS,
  REQUEST_IBON_PLANS_SUCCESS
} from "constants/actionTypes"

export const onRequestIbonPlans = () => {
  return {
    type: REQUEST_IBON_PLANS
  }
}

export const onRequestIbonPlansSuccess = ibonPlans => {
  return {
    type: REQUEST_IBON_PLANS_SUCCESS,
    payload: { ibonPlans }
  }
}
