import {
  CHANGE_SEARCH_KEY,
  REQUEST_SEARCH_ARTICLES,
  REQUEST_SEARCH_ARTICLES_SUCCESS
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({
  key: "",
  searching: false
})

const reducerMap = new Map([
  [
    CHANGE_SEARCH_KEY,
    (state, action) => {
      return state
        .set("prevKey", state.get("key"))
        .set("key", action.payload.key)
    }
  ],
  [
    REQUEST_SEARCH_ARTICLES,
    state => {
      return state.set("searching", true)
    }
  ],
  [
    REQUEST_SEARCH_ARTICLES_SUCCESS,
    (state, action) => {
      return state.set("searching", false)
    }
  ]
])

export default handleActions(reducerMap, initialState)
