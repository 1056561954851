import KonoApi from "../KonoApi"

class DeleteBookmarkApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/bookmarks`)
    this.articleIds = [data.articleId]
  }

  call() {
    return this.axios.delete(this.url, {
      data: { article_ids: this.articleIds }
    })
  }
}

export default DeleteBookmarkApi
