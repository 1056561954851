import {
  REQUEST_APTG_SMS,
  REQUEST_APTG_SMS_ERROR,
  REQUEST_APTG_SMS_SUCCESS,
  CLEAR_APTG_SMS_SENDER,
  DECREASE_APTG_SMS_TIME,
  BIND_APTG_SMS,
  BIND_APTG_SMS_SUCCESS,
  CLEAR_APTG_SMS_BINDER,
  BIND_APTG_SMS_ERROR,
  EXIST_APTG_TOKEN,
  EXIST_APTG_TOKEN_SUCCESS,
  EXIST_APTG_TOKEN_ERROR,
  CLEAR_APTG_TOKEN,
  BIND_APTG_TOKEN,
  BIND_APTG_TOKEN_SUCCESS,
  BIND_APTG_TOKEN_ERROR
} from "constants/actionTypes"

export const onRequestAptgSms = phoneNumber => ({
  type: REQUEST_APTG_SMS,
  payload: { phoneNumber }
})

export const onRequestAptgSmsSuccess = () => ({
  type: REQUEST_APTG_SMS_SUCCESS
})

export const onRequestAptgSmsError = (code, cdTime) => ({
  type: REQUEST_APTG_SMS_ERROR,
  payload: { code, cdTime }
})

export const onClearSmsSender = () => ({
  type: CLEAR_APTG_SMS_SENDER
})

export const onDecreaseSmsTime = () => ({
  type: DECREASE_APTG_SMS_TIME
})

export const onBindAptgSms = confirmationCode => ({
  type: BIND_APTG_SMS,
  payload: { confirmationCode }
})

export const onBindAptgSmsSuccess = () => ({
  type: BIND_APTG_SMS_SUCCESS
})

export const onBindAptgSmsError = error => ({
  type: BIND_APTG_SMS_ERROR,
  payload: { code: error }
})

export const onClearSmsBinder = () => ({
  type: CLEAR_APTG_SMS_BINDER
})

export const onExistAptgToken = token => ({
  type: EXIST_APTG_TOKEN,
  payload: { token }
})

export const onExistAptgTokenSuccess = () => ({
  type: EXIST_APTG_TOKEN_SUCCESS
})

export const onExistAptgTokenError = error => ({
  type: EXIST_APTG_TOKEN_ERROR,
  payload: { code: error.data.code, status: error.status }
})

export const onClearAptgToken = () => ({
  type: CLEAR_APTG_TOKEN
})

export const onBindAptgToken = (userId, token) => ({
  type: BIND_APTG_TOKEN,
  payload: { userId, token }
})

export const onBindAptgTokenSuccess = () => ({
  type: BIND_APTG_TOKEN_SUCCESS
})

export const onBindAptgTokenError = error => ({
  type: BIND_APTG_TOKEN_ERROR,
  payload: { code: error.data.code, status: error.status }
})
