import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { onToggleDeviceSearchBar } from "actions/deviceSearchBar"
import closeIcon from "./assets/images/close.svg"
import SearchBar from "components/container/SearchBar"
import { withTranslation } from "react-i18next"
import styled from "styled-components/macro"
import media from "utilities/media"

const DeviceSearchBarWrapper = styled.div`
  width: 100vw;
  height: 46px;
  background-color: #ffffff;
  position: absolute;
  top: ${props => (props.open ? "0px" : "-46px")};
  left: 0px;
  transition: top 0.3s ease-out;

  ${media.desktop`display: none;`};
  ${media.tablet`display: flex;`};
  ${media.mobile`display: flex;`};
`

const DeviceSearchBarContent = styled.div`
  display: flex;
  flex-grow: 1;
  height: 46px;
  padding-left: 6px;
  align-items: center;
`

const CloseBtnIcon = styled.img.attrs({
  src: closeIcon,
  alt: "close"
})`
  width: 46px;
  height: 46px;
  padding: 0 10px;
`

class DeviceSearchBar extends Component {
  render() {
    return (
      <DeviceSearchBarWrapper open={this.props.isDeviceSearchBarOpen}>
        <DeviceSearchBarContent>
          <SearchBar />
        </DeviceSearchBarContent>
        <CloseBtnIcon onClick={this.props.onToggleDeviceSearchBar} />
      </DeviceSearchBarWrapper>
    )
  }
}

const mapStateToProps = state => ({
  isDeviceSearchBarOpen: state.getIn(["general", "deviceSearchBar", "isOpen"])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onToggleDeviceSearchBar }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("container")(DeviceSearchBar))
