import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestRecommendationTreeSuccess } from "actions/recommendationTree"
import FetchRecommendationTreeApi from "apis/FetchRecommendationTreeApi"
import { REQUEST_RECOMMENDATION_TREE } from "constants/actionTypes"

function* fetchRecommendationTree(action) {
  try {
    const fetchRecommendationTreeApi = new FetchRecommendationTreeApi()
    const response = yield call(fetchRecommendationTreeApi.call)
    yield put(onRequestRecommendationTreeSuccess(response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRecommendationTree() {
  yield takeEvery(REQUEST_RECOMMENDATION_TREE, fetchRecommendationTree)
}
