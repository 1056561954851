import { DETECT_WEBP_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({
  lossy: false,
  lossless: false,
  alpha: false,
  animation: false,
  acceptWebP: false,
  detected: false
})

const reducerMap = new Map([
  [
    DETECT_WEBP_SUCCESS,
    (state, action) => fromJS(action.payload.result).set("detected", true)
  ]
])

export default handleActions(reducerMap, initialState)
