import {
  OPEN_HEADER,
  CLOSE_HEADER,
  OPEN_HEADER_MENU,
  CLOSE_HEADER_MENU
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import Immutable from "immutable"

const initialState = Immutable.fromJS({
  isOpen: true,
  currentOpenMenu: null
})

const reducerMap = new Map([
  [
    OPEN_HEADER,
    state => {
      return state.set("isOpen", true)
    }
  ],
  [
    CLOSE_HEADER,
    state => {
      return state.set("isOpen", false)
    }
  ],
  [
    OPEN_HEADER_MENU,
    (state, action) => {
      return state.set("currentOpenMenu", action.payload.menu)
    }
  ],
  [
    CLOSE_HEADER_MENU,
    state => {
      return state.set("currentOpenMenu", null)
    }
  ]
])

export default handleActions(reducerMap, initialState)
