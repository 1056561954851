import { put, takeEvery, call } from "redux-saga/effects"
import {
  onRequestIssueSuccess,
  onRequestIssueThumbnailsSuccess,
  onRequestIssueArticlesSuccess
} from "actions/issues"
import {
  REQUEST_ISSUE,
  REQUEST_ISSUE_THUMBNAILS,
  REQUEST_ISSUE_ARTICLES
} from "constants/actionTypes"
import FetchIssueApi from "apis/FetchIssueApi"
import FetchIssueThumbnailsApi from "apis/FetchIssueThumbnailsApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { ISSUE_TTL, ISSUE_THUMBNAILS_TTL } from "constants/ttl"
import FetchIssueArticlesApi from "apis/FetchIssueArticlesApi"
import { withLock } from "utilities/ApiLock"

function* fetchIssue(action) {
  const { issueId } = action.payload

  try {
    const isExisted = yield* isStoreExisted("issues", issueId)
    const isExpired = yield* isStoreExpired(ISSUE_TTL, "issues", issueId)
    if (isExisted && !isExpired) {
      return
    }
    const response = yield call(new FetchIssueApi({ issueId }).call)
    yield put(onRequestIssueSuccess(response.data))
  } catch (e) {
    const { onError } = action.payload
    onError ? onError(e) : console.log(e)
  }
}

export function* watchRequestIssue() {
  yield takeEvery(REQUEST_ISSUE, withLock(fetchIssue))
}

function* fetchIssueThumbnails(action) {
  const { issueId } = action.payload

  try {
    const isExisted = yield* isStoreExisted("issueThumbnails", issueId)
    const isExpired = yield* isStoreExpired(
      ISSUE_THUMBNAILS_TTL,
      "issueThumbnails",
      issueId
    )
    if (isExisted && !isExpired) {
      return
    }
    const response = yield call(new FetchIssueThumbnailsApi({ issueId }).call)
    yield put(onRequestIssueThumbnailsSuccess(issueId, response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestIssueThumbnails() {
  yield takeEvery(REQUEST_ISSUE_THUMBNAILS, withLock(fetchIssueThumbnails))
}

function* fetchIssueArticles(action) {
  const { issueId } = action.payload
  try {
    const response = yield call(new FetchIssueArticlesApi({ issueId }).call)
    yield put(onRequestIssueArticlesSuccess(issueId, response.data.articles))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestIssueArticles() {
  yield takeEvery(REQUEST_ISSUE_ARTICLES, withLock(fetchIssueArticles, true))
}
