import KonoApi from "../KonoApi"

class FetchCurationChannelsApi extends KonoApi {
  constructor() {
    super(`/curation_channels`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchCurationChannelsApi
