import KonoApi from "../KonoApi"

class FetchMagazineIssuesApi extends KonoApi {
  constructor(data) {
    super(`/titles/${data.magazineId}/magazines?limit=3`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchMagazineIssuesApi
