import { SET_USER_PROPERTIES } from "constants/trackingEvents"

export const pushGtmDataLayer = params => {
  if (process.env.REACT_APP_PRODUCT === "aycr") {
    window.gtmDataLayer = window.gtmDataLayer || []
    window.gtmDataLayer.push(params)

    //I don't know what this line is doing. Command this line temporarily.
    //TagManager.dataLayer(window.gtmDataLayer)
  }
}

export const updateGtmUserId = userId => {
  const params = { userId: userId }
  pushGtmDataLayer(params)
}

export const addGtmEvent = (event, params = {}) => {
  const gtmParams = { event: event, ...params }
  pushGtmDataLayer(gtmParams)
}

// Use this format to send event to GTM in most cases
// eventType should be UPPER_SNAKE_CASE and keys of eventProperties should be snake_case
// Use `SET_USER_ID` and `SET_USER_PROPERTIES` to replace amplitude event
export const gtmLogEvent = (eventType, eventProperties) => {
  const params = {
    event: eventType,
    eventType: eventType,
    eventProperties: eventProperties
  }
  pushGtmDataLayer(params)
}

// Use this function after `updateGtmUserId`
// because it would alse trigger amplitude to set user id
export const gtmSetUserProperties = userProperties => {
  const params = {
    event: SET_USER_PROPERTIES,
    userProperties: userProperties
  }
  pushGtmDataLayer(params)
}
