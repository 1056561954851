export const LOG_IN = "LOG_IN"
export const LOG_OUT = "LOG_OUT"
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS"
export const LOG_IN_FAIL = "LOG_IN_FAIL"
export const SIGN_UP = "SIGN_UP"
export const SIGN_UP_SMARTONE = "SIGN_UP_SMARTONE"
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS"
export const SIGN_UP_FAIL = "SIGN_UP_FAIL"
export const REQUEST_USER_PLATFORMS = "REQUEST_USER_PLATFORMS"
export const REQUEST_USER_PLATFORMS_SUCCESS = "REQUEST_USER_PLATFORMS_SUCCESS"
export const CLEAR_AUTHENTICATION_ERROR = "CLEAR_AUTHENTICATION_ERROR"
export const CLEAR_USER_PLATFORMS = "CLEAR_USER_PLATFORMS"
export const CHANGE_STATE = "CHANGE_STATE"
export const REQUEST_CATEGORY = "REQUEST_CATEGORY"
export const REQUEST_CATEGORY_SUCCESS = "REQUEST_CATEGORY_SUCCESS"
export const REQUEST_CATEGORIES = "REQUEST_CATEGORIES"
export const REQUEST_CATEGORIES_SUCCESS = "REQUEST_CATEGORIES_SUCCESS"
export const CHANGE_SEARCH_KEY = "CHANGE_SEARCH_KEY"
export const TOGGLE_DEVICE_SEARCH_BAR = "TOGGLE_DEVICE_SEARCH_BAR"
export const TOGGLE_SIDE_TAB_MENU = "TOGGLE_SIDE_TAB_MENU"
export const CLOSE_SIDE_TAB_MENU = "CLOSE_SIDE_TAB_MENU"
export const REQUEST_CATEGORY_LATEST_ISSUES = "REQUEST_CATEGORY_LATEST_ISSUES"
export const REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS =
  "REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS"
export const REQUEST_LATEST_ISSUE = "REQUEST_LATEST_ISSUE"
export const REQUEST_LATEST_ISSUE_SUCCESS = "REQUEST_LATEST_ISSUE_SUCCESS"
export const REQUEST_LATEST_ISSUES = "REQUEST_LATEST_ISSUES"
export const REQUEST_LATEST_ISSUES_SUCCESS = "REQUEST_LATEST_ISSUES_SUCCESS"
export const REQUEST_BANNER = "REQUEST_BANNER"
export const REQUEST_BANNER_SUCCESS = "REQUEST_BANNER_SUCCESS"
export const REQUEST_FOLLOWED_MAGAZINES = "REQUEST_FOLLOWED_MAGAZINES"
export const REQUEST_FOLLOWED_MAGAZINES_SUCCESS =
  "REQUEST_FOLLOWED_MAGAZINES_SUCCESS"
export const FOLLOW_MAGAZINE = "FOLLOW_MAGAZINE"
export const UNFOLLOW_MAGAZINE = "UNFOLLOW_MAGAZINE"
export const REQUEST_MAGAZINE = "REQUEST_MAGAZINE"
export const REQUEST_MAGAZINE_SUCCESS = "REQUEST_MAGAZINE_SUCCESS"
export const REQUEST_MAGAZINE_YEARS = "REQUEST_MAGAZINE_YEARS"
export const REQUEST_MAGAZINE_YEARS_SUCCESS = "REQUEST_MAGAZINE_YEARS_SUCCESS"
export const REQUEST_MAGAZINE_YEAR_ISSUES = "REQUEST_MAGAZINE_YEAR_ISSUES"
export const REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS =
  "REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS"
export const REQUEST_ISSUE = "REQUEST_ISSUE"
export const REQUEST_ISSUE_SUCCESS = "REQUEST_ISSUE_SUCCESS"
export const REQUEST_MAGAZINE_ISSUES = "REQUEST_MAGAZINE_ISSUES"
export const REQUEST_MAGAZINE_ISSUES_SUCCESS = "REQUEST_MAGAZINE_ISSUES_SUCCESS"
export const REQUEST_ISSUE_THUMBNAILS = "REQUEST_ISSUE_THUMBNAILS"
export const REQUEST_ISSUE_THUMBNAILS_SUCCESS =
  "REQUEST_ISSUE_THUMBNAILS_SUCCESS"
export const REQUEST_ARTICLE = "REQUEST_ARTICLE"
export const REQUEST_ARTICLE_SUCCESS = "REQUEST_ARTICLE_SUCCESS"
export const REQUEST_ISSUE_ARTICLES = "REQUEST_ISSUE_ARTICLES"
export const REQUEST_ISSUE_ARTICLES_SUCCESS = "REQUEST_ISSUE_ARTICLES_SUCCESS"
export const REQUEST_BOOKMARKS = "REQUEST_BOOKMARKS"
export const REQUEST_BOOKMARKS_SUCCESS = "REQUEST_BOOKMARKS_SUCCESS"
export const REQUEST_BOOKMARKS_STATISTICS = "REQUEST_BOOKMARKS_STATISTICS"
export const REQUEST_BOOKMARKS_STATISTICS_SUCCESS =
  "REQUEST_BOOKMARKS_STATISTICS_SUCCESS"
export const CREATE_BOOKMARK = "CREATE_BOOKMARK"
export const CREATE_BOOKMARK_SUCCESS = "CREATE_BOOKMARK_SUCCESS"
export const CREATE_BOOKMARK_ERROR = "CREATE_BOOKMARK_ERROR"
export const DELETE_BOOKMARK = "DELETE_BOOKMARK"
export const DELETE_BOOKMARK_SUCCESS = "DELETE_BOOKMARK_SUCCESS"
export const DELETE_BOOKMARK_ERROR = "DELETE_BOOKMARK_ERROR"
export const REQUEST_SEARCH_ARTICLES = "REQUEST_SEARCH_ARTICLES"
export const REQUEST_SEARCH_ARTICLES_SUCCESS = "REQUEST_SEARCH_ARTICLES_SUCCESS"
export const REQUEST_MAGAZINES = "REQUEST_MAGAZINES"
export const REQUEST_MAGAZINES_SUCCESS = "REQUEST_MAGAZINES_SUCCESS"
export const REQUEST_BOOKMARKED_ARTICLES_SUCCESS =
  "REQUEST_BOOKMARKED_ARTICLES_SUCCESS"
export const REQUEST_POPULAR_ARTICLES = "REQUEST_POPULAR_ARTICLES"
export const REQUEST_POPULAR_ARTICLES_SUCCESS =
  "REQUEST_POPULAR_ARTICLES_SUCCESS"
export const CLEAN_BOOKMARK_ARTICLES = "CLEAN_BOOKMARK_ARTICLES"
export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"
export const REQUEST_CURRENT_FREE_ISSUE = "REQUEST_CURRENT_FREE_ISSUE"
export const REQUEST_CURRENT_FREE_ISSUE_SUCCESS =
  "REQUEST_CURRENT_FREE_ISSUE_SUCCESS"
export const REQUEST_CURATION_POST = "REQUEST_CURATION_POST"
export const REQUEST_CURATION_POST_SUCCESS = "REQUEST_CURATION_POST_SUCCESS"
export const REQUEST_CURATION_POST_ARTICLES = "REQUEST_CURATION_POST_ARTICLES"
export const REQUEST_CURATION_POST_ARTICLES_SUCCESS =
  "REQUEST_CURATION_POST_ARTICLES_SUCCESS"
export const REQUEST_CURATION_CHANNEL = "REQUEST_CURATION_CHANNEL"
export const REQUEST_CURATION_CHANNEL_SUCCESS =
  "REQUEST_CURATION_CHANNEL_SUCCESS"
export const REQUEST_CURATION_CHANNELS = "REQUEST_CURATION_CHANNELS"
export const REQUEST_CURATION_CHANNELS_SUCCESS =
  "REQUEST_CURATION_CHANNELS_SUCCESS"
export const REQUEST_CURATION_CHANNEL_POSTS = "REQUEST_CURATION_CHANNEL_POSTS"
export const REQUEST_CURATION_CHANNEL_POSTS_SUCCESS =
  "REQUEST_CURATION_CHANNEL_POSTS_SUCCESS"
export const REQUEST_RECOMMEND_ARTICLES = "REQUEST_RECOMMEND_ARTICLES"
export const REQUEST_RECOMMEND_ARTICLES_SUCCESS =
  "REQUEST_RECOMMEND_ARTICLES_SUCCESS"
export const REQUEST_POPULAR_MAGAZINES = "REQUEST_POPULAR_MAGAZINES"
export const REQUEST_POPULAR_MAGAZINES_SUCCESS =
  "REQUEST_POPULAR_MAGAZINES_SUCCESS"
export const REQUEST_RECOMMENDATION_TREE = "REQUEST_RECOMMENDATION_TREE"
export const REQUEST_RECOMMENDATION_TREE_SUCCESS =
  "REQUEST_RECOMMENDATION_TREE_SUCCESS"
export const REQUEST_CLIENT_INFO = "REQUEST_CLIENT_INFO"
export const REQUEST_CLIENT_INFO_SUCCESS = "REQUEST_CLIENT_INFO_SUCCESS"
export const REMIND_PASSWORD = "REMIND_PASSWORD"
export const REMIND_PASSWORD_SUCCESS = "REMIND_PASSWORD_SUCCESS"
export const REMIND_PASSWORD_ERROR = "REMIND_PASSWORD_ERROR"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"
export const INIT_REMIND_PASSWORD = "INIT_REMIND_PASSWORD"
export const INIT_RESET_PASSWORD = "INIT_RESET_PASSWORD"
export const REQUEST_VIP_PERIODS = "REQUEST_VIP_PERIODS"
export const REQUEST_VIP_PERIODS_THROTTLING = "REQUEST_VIP_PERIODS_THROTTLING"
export const REQUEST_VIP_PERIODS_SUCCESS = "REQUEST_VIP_PERIODS_SUCCESS"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const REQUEST_TSTAR_SMS = "REQUEST_TSTAR_SMS"
export const REQUEST_TSTAR_SMS_SUCCESS = "REQUEST_TSTAR_SMS_SUCCESS"
export const REQUEST_TSTAR_SMS_ERROR = "REQUEST_TSTAR_SMS_ERROR"
export const CLEAR_TSTAR_SMS_SENDER = "CLEAR_TSTAR_SMS_SENDER"
export const DECREASE_TSTAR_SMS_TIME = "DECREASE_TSTAR_SMS_TIME"
export const BIND_TSTAR_SMS = "BIND_TSTAR_SMS"
export const BIND_TSTAR_SMS_SUCCESS = "BIND_TSTAR_SMS_SUCCESS"
export const BIND_TSTAR_SMS_ERROR = "BIND_TSTAR_SMS_ERROR"
export const CLEAR_TSTAR_SMS_BINDER = "CLEAR_TSTAR_SMS_BINDER"
export const EXIST_TSTAR_TOKEN = "EXIST_TSTAR_TOKEN"
export const EXIST_TSTAR_TOKEN_SUCCESS = "EXIST_TSTAR_TOKEN_SUCCESS"
export const EXIST_TSTAR_TOKEN_ERROR = "EXIST_TSTAR_TOKEN_ERROR"
export const CLEAR_TSTAR_TOKEN = "CLEAR_TSTAR_TOKEN"
export const BIND_TSTAR_TOKEN = "BIND_TSTAR_TOKEN"
export const BIND_TSTAR_TOKEN_SUCCESS = "BIND_TSTAR_TOKEN_SUCCESS"
export const BIND_TSTAR_TOKEN_ERROR = "BIND_TSTAR_TOKEN_ERROR"
export const REQUEST_APTG_SMS = "REQUEST_APTG_SMS"
export const REQUEST_APTG_SMS_SUCCESS = "REQUEST_APTG_SMS_SUCCESS"
export const REQUEST_APTG_SMS_ERROR = "REQUEST_APTG_SMS_ERROR"
export const CLEAR_APTG_SMS_SENDER = "CLEAR_APTG_SMS_SENDER"
export const DECREASE_APTG_SMS_TIME = "DECREASE_APTG_SMS_TIME"
export const BIND_APTG_SMS = "BIND_APTG_SMS"
export const BIND_APTG_SMS_SUCCESS = "BIND_APTG_SMS_SUCCESS"
export const BIND_APTG_SMS_ERROR = "BIND_APTG_SMS_ERROR"
export const CLEAR_APTG_SMS_BINDER = "CLEAR_APTG_SMS_BINDER"
export const EXIST_APTG_TOKEN = "EXIST_APTG_TOKEN"
export const EXIST_APTG_TOKEN_SUCCESS = "EXIST_APTG_TOKEN_SUCCESS"
export const EXIST_APTG_TOKEN_ERROR = "EXIST_APTG_TOKEN_ERROR"
export const BIND_APTG_TOKEN = "BIND_APTG_TOKEN"
export const BIND_APTG_TOKEN_SUCCESS = "BIND_APTG_TOKEN_SUCCESS"
export const BIND_APTG_TOKEN_ERROR = "BIND_APTG_TOKEN_ERROR"
export const CLEAR_APTG_TOKEN = "CLEAR_APTG_TOKEN"
export const REQUEST_ARTICLE_ACCESS_TOKEN = "REQUEST_ARTICLE_ACCESS_TOKEN"
export const REQUEST_ARTICLE_ACCESS_TOKEN_SUCCESS =
  "REQUEST_ARTICLE_ACCESS_TOKEN_SUCCESS"
export const REQUEST_ARTICLE_ACCESS_TOKEN_ERROR =
  "REQUEST_ARTICLE_ACCESS_TOKEN_ERROR"
export const REQUEST_ARTICLE_TEXT = "REQUEST_ARTICLE_TEXT"
export const REQUEST_ARTICLE_TEXT_SUCCESS = "REQUEST_ARTICLE_TEXT_SUCCESS"
export const REQUEST_ARTICLE_TEXT_ERROR = "REQUEST_ARTICLE_TEXT_ERROR"
export const OPEN_HEADER = "OPEN_HEADER"
export const CLOSE_HEADER = "CLOSE_HEADER"
export const OPEN_ARTICLE_WIDGET_BAR = "OPEN_ARTICLE_WIDGET_BAR"
export const CLOSE_ARTICLE_WIDGET_BAR = "CLOSE_ARTICLE_WIDGET_BAR"
export const REQUEST_ARTICLE_RELEVANT_ARTICLES =
  "REQUEST_ARTICLE_RELEVANT_ARTICLES"
export const REQUEST_ARTICLE_RELEVANT_ARTICLES_SUCCESS =
  "REQUEST_ARTICLE_RELEVANT_ARTICLES_SUCCESS"
export const OPEN_PAGE_WIDGET_BAR = "OPEN_PAGE_WIDGET_BAR"
export const CLOSE_PAGE_WIDGET_BAR = "CLOSE_PAGE_WIDGET_BAR"
export const REQUEST_BRAINTREE_PLANS = "REQUEST_BRAINTREE_PLANS"
export const CLEAR_BRAINTREE_PROMOTION_CODE = "CLEAR_BRAINTREE_PROMOTION_CODE"
export const APPLY_BRAINTREE_PROMOTION_CODE = "APPLY_BRAINTREE_PROMOTION_CODE"
export const REQUEST_BRAINTREE_PLANS_SUCCESS = "REQUEST_BRAINTREE_PLANS_SUCCESS"
export const REQUEST_BRAINTREE_PLANS_ERROR = "REQUEST_BRAINTREE_PLANS_ERROR"
export const REQUEST_IBON_PLANS = "REQUEST_IBON_PLANS"
export const REQUEST_IBON_PLANS_SUCCESS = "REQUEST_IBON_PLANS_SUCCESS"
export const REQUEST_GIFTING_PLAN = "REQUEST_GIFTING_PLAN"
export const REQUEST_GIFTING_PLAN_SUCCESS = "REQUEST_GIFTING_PLAN_SUCCESS"
export const REQUEST_SENT_GIFTS = "REQUEST_SENT_GIFTS"
export const REQUEST_SENT_GIFTS_SUCCESS = "REQUEST_SENT_GIFTS_SUCCESS"
export const REQUEST_BRAINTREE_SUBSCRIPTION = "REQUEST_BRAINTREE_SUBSCRIPTION"
export const REQUEST_BRAINTREE_SUBSCRIPTION_THROTTLING =
  "REQUEST_BRAINTREE_SUBSCRIPTION_THROTTLING"
export const REQUEST_BRAINTREE_SUBSCRIPTION_SUCCESS =
  "REQUEST_BRAINTREE_SUBSCRIPTION_SUCCESS"
export const REQUEST_BRAINTREE_SUBSCRIPTION_ERROR =
  "REQUEST_BRAINTREE_SUBSCRIPTION_ERROR"
export const REQUEST_HAMI_READ_COUNT = "REQUEST_HAMI_READ_COUNT"
export const REQUEST_HAMI_READ_COUNT_SUCCESS = "REQUEST_HAMI_READ_COUNT_SUCCESS"
export const REQUEST_FREE_ARTICLE_QUOTA = "REQUEST_FREE_ARTICLE_QUOTA"
export const REQUEST_FREE_ARTICLE_QUOTA_SUCCESS =
  "REQUEST_FREE_ARTICLE_QUOTA_SUCCESS"
export const REQUEST_BRAINTREE_PAYMENT_METHODS =
  "REQUEST_BRAINTREE_PAYMENT_METHODS"
export const REQUEST_BRAINTREE_PAYMENT_METHODS_SUCCESS =
  "REQUEST_BRAINTREE_PAYMENT_METHODS_SUCCESS"
export const CHANGE_BRAINTREE_PAYMENT_METHODS =
  "CHANGE_BRAINTREE_PAYMENT_METHODS"
export const CHANGE_BRAINTREE_PAYMENT_METHODS_SUCCESS =
  "CHANGE_BRAINTREE_PAYMENT_METHODS_SUCCESS"
export const CHANGE_BRAINTREE_PAYMENT_METHODS_ERROR =
  "CHANGE_BRAINTREE_PAYMENT_METHODS_ERROR"
export const UPDATE_REFER_TOKEN = "UPDATE_REFER_TOKEN"
export const SHOW_LOADING = "SHOW_LOADING"
export const HIDE_LOADING = "HIDE_LOADING"
export const CHANGE_LOADING_SUCCESS = "CHANGE_LOADING_SUCCESS"
export const REQUEST_RECOMMENDED_MAGAZINES = "REQUEST_RECOMMENDED_MAGAZINES"
export const REQUEST_RECOMMENDED_MAGAZINES_SUCCESS =
  "REQUEST_RECOMMENDED_MAGAZINES_SUCCESS"
export const REQUEST_USER_INFO = "REQUEST_USER_INFO"
export const REQUEST_USER_INFO_SUCCESS = "REQUEST_USER_INFO_SUCCESS"
export const DETECT_WEBP = "DETECT_WEBP"
export const DETECT_WEBP_SUCCESS = "DETECT_WEBP_SUCCESS"
export const CLEAR_STATE = "CLEAR_STATE"
export const RECORD_USER_ACTIVITY = "RECORD_USER_ACTIVITY"
export const RECORD_USER_ACTIVITY_SUCCESS = "RECORD_USER_ACTIVITY_SUCCESS"
export const START_CONSUMING_ARTICLE_READ_EVENTS =
  "START_CONSUMING_ARTICLE_READ_EVENTS"
export const END_CONSUMING_ARTICLE_READ_EVENTS =
  "END_CONSUMING_ARTICLE_READ_EVENTS"
export const CONSUMING_ARTICLE_READ_EVENTS = "CONSUMING_ARTICLE_READ_EVENTS"
export const PRODUCING_ARTICLE_READ_EVENTS = "PRODUCING_ARTICLE_READ_EVENTS"
export const ADD_ARTICLE_READ_EVENT = "ADD_ARTICLE_READ_EVENT"
export const CLEAR_STORED_ARTICLE_READ_EVENTS =
  "CLEAD_STORED_ARTICLE_READ_EVENTS"
export const SEND_SQS_MESSAGES = "SEND_SQS_MESSAGES"
export const SEND_SQS_MESSAGES_SUCCESS = "SEND_SQS_MESSAGES_SUCCESS"
export const CHANGE_TRAFFIC_SOURCE = "CHANGE_TRAFFIC_SOURCE"
export const REPORT_ERROR = "REPORT_ERROR"
export const REQUEST_VIP_STATUS = "REQUEST_VIP_STATUS"
export const INIT_CURRENT_READING_INFO = "INIT_CURRENT_READING_INFO"
export const UPDATE_CURRENT_READING_INFO = "UPDATE_CURRENT_READING_INFO"
export const UPDATE_ARTICLE_READ_TRACKING_HASH =
  "UPDATE_ARTICLE_READ_TRACKING_HASH"
export const CLEAR_CURRENT_READING_INFO_AND_TRACKING_HASH =
  "CLEAR_CURRENT_READING_INFO_AND_TRACKING_HASH"
export const CHANGE_PAGE_NUMBERS = "CHANGE_PAGE_NUMBERS"
export const CHANGE_STATE_VIA = "CHANGE_STATE_VIA"
export const REQUEST_NEWS = "REQUEST_NEWS"
export const REQUEST_NEWS_SUCCESS = "REQUEST_NEWS_SUCCESS"
export const REQUEST_TEAM = "REQUEST_TEAM"
export const REQUEST_TEAM_SUCCESS = "REQUEST_TEAM_SUCCESS"
export const REQUEST_MAGAZINE_LIST = "REQUEST_MAGAZINE_LIST"
export const REQUEST_MAGAZINE_LIST_SUCCESS = "REQUEST_MAGAZINE_LIST_SUCCESS"
export const REQUEST_INTRO_ARTICLES = "REQUEST_INTRO_ARTICLES"
export const REQUEST_INTRO_ARTICLES_SUCCESS = "REQUEST_INTRO_ARTICLES_SUCCESS"
// tracking events
export const LOG_SIGN_UP = "LOG_SIGN_UP"
export const LOG_SIGN_IN = "LOG_SIGN_IN"
export const LOG_TELECOM_BINDING = "LOG_BINDING"
export const LOG_OPEN_ARTICLE = "LOG_OPEN_ARTICLE"
export const LOG_OPEN_CURATION_POST = "LOG_OPEN_CURATION_POST"
export const LOG_OPEN_CURATION_POST_ARTICLE = "LOG_OPEN_CURATION_POST_ARTICLE"
export const LOG_OPEN_RECOMMEND_ARTICLE = "LOG_OPEN_RECOMMEND_ARTICLE"
export const LOG_UPGRADE_TRY_PURCHASE = "LOG_UPGRADE_TRY_PURCHASE"
export const LOG_UPGRADE_PLAN_SELECTED = "LOG_UPGRADE_PLAN_SELECTED"
export const LOG_UPGRADE_SUCCESS = "LOG_UPGRADE_SUCCESS"
export const LOG_UPGRADE_TRY_USE_PROMOTION_CODE =
  "LOG_UPGRADE_TRY_USE_PROMOTION_CODE"
export const LOG_UPGRADE_COUPON = "LOG_UPGRADE_COUPON"
export const LOG_BANNER_CLICK = "LOG_BANNER_CLICK"
export const LOG_SEARCH_RESULT_MAGAZINE_CLICK =
  "LOG_SEARCH_RESULT_MAGAZINE_CLICK"
export const LOG_SEARCH_RESULT_ARTICLE_CLICK = "LOG_SEARCH_RESULT_ARTICLE_CLICK"
export const LOG_FREE_MAGAZINE_INTRO_DISPLAY = "LOG_FREE_MAGAZINE_INTRO_DISPLAY"
export const LOG_FREE_MAGAZINE_INTRO_ACTION_CLICK =
  "LOG_FREE_MAGAZINE_INTRO_ACTION_CLICK"
export const LOG_RECOMMEND_QUESTION_ASKED = "LOG_RECOMMEND_QUESTION_ASKED"
export const LOG_RECOMMEND_LIST_ENTER = "LOG_RECOMMEND_LIST_ENTER"
export const LOG_SHARE_ARTICLE = "LOG_SHARE_ARTICLE"
export const LOG_DOWNLOAD_APP = "LOG_DOWNLOAD_APP"
export const LOG_RESEND_CONFIRMATION_EMAIL = "LOG_RESEND_CONFIRMATION_EMAIL"
export const LOG_WIDGET_BAR_CLICK = "LOG_WIDGET_BAR_CLICK"
export const LOG_GET_IBON_PINCODE_SUCCESS = "LOG_GET_IBON_PINCODE_SUCCESS"
export const LOG_PLAY_AUDIO = "LOG_PLAY_AUDIO"
export const LOG_BUY_GIFTING = "LOG_BUY_GIFTING"
export const SET_VERSION = "SET_VERSION"
export const FORCE_UPDATE_VERSION = "FORCE_UPDATE_VERSION"
export const FORCE_UPDATE_VERSION_SUCCESS = "FORCE_UPDATE_VERSION_SUCCESS"
export const REQUEST_WECHAT_ACCESS_TOKEN = "REQUEST_WECHAT_ACCESS_TOKEN"
export const REQUEST_WECHAT_ACCESS_TOKEN_FINISH =
  "REQUEST_WECHAT_ACCESS_TOKEN_FINISH"
export const WECHAT_LOG_IN = "WECHAT_LOG_IN"
export const OPEN_HEADER_MENU = "OPEN_HEADER_MENU"
export const CLOSE_HEADER_MENU = "CLOSE_HEADER_MENU"
export const AGREE_COOKIE = "AGREE_COOKIE"
export const REQUEST_ARTICLE_TEXT_V2_SUCCESS = "REQUEST_ARTICLE_TEXT_V2_SUCCESS"
export const SET_ARTICLE_VIEWER_BACK_HREF = "SET_ARTICLE_VIEWER_BACK_HREF"
export const CLEAR_ARTICLE_VIEWER_BUNS = "CLEAR_ARTICLE_VIEWER_BUNS"
export const REQUEST_K4L_LIBRARIES = "REQUEST_K4L_LIBRARIES"
export const REQUEST_K4L_LIBRARIES_SUCCESS = "REQUEST_K4L_LIBRARIES_SUCCESS"
export const SET_CURRENT_LIBRARY = "SET_CURRENT_LIBRARY"
export const CLEAR_CURRENT_LIBRARY = "CLEAR_CURRENT_LIBRARY"
export const SET_CURRENT_LIBRARY_BY_ID = "SET_CURRENT_LIBRARY_BY_ID"
export const SET_K4L_ERROR = "SET_K4L_ERROR"
export const SET_BOOK_LIST_ID = "SET_BOOK_LIST_ID"
export const K4L_SIGN_UP = "K4L_SIGN_UP"
export const K4L_LOG_IN = "K4L_LOG_IN"
export const REQUEST_IOS_RECEIPTS = "REQUEST_IOS_RECEIPTS"
export const REQUEST_IOS_RECEIPTS_SUCCESS = "REQUEST_IOS_RECEIPTS_SUCCESS"
export const SET_BREADCRUMB = "SET_BREADCRUMB"
export const REQUEST_USER_PICTURE = "REQUEST_USER_PICTURE"
export const REQUEST_USER_PICTURE_SUCCESS = "REQUEST_USER_PICTURE_SUCCESS"
export const UPDATE_FACEBOOK_STATUS = "UPDATE_FACEBOOK_STATUS"
export const UPDATE_FACEBOOK_STATUS_SUCCESS = "UPDATE_FACEBOOK_STATUS_SUCCESS"
export const REQUEST_READING_PROGRESSES = "REQUEST_READING_PROGRESSES"
export const REQUEST_READING_PROGRESSES_SUCCESS =
  "REQUEST_READING_PROGRESS_SUCCESS"
export const DELETE_READING_PROGRESS = "DELETE_READING_PROGRESS"
export const DELETE_READING_PROGRESS_SUCCESS = "DELETE_READING_PROGRESS_SUCCESS"
export const DELETE_READING_PROGRESS_ERROR = "DELETE_READING_PROGRESS_ERROR"
export const DELETE_READING_PROGRESSES = "DELETE_READING_PROGRESSES"
export const DELETE_READING_PROGRESSES_SUCCESS =
  "DELETE_READING_PROGRESSES_SUCCESS"
export const DELETE_READING_PROGRESSES_ERROR = "DELETE_READING_PROGRESSES_ERROR"
export const UPDATE_READING_PROGRESS = "UPDATE_READING_PROGRESS"
export const REQUEST_ISSUE_READING_PROGRESSES =
  "REQUEST_ISSUE_READING_PROGRESSES"
export const REQUEST_ISSUE_READING_PROGRESSES_SUCCESS =
  "REQUEST_ISSUE_READING_PROGRESSES_SUCCESS"
export const OPEN_READING_PROGRESS_PROMPT_TOAST =
  "OPEN_READING_PROGRESS_PROMPT_TOAST"
export const CLOSE_READING_PROGRESS_PROMPT_TOAST =
  "CLOSE_READING_PROGRESS_PROMPT_TOAST"
export const RESET_READING_PROGRESSES_FETCHING_STATUS =
  "RESET_READING_PROGRESSES_FETCHING_STATUS"
export const REQUEST_MAGAZINE_YEAR_READING_STATUSES =
  "REQUEST_MAGAZINE_YEAR_READING_STATUSES"
export const REQUEST_MAGAZINE_YEAR_READING_STATUSES_SUCCESS =
  "REQUEST_MAGAZINE_YEAR_READING_STATUSES_SUCCESS"
export const REQUEST_ISSUE_READING_STATUS = "REQUEST_ISSUE_READING_STATUS"
export const REQUEST_ISSUE_READING_STATUS_SUCCESS =
  "REQUEST_ISSUE_READING_STATUS_SUCCESS"
export const OPEN_TOAST = "OPEN_TOAST"
export const CLOSE_TOAST = "CLOSE_TOAST"
export const LOG_HOME_MY_FOLLOW_CLICK = "LOG_HOME_MY_FOLLOW_CLICK"
export const LOG_HOME_RECOMMEND_MAGAZINE_CLICK =
  "LOG_HOME_RECOMMEND_MAGAZINE_CLICK"
export const LOG_HOME_WEEKLY_ISSUE_CLICK = "LOG_HOME_WEEKLY_ISSUE_CLICK"
export const LOG_HOME_LATEST_ISSUE_CLICK = "LOG_HOME_LATEST_ISSUE_CLICK"
export const LOG_HOME_CURATION_CHANNEL_CLICK = "LOG_HOME_CURATION_CHANNEL_CLICK"
export const LOG_HOME_CURATION_POST_CLICK = "LOG_HOME_CURATION_POST_CLICK"
export const LOG_HOME_CURATION_POST_ARTICLE_CLICK =
  "LOG_HOME_CURATION_POST_ARTICLE_CLICK"
export const LOG_HOME_RECOMMEND_ARTICLE_CLICK =
  "LOG_HOME_RECOMMEND_ARTICLE_CLICK"
export const LOG_HOME_POPULAR_MAGAZINE_CLICK = "LOG_HOME_POPULAR_MAGAZINE_CLICK"
export const LOG_HOME_POPULAR_ARTICLE_CLICK = "LOG_HOME_POPULAR_ARTICLE_CLICK"
export const LOG_HOME_MY_BOOKMARK_CLICK = "LOG_HOME_MY_BOOKMARK_CLICK"
export const LOG_HEADER_CLICK = "LOG_HEADER_CLICK"
export const LOG_PREVIEW_ARTICLE_DOWNLOAD_APP_CLICK =
  "LOG_PREVIEW_ARTICLE_DOWNLOAD_APP_CLICK"
export const LOG_HEADER_NAVIGATION_CATEGORY_CLICK =
  "LOG_HEADER_NAVIGATION_CATEGORY_CLICK"
export const LOG_HEADER_NAVIGATION_LIBRARY_CLICK =
  "LOG_HEADER_NAVIGATION_LIBRARY_CLICK"
export const LOG_ENTER_HOME = "LOG_ENTER_HOME"
export const TEMPORARY_LOGIN = "TEMPORARY_LOGIN"
export const TEMPORARY_LOGIN_SUCCESS = "TEMPORARY_LOGIN_SUCCESS"
export const TEMPORARY_LOGIN_FAIL = "TEMPORARY_LOGIN_FAIL"
export const UPDATE_TASK_RECORDS = "UPDATE_TASK_RECORDS"
export const LOG_START_TRIAL_SHOW = "LOG_START_TRIAL_SHOW"
export const LOG_START_TRIAL_CLICK = "LOG_START_TRIAL_CLICK"
export const SET_YEARLY_REPORT = "SET_YEARLY_REPORT"
export const CLOSE_YEARLY_REPORT = "CLOSE_YEARLY_REPORT"
export const REQUEST_TRANSLATIONS = "REQUEST_TRANSLATIONS"
export const REQUEST_TRANSLATIONS_SUCCESS = "REQUEST_TRANSLATIONS_SUCCESS"
export const LOG_OPEN_REPORT = "LOG_OPEN_REPORT"
export const LOG_CLICK_SURVEY = "LOG_CLICK_SURVEY"
export const REQUEST_SUBSCRIPTION_INFO = "REQUEST_SUBSCRIPTION_INFO"
export const REQUEST_SUBSCRIPTION_INFO_SUCCESS =
  "REQUEST_SUBSCRIPTION_INFO_SUCCESS"
export const LOG_VIEW_PRICE_PAGE = "LOG_VIEW_PRICE_PAGE"
export const LOG_CLICK_SUBSCRIBE = "LOG_CLICK_SUBSCRIBE"
export const LOG_VIEW_PAYMENT_PAGE = "LOG_VIEW_PAYMENT_PAGE"
export const LOG_CONFIRM_SUBSCRIBE = "LOG_CONFIRM_SUBSCRIBE"
export const LOG_VIEW_THANK_YOU_PAGE = "LOG_VIEW_THANK_YOU_PAGE"
export const AMP_INIT_USER_PROPERTIES = "AMP_INIT_USER_PROPERTIES"
export const SET_FIRST_LANDING_PAGE = "SET_FIRST_LANDING_PAGE"
export const LOG_CLICK_OOBE_NEXT_STEP = "LOG_CLICK_OOBE_NEXT_STEP"
export const LOG_COMPLETE_OOBE = "LOG_COMPLETE_OOBE"
export const LOG_VIEW_ARTICLE_PREVIEW = "LOG_VIEW_ARTICLE_PREVIEW"
export const LOG_CLICK_ARTICLE_PREVIEW_CELL = "LOG_CLICK_ARTICLE_PREVIEW_CELL"
export const LOG_PLAY_VIDEO = "LOG_PLAY_VIDEO"
export const UPDATE_AUDIO_VOLUME = "UPDATE_AUDIO_VOLUME"
export const UPDATE_AUDIO_PLAYBACK = "UPDATE_AUDIO_PLAYBACK"
export const CLOSE_AUDIO_TOAST = "CLOSE_AUDIO_TOAST"
export const CLOSE_TSTAR_VIP_TRANSFER_MODAL = "CLOSE_TSTAR_VIP_TRANSFER_MODAL"
export const LOG_VIEW_HOME = "LOG_VIEW_HOME"
export const LOG_SEARCH_RESULT = "LOG_SEARCH_RESULT"
export const LOG_SEARCH_RESULT_CLICK = "LOG_SEARCH_RESULT_CLICK"
export const LOG_SHOW_ARTICLE_PREVIEW_MODAL = "LOG_SHOW_ARTICLE_PREVIEW_MODAL"
