import { REQUEST_MAGAZINE_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const getCategoryGroup = categories => {
  const library = categories
    .filter(category => Boolean(category.category_group_id))
    .map(category => category.category_group_id)

  return library.length ? library[0] : undefined
}

const getCategoriesByGroup = (categories, library) => {
  const regularCategories = categories
    .filter(category => category.category_group_id === library)
    .map(category => {
      return {
        id: category.id,
        dictKey: category.dict_key
      }
    })

  return regularCategories
}

const formatMagazine = magazine => {
  const library = getCategoryGroup(magazine.categories)
  const regularCategories = getCategoriesByGroup(magazine.categories, library)
  return {
    id: magazine.title,
    library: library,
    category: regularCategories
  }
}

const reducerMap = new Map([
  [
    REQUEST_MAGAZINE_SUCCESS,
    (state, action) => {
      const { magazine } = action.payload
      const magazineCategory = formatMagazine(magazine)
      return state.set(magazine.title, fromJS(magazineCategory))
    }
  ]
])

export default handleActions(reducerMap, initialState)
