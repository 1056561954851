import {
  REQUEST_INTRO_ARTICLES,
  REQUEST_INTRO_ARTICLES_SUCCESS
} from "constants/actionTypes"

export const onRequestIntroArticles = () => {
  return {
    type: REQUEST_INTRO_ARTICLES
  }
}

export const onRequestIntroArticlesSuccess = articles => {
  return {
    type: REQUEST_INTRO_ARTICLES_SUCCESS,
    payload: { articles }
  }
}
