import { put, takeEvery, throttle, call } from "redux-saga/effects"
import {
  onRequestCurationChannelSuccess,
  onRequestCurationChannelsSuccess,
  onRequestCurationChannelPostsSuccess
} from "actions/curationChannels"
import {
  REQUEST_CURATION_CHANNEL,
  REQUEST_CURATION_CHANNELS,
  REQUEST_CURATION_CHANNEL_POSTS
} from "constants/actionTypes"
import FetchCurationChannelApi from "apis/FetchCurationChannelApi"
import FetchCurationChannelsApi from "apis/FetchCurationChannelsApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { CURATION_CHANNEL_TTL, CURATION_CHANNEL_POST_TTL } from "constants/ttl"
import FetchCurationChannelPostsApi from "apis/FetchCurationChannelPostsApi"

function* fetchCurationChannel(action) {
  try {
    const curationChannelId = action.payload.curationChannelId.toString()
    const isExisted = yield* isStoreExisted(
      "curationChannels",
      curationChannelId
    )
    const isExpired = yield* isStoreExpired(
      CURATION_CHANNEL_TTL,
      "curationChannels",
      curationChannelId
    )
    if (isExisted && !isExpired) {
      return
    }

    const fetchCurationChannelApi = new FetchCurationChannelApi({
      curationChannelId
    })
    const response = yield call(fetchCurationChannelApi.call)
    const curationChannel = response.data
    yield put(onRequestCurationChannelSuccess(curationChannel))
  } catch (e) {
    const { onError } = action.payload
    onError ? onError(e) : console.log(e)
  }
}

export function* watchRequestCurationChannel() {
  yield takeEvery(REQUEST_CURATION_CHANNEL, fetchCurationChannel)
}

function* fetchCurationChannels() {
  try {
    const curationChannelIds = ["1", "2", "3"]
    const isExisted = yield* curationChannelIds.reduce(
      (acc, cur) => acc && isStoreExisted("curationChannels", cur),
      true
    )
    const isExpired = yield* curationChannelIds.reduce(
      (acc, cur) =>
        acc || isStoreExpired(CURATION_CHANNEL_TTL, "curationChannels", cur),
      false
    )

    if (isExisted && !isExpired) {
      return
    }

    const fetchCurationChannelsApi = new FetchCurationChannelsApi()
    const response = yield call(fetchCurationChannelsApi.call)
    const curationChannels = response.data
    yield put(onRequestCurationChannelsSuccess(curationChannels))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestCurationChannels() {
  yield throttle(10000, REQUEST_CURATION_CHANNELS, fetchCurationChannels)
}

function* fetchCurationChannelPosts(action) {
  try {
    const { curationChannelId } = action.payload
    const isExisted = yield* isStoreExisted(
      "curationChannelPosts",
      curationChannelId
    )
    const isExpired = yield* isStoreExpired(
      CURATION_CHANNEL_POST_TTL,
      "curationChannelPosts",
      curationChannelId
    )
    if (isExisted && !isExpired) {
      return
    }
    const fetchCurationChannelPostsApi = new FetchCurationChannelPostsApi({
      curationChannelId
    })
    const response = yield call(fetchCurationChannelPostsApi.call)
    const curationChannelPosts = response.data
    yield put(
      onRequestCurationChannelPostsSuccess(
        curationChannelId,
        curationChannelPosts
      )
    )
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestCurationChannelPosts() {
  yield takeEvery(REQUEST_CURATION_CHANNEL_POSTS, fetchCurationChannelPosts)
}
