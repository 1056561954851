import {
  REQUEST_GIFTING_PLAN,
  REQUEST_GIFTING_PLAN_SUCCESS
} from "constants/actionTypes"

export const onRequestGiftingPlans = () => {
  return {
    type: REQUEST_GIFTING_PLAN
  }
}

export const onRequestGiftingPlansSuccess = giftingPlans => {
  return {
    type: REQUEST_GIFTING_PLAN_SUCCESS,
    payload: { giftingPlans }
  }
}
