import styled from "styled-components/macro"
import media from "utilities/media"

const Input = styled.input`
  width: 100%;
  color: #222222;
  text-align: ${props => (props.textAlign ? props.textAlign : "center")};
  padding-left: ${props => (props.textAlign === "left" ? "20px" : "")};
  border: solid 1px ${props => (props.error ? "#ff721a" : "#d4cfc1")};
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;

  ::placeholder {
    color: ${props => (props.opacity ? "#c9caca" : "")};
  }

  &:focus {
    border: solid 2px ${props => (props.error ? "#ff721a" : "#d4cfc1")};
  }

  ${media.desktop`
    font-size: 16px;
    padding-left: 18px;
    height: 52px;
    border-radius: ${props => (props.circle ? "26px" : "3px")};
  `};
  ${media.tablet`
    font-size: 14px;
    padding-left: 15px;
    height: 52px;
    border-radius: ${props => (props.circle ? "26px" : "3px")};
  `};
  ${media.mobile`
    font-size: 14px;
    height: 44px;
    padding-left: 15px;
    border-radius: ${props => (props.circle ? "22px" : "3px")};
  `};
`

export default Input
