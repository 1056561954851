const logEvent = (eventType, eventProperties = {}) => {
  window.amplitude.getInstance().logEvent(eventType, eventProperties)
}

const setUserId = userId => {
  window.amplitude.getInstance().setUserId(userId)
}

const setUserProperties = userProperties => {
  window.amplitude.getInstance().setUserProperties(userProperties)
}

const setOnce = (key, value) => {
  const { amplitude } = window
  const identify = new amplitude.Identify().setOnce(key, value)
  amplitude.getInstance().identify(identify)
}

const ampInitUserProperties = (userId, platform, alreadyTrial, inTrial) => {
  if (userId) {
    setUserId(userId)

    setUserProperties({
      "account type": platform,
      registration_type: platform,
      taken_trial: alreadyTrial,
      in_trial: inTrial
    })
  }
}

const object = {
  logEvent,
  setUserId,
  setUserProperties,
  setOnce,
  ampInitUserProperties
}

export default object
