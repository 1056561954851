import { put, takeEvery, throttle, call } from "redux-saga/effects"
import {
  onRequestBraintreeSubscriptionSuccess,
  onRequestBraintreeSubscriptionError
} from "actions/braintreeSubscription"
import { onStartLoading, onEndLoading } from "actions/loading"
import { REQUEST_BT_SUBSCRIPTION_LOADING } from "constants/loadingTypes"

import {
  REQUEST_BRAINTREE_SUBSCRIPTION,
  REQUEST_BRAINTREE_SUBSCRIPTION_THROTTLING
} from "constants/actionTypes"
import FetchBraintreeSubscriptionApi from "apis/FetchBraintreeSubscriptionApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { BRAINTREE_TTL } from "constants/ttl"

function* fetchBraintreeSubscriptionForce(action) {
  try {
    const { userId } = action.payload
    const fetchBraintreeSubscriptionApi = new FetchBraintreeSubscriptionApi({
      userId
    })
    yield put(onStartLoading(REQUEST_BT_SUBSCRIPTION_LOADING))
    const response = yield call(fetchBraintreeSubscriptionApi.call)
    const braintreeSubscription = response.data
    yield put(onRequestBraintreeSubscriptionSuccess(braintreeSubscription))
    yield put(onEndLoading(REQUEST_BT_SUBSCRIPTION_LOADING))
  } catch (e) {
    yield put(onRequestBraintreeSubscriptionError(e.response.status))
    yield put(onEndLoading(REQUEST_BT_SUBSCRIPTION_LOADING))
  }
}

export function* watchRequestbraintreeSubscription() {
  yield takeEvery(
    REQUEST_BRAINTREE_SUBSCRIPTION,
    fetchBraintreeSubscriptionForce
  )
}

function* fetchBraintreeSubscription(action) {
  try {
    const isExisted = yield* isStoreExisted("braintreeSubscription")
    const isExpired = yield* isStoreExpired(
      BRAINTREE_TTL,
      "braintreeSubscription"
    )
    if (isExisted && !isExpired) {
      return
    }

    const { userId } = action.payload
    const fetchBraintreeSubscriptionApi = new FetchBraintreeSubscriptionApi({
      userId
    })
    const response = yield call(fetchBraintreeSubscriptionApi.call)
    const braintreeSubscription = response.data
    yield put(onRequestBraintreeSubscriptionSuccess(braintreeSubscription))
  } catch (e) {
    yield put(onRequestBraintreeSubscriptionError(e.response.status))
  }
}

export function* watchRequestbraintreeSubscriptionThrottling() {
  yield throttle(
    10000,
    REQUEST_BRAINTREE_SUBSCRIPTION_THROTTLING,
    fetchBraintreeSubscription
  )
}
