import KonoApi from "../KonoApi"
import store from "../../store"

class FetchIssueArticlesApi extends KonoApi {
  constructor(data) {
    super(`/magazines/${data.issueId}/articles`)
  }

  call() {
    const currentUserId = store.getState().getIn(["user", "current", "id"])
    return this.axios.get(this.url, { params: { user_id: currentUserId } })
  }
}

export default FetchIssueArticlesApi
