import KonoApi from "../KonoApi"

class FetchCategoryApi extends KonoApi {
  constructor(data) {
    super(`/categories/${data.categoryId}`)
  }

  call(params) {
    return this.axios.get(this.url, {
      params: { book_list_id: params.bookListId }
    })
  }
}

export default FetchCategoryApi
