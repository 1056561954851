import { put, takeEvery, throttle, call } from "redux-saga/effects"
import camelcaseKeys from "camelcase-keys"
import {
  onRequestVipPeriods,
  onRequestVipPeriodsSuccess
} from "actions/vipPeriods"
import { onStartLoading, onEndLoading } from "actions/loading"
import { onRequestBraintreeSubscription } from "actions/braintreeSubscription"
import { onRequestFreeArticleQuota } from "actions/freeArticleQuota"
import { REQUEST_VIP_PERIODS_LOADING } from "constants/loadingTypes"
import {
  REQUEST_VIP_PERIODS,
  REQUEST_VIP_PERIODS_THROTTLING,
  REQUEST_VIP_STATUS
} from "constants/actionTypes"
import FetchVipPeriodsApi from "apis/FetchVipPeriodsApi"
import {
  isStoreExisted,
  isStoreExpired,
  selectStore
} from "utilities/storeCheck"
import { VIP_PERIODS_TTL } from "constants/ttl"

function* fetchVipPeriodsForce(action) {
  try {
    const { userId } = action.payload
    yield put(onStartLoading(REQUEST_VIP_PERIODS_LOADING))
    const fetchVipPeriodsApi = new FetchVipPeriodsApi({ userId })
    const response = yield call(fetchVipPeriodsApi.call)
    yield put(onRequestVipPeriodsSuccess(response.data))
    yield put(onEndLoading(REQUEST_VIP_PERIODS_LOADING))
  } catch (e) {
    console.log(e)
    yield put(onEndLoading(REQUEST_VIP_PERIODS_LOADING))
  }
}

export function* watchRequestVipPeriods() {
  yield takeEvery(REQUEST_VIP_PERIODS, fetchVipPeriodsForce)
}

function* fetchVipPeriods(action) {
  try {
    const { userId } = action.payload
    const isExisted = yield* isStoreExisted("vipPeriods")
    const isExpired = yield* isStoreExpired(VIP_PERIODS_TTL, "vipPeriods")
    if (isExisted && !isExpired) {
      return
    }

    const fetchVipPeriodsApi = new FetchVipPeriodsApi({ userId })
    const response = yield call(fetchVipPeriodsApi.call)
    yield put(onRequestVipPeriodsSuccess(response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestVipPeriodsThrottling() {
  yield throttle(1000, REQUEST_VIP_PERIODS_THROTTLING, fetchVipPeriods)
}

function* fetchUserVipStatus(action) {
  const { userId, throttling } = action.payload
  try {
    yield put(onRequestVipPeriods(userId, throttling))
    yield put(onRequestBraintreeSubscription(userId, throttling))
    yield put(onRequestFreeArticleQuota(userId))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestUserVipStatusThrottling() {
  yield throttle(3000, REQUEST_VIP_STATUS, fetchUserVipStatus)
}

export function* fetchAndReturnVipPeriods() {
  try {
    let vipPeriods = []

    const isExisted = yield* isStoreExisted("vipPeriods")
    const isExpired = yield* isStoreExpired(VIP_PERIODS_TTL, "vipPeriods")
    if (isExisted && !isExpired) {
      vipPeriods = yield selectStore("vipPeriods")
      vipPeriods = vipPeriods.toJS()
    } else {
      const userId = yield* selectStore("user", "current", "id")
      if (!userId) return
      const fetchVipPeriodsApi = new FetchVipPeriodsApi({ userId })
      const response = yield call(fetchVipPeriodsApi.call)
      vipPeriods = response.data
      yield put(onRequestVipPeriodsSuccess(vipPeriods))
      vipPeriods = camelcaseKeys(vipPeriods, { deep: true })
    }
    return vipPeriods
  } catch (e) {
    console.log(e)
  }
}
