import styled from "styled-components/macro"
import media from "utilities/media"

const Subtitle = styled.div`
  width: 100%;
  line-height: 1.4em;
  color: #222222;
  display: flex;
  ${media.desktop`
    font-size: 16px;
    margin-bottom: 8px;
  `};
  ${media.tablet`
    font-size: 14px;
    margin-bottom: 6px;
  `};
  ${media.mobile`
    font-size: 14px;
    margin-bottom: 6px;
  `};
`

export default Subtitle
