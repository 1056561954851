import { put, takeEvery, call } from "redux-saga/effects"
import {
  onRequestbraintreePlansSuccess,
  onRequestbraintreePlansError,
  onRequestbraintreePlans
} from "actions/braintreePlans"
import { onLogUpgradeTryUsePromotionCode } from "actions/trackingEvents"
import {
  REQUEST_BRAINTREE_PLANS,
  APPLY_BRAINTREE_PROMOTION_CODE
} from "constants/actionTypes"
import FetchBraintreePlansApi from "apis/FetchBraintreePlansApi"

function* fetchbraintreePlans(action) {
  try {
    const { userId, preserveError } = action.payload
    const fetchBraintreePlansApi = new FetchBraintreePlansApi({ userId })
    const response = yield call(fetchBraintreePlansApi.call)
    const braintreePlans = response.data
    yield put(
      onRequestbraintreePlansSuccess(braintreePlans, undefined, preserveError)
    )
  } catch (e) {
    yield put(onRequestbraintreePlansError(e.response.data.code))
  }
}

export function* watchRequestbraintreePlans() {
  yield takeEvery(REQUEST_BRAINTREE_PLANS, fetchbraintreePlans)
}

function* applyBraintreePromotionCode(action) {
  const { userId, promotionCode, fallback } = action.payload
  try {
    const fetchBraintreePlansApi = new FetchBraintreePlansApi({
      userId,
      promotionCode
    })
    const response = yield call(fetchBraintreePlansApi.call)
    const braintreePlans = response.data
    yield put(onRequestbraintreePlansSuccess(braintreePlans, promotionCode))
    yield put(onLogUpgradeTryUsePromotionCode(promotionCode))
  } catch (e) {
    const errorCode = e.response.data.code || e.response.status
    yield put(onRequestbraintreePlansError(errorCode, promotionCode))
    if (fallback) yield put(onRequestbraintreePlans(userId, true))
  }
}

export function* watchApplyBraintreePromotionCode() {
  yield takeEvery(APPLY_BRAINTREE_PROMOTION_CODE, applyBraintreePromotionCode)
}
