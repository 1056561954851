import { REQUEST_TEAM, REQUEST_TEAM_SUCCESS } from "constants/actionTypes"

export const onRequestTeam = () => {
  return {
    type: REQUEST_TEAM
  }
}

export const onRequestTeamSuccess = team => {
  return {
    type: REQUEST_TEAM_SUCCESS,
    payload: { team }
  }
}
