import KonoApi from "../KonoApi"

class RecordUserActivityApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/activate`)
  }

  call() {
    return this.axios.post(this.url)
  }
}

export default RecordUserActivityApi
