import { put, throttle, call } from "redux-saga/effects"
import { onRequestFollowedMagazinesSuccess } from "actions/followedMagazines"
import { REQUEST_FOLLOWED_MAGAZINES } from "constants/actionTypes"
import FetchLatestFollowedMagazinesApi from "apis/FetchLatestFollowedMagazinesApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { FOLLOWED_MAGAZINES_TTL } from "constants/ttl"

function* fetchFollowedMagazines(action) {
  try {
    const { userId, forceUpdate } = action.payload
    if (!forceUpdate) {
      const isExisted = yield* isStoreExisted("followedMagazines")
      const isExpired = yield* isStoreExpired(
        FOLLOWED_MAGAZINES_TTL,
        "followedMagazines"
      )
      if (isExisted && !isExpired) {
        return
      }
    }

    const fetchLatestFollowedMagazinesApi = new FetchLatestFollowedMagazinesApi(
      {
        userId
      }
    )
    const response = yield call(fetchLatestFollowedMagazinesApi.call)
    yield put(onRequestFollowedMagazinesSuccess(response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestfetchFollowedMagazines() {
  yield throttle(3000, REQUEST_FOLLOWED_MAGAZINES, fetchFollowedMagazines)
}
