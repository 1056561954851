const blacklist = [
  "search",
  "articles",
  "issueArticles",
  "bookmarks",
  "state",
  "recommendationTrees",
  "tstar",
  "articleAccessToken",
  "articleText",
  "header",
  "articleStatistics",
  "aptg",
  "braintree",
  "articleTextV2",
  "articleRelevantArticles",
  "readingProgresses",
  "readingStatuses",
  "toasts",
  "generalBlacklist",
  "loading"
]

export default blacklist
