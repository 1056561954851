import KonoApi from "../KonoApi"
import store from "../../store"

class SearchArticlesApi extends KonoApi {
  constructor() {
    super(`/search_articles`)
  }

  call(params) {
    const currentUserId = store.getState().getIn(["user", "current", "id"])
    return this.axios.get(this.url, {
      params: {
        q: params.q,
        user_id: currentUserId,
        book_list_id: params.bookListId
      }
    })
  }
}

export default SearchArticlesApi
