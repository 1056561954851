import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import back from "./assets/images/back.svg"
import menu from "./assets/images/show-navigation.svg"
import styled from "styled-components/macro"
import { onCloseHeader, onOpenHeader } from "actions/header"

const MainWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 108px;
  height: 32px;
  border-radius: 1000px;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: ${props => (props.opacity ? 0.6 : 1)};
  transition: opacity 0.7s ease-in-out;

  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`

const BackImage = styled.div`
  background-image: url(${back});
  width: 48px;
  height: 32px;
  background-size: 48px 32px;
`

const MenuImage = styled.div`
  background-image: url(${menu});
  width: 48px;
  height: 32px;
  background-size: 48px 32px;
`

class ArticleReaderAppend extends Component {
  constructor() {
    super()
    this.onClickBack = this.onClickBack.bind(this)
    this.onClickMenu = this.onClickMenu.bind(this)
  }

  onClickBack() {
    this.props.history.push(this.props.backHref)
  }

  onClickMenu() {
    this.props.isOpen ? this.props.onCloseHeader() : this.props.onOpenHeader()
  }

  render() {
    return (
      <MainWrapper opacity={!this.props.isOpen ? "true" : ""}>
        <BackImage onClick={this.onClickBack} />
        <MenuImage onClick={this.onClickMenu} />
      </MainWrapper>
    )
  }
}

const mapStateToProps = state => ({
  isOpen: state.getIn(["header", "isOpen"])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onCloseHeader, onOpenHeader }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ArticleReaderAppend))
