import KonoApi from "../KonoApi"

class FetchNewsApi extends KonoApi {
  constructor() {
    super(`/news`)
  }

  call(params) {
    return this.axios.get(this.url, { params })
  }
}

export default FetchNewsApi
