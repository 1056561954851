import {
  REQUEST_CURRENT_FREE_ISSUE,
  REQUEST_CURRENT_FREE_ISSUE_SUCCESS
} from "constants/actionTypes"

export const onRequestCurrentFreeIssue = onError => {
  return {
    type: REQUEST_CURRENT_FREE_ISSUE,
    payload: { onError }
  }
}

export const onRequestCurrentFreeIssueSuccess = freeIssue => {
  return {
    type: REQUEST_CURRENT_FREE_ISSUE_SUCCESS,
    payload: { freeIssue }
  }
}
