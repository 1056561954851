import styled from "styled-components/macro"

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;
  background-color: ${props =>
    props.focus ? (props.dark ? "#40403f" : "#f6f3ed") : "none"};
  padding: 0px 12px;
  color: ${props => (props.dark ? "#eee9e0" : "#222222")};
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: ${props => (props.dark ? "#40403f" : "#f6f3ed")};
  }
`

export default ItemWrapper
