import {
  REQUEST_BRAINTREE_SUBSCRIPTION_SUCCESS,
  REQUEST_BRAINTREE_SUBSCRIPTION_ERROR
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import { languageMapping } from "utilities/translations"

const initialState = fromJS({})

const formatNames = plans => {
  let final = {}
  Object.keys(plans).forEach(language => {
    const langKey = languageMapping[language]
    final[langKey] = plans[language].name
  })
  return final
}

const reduceMap = new Map([
  [
    REQUEST_BRAINTREE_SUBSCRIPTION_SUCCESS,
    (state, action) => {
      const { subscription } = action.payload
      return fromJS({
        id: subscription.id,
        status: subscription.status,
        nextBillAmount: subscription.next_bill_amount,
        currencyIsoCode: subscription.currency_iso_code,
        billingPeriodStartDate: subscription.billing_period_start_date,
        billingPeriodEndDate: subscription.billing_period_end_date,
        braintreePlanId: subscription.braintree_plan.id,
        name: subscription.braintree_plan.name,
        names: formatNames(subscription.braintree_plan.plans)
      })
    }
  ],
  [
    REQUEST_BRAINTREE_SUBSCRIPTION_ERROR,
    (state, action) => {
      const { errorStatus } = action.payload
      return fromJS({ errorStatus: errorStatus })
    }
  ]
])

export default handleActions(reduceMap, initialState)
