import {
  REQUEST_IOS_RECEIPTS,
  REQUEST_IOS_RECEIPTS_SUCCESS
} from "constants/actionTypes"

export const onRequestIosReceipts = userId => {
  return {
    type: REQUEST_IOS_RECEIPTS,
    payload: { userId }
  }
}

export const onRequestIosReceiptsSuccess = receipts => {
  return {
    type: REQUEST_IOS_RECEIPTS_SUCCESS,
    payload: { receipts }
  }
}
