import React from "react"
import logo from "./assets/images/logo.svg"
import styled from "styled-components"

const Row = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img.attrs({ src: logo, alt: "kono" })`
  height: auto;
  width: auto;
`

export default function LogoRow(props) {
  return (
    <Row>
      <Logo />
    </Row>
  )
}
