import KonoApi from "../KonoApi"

class FetchLatestFollowedMagazinesApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/followed_magazines`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchLatestFollowedMagazinesApi
