import styled from "styled-components/macro"
import media from "utilities/media"

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eee9e0;
  ${media.desktop`
    margin: 30px 0px;
  `};
  ${media.tablet`
    margin: 25px 0px;
  `};
  ${media.mobile`
    margin: 25px 0px;
  `};
`

export default Separator
