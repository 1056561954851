import {
  REQUEST_K4L_LIBRARIES,
  REQUEST_K4L_LIBRARIES_SUCCESS,
  SET_CURRENT_LIBRARY,
  CLEAR_CURRENT_LIBRARY,
  SET_CURRENT_LIBRARY_BY_ID
} from "constants/actionTypes"

export const onRequestK4lLibraries = () => {
  return {
    type: REQUEST_K4L_LIBRARIES
  }
}

export const onRequestK4lLibrariesSuccess = libraries => {
  return {
    type: REQUEST_K4L_LIBRARIES_SUCCESS,
    payload: { libraries }
  }
}

export const onSetCurrentLibrary = library => {
  return {
    type: SET_CURRENT_LIBRARY,
    payload: { library }
  }
}

export const onClearCurrentLibrary = () => {
  return {
    type: CLEAR_CURRENT_LIBRARY
  }
}

export const onSetCurrentLibraryById = libraryId => {
  return {
    type: SET_CURRENT_LIBRARY_BY_ID,
    payload: { libraryId }
  }
}
