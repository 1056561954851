import {
  REQUEST_BRAINTREE_PLANS_SUCCESS,
  CLEAR_BRAINTREE_PROMOTION_CODE,
  REQUEST_BRAINTREE_PLANS_ERROR
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import { languageMapping } from "utilities/translations"

const initialState = fromJS({})

const formatAddOnOrDiscount = aood => {
  return {
    numberOfBillingCycles: aood.number_of_billing_cycles,
    amount: aood.amount
  }
}

const renameLanguages = plans => {
  let final = {}
  Object.keys(plans).forEach(language => {
    const langKey = languageMapping[language]
    final[langKey] = plans[language]
  })
  return final
}

const formatPromotionCode = promotionCode => {
  return {
    id: promotionCode.id,
    description: promotionCode.description,
    descriptions: renameLanguages(promotionCode.descriptions),
    addOns: promotionCode.add_ons.map(formatAddOnOrDiscount),
    discounts: promotionCode.discounts.map(formatAddOnOrDiscount)
  }
}

const formatBraintreePlan = plan => {
  return {
    id: plan.id,
    name: plan.name,
    description: plan.description,
    plans: renameLanguages(plan.plans),
    billingFrequency: plan.billing_frequency,
    currencyIsoCode: plan.currency_iso_code,
    price: plan.price,
    promotionCodes: plan.promotion_codes.map(formatPromotionCode)
  }
}

const reducerMap = new Map([
  [
    REQUEST_BRAINTREE_PLANS_SUCCESS,
    (state, action) => {
      const braintreePlans =
        action.payload.braintreePlans.map(formatBraintreePlan)
      const { promotionCode, preserveError } = action.payload

      return preserveError
        ? state.set("plans", fromJS(braintreePlans))
        : state
            .set("plans", fromJS(braintreePlans))
            .set("promotionCode", promotionCode)
            .set("errorCode", undefined)
    }
  ],
  [
    REQUEST_BRAINTREE_PLANS_ERROR,
    (state, action) => {
      const { errorCode, promotionCode } = action.payload
      return state
        .set("promotionCode", promotionCode)
        .set("errorCode", errorCode)
    }
  ],
  [
    CLEAR_BRAINTREE_PROMOTION_CODE,
    state => {
      return state.set("promotionCode", undefined).set("errorCode", undefined)
    }
  ]
])

export default handleActions(reducerMap, initialState)
