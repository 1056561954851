import { REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { List, fromJS } from "immutable"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS,
    (state, action) => {
      const issues = action.payload.latestIssues
      const magazineIds = issues.map(issue => issue.title)
      return state.set(action.payload.categoryId, List(magazineIds))
    }
  ]
])

export default handleActions(reducerMap, initialState)
