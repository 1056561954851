import React, { Component } from "react"
// import StackTrace from "stacktrace-js"
// import store from "store"
// import { REPORT_ERROR } from "constants/actionTypes"
import ErrorPage from "./components/ErrorPage"

//We halt reporting error

// window.onerror = function(message, source, lineno, colno, error) {
// if (process.env.REACT_APP_SEND_ERROR_REPORT === "TRUE") {
//   StackTrace.fromError(error).then(err => {
//     store.dispatch({
//       type: REPORT_ERROR,
//       payload: {
//         url: window.location.href,
//         agent: window.navigator.userAgent,
//         message: `${error.name}: ${error.message}`,
//         stackFrames: err.map(stackFrame => stackFrame.toString())
//       }
//     })
//   })
// } else {
//   console.error(error)
// }
// }

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, info: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    this.setState({ error, info: info.componentStack })
  }

  render() {
    return this.state.hasError ? (
      <ErrorPage
        error={this.state.error && this.state.error.toString()}
        info={this.state.info}
      />
    ) : (
      this.props.children
    )
  }
}

export default ErrorBoundary
