export const getNow = () => {
  return new Date().getTime() / 1000
}

export const secondToDate = second => {
  if (typeof second !== "number" || second < 0) return ""

  const date = new Date(second * 1000)
  const month =
    date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
  const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
  return `${date.getFullYear()}.${month}.${day}`
}

export const beginEndSecondToDate = (beginAt, endAt) => {
  if (!beginAt || !endAt) return ""
  const beginDate = secondToDate(beginAt)
  let endDate = secondToDate(endAt)
  const [beginYear, beginMonth] = beginDate.split(".").slice(0, 2)
  const [endYear, endMonth] = endDate.split(".").slice(0, 2)

  if (beginYear === endYear) {
    endDate = beginMonth === endMonth ? endDate.slice(-2) : endDate.slice(-5)
  }
  return `${beginDate} ~ ${endDate}`
}

export const secondToHMS = sec => {
  if (!sec) return ""
  var hours = Math.floor(sec / 3600)
  var minutes = Math.floor((sec - hours * 3600) / 60)
  var seconds = sec - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = "0" + hours
  }
  if (minutes < 10) {
    minutes = "0" + minutes
  }
  if (seconds < 10) {
    seconds = "0" + seconds
  }
  return hours + ":" + minutes + ":" + seconds
}
