import React from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"
import media from "utilities/media"

const LinkWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  ${media.mobile`
    width: 50%;
  `}
`

const LinkItem = styled.a`
  font-size: 12px;
  line-height: 1.42;
  text-decoration: none;
  color: #222222;
  margin-top: 10px;
  margin-right: 70px;

  ${media.mobile`
    margin-right: 0;
  `}
`

function NavigationGroup(props) {
  function renderLink(link) {
    return link.external ? (
      <LinkItem
        href={link.to}
        target="_blank"
        rel="noopener noreferrer"
        key={link.text}
      >
        {props.t(link.text)}
      </LinkItem>
    ) : (
      <LinkItem as={Link} to={link.to} key={link.text}>
        {props.t(link.text)}
      </LinkItem>
    )
  }

  return <LinkWrapper>{props.links.map(renderLink)}</LinkWrapper>
}

export default withTranslation("container")(NavigationGroup)
