import KonoApi from "../KonoApi"
import store from "../../store"

class AptgBindSmsApi extends KonoApi {
  constructor() {
    super(`/aptg_users`)
  }

  call(params) {
    return this.axios.post(this.url, {
      user_id: store.getState().getIn(["user", "current", "id"]),
      confirmation_code: params.confirmationCode
    })
  }
}

export default AptgBindSmsApi
