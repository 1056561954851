import {
  REQUEST_ARTICLE_TEXT_V2_SUCCESS,
  REQUEST_ARTICLE_TEXT_ERROR
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import camelcaseKeys from "camelcase-keys"

const initialState = fromJS({})

function breakLineConverter(textJson) {
  textJson.elements
    .filter(elementCell => elementCell.element.dtype === "TEXT")
    .map(elementCell => {
      elementCell.element.content = elementCell.element.content.replace(
        /\n/g,
        "<br />"
      )
      return elementCell
    })
}

const reducerMap = new Map([
  [
    REQUEST_ARTICLE_TEXT_V2_SUCCESS,
    (state, action) => {
      breakLineConverter(action.payload.text)
      const text = camelcaseKeys(action.payload.text, { deep: true })
      const issueId = text.magazine.bid || "unknown"
      return state
        .setIn(
          [issueId, action.payload.articleId],
          fromJS({ text: text, error: false })
        )
        .filter((value, key) => key === issueId)
    }
  ],
  [
    REQUEST_ARTICLE_TEXT_ERROR,
    (state, action) => {
      return state.set(
        action.payload.articleId,
        fromJS({
          text: null,
          error: true
        })
      )
    }
  ]
])

export default handleActions(reducerMap, initialState)
