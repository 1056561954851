// value should be consistent with amplitude document
export const VIA_PERVIEW = "preview"
export const VIA_BREADCRUMB = "breadcrumb"
export const VIA_BANNER = "banner"
export const VIA_HEADER = "header"
export const VIA_FOOTER = "footer"
export const VIA_SIDEBAR = "sidebar"
export const VIA_BOOKMARKED_ARTICLE = "bookmarded_article"
export const VIA_CURATION_CHANNEL = "curation_channel"
export const VIA_CURATION_POST = "curation_post"
export const VIA_KONO_EDITOR = "kono_editor"
export const VIA_LATEST_ISSUES = "latest_issues"
export const VIA_MY_FOLLOW = "my_follow"
export const VIA_TRENDING_ARTICLE = "trending_article"
export const VIA_POPULAR_MAGAZINE = "popular_magazine"
export const VIA_RECOMMEND_ARTICLE = "recommend_article"
export const VIA_RECOMMEND_TITLE = "recommend_title"
export const VIA_WEEKLY_ISSUE = "weekly_issue"
