import {
  REQUEST_TRANSLATIONS,
  REQUEST_TRANSLATIONS_SUCCESS
} from "constants/actionTypes"

export const onRequestTranslations = () => ({
  type: REQUEST_TRANSLATIONS
})

export const onRequestTranslationsSuccess = translations => ({
  type: REQUEST_TRANSLATIONS_SUCCESS,
  payload: { translations }
})
