import {
  REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS,
  REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS,
  REQUEST_ISSUE_SUCCESS,
  REQUEST_MAGAZINE_ISSUES_SUCCESS,
  REQUEST_LATEST_ISSUE_SUCCESS,
  REQUEST_BOOKMARKED_ARTICLES_SUCCESS,
  REQUEST_ARTICLE_SUCCESS,
  REQUEST_LATEST_ISSUES_SUCCESS,
  REQUEST_RECOMMEND_ARTICLES_SUCCESS,
  REQUEST_CURATION_POST_ARTICLES_SUCCESS,
  REQUEST_CURRENT_FREE_ISSUE_SUCCESS,
  REQUEST_FOLLOWED_MAGAZINES_SUCCESS,
  REQUEST_POPULAR_ARTICLES_SUCCESS,
  REQUEST_ARTICLE_RELEVANT_ARTICLES_SUCCESS,
  REQUEST_READING_PROGRESSES_SUCCESS
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const formattedIssue = issue => {
  return {
    id: issue.bid,
    name: issue.issue,
    magazineId: issue.title,
    description: issue.description,
    isAdult: issue.is_adult,
    isNew: issue.is_new,
    magazineName: issue.name,
    hasTranslation: issue.has_translation,
    hasPdf: issue.has_pdf,
    isLeftFlip: issue.is_left_flip,
    hasAudio: issue.has_audio,
    hasVideo: issue.has_video,
    hasFitReading: issue.has_fit_reading,
    publishedDate: issue.published_date,
    covers: {
      large: issue.covers.large.url,
      medium: issue.covers.medium.url,
      small: issue.covers.small.url
    }
  }
}

const arrayReducer = (acc, cur) => {
  acc[cur.bid] = formattedIssue(cur)
  return acc
}

const reducerMap = new Map([
  [
    REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS,
    (state, action) => {
      const issues = action.payload.latestIssues
      const issuesMap = issues.reduce(arrayReducer, {})
      return state.merge(fromJS(issuesMap))
    }
  ],
  [
    REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS,
    (state, action) => {
      const issues = action.payload.issues
      const issuesMap = issues.reduce(arrayReducer, {})
      return state.merge(fromJS(issuesMap))
    }
  ],
  [
    REQUEST_ISSUE_SUCCESS,
    (state, action) => {
      const issue = formattedIssue(action.payload.issue)
      return state.setIn([issue.id], fromJS(issue))
    }
  ],
  [
    REQUEST_MAGAZINE_ISSUES_SUCCESS,
    (state, action) => {
      const issues = action.payload.issues
      const issuesMap = issues.reduce(arrayReducer, {})
      return state.merge(fromJS(issuesMap))
    }
  ],
  [
    REQUEST_LATEST_ISSUE_SUCCESS,
    (state, action) => {
      const issue = formattedIssue(action.payload.latestIssue)
      return state.setIn([issue.id], fromJS(issue))
    }
  ],
  [
    REQUEST_BOOKMARKED_ARTICLES_SUCCESS,
    (state, action) => {
      const issues = action.payload.bookmarks.map(bookmark => bookmark.magazine)
      const issuesMap = issues.reduce(arrayReducer, {})
      return state.merge(fromJS(issuesMap))
    }
  ],
  [
    REQUEST_ARTICLE_SUCCESS,
    (state, action) => {
      const issue = formattedIssue(action.payload.article.magazine)
      return state.setIn([issue.id], fromJS(issue))
    }
  ],
  [
    REQUEST_LATEST_ISSUES_SUCCESS,
    (state, action) => {
      const issues = action.payload.latestIssues
      const issuesMap = issues.reduce(arrayReducer, {})
      return state.merge(fromJS(issuesMap))
    }
  ],
  [
    REQUEST_RECOMMEND_ARTICLES_SUCCESS,
    (state, action) => {
      const recommend = action.payload.recommendArticles
      const issue = recommend.map(recommend => recommend.magazine)
      return state.merge(fromJS(issue.reduce(arrayReducer, {})))
    }
  ],
  [
    REQUEST_CURATION_POST_ARTICLES_SUCCESS,
    (state, action) => {
      const issues = action.payload.articles.map(a => a.magazine)
      const issuesMap = issues.reduce(arrayReducer, {})
      return state.merge(fromJS(issuesMap))
    }
  ],
  [
    REQUEST_CURRENT_FREE_ISSUE_SUCCESS,
    (state, action) => {
      const issue = formattedIssue(action.payload.freeIssue.magazine)
      return state.set(issue.id, fromJS(issue))
    }
  ],
  [
    REQUEST_FOLLOWED_MAGAZINES_SUCCESS,
    (state, action) => {
      const issuesMap = action.payload.followedIssues.reduce(arrayReducer, {})
      return state.merge(fromJS(issuesMap))
    }
  ],
  [
    REQUEST_POPULAR_ARTICLES_SUCCESS,
    (state, action) => {
      const issues = action.payload.popularArticles.map(
        popularArticle => popularArticle.magazine
      )
      const issuesMap = issues.reduce(arrayReducer, {})
      return state.merge(fromJS(issuesMap))
    }
  ],
  [
    REQUEST_ARTICLE_RELEVANT_ARTICLES_SUCCESS,
    (state, action) => {
      const issues = action.payload.relevantArticles.map(
        article => article.magazine
      )
      const issuesMap = issues.reduce(arrayReducer, {})
      return state.merge(fromJS(issuesMap))
    }
  ],
  [
    REQUEST_READING_PROGRESSES_SUCCESS,
    (state, action) => {
      const issues = action.payload.readingProgresses.map(rp => rp.magazine)
      const issuesMap = issues.reduce(arrayReducer, {})
      return state.merge(fromJS(issuesMap))
    }
  ]
])

export default handleActions(reducerMap, initialState)
