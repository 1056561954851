import {
  REQUEST_RECOMMENDATION_TREE,
  REQUEST_RECOMMENDATION_TREE_SUCCESS
} from "constants/actionTypes"

export const onRequestRecommendationTree = () => ({
  type: REQUEST_RECOMMENDATION_TREE
})

export const onRequestRecommendationTreeSuccess = tree => ({
  type: REQUEST_RECOMMENDATION_TREE_SUCCESS,
  payload: { tree }
})
