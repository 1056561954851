import {
  REQUEST_TSTAR_SMS_SUCCESS,
  REQUEST_TSTAR_SMS_ERROR,
  CLEAR_TSTAR_SMS_SENDER,
  DECREASE_TSTAR_SMS_TIME,
  BIND_TSTAR_SMS_SUCCESS,
  BIND_TSTAR_SMS_ERROR,
  CLEAR_TSTAR_SMS_BINDER,
  EXIST_TSTAR_TOKEN_SUCCESS,
  EXIST_TSTAR_TOKEN_ERROR,
  CLEAR_TSTAR_TOKEN,
  BIND_TSTAR_TOKEN_SUCCESS,
  BIND_TSTAR_TOKEN_ERROR
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({
  smsSender: {
    cdTime: 0,
    success: false,
    error: ""
  },
  smsBinder: {
    success: false,
    error: ""
  },
  tokenBinder: {
    success: false,
    error: ""
  },
  smsTokenBinder: {
    exist: undefined,
    success: undefined,
    error: "",
    errorStatus: ""
  }
})

const reducerMap = new Map([
  [
    REQUEST_TSTAR_SMS_SUCCESS,
    state => {
      return state
        .setIn(["smsSender", "success"], true)
        .setIn(["smsSender", "cdTime"], 120)
    }
  ],
  [
    REQUEST_TSTAR_SMS_ERROR,
    (state, action) => {
      return action.payload.cdTime
        ? state
            .setIn(["smsSender", "error"], action.payload.code)
            .setIn(["smsSender", "cdTime"], action.payload.cdTime)
        : state.setIn(["smsSender", "error"], action.payload.code)
    }
  ],
  [
    CLEAR_TSTAR_SMS_SENDER,
    state => {
      return state
        .setIn(["smsSender", "success"], false)
        .setIn(["smsSender", "error"], "")
        .setIn(["smsSender", "cdTime"], 0)
    }
  ],
  [
    DECREASE_TSTAR_SMS_TIME,
    state => {
      return state.setIn(
        ["smsSender", "cdTime"],
        state.getIn(["smsSender", "cdTime"]) - 1
      )
    }
  ],
  [
    BIND_TSTAR_SMS_SUCCESS,
    state => {
      return state.setIn(["smsBinder", "success"], true)
    }
  ],
  [
    BIND_TSTAR_SMS_ERROR,
    (state, action) => {
      return state.setIn(["smsBinder", "error"], action.payload.code)
    }
  ],
  [
    CLEAR_TSTAR_SMS_BINDER,
    state => {
      return state
        .setIn(["smsSender", "success"], false)
        .setIn(["smsSender", "error"], "")
    }
  ],
  [
    EXIST_TSTAR_TOKEN_SUCCESS,
    state => {
      return state.setIn(["smsTokenBinder", "exist"], true)
    }
  ],
  [
    EXIST_TSTAR_TOKEN_ERROR,
    (state, action) => {
      return state
        .setIn(["smsTokenBinder", "exist"], false)
        .setIn(["smsTokenBinder", "error"], action.payload.code)
        .setIn(["smsTokenBinder", "errorStatus"], action.payload.status)
    }
  ],
  [
    CLEAR_TSTAR_TOKEN,
    state => {
      return state
        .setIn(["smsTokenBinder", "exist"], undefined)
        .setIn(["smsTokenBinder", "success"], undefined)
        .setIn(["smsTokenBinder", "error"], "")
        .setIn(["smsTokenBinder", "errorStatus"], "")
    }
  ],
  [
    BIND_TSTAR_TOKEN_SUCCESS,
    state => {
      return state
        .setIn(["smsTokenBinder", "success"], true)
        .setIn(["smsTokenBinder", "error"], "")
        .setIn(["smsTokenBinder", "errorStatus"], "")
    }
  ],
  [
    BIND_TSTAR_TOKEN_ERROR,
    (state, action) => {
      return state
        .setIn(["smsTokenBinder", "success"], false)
        .setIn(["smsTokenBinder", "error"], action.payload.code)
        .setIn(["smsTokenBinder", "errorStatus"], action.payload.status)
    }
  ]
])

const tstar = handleActions(reducerMap, initialState)
export default tstar
