import {
  REQUEST_MAGAZINE,
  REQUEST_MAGAZINE_SUCCESS,
  REQUEST_MAGAZINE_YEARS,
  REQUEST_MAGAZINE_YEARS_SUCCESS,
  REQUEST_MAGAZINE_YEAR_ISSUES,
  REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS,
  REQUEST_MAGAZINE_ISSUES,
  REQUEST_MAGAZINE_ISSUES_SUCCESS,
  REQUEST_MAGAZINES,
  REQUEST_MAGAZINES_SUCCESS,
  REQUEST_POPULAR_MAGAZINES,
  REQUEST_POPULAR_MAGAZINES_SUCCESS,
  REQUEST_RECOMMENDED_MAGAZINES,
  REQUEST_RECOMMENDED_MAGAZINES_SUCCESS
} from "constants/actionTypes"

export const onRequestMagazine = (magazineId, onError) => {
  return {
    type: REQUEST_MAGAZINE,
    payload: { magazineId, onError }
  }
}

export const onRequestMagazineSuccess = magazine => {
  return {
    type: REQUEST_MAGAZINE_SUCCESS,
    payload: { magazine }
  }
}

export const onRequestMagazineYears = magazineId => {
  return {
    type: REQUEST_MAGAZINE_YEARS,
    payload: { magazineId }
  }
}

export const onRequestMagazineYearsSuccess = (magazineId, years) => {
  return {
    type: REQUEST_MAGAZINE_YEARS_SUCCESS,
    payload: { magazineId, years }
  }
}

export const onRequestMagazineYearIssues = (magazineId, year) => {
  return {
    type: REQUEST_MAGAZINE_YEAR_ISSUES,
    payload: { magazineId, year }
  }
}

export const onRequestMagazineYearIssuesSuccess = (
  magazineId,
  year,
  issues
) => {
  return {
    type: REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS,
    payload: { magazineId, year, issues }
  }
}

export const onRequestMagazineIssues = magazineId => {
  return {
    type: REQUEST_MAGAZINE_ISSUES,
    payload: { magazineId }
  }
}

export const onRequestMagazineIssuesSuccess = (magazineId, issues) => {
  return {
    type: REQUEST_MAGAZINE_ISSUES_SUCCESS,
    payload: { magazineId, issues }
  }
}

export const onRequestMagazines = bookListId => {
  return {
    type: REQUEST_MAGAZINES,
    payload: { bookListId }
  }
}

export const onRequestMagazinesSuccess = magazines => {
  return {
    type: REQUEST_MAGAZINES_SUCCESS,
    payload: { magazines }
  }
}

export const onRequestPopularMagazines = () => {
  return {
    type: REQUEST_POPULAR_MAGAZINES
  }
}

export const onRequestPopularMagazinesSuccess = magazines => {
  return {
    type: REQUEST_POPULAR_MAGAZINES_SUCCESS,
    payload: { magazines }
  }
}

export const onRequestRecommendedMagazines = userId => {
  return {
    type: REQUEST_RECOMMENDED_MAGAZINES,
    payload: { userId }
  }
}

export const onRequestRecommendedMagazinesSuccess = magazines => {
  return {
    type: REQUEST_RECOMMENDED_MAGAZINES_SUCCESS,
    payload: { magazines }
  }
}
