import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestCategoriesSuccess } from "actions/categories"
import { REQUEST_CATEGORIES } from "constants/actionTypes"
import FetchCategoryGroupsApi from "apis/FetchCategoryGroupsApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { CATEGORIES_TTL } from "constants/ttl"
import ApiLock from "utilities/ApiLock"

function* fetchCategories(action) {
  const lock = yield call(ApiLock.acquireLock, `library-category`, 5000)

  try {
    const { bookListId, language } = action.payload
    if (!bookListId) return

    const isExisted = yield* isStoreExisted("libraries")
    const isExpired = yield* isStoreExpired(CATEGORIES_TTL, "libraries")
    if (isExisted && !isExpired) {
      return
    }
    const fetchCategoryGroupsApi = new FetchCategoryGroupsApi({
      bookListId
    })

    const response = yield call(fetchCategoryGroupsApi.call, { language })
    const categoryGroups = response.data
    yield put(onRequestCategoriesSuccess(categoryGroups))
  } catch (e) {
    console.log(e)
  } finally {
    lock.release()
  }
}

export function* watchRequestCategories() {
  yield takeEvery(REQUEST_CATEGORIES, fetchCategories)
}
