import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestCurationPostArticlesSuccess } from "actions/curationPostArticles"
import { REQUEST_CURATION_POST_ARTICLES } from "constants/actionTypes"
import FetchCurationPostArticlesApi from "apis/FetchCurationPostArticlesApi"

function* fetchCurationPostArticles(action) {
  try {
    const curationPostId = action.payload.curationPostId
    const fetchCurationPostArticlesApi = new FetchCurationPostArticlesApi({
      curationPostId
    })
    const response = yield call(fetchCurationPostArticlesApi.call)
    const articles = response.data
    yield put(onRequestCurationPostArticlesSuccess(articles, curationPostId))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestCurationPostArticles() {
  yield takeEvery(REQUEST_CURATION_POST_ARTICLES, fetchCurationPostArticles)
}
