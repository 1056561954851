import { put, takeEvery, call } from "redux-saga/effects"
import { detectWebPSuccess } from "actions/webP"
import { DETECT_WEBP } from "constants/actionTypes"

const kTestImages = {
  lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
  lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
  alpha:
    "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
  animation:
    "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
}

function checkWebP(feature) {
  return new Promise(resolve => {
    let img = new Image()
    img.onload = () => resolve(img.width > 0 && img.height > 0)
    img.onerror = () => resolve(false)
    img.src = "data:image/webp;base64," + kTestImages[feature]
  })
}

function* detectWebP() {
  try {
    const lossy = yield call(checkWebP, "lossy")
    const lossless = yield call(checkWebP, "lossless")
    const alpha = yield call(checkWebP, "alpha")
    const animation = yield call(checkWebP, "animation")
    const acceptWebP = lossy && lossless && alpha && animation
    const result = {
      lossy,
      lossless,
      alpha,
      animation,
      acceptWebP
    }
    yield put(detectWebPSuccess(result))
  } catch (e) {
    console.log(e)
  }
}

export function* watchDetectWebP() {
  yield takeEvery(DETECT_WEBP, detectWebP)
}
