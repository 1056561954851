import { REQUEST_IBON_PLANS_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS([])

const formatIbonPlan = plan => {
  return {
    id: plan.id,
    name: plan.name,
    description: plan.description,
    amount: plan.amount,
    vipType: plan.vip_type,
    vipLength: plan.vip_length,
    billingFrequency: Math.round(plan.vip_length / 86400 / 30)
  }
}

const reducerMap = new Map([
  [
    REQUEST_IBON_PLANS_SUCCESS,
    (state, action) => {
      const plans = action.payload.ibonPlans.map(formatIbonPlan)
      return fromJS(plans)
    }
  ]
])

export default handleActions(reducerMap, initialState)
