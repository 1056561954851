import React from "react"
import { NoStyledH1 } from "components/presentational/Headline"
import styled from "styled-components/macro"
import media from "utilities/media"

const ContentWrapper = styled.div`
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  height: 100%;
  color: ${props => props.fontColor || "#ffffff"};

  ${media.desktop`
    width: 940px;
    left: calc(50vw - 470px);
    font-size: 40px;
  `};
  ${media.tablet`
    width: 460px;
    left: calc(50vw - 230px);
    font-size: 24px;
  `};
  ${media.mobile`
    width: calc(100vw - 40px);
    left: 20px;
    font-size: 26px;
    text-shadow: ${props =>
      props.textShadow ? "2px 2px 4px rgba(0, 0, 0, 0.5)" : ""};    
  `};
`

const MainImage = styled.img.attrs({ alt: "" })`
  height: 100%;
`

const MainWrapper = styled.div`
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  background-image: url(${props => props.backgroundImage});
  ${media.desktop`
    height: 400px;
    background-size: 1px 400px;
  `};
  ${media.tablet`
    height: 200px;
    background-size: 1px 200px;
  `};
  ${media.mobile`
    height: 219px;
    background-size: 1px 219px;
  `};
`

export default function FullScreenWidthBanner(props) {
  return (
    <MainWrapper backgroundImage={props.backgroundImage}>
      <MainImage src={props.mainImage} />
      <ContentWrapper fontColor={props.fontColor} textShadow={!props.fontColor}>
        <NoStyledH1>{props.headline}</NoStyledH1>
      </ContentWrapper>
    </MainWrapper>
  )
}
