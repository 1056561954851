import {
  REQUEST_K4L_LIBRARIES_SUCCESS,
  SET_CURRENT_LIBRARY,
  CLEAR_CURRENT_LIBRARY
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import camelcaseKeys from "camelcase-keys"

const initialState = fromJS({ all: undefined, current: undefined })

const reducerMap = new Map([
  [
    REQUEST_K4L_LIBRARIES_SUCCESS,
    (state, action) => {
      const libraries = fromJS(
        camelcaseKeys(action.payload.libraries, { deep: true })
      )
      return state.set("all", libraries)
    }
  ],
  [
    SET_CURRENT_LIBRARY,
    (state, action) => {
      return state.set(
        "current",
        fromJS(camelcaseKeys(action.payload.library, { deep: true }))
      )
    }
  ],
  [
    CLEAR_CURRENT_LIBRARY,
    (state, action) => {
      return state.set("current", null)
    }
  ]
])

const k4lLibraries = handleActions(reducerMap, initialState)

export default k4lLibraries
