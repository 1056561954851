import {
  SET_ARTICLE_VIEWER_BACK_HREF,
  CLEAR_ARTICLE_VIEWER_BUNS
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    SET_ARTICLE_VIEWER_BACK_HREF,
    (state, action) => {
      return state.set("backHref", action.payload.backHref)
    }
  ],
  [
    CLEAR_ARTICLE_VIEWER_BUNS,
    state => {
      return initialState
    }
  ]
])

export default handleActions(reducerMap, initialState)
