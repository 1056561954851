import KonoApi from "../KonoApi"

class WechatLoginApi extends KonoApi {
  constructor() {
    super(`/users/wechat/login`)
  }

  call(params) {
    return this.axios.post(this.url, {
      openid: params.openId,
      unionid: params.unionId,
      access_token: params.accessToken,
      referrer_token: params.referrerToken
    })
  }
}

export default WechatLoginApi
