import axios from "axios"
import KonoApi from "../KonoApi"

class ResetPasswordApi extends KonoApi {
  constructor() {
    super(`/users/reset_password`)
  }

  call(params) {
    return axios.post(this.url, {
      token: params.token,
      password: params.password
    })
  }
}

export default ResetPasswordApi
