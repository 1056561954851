import {
  REQUEST_BOOKMARKS_STATISTICS,
  REQUEST_BOOKMARKS_STATISTICS_SUCCESS,
  REQUEST_BOOKMARKS,
  REQUEST_BOOKMARKS_SUCCESS,
  CREATE_BOOKMARK,
  CREATE_BOOKMARK_SUCCESS,
  CREATE_BOOKMARK_ERROR,
  DELETE_BOOKMARK,
  DELETE_BOOKMARK_SUCCESS,
  DELETE_BOOKMARK_ERROR,
  CLEAN_BOOKMARK_ARTICLES
} from "constants/actionTypes"

export const onRequestBookmarks = (userId, limit = 12, offset = 0) => ({
  type: REQUEST_BOOKMARKS,
  payload: { userId, limit, offset }
})

export const onRequestBookmarksSuccess = bookmarks => ({
  type: REQUEST_BOOKMARKS_SUCCESS,
  payload: { bookmarks }
})

export const onRequestBookmarksStatistics = userId => ({
  type: REQUEST_BOOKMARKS_STATISTICS,
  payload: { userId }
})

export const onRequestBookmarksStatisticsSuccess = statistics => ({
  type: REQUEST_BOOKMARKS_STATISTICS_SUCCESS,
  payload: { statistics }
})

export const onCreateBookmark = (userId, articleId) => ({
  type: CREATE_BOOKMARK,
  payload: { userId, articleId }
})

export const onCreateBookmarkSuccess = bookmark => ({
  type: CREATE_BOOKMARK_SUCCESS,
  payload: { bookmark }
})

export const onCreateBookmarkError = articleId => ({
  type: CREATE_BOOKMARK_ERROR,
  payload: { articleId }
})

export const onDeleteBookmark = (userId, articleId) => ({
  type: DELETE_BOOKMARK,
  payload: { userId, articleId }
})

export const onDeleteBookmarkSuccess = articleId => ({
  type: DELETE_BOOKMARK_SUCCESS,
  payload: { articleId }
})

export const onDeleteBookmarkError = articleId => ({
  type: DELETE_BOOKMARK_ERROR,
  payload: { articleId }
})

export const onCleanBookmark = () => ({ type: CLEAN_BOOKMARK_ARTICLES })
