import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import {
  REQUEST_SEARCH_ARTICLES,
  REQUEST_SEARCH_ARTICLES_SUCCESS
} from "constants/actionTypes"

const initialState = fromJS({ default: { offset: 0, hitArticles: [] } })

const reducerMap = new Map([
  [
    REQUEST_SEARCH_ARTICLES,
    () => {
      return initialState
    }
  ],
  [
    REQUEST_SEARCH_ARTICLES_SUCCESS,
    (state, action) => {
      const { sortType, result } = action.payload
      if (result === null) return state
      const articles = result.data
      const prevArticleIds = state.getIn([sortType, "hitArticles"]).toJS()
      const articleIds = articles
        .map(article => article.article_id)
        .filter(articleId => !prevArticleIds.includes(articleId))

      return state
        .setIn([sortType, "offset"], result.offset)
        .setIn(
          [sortType, "hitArticles"],
          fromJS([...prevArticleIds, ...articleIds])
        )
    }
  ]
])

export default handleActions(reducerMap, initialState)
