import KonoApi from "../KonoApi"

class FetchIbonPlansApi extends KonoApi {
  constructor() {
    super("/ibon_plans")
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchIbonPlansApi
