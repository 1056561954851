import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import {
  REQUEST_MAGAZINE_YEAR_READING_STATUSES_SUCCESS,
  REQUEST_ISSUE_READING_STATUS_SUCCESS
} from "constants/actionTypes"
import camelcaseKeys from "camelcase-keys"

const initialState = fromJS({})

const arrayReducer = (acc, cur) => {
  acc[cur.issue_id] = camelcaseKeys(cur, { deep: true })
  return acc
}

const reducerMap = new Map([
  [
    REQUEST_MAGAZINE_YEAR_READING_STATUSES_SUCCESS,
    (state, action) => {
      const { readingStatuses } = action.payload
      const statusMap = readingStatuses.reduce(arrayReducer, {})

      return state.merge(fromJS(statusMap))
    }
  ],
  [
    REQUEST_ISSUE_READING_STATUS_SUCCESS,
    (state, action) => {
      const { readingStatus } = action.payload

      return state.set(
        readingStatus.issue_id,
        fromJS(camelcaseKeys(readingStatus, { deep: true }))
      )
    }
  ]
])

export default handleActions(reducerMap, initialState)
