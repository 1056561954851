import {
  LOG_IN_SUCCESS,
  SIGN_UP_SUCCESS,
  REQUEST_USER_PLATFORMS_SUCCESS,
  CLEAR_USER_PLATFORMS,
  UPDATE_USER_SUCCESS,
  REQUEST_USER_INFO_SUCCESS,
  REQUEST_VIP_PERIODS_SUCCESS,
  REQUEST_BRAINTREE_SUBSCRIPTION_SUCCESS,
  REQUEST_FREE_ARTICLE_QUOTA_SUCCESS,
  REQUEST_USER_PICTURE_SUCCESS,
  TEMPORARY_LOGIN_SUCCESS
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import {
  findCurrentVip,
  isAutoRenewExcludeBraintree
} from "utilities/vipPeriodMap"

const initialState = fromJS({
  current: null,
  platforms: null,
  isAutoRenew: false,
  isHami: false,
  btStatus: null,
  currentVipPeriod: null
})

const transformUser = user => {
  return fromJS({
    id: user.kid,
    email: user.primary_email,
    nickname: user.nickname,
    token: user.token,
    picture: user.picture,
    isNew: user.is_new,
    emailConfirmation: user.email_confirmation,
    adult: user.adult,
    platform: user.platform,
    gender: user.gender,
    trialGroup: user.trial_group,
    createdAt: user.created_at
  })
}

const reducerMap = new Map([
  [
    LOG_IN_SUCCESS,
    (state, action) => {
      return state.set("current", transformUser(action.payload))
    }
  ],
  [
    SIGN_UP_SUCCESS,
    (state, action) => {
      return state.set("current", transformUser(action.payload))
    }
  ],
  [
    REQUEST_USER_PLATFORMS_SUCCESS,
    (state, action) => {
      return state.set(
        "platforms",
        fromJS(action.payload.platforms.map(platform => platform.platform))
      )
    }
  ],
  [
    CLEAR_USER_PLATFORMS,
    state => {
      return state.set("platforms", null)
    }
  ],
  [
    UPDATE_USER_SUCCESS,
    (state, action) => {
      const user = action.payload
      return state
        .setIn(["current", "nickname"], user.nickname)
        .setIn(["current", "adult"], user.adult)
    }
  ],
  [
    REQUEST_USER_INFO_SUCCESS,
    (state, action) => {
      const user = action.payload
      // do not reset token, isNew and picture
      // Facebook picture will be broken if its token expires.
      // Use /users/:userId/picture instead
      return state
        .setIn(["current", "email"], user.primary_email)
        .setIn(["current", "nickname"], user.nickname)
        .setIn(["current", "emailConfirmation"], user.email_confirmation)
        .setIn(["current", "adult"], user.adult)
        .setIn(["current", "platform"], user.platform)
        .setIn(["current", "gender"], user.gender)
        .setIn(["current", "trialGroup"], user.trial_group)
    }
  ],
  [
    REQUEST_VIP_PERIODS_SUCCESS,
    (state, action) => {
      const vipPeriods = fromJS(
        action.payload.vipPeriods.map(vipPeriod => {
          return {
            id: vipPeriod.id,
            planType: vipPeriod.plan_type,
            paymentType: vipPeriod.payment_type,
            currency: vipPeriod.currency,
            amount: vipPeriod.amount,
            beginAt: vipPeriod.begin_at,
            endAt: vipPeriod.end_at,
            paid: vipPeriod.paid
          }
        })
      )
      const currentVipPeriod = findCurrentVip(vipPeriods)
      return currentVipPeriod &&
        isAutoRenewExcludeBraintree(currentVipPeriod.get("paymentType"))
        ? state
            .set("isAutoRenew", true)
            .set("currentVipPeriod", currentVipPeriod)
        : state.set("currentVipPeriod", currentVipPeriod)
    }
  ],
  [
    REQUEST_BRAINTREE_SUBSCRIPTION_SUCCESS,
    (state, action) => {
      const subscriptionStatus = action.payload.subscription.status
      return subscriptionStatus === "Active" || subscriptionStatus === "Pending"
        ? state.set("isAutoRenew", true).set("btStatus", subscriptionStatus)
        : state
    }
  ],
  [
    REQUEST_FREE_ARTICLE_QUOTA_SUCCESS,
    (state, action) => {
      const quota = action.payload.quota.active
      return state.set("isHami", quota > 0)
    }
  ],
  [
    REQUEST_USER_PICTURE_SUCCESS,
    (state, action) => {
      const { url } = action.payload
      return state.setIn(["current", "picture"], url)
    }
  ],
  [
    TEMPORARY_LOGIN_SUCCESS,
    (state, action) => {
      return state.set("current", transformUser(action.payload.user))
    }
  ]
])

export default handleActions(reducerMap, initialState)
