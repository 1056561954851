import KonoApi from "../KonoApi"

class FetchFreeArticleQuotaApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/free_article_quotas`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchFreeArticleQuotaApi
