import { persistStore } from "redux-persist-immutable"

import store from "../store"
import blacklist from "../blacklist"

export default function configureStore() {
  return new Promise((resolve, reject) => {
    try {
      persistStore(store, { blacklist: blacklist }, () => resolve(store))
    } catch (e) {
      reject(e)
    }
  })
}
