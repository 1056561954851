import KonoApi from "../KonoApi"

class CreateBookmarkApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/bookmarks`)
    this.articleIds = [data.articleId]
  }

  call() {
    return this.axios.post(this.url, { article_ids: this.articleIds })
  }
}

export default CreateBookmarkApi
