import {
  TOGGLE_SIDE_TAB_MENU,
  CLOSE_SIDE_TAB_MENU
} from "constants/actionTypes"

export const onToggleSideTabMenu = () => ({
  type: TOGGLE_SIDE_TAB_MENU
})

export const onCloseSideTabMenu = () => ({
  type: CLOSE_SIDE_TAB_MENU
})
