import {
  REQUEST_HAMI_READ_COUNT,
  REQUEST_HAMI_READ_COUNT_SUCCESS
} from "constants/actionTypes"

export const onRequestHamiReadCount = userId => {
  return {
    type: REQUEST_HAMI_READ_COUNT,
    payload: { userId }
  }
}

export const onRequestHamiReadCountSuccess = count => {
  return {
    type: REQUEST_HAMI_READ_COUNT_SUCCESS,
    payload: { count }
  }
}
