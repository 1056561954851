import { SET_BOOK_LIST_ID } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({
  bookListId: null
})

const reducerMap = new Map([
  [
    SET_BOOK_LIST_ID,
    (state, action) => state.set("bookListId", action.payload.bookListId)
  ]
])

export default handleActions(reducerMap, initialState)
