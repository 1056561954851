import { all } from "redux-saga/effects"
import {
  watchLogIn,
  watchSignUp,
  watchSignUpSmartone,
  watchSearchPlatforms,
  watchResetPassword,
  watchRemindPassword,
  watchUpdateUser,
  watchRequestUserInfo,
  watchRequestWechatAccessToken,
  watchWechatLogIn,
  watchK4lLogIn,
  watchK4lSignUp,
  watchRequestUserPicture,
  watchTemporaryLogin
} from "./authenticationSaga"
import { watchRequestCategories } from "./librariesSaga"
import { watchRequestCategory } from "./categoriesSaga"
import { watchRequestCategoryLatestIssues } from "./categoryLatestIssuesSaga"
import {
  watchRequestfetchLatestIssue,
  watchRequestfetchLatestIssues
} from "./latestIssueSaga"
import { watchRequestBanners } from "./bannersSaga"
import { watchRequestfetchFollowedMagazines } from "./followedMagazinesSaga"
import { watchFollowMagazine } from "./followMagazineSaga"
import { watchUnfollowMagazine } from "./unfollowMagazineSaga"
import {
  watchRequestMagazine,
  watchRequestMagazineYears,
  watchRequestMagazineYearIssues,
  watchRequestMagazineIssues,
  watchRequestMagazines,
  watchRequestPopularMagazines,
  watchRequestRecommendedMagazines
} from "./magazineSaga"
import {
  watchRequestIssue,
  watchRequestIssueThumbnails,
  watchRequestIssueArticles
} from "./issueSaga"
import {
  watchRequestBookmarks,
  watchRequestBookmarksStatistics,
  watchCreateBookmark,
  watchDeleteBookmark
} from "./bookmarksSaga"
import {
  watchSearchArticles,
  watchArticle,
  watchRequestPopularArticles,
  watchRequestRecommendArticles,
  watchArticleAccessToken,
  watchArticleText,
  watchArticleRelevantArticles
} from "./articleSaga"
import { watchRequestCurrentFreeIssue } from "./freeIssuesSaga"
import { watchRequestCurationPost } from "./curationPostSaga"
import { watchRequestCurationPostArticles } from "./curationPostArticlesSaga"
import {
  watchRequestCurationChannel,
  watchRequestCurationChannels,
  watchRequestCurationChannelPosts
} from "./curationChannelsSaga"
import { watchRecommendationTree } from "./recommendationTreeSaga"
import { watchClientInfo } from "./clientInfoSaga"
import {
  watchRequestVipPeriods,
  watchRequestVipPeriodsThrottling,
  watchRequestUserVipStatusThrottling
} from "./vipPeriodsSaga"
import {
  watchRequestTstarSms,
  watchBindTstarSms,
  watchExistTstarToken,
  watchBindTstarToken
} from "./tstarSaga"
import {
  watchRequestAptgSms,
  watchBindAptgSms,
  watchExistAptgToken,
  watchBindAptgToken
} from "./aptgSaga"
import {
  watchRequestbraintreePlans,
  watchApplyBraintreePromotionCode
} from "./braintreePlansSaga"
import { watchRequestIbonPlans } from "./ibonPlansSaga"
import { watchRequestGiftingPlans } from "./giftingPlansSaga"
import { watchRequestSentGifts } from "./sentGiftsSaga"
import {
  watchRequestbraintreeSubscription,
  watchRequestbraintreeSubscriptionThrottling
} from "./braintreeSubscriptionSaga"
import { watchRequestHamiReadCount } from "./hamiReadCount"
import { watchRequestFreeArticleQuota } from "./freeArticleQuotaSaga"
import { watchRequestBraintreePaymentMethods } from "./braintreePaymentMethodsSaga"
import { watchDetectWebP } from "./webPSaga"
import { watchRecordUserActivity } from "./userActivitySaga"
import {
  watchStartConsumingArticleReadEvents,
  watchOnConsumingArticleReadEvents,
  watchOnProducingArticleReadEvents,
  watchEndConsumingArticleReadEvents,
  watchSendSqsMessages,
  watchInitCurrentReadingInfo,
  watchChangePageNumber
} from "./articleReadEventsSaga"
import { watchReportError } from "./errorSaga"
import {
  watchSignUpEvent,
  watchSignInEvent,
  watchFollowTitleEvent,
  watchUnfollowTitleEvent,
  watchBookmarkEvent,
  watchUnbookmarkEvent,
  watchTelecomBindingEvent,
  watchOpenArticleEvent,
  watchOpenCurationPostEvent,
  watchOpenCurationPostArticleEvent,
  watchOpenRecommendArticleEvent,
  watchUpgradeTryPurchaseEvent,
  watchUpgradePlanSelectedEvent,
  watchUpgradeSuccessEvent,
  watchUpgradeTryUsePromotionCodeEvent,
  watchUpgradeCouponCodeEvent,
  watchBannerClickEvent,
  watchSearchEvent,
  watchSearchResultMagazineClickEvent,
  watchSearchResultArticleClickEvent,
  watchFreeMagazineIntroDisplayEvent,
  watchFreeMagazineIntroActionClickEvent,
  watchRecommendQuestionAskedEvent,
  watchRecommendListEnterEvent,
  watchShareArticleEvent,
  watchChangeStateV0Event,
  watchDownloadAppEvent,
  watchResendConfirmationEmailEvent,
  watchWidgetBarClickEvent,
  watchGetIbonPincodeSuccessEvent,
  watchPlayAudioEvent,
  watchBuyGiftingEvent,
  watchHeaderClickEvent,
  watchPreviewArticleDownloadAppClickEvent,
  watchHeaderNavigationCategoryClickEvent,
  watchHeaderNavigationLibraryClickEvent,
  watchStartTrialShowEvent,
  watchStartTrialClickEvent,
  watchOpenReportEvent,
  watchClickSurveyEvent,
  watchViewPricePageEvent,
  watchClickSubscribeEvent,
  watchViewPaymentPageEvent,
  watchConfirmSubscribeEvent,
  watchViewThankYouPageEvent
} from "./trackingEventsSaga"
import {
  watchHomeMyFollowClickEvent,
  watchHomeRecommendMagazineClickEvent,
  watchHomeWeeklyIssueClickEvent,
  watchHomeLatestIssueClickEvent,
  watchHomeCurationChannelClickEvent,
  watchHomeCurationPostClickEvent,
  watchHomeCurationPostArticleClickEvent,
  watchHomeRecommendArticleClickEvent,
  watchHomePopularMagazineClickEvent,
  watchHomePopularArticleClickEvent,
  watchHomeMyBookmarkClickEvent,
  watchEnterHomeEvent
} from "./trackingHomeEventsSaga"
import { watchForceUpdateVersion } from "./versionSaga"
import {
  watchRequestK4lLibraries,
  watchSetCurrentLibraryById
} from "./k4lLibrariesSaga"
import { watchRequestIosReceipts } from "./iosReceiptsSaga"
import {
  watchRequestReadingProgresses,
  watchDeleteReadingProgress,
  watchDeleteReadingProgresses,
  watchUpdateReadingProgress,
  watchRequestIssueReadingProgress
} from "./readingProgressesSaga"
import {
  watchRequestMagazineYearReadingStatuses,
  watchRequestIssueReadingStatus
} from "./readingStatusesSaga"
import { watchOnUpdateTaskRecords } from "./taskRecordsSaga"
import { watchRequestTranslations } from "./translationsSaga"
import { watchRequestSubscriptionInfo } from "./subscriptionInfoSaga"
import {
  watchInitUserPropertiesEvent,
  watchSignEvent,
  watchRequestVipPeriodsEvent,
  watchSetFirstLandingPageEvent,
  watchChangeStateEvent,
  watchClickOobeNextStepEvent,
  watchCompleteOobeEvent,
  watchViewArticlePreviewEvent,
  watchClickArticlePreviewCellEvent,
  watchPlayVideoEvent,
  watchSignUpEventV2,
  watchSignInEventV2,
  watchViewHomeEvent,
  watchViewArticleEvent,
  watchFollowMagazinesEvent,
  watchSearchResultEvent,
  watchSearchResultClickEvent,
  watchShowArticlePreviewModalEvent
} from "./trackingEventsV2Saga"
import { watchRequestNews } from "./newsSaga"
import { watchRequestTeam } from "./aboutSaga"
import { watchRequestMagazineList } from "./magazineListSaga"
import { watchRequestIntroArticles } from "./introSaga"

export default function* rootSaga() {
  yield all([
    watchLogIn(),
    watchRequestCategory(),
    watchRequestCategories(),
    watchRequestCategoryLatestIssues(),
    watchRequestfetchLatestIssue(),
    watchRequestBanners(),
    watchRequestfetchFollowedMagazines(),
    watchFollowMagazine(),
    watchUnfollowMagazine(),
    watchRequestMagazine(),
    watchRequestMagazineYears(),
    watchRequestMagazineYearIssues(),
    watchRequestIssue(),
    watchRequestMagazineIssues(),
    watchRequestIssueThumbnails(),
    watchRequestIssueArticles(),
    watchRequestBookmarks(),
    watchRequestBookmarksStatistics(),
    watchCreateBookmark(),
    watchDeleteBookmark(),
    watchRequestMagazines(),
    watchSearchArticles(),
    watchArticle(),
    watchRequestPopularArticles(),
    watchRequestCurrentFreeIssue(),
    watchRequestCurationPost(),
    watchRequestCurationPostArticles(),
    watchRequestCurationChannel(),
    watchRequestCurationChannels(),
    watchRequestCurationChannelPosts(),
    watchRequestfetchLatestIssues(),
    watchRequestRecommendArticles(),
    watchRequestPopularMagazines(),
    watchRequestGiftingPlans(),
    watchSignUp(),
    watchSignUpSmartone(),
    watchSearchPlatforms(),
    watchRecommendationTree(),
    watchClientInfo(),
    watchRemindPassword(),
    watchResetPassword(),
    watchRequestVipPeriods(),
    watchRequestVipPeriodsThrottling(),
    watchRequestUserVipStatusThrottling(),
    watchUpdateUser(),
    watchRequestTstarSms(),
    watchBindTstarSms(),
    watchExistTstarToken(),
    watchBindTstarToken(),
    watchRequestAptgSms(),
    watchBindAptgSms(),
    watchExistAptgToken(),
    watchBindAptgToken(),
    watchArticleAccessToken(),
    watchArticleText(),
    watchArticleRelevantArticles(),
    watchRequestbraintreePlans(),
    watchApplyBraintreePromotionCode(),
    watchRequestIbonPlans(),
    watchRequestSentGifts(),
    watchRequestbraintreeSubscription(),
    watchRequestbraintreeSubscriptionThrottling(),
    watchRequestHamiReadCount(),
    watchRequestFreeArticleQuota(),
    watchRequestBraintreePaymentMethods(),
    watchRequestRecommendedMagazines(),
    watchRequestUserInfo(),
    watchDetectWebP(),
    watchRecordUserActivity(),
    watchStartConsumingArticleReadEvents(),
    watchOnConsumingArticleReadEvents(),
    watchOnProducingArticleReadEvents(),
    watchEndConsumingArticleReadEvents(),
    watchSendSqsMessages(),
    watchInitCurrentReadingInfo(),
    watchChangePageNumber(),
    watchReportError(),
    watchSignUpEvent(),
    watchSignInEvent(),
    watchFollowTitleEvent(),
    watchUnfollowTitleEvent(),
    watchBookmarkEvent(),
    watchUnbookmarkEvent(),
    watchTelecomBindingEvent(),
    watchOpenArticleEvent(),
    watchOpenCurationPostEvent(),
    watchOpenCurationPostArticleEvent(),
    watchOpenRecommendArticleEvent(),
    watchUpgradeTryPurchaseEvent(),
    watchUpgradePlanSelectedEvent(),
    watchUpgradeSuccessEvent(),
    watchUpgradeTryUsePromotionCodeEvent(),
    watchUpgradeCouponCodeEvent(),
    watchBannerClickEvent(),
    watchSearchEvent(),
    watchSearchResultMagazineClickEvent(),
    watchSearchResultArticleClickEvent(),
    watchFreeMagazineIntroDisplayEvent(),
    watchFreeMagazineIntroActionClickEvent(),
    watchRecommendQuestionAskedEvent(),
    watchRecommendListEnterEvent(),
    watchShareArticleEvent(),
    watchChangeStateV0Event(),
    watchDownloadAppEvent(),
    watchResendConfirmationEmailEvent(),
    watchWidgetBarClickEvent(),
    watchGetIbonPincodeSuccessEvent(),
    watchPlayAudioEvent(),
    watchBuyGiftingEvent(),
    watchForceUpdateVersion(),
    watchRequestWechatAccessToken(),
    watchWechatLogIn(),
    watchRequestK4lLibraries(),
    watchSetCurrentLibraryById(),
    watchK4lLogIn(),
    watchK4lSignUp(),
    watchRequestIosReceipts(),
    watchRequestUserPicture(),
    watchRequestReadingProgresses(),
    watchDeleteReadingProgress(),
    watchDeleteReadingProgresses(),
    watchUpdateReadingProgress(),
    watchRequestIssueReadingProgress(),
    watchRequestMagazineYearReadingStatuses(),
    watchRequestIssueReadingStatus(),
    watchHomeMyFollowClickEvent(),
    watchHomeRecommendMagazineClickEvent(),
    watchHomeWeeklyIssueClickEvent(),
    watchHomeLatestIssueClickEvent(),
    watchHomeCurationChannelClickEvent(),
    watchHomeCurationPostClickEvent(),
    watchHomeCurationPostArticleClickEvent(),
    watchHomeRecommendArticleClickEvent(),
    watchHomePopularMagazineClickEvent(),
    watchHomePopularArticleClickEvent(),
    watchHomeMyBookmarkClickEvent(),
    watchHeaderClickEvent(),
    watchPreviewArticleDownloadAppClickEvent(),
    watchHeaderNavigationCategoryClickEvent(),
    watchHeaderNavigationLibraryClickEvent(),
    watchEnterHomeEvent(),
    watchTemporaryLogin(),
    watchOnUpdateTaskRecords(),
    watchStartTrialShowEvent(),
    watchStartTrialClickEvent(),
    watchRequestTranslations(),
    watchOpenReportEvent(),
    watchClickSurveyEvent(),
    watchRequestSubscriptionInfo(),
    watchViewPricePageEvent(),
    watchClickSubscribeEvent(),
    watchViewPaymentPageEvent(),
    watchConfirmSubscribeEvent(),
    watchViewThankYouPageEvent(),
    watchInitUserPropertiesEvent(),
    watchSignEvent(),
    watchRequestVipPeriodsEvent(),
    watchSetFirstLandingPageEvent(),
    watchChangeStateEvent(),
    watchClickOobeNextStepEvent(),
    watchCompleteOobeEvent(),
    watchViewArticlePreviewEvent(),
    watchClickArticlePreviewCellEvent(),
    watchRequestNews(),
    watchRequestTeam(),
    watchRequestMagazineList(),
    watchRequestIntroArticles(),
    watchPlayVideoEvent(),
    watchSignUpEventV2(),
    watchSignInEventV2(),
    watchViewHomeEvent(),
    watchViewArticleEvent(),
    watchFollowMagazinesEvent(),
    watchSearchResultEvent(),
    watchSearchResultClickEvent(),
    watchShowArticlePreviewModalEvent()
  ])
}
