import { put, throttle, call } from "redux-saga/effects"
import { REQUEST_TRANSLATIONS } from "constants/actionTypes"
import FetchTranslationsApi from "apis/FetchTranslationsApi"
import { onRequestTranslationsSuccess } from "actions/translations"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { TRANSLATIONS_TTL } from "constants/ttl"
import { addResources } from "utilities/translations"

function* fetchTranslations(action) {
  try {
    const isExisted = yield* isStoreExisted("translations")
    const isExpired = yield* isStoreExpired(TRANSLATIONS_TTL, "translations")
    if (isExisted && !isExpired) {
      return
    }

    const fetchTranslationsApi = new FetchTranslationsApi()
    const response = yield call(fetchTranslationsApi.call)
    const replacements = { zh_hant: "zh_tw", zh_hans: "zh_cn" }
    const translations = Object.keys(response.data).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [replacements[key] || key]: response.data[key] }
      }),
      {}
    )
    addResources(translations)
    yield put(onRequestTranslationsSuccess(translations))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestTranslations() {
  yield throttle(2000, REQUEST_TRANSLATIONS, fetchTranslations)
}
