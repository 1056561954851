import {
  REQUEST_CATEGORY_SUCCESS,
  REQUEST_CATEGORIES_SUCCESS,
  REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS,
  REQUEST_LATEST_ISSUE_SUCCESS,
  REQUEST_BANNER_SUCCESS,
  REQUEST_FOLLOWED_MAGAZINES_SUCCESS,
  REQUEST_MAGAZINE_SUCCESS,
  REQUEST_MAGAZINE_YEARS_SUCCESS,
  REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS,
  REQUEST_ISSUE_SUCCESS,
  REQUEST_MAGAZINE_ISSUES_SUCCESS,
  REQUEST_ISSUE_THUMBNAILS_SUCCESS,
  REQUEST_ISSUE_ARTICLES_SUCCESS,
  REQUEST_ARTICLE_SUCCESS,
  LOG_IN_SUCCESS,
  REQUEST_MAGAZINES_SUCCESS,
  REQUEST_POPULAR_ARTICLES_SUCCESS,
  REQUEST_ARTICLE_RELEVANT_ARTICLES_SUCCESS,
  REQUEST_CURRENT_FREE_ISSUE_SUCCESS,
  REQUEST_CURATION_POST_SUCCESS,
  REQUEST_CURATION_POST_ARTICLES_SUCCESS,
  REQUEST_CURATION_CHANNELS_SUCCESS,
  REQUEST_CURATION_CHANNEL_SUCCESS,
  REQUEST_CURATION_CHANNEL_POSTS_SUCCESS,
  REQUEST_RECOMMEND_ARTICLES_SUCCESS,
  REQUEST_POPULAR_MAGAZINES_SUCCESS,
  SIGN_UP_SUCCESS,
  REQUEST_VIP_PERIODS_SUCCESS,
  REQUEST_RECOMMENDED_MAGAZINES_SUCCESS,
  REQUEST_CLIENT_INFO_SUCCESS,
  REQUEST_USER_INFO_SUCCESS,
  RECORD_USER_ACTIVITY_SUCCESS,
  REQUEST_BRAINTREE_SUBSCRIPTION_SUCCESS,
  REQUEST_ARTICLE_ACCESS_TOKEN_SUCCESS,
  REQUEST_ARTICLE_ACCESS_TOKEN_ERROR,
  REQUEST_FREE_ARTICLE_QUOTA_SUCCESS,
  REQUEST_K4L_LIBRARIES_SUCCESS,
  REQUEST_READING_PROGRESSES_SUCCESS,
  OPEN_READING_PROGRESS_PROMPT_TOAST,
  REQUEST_TRANSLATIONS_SUCCESS,
  REQUEST_SUBSCRIPTION_INFO_SUCCESS,
  REQUEST_NEWS_SUCCESS,
  REQUEST_TEAM_SUCCESS,
  REQUEST_MAGAZINE_LIST_SUCCESS,
  REQUEST_INTRO_ARTICLES_SUCCESS
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { getNow } from "utilities/time"
import Immutable from "immutable"

const initialState = Immutable.fromJS({})

const reducerMap = new Map([
  [
    REQUEST_CATEGORY_SUCCESS,
    (state, action) => {
      const { category } = action.payload

      return state.setIn(["categories", category.id.toString()], getNow())
    }
  ],
  [
    REQUEST_CATEGORIES_SUCCESS,
    (state, action) => {
      const { categoryGroups } = action.payload
      state = state.set("libraries", getNow())

      categoryGroups.forEach(group => {
        group.categories.forEach(category => {
          state = state.setIn(["categories", category.id.toString()], getNow())
        })
      })
      return state
    }
  ],
  [
    REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS,
    (state, action) => {
      state = state.setIn(
        ["categoryMagazines", action.payload.categoryId],
        getNow()
      )
      action.payload.latestIssues.forEach(issue => {
        state = state.setIn(["magazinesLatestIssues", issue.title], getNow())
      })
      return state
    }
  ],
  [
    REQUEST_LATEST_ISSUE_SUCCESS,
    (state, action) => {
      return state.setIn(
        ["magazinesLatestIssues", action.payload.magazineId],
        getNow()
      )
    }
  ],
  [
    REQUEST_BANNER_SUCCESS,
    (state, action) => {
      return state.set("banners", getNow())
    }
  ],
  [
    REQUEST_FOLLOWED_MAGAZINES_SUCCESS,
    (state, action) => {
      state = state.set("followedMagazines", getNow())

      action.payload.followedIssues.forEach(issue => {
        state = state.setIn(["magazinesLatestIssues", issue.title], getNow())
      })

      action.payload.followedIssues.forEach(issue => {
        state = state.setIn(["issues", issue.bid], getNow())
      })

      return state
    }
  ],
  [
    REQUEST_MAGAZINE_SUCCESS,
    (state, action) => {
      return state.setIn(["magazines", action.payload.magazine.title], getNow())
    }
  ],
  [
    REQUEST_MAGAZINE_YEARS_SUCCESS,
    (state, action) => {
      return state.setIn(["magazineYears", action.payload.magazineId], getNow())
    }
  ],
  [
    REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS,
    (state, action) => {
      state = state.setIn(
        ["magazineYearIssues", action.payload.magazineId, action.payload.year],
        getNow()
      )
      action.payload.issues.forEach(issue => {
        state = state.setIn(["issues", issue.bid], getNow())
      })
      return state
    }
  ],
  [
    REQUEST_ISSUE_SUCCESS,
    (state, action) => {
      return state.setIn(["issues", action.payload.issue.bid], getNow())
    }
  ],
  [
    REQUEST_MAGAZINE_ISSUES_SUCCESS,
    (state, action) => {
      state = state.setIn(
        ["magazineIssues", action.payload.magazineId],
        getNow()
      )
      action.payload.issues.forEach(issue => {
        state = state.setIn(["issues", issue.bid], getNow())
      })
      return state
    }
  ],
  [
    REQUEST_ISSUE_THUMBNAILS_SUCCESS,
    (state, action) => {
      return state.setIn(["issueThumbnails", action.payload.issueId], getNow())
    }
  ],
  [
    REQUEST_ISSUE_ARTICLES_SUCCESS,
    (state, action) => {
      state = state.setIn(["issueArticles", action.payload.issueId], getNow())
      action.payload.articles.forEach(article => {
        state = state.setIn(["articles", article.article_id], getNow())
      })
      return state
    }
  ],
  [
    REQUEST_ARTICLE_SUCCESS,
    (state, action) => {
      return state.setIn(
        ["articles", action.payload.article.article_id],
        getNow()
      )
    }
  ],
  [
    LOG_IN_SUCCESS,
    () => {
      return initialState
    }
  ],
  [
    SIGN_UP_SUCCESS,
    (state, action) => {
      return action.payload.goSignIn ? initialState : state
    }
  ],
  [
    REQUEST_MAGAZINES_SUCCESS,
    (state, action) => {
      state = state.setIn(["allMagazines"], getNow())

      return state
    }
  ],
  [
    REQUEST_POPULAR_ARTICLES_SUCCESS,
    (state, action) => {
      action.payload.popularArticles.forEach(popularArticle => {
        state = state
          .setIn(["issues", popularArticle.magazine.bid], getNow())
          .setIn(["articles", popularArticle.article_id], getNow())
      })
      return state.setIn(["popularArticles"], getNow())
    }
  ],
  [
    REQUEST_ARTICLE_RELEVANT_ARTICLES_SUCCESS,
    (state, action) => {
      action.payload.relevantArticles.forEach(article => {
        state = state
          .setIn(["issues", article.magazine.bid], getNow())
          .setIn(["articles", article.article_id], getNow())
      })
      return state
    }
  ],
  [
    REQUEST_CURRENT_FREE_ISSUE_SUCCESS,
    (state, action) => {
      action.payload.freeIssue.highlight_articles.forEach(article => {
        state = state.setIn(["articles", article.article_id], getNow())
      })
      state = state.setIn(["freeIssues", "current"], getNow())

      return state
    }
  ],
  [
    REQUEST_CURATION_POST_SUCCESS,
    (state, action) => {
      return state.setIn(
        ["curationPosts", action.payload.curationPost.id],
        getNow()
      )
    }
  ],
  [
    REQUEST_CURATION_CHANNELS_SUCCESS,
    (state, action) => {
      action.payload.curationChannels.forEach(curationChannel => {
        state = state.setIn(
          ["curationChannels", curationChannel.id.toString()],
          getNow()
        )
      })
      return state
    }
  ],
  [
    REQUEST_CURATION_CHANNEL_SUCCESS,
    (state, action) => {
      return state.setIn(
        ["curationChannels", action.payload.curationChannel.id.toString()],
        getNow()
      )
    }
  ],
  [
    REQUEST_CURATION_CHANNEL_POSTS_SUCCESS,
    (state, action) => {
      action.payload.curationChannelPosts.forEach(post => {
        state.setIn(["curationPosts", post.id], getNow())
      })
      return state.setIn(
        ["curationChannelPosts", action.payload.curationChannelId],
        getNow()
      )
    }
  ],
  [
    REQUEST_RECOMMEND_ARTICLES_SUCCESS,
    (state, action) => {
      const recommend = action.payload.recommendArticles
      const articles = recommend.map(recommend => recommend.article)
      articles.forEach(article => {
        state = state.setIn(["articles", article.article_id], getNow())
      })
      state = state.setIn(["recommendArticles"], getNow())
      return state
    }
  ],
  [
    REQUEST_POPULAR_MAGAZINES_SUCCESS,
    state => {
      state = state.setIn(["popularMagazines"], getNow())
      return state
    }
  ],
  [
    REQUEST_VIP_PERIODS_SUCCESS,
    state => {
      state = state.setIn(["vipPeriods"], getNow())
      return state
    }
  ],
  [
    REQUEST_CURATION_POST_ARTICLES_SUCCESS,
    (state, action) => {
      state = state.setIn(
        ["curationPostArticles", action.payload.postId],
        getNow()
      )
      action.payload.articles.forEach(article => {
        state = state.setIn(["articles", article.article_id], getNow())
      })
      return state
    }
  ],
  [
    REQUEST_RECOMMENDED_MAGAZINES_SUCCESS,
    state => {
      return state.setIn(["recommendedMagazines"], getNow())
    }
  ],
  [
    REQUEST_CLIENT_INFO_SUCCESS,
    state => {
      return state.setIn(["clientInfo"], getNow())
    }
  ],
  [
    REQUEST_USER_INFO_SUCCESS,
    state => {
      return state.setIn(["userInfo"], getNow())
    }
  ],
  [
    RECORD_USER_ACTIVITY_SUCCESS,
    state => {
      return state.setIn(["lastActivity"], getNow())
    }
  ],
  [
    REQUEST_BRAINTREE_SUBSCRIPTION_SUCCESS,
    state => {
      return state.setIn(["braintreeSubscription"], getNow())
    }
  ],
  [
    REQUEST_ARTICLE_ACCESS_TOKEN_SUCCESS,
    (state, action) => {
      return state.setIn(
        ["articleAccessTokens", action.payload.articleId],
        getNow()
      )
    }
  ],
  [
    REQUEST_ARTICLE_ACCESS_TOKEN_ERROR,
    (state, action) => {
      return state.setIn(
        ["articleAccessTokens", action.payload.articleId],
        getNow()
      )
    }
  ],
  [
    REQUEST_FREE_ARTICLE_QUOTA_SUCCESS,
    state => {
      return state.set("freeArticleQuota", getNow())
    }
  ],
  [
    REQUEST_K4L_LIBRARIES_SUCCESS,
    state => {
      return state.setIn(["k4lLibraries", "all"], getNow())
    }
  ],
  [
    REQUEST_READING_PROGRESSES_SUCCESS,
    (state, action) => {
      action.payload.readingProgresses.forEach(readingProgress => {
        state = state
          .setIn(["issues", readingProgress.issue_id], getNow())
          .setIn(["articles", readingProgress.article_id], getNow())
      })
      return state
    }
  ],
  [
    OPEN_READING_PROGRESS_PROMPT_TOAST,
    (state, action) => {
      return state.setIn(
        ["readingProgressPrompts", action.payload.issueId],
        getNow()
      )
    }
  ],
  [
    REQUEST_TRANSLATIONS_SUCCESS,
    state => {
      return state.set("translations", getNow())
    }
  ],
  [
    REQUEST_SUBSCRIPTION_INFO_SUCCESS,
    state => {
      return state.set("subscriptionInfo", getNow())
    }
  ],
  [
    REQUEST_NEWS_SUCCESS,
    state => {
      return state.set("news", getNow())
    }
  ],
  [
    REQUEST_TEAM_SUCCESS,
    state => {
      return state.set("team", getNow())
    }
  ],
  [
    REQUEST_MAGAZINE_LIST_SUCCESS,
    state => {
      return state.set("magazineList", getNow())
    }
  ],
  [
    REQUEST_INTRO_ARTICLES_SUCCESS,
    state => {
      return state.set("intro", getNow())
    }
  ]
])

export default handleActions(reducerMap, initialState)
