import {
  REQUEST_WECHAT_ACCESS_TOKEN_FINISH,
  CLEAR_AUTHENTICATION_ERROR
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import Immutable from "immutable"

export const initialState = Immutable.fromJS({
  accessToken: null,
  openId: null,
  unionId: null,
  errCode: "",
  errMsg: ""
})

const reducerMap = new Map([
  [
    REQUEST_WECHAT_ACCESS_TOKEN_FINISH,
    (state, action) => {
      return state
        .set("accessToken", action.payload.access_token)
        .set("openId", action.payload.openid)
        .set("unionId", action.payload.unionid)
        .set("errCode", action.payload.errcode)
        .set("errMsg", action.payload.errmsg)
    }
  ],
  [
    CLEAR_AUTHENTICATION_ERROR,
    () => {
      return initialState
    }
  ]
])

export default handleActions(reducerMap, initialState)
