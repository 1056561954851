import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestTeamSuccess } from "actions/about"
import { REQUEST_TEAM } from "constants/actionTypes"
import FetchEmployeesApi from "apis/FetchEmployeesApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { ABOUT_TTL } from "constants/ttl"

function* fetchTeam() {
  try {
    const isExisted = yield* isStoreExisted("about", "team")
    const isExpired = yield* isStoreExpired(ABOUT_TTL, "team")
    if (isExisted && !isExpired) {
      return
    }
    const fetchEmployeesApi = new FetchEmployeesApi()
    const response = yield call(fetchEmployeesApi.call)
    const team = response.data
    yield put(onRequestTeamSuccess(team))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestTeam() {
  yield takeEvery(REQUEST_TEAM, fetchTeam)
}
