import React, { useState, useRef } from "react"
import { useOutsideClick } from "utilities/customHook"
import DropDownButton from "components/presentational/DropDownButton"
import { ReactComponent as EarthSvg } from "./assets/images/earth.svg"
import styled from "styled-components/macro"
import { useTranslation } from "react-i18next"
import DropDownContent from "./components/DropDownContent"

const RelativeWrapper = styled.div`
  position: relative;
`

const LanguageWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  height: 35px;
  border-radius: 18px;
  position: relative;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      props.darkBackground ? "#433e36" : "#f6f3ed"};
  }
`

const Earth = styled(EarthSvg)`
  display: block;
  width: 16px;
  height: 16px;
  stroke: ${props => (props.darkbackground ? "#ffffff" : "#222222")};
  margin-left: 12px;
  ${props => (props.fat ? "margin-right: 12px;" : "")}
`

const SplitLine = styled.div`
  height: 16px;
  width: 0px;
  border-left: 1px solid;
  border-color: #c7c7c7;
  margin: 0 14.5px 0 5.5px;
`

const Text = styled.div`
  font-size: 12px;
  color: ${props => (props.darkBackground ? "#ffffff" : "#222222")};
`

const SpaceMargin = styled.div`
  margin-right: 3px;
`

function LanguageSelector(props) {
  const { darkBackground, simpleMode } = props
  const [isMenuOpen, setMenuOpen] = useState(false)
  const { i18n, t } = useTranslation("language")
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setMenuOpen(false))

  function handleClick() {
    setMenuOpen(status => !status)
  }

  function handleChangeLanguage(e, language) {
    e.stopPropagation()
    if (language !== i18n.language) i18n.changeLanguage(language)
    setMenuOpen(false)
  }

  return (
    <LanguageWrapper
      darkBackground={darkBackground}
      onClick={handleClick}
      ref={isMenuOpen ? wrapperRef : null}
    >
      {simpleMode ? (
        <React.Fragment>
          <Earth
            darkbackground={darkBackground ? "true" : undefined}
            fat={simpleMode}
          />
          <RelativeWrapper>
            <DropDownContent
              isMenuOpen={isMenuOpen}
              handleChangeLanguage={handleChangeLanguage}
              simpleMode
            />
          </RelativeWrapper>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Earth darkbackground={darkBackground ? "true" : undefined} />
          <SplitLine />
          <RelativeWrapper>
            <Text darkBackground={darkBackground}>{t(i18n.language)}</Text>
            <DropDownContent
              isMenuOpen={isMenuOpen}
              handleChangeLanguage={handleChangeLanguage}
            />
          </RelativeWrapper>
          <SpaceMargin />
          <DropDownButton
            mode={darkBackground ? "white" : "black"}
            isToggle={isMenuOpen}
            upSideDown={true}
          />
        </React.Fragment>
      )}
    </LanguageWrapper>
  )
}

export default LanguageSelector
