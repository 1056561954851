import {
  START_CONSUMING_ARTICLE_READ_EVENTS,
  CONSUMING_ARTICLE_READ_EVENTS,
  PRODUCING_ARTICLE_READ_EVENTS,
  END_CONSUMING_ARTICLE_READ_EVENTS,
  ADD_ARTICLE_READ_EVENT,
  CLEAR_STORED_ARTICLE_READ_EVENTS,
  SEND_SQS_MESSAGES,
  SEND_SQS_MESSAGES_SUCCESS,
  CHANGE_TRAFFIC_SOURCE,
  INIT_CURRENT_READING_INFO,
  UPDATE_CURRENT_READING_INFO,
  UPDATE_ARTICLE_READ_TRACKING_HASH,
  CLEAR_CURRENT_READING_INFO_AND_TRACKING_HASH,
  CHANGE_PAGE_NUMBERS
} from "constants/actionTypes"

export const onStartConsumingArticleReadEvents = () => {
  return {
    type: START_CONSUMING_ARTICLE_READ_EVENTS
  }
}

export const onConsumingArticleReadEvents = () => {
  return {
    type: CONSUMING_ARTICLE_READ_EVENTS
  }
}

export const onProducingArticleReadEvents = () => {
  return {
    type: PRODUCING_ARTICLE_READ_EVENTS
  }
}

export const onEndConsumingArticleReadEvents = () => {
  return {
    type: END_CONSUMING_ARTICLE_READ_EVENTS
  }
}

export const onAddArticleReadEvent = articleReadEvent => {
  return {
    type: ADD_ARTICLE_READ_EVENT,
    payload: { articleReadEvent }
  }
}

export const clearStoredArticleReadEvents = () => {
  return {
    type: CLEAR_STORED_ARTICLE_READ_EVENTS
  }
}

export const onSendSqsMessages = (messages, userId) => {
  return {
    type: SEND_SQS_MESSAGES,
    payload: { messages, userId }
  }
}

export const onSendSqsMessagesSuccess = config => {
  return {
    type: SEND_SQS_MESSAGES_SUCCESS,
    payload: { config }
  }
}

export const onChangeTrafficSource = trafficSource => {
  return {
    type: CHANGE_TRAFFIC_SOURCE,
    payload: { trafficSource }
  }
}

export const onInitCurrentReadingInfo = params => {
  return {
    type: INIT_CURRENT_READING_INFO,
    payload: { params }
  }
}

export const onUpdateCurrentReadingInfo = readingInfo => {
  return {
    type: UPDATE_CURRENT_READING_INFO,
    payload: { readingInfo }
  }
}

export const onUpdateArticleReadTrackingHash = (...articleIds) => {
  return {
    type: UPDATE_ARTICLE_READ_TRACKING_HASH,
    payload: { articleIds }
  }
}

export const onClearCurrentReadingInfoAndTrackingHash = () => {
  return {
    type: CLEAR_CURRENT_READING_INFO_AND_TRACKING_HASH
  }
}

export const onChangePageNumber = pageNumbers => {
  if (!Array.isArray(pageNumbers)) pageNumbers = [pageNumbers]

  return {
    type: CHANGE_PAGE_NUMBERS,
    payload: { pageNumbers }
  }
}
