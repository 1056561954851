import React, { Component } from "react"
import HeaderUI from "components/presentational/HeaderUI"
import { bindActionCreators } from "redux"
import { onRequestCategories } from "actions/categories"
import { onOpenHeaderMenu, onCloseHeaderMenu } from "actions/header"
import { connect } from "react-redux"
import { LIBRARY_STATE } from "constants/state"
import DropDownButton from "components/presentational/DropDownButton"
import SmoothCollapse from "react-smooth-collapse"
import { withTranslation } from "react-i18next"
import styled from "styled-components/macro"

const MainWrapper = styled.div`
  position: relative;
`

class LibraryLink extends Component {
  constructor() {
    super()
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    if (this.props.menuOpen && this.props.libraryName === this.props.menuOpen)
      this.props.onCloseHeaderMenu()
    else this.props.onOpenHeaderMenu(this.props.libraryName)
  }

  get libraryHref() {
    return `/libraries/${this.props.libraryName}`
  }

  getCategoryHref(category) {
    return `/categories/${category.get("id")}`
  }

  get isFocus() {
    return (
      this.props.currentState === LIBRARY_STATE &&
      this.props.libraryName === this.props.currentStateParams?.libraryId
    )
  }

  renderCategories() {
    const categories =
      this.props.libraries
        .find(
          library =>
            typeof library.get === "function" &&
            library.get("id") === this.props.libraryName
        )
        ?.get("categories") || []
    return categories.map(category => {
      return (
        <HeaderUI.ItemLinkWrapper
          dark={this.props.dark}
          key={category.get("id")}
          to={this.getCategoryHref(category)}
        >
          <HeaderUI.NavigationsText dark={this.props.dark}>
            {this.props.t(`category:${category.get("dictKey")}`)}
          </HeaderUI.NavigationsText>
        </HeaderUI.ItemLinkWrapper>
      )
    })
  }

  renderLibraryText() {
    return this.props.t(`library:${this.props.libraryName}`)
  }

  render() {
    return (
      <MainWrapper>
        <HeaderUI.LinkWrapper
          dark={this.props.dark}
          focus={this.isFocus ? 1 : 0}
          padding="0 0 0 12px"
          onClick={this.handleClick}
        >
          <HeaderUI.NavigationsText dark={this.props.dark}>
            {this.renderLibraryText()}
          </HeaderUI.NavigationsText>
          <DropDownButton
            mode={this.props.dark ? "white" : "normal"}
            isToggle={this.props.libraryName === this.props.menuOpen}
          />
        </HeaderUI.LinkWrapper>
        <HeaderUI.MenuWrapper>
          <SmoothCollapse
            expanded={this.props.libraryName === this.props.menuOpen}
          >
            <HeaderUI.DropDownMenu dark={this.props.dark}>
              <HeaderUI.MenuColumn>
                <HeaderUI.ItemLinkWrapper
                  dark={this.props.dark}
                  to={this.libraryHref}
                >
                  <HeaderUI.NavigationsText dark={this.props.dark}>
                    {this.props.t("ALL")}
                  </HeaderUI.NavigationsText>
                </HeaderUI.ItemLinkWrapper>
                {this.renderCategories()}
              </HeaderUI.MenuColumn>
            </HeaderUI.DropDownMenu>
          </SmoothCollapse>
        </HeaderUI.MenuWrapper>
      </MainWrapper>
    )
  }
}

const mapStateToProps = state => ({
  currentState: state.getIn(["state", "current"]),
  currentStateParams: state.getIn(["state", "currentParams"]),
  libraries: state.get("libraries"),
  isOpen: state.getIn(["header", "isOpen"]),
  menuOpen: state.getIn(["header", "currentOpenMenu"])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { onRequestCategories, onOpenHeaderMenu, onCloseHeaderMenu },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["container", "category", "library"])(LibraryLink))
