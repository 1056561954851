import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestBannersSuccess } from "actions/banners"
import { REQUEST_BANNER } from "constants/actionTypes"
import FetchBannersApi from "apis/FetchBannersApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { BANNERS_TTL } from "constants/ttl"

function* fetchBanners(action) {
  try {
    const { platform, libraryId, userId, acceptWebP } = action.payload
    const isExisted = yield* isStoreExisted("banners")
    const isExpired = yield* isStoreExpired(BANNERS_TTL, "banners")
    if (isExisted && !isExpired) {
      return
    }
    const fetchBannersApi = new FetchBannersApi()
    const response = yield call(fetchBannersApi.call, {
      platform,
      libraryId,
      userId,
      acceptWebP
    })
    const banners = response.data
    yield put(onRequestBannersSuccess(banners))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestBanners() {
  yield takeEvery(REQUEST_BANNER, fetchBanners)
}
