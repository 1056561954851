import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestIntroArticlesSuccess } from "actions/intro"
import { REQUEST_INTRO_ARTICLES } from "constants/actionTypes"
import FetchNewsApi from "apis/FetchNewsApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { INTRO_TTL } from "constants/ttl"

function* fetchIntroArticles() {
  try {
    const isExisted = yield* isStoreExisted("intro")
    const isExpired = yield* isStoreExpired(INTRO_TTL, "intro")
    if (isExisted && !isExpired) {
      return
    }
    const fetchNewsApi = new FetchNewsApi()
    const response = yield call(fetchNewsApi.call, { type: "intro" })
    const articles = response.data
    yield put(onRequestIntroArticlesSuccess(articles))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestIntroArticles() {
  yield takeEvery(REQUEST_INTRO_ARTICLES, fetchIntroArticles)
}
