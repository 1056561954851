import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestCurationPostSuccess } from "actions/curationPosts"
import { REQUEST_CURATION_POST } from "constants/actionTypes"
import FetchCurationPostApi from "apis/FetchCurationPostApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { CURATION_POST_TTL } from "constants/ttl"

function* fetchCurationPost(action) {
  try {
    const curationPostId = action.payload.curationPostId
    const isExisted = yield* isStoreExisted(
      "curationPosts",
      parseInt(curationPostId, 10)
    )
    const isExpired = yield* isStoreExpired(
      CURATION_POST_TTL,
      "curationPosts",
      parseInt(curationPostId, 10)
    )
    if (isExisted && !isExpired) {
      return
    }
    const fetchCurationPostApi = new FetchCurationPostApi({
      curationPostId: curationPostId
    })
    const response = yield call(fetchCurationPostApi.call)
    const curationPost = response.data
    yield put(onRequestCurationPostSuccess(curationPost))
  } catch (e) {
    const { onError } = action.payload
    onError ? onError(e) : console.log(e)
  }
}

export function* watchRequestCurationPost() {
  yield takeEvery(REQUEST_CURATION_POST, fetchCurationPost)
}
