import {
  START_LOADING,
  END_LOADING,
  SHOW_LOADING,
  HIDE_LOADING,
  CHANGE_LOADING_SUCCESS
} from "constants/actionTypes"

export const onStartLoading = (...name) => {
  return {
    type: START_LOADING,
    payload: { name }
  }
}

export const onEndLoading = (...name) => {
  return {
    type: END_LOADING,
    payload: { name }
  }
}

export const showLoading = () => ({
  type: SHOW_LOADING
})

export const hideLoading = () => ({
  type: HIDE_LOADING
})

export const changeLoadingSuccess = () => ({
  type: CHANGE_LOADING_SUCCESS
})
