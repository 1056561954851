import React from "react"
import LanguageSelector from "components/presentational/LanguageSelector"
import styled from "styled-components/macro"
import media from "utilities/media"

const BaseInfoWrapper = styled.div`
  width: 100%;
  order: 3;
  margin-top: ${props => (props.noMargin ? "0" : "10px")};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`

const CopyrightText = styled.div`
  font-size: 12px;
  line-height: 1.42;
  text-align: left;
  color: #222222;

  ${media.mobile`
    margin-bottom: 20px;
  `}
`

const LanguageWrapper = styled.div`
  ${media.mobile`
    margin-left: -12px;
  `};
`

export default function BaseInfo(props) {
  function getCurrentYear() {
    return new Date().getFullYear()
  }

  return (
    <BaseInfoWrapper noMargin={props.noMargin}>
      <CopyrightText>
        Kono Libraries ｜ Copyright © 2011-{getCurrentYear()}, Kono Digital Inc.
        All Rights Reserved.
      </CopyrightText>
      <LanguageWrapper>
        <LanguageSelector />
      </LanguageWrapper>
    </BaseInfoWrapper>
  )
}
