import React, { useState, useEffect } from "react"
import { getMql } from "utilities/media"

const mediaType = {
  desktop: "desktop",
  tablet: "tablet",
  mobile: "mobile"
}

/**
 * Return a HOC and provide `isDesktop()`, `isTablet()` and `isMobile` three methods
 * in props to wrapped component by using metchMedia API.
 */
export default function withMql(WrappedComponent) {
  function ComponentWithMql(props) {
    const desktopMql = getMql(mediaType.desktop)
    const tabletMql = getMql(mediaType.tablet)
    const mobileMql = getMql(mediaType.mobile)

    const [currentMediaType, setMediaType] = useState("")

    const isDesktop = () => currentMediaType === mediaType.desktop
    const isTablet = () => currentMediaType === mediaType.tablet
    const isMobile = () => currentMediaType === mediaType.mobile

    useEffect(() => {
      const handleViewportChange = () => {
        if (desktopMql.matches) setMediaType(mediaType.desktop)
        else if (tabletMql.matches) setMediaType(mediaType.tablet)
        else if (mobileMql.matches) setMediaType(mediaType.mobile)
      }

      if (typeof desktopMql?.addEventListener === "function")
        desktopMql.addEventListener("change", handleViewportChange)
      if (typeof tabletMql?.addEventListener === "function")
        tabletMql.addEventListener("change", handleViewportChange)
      if (typeof mobileMql?.addEventListener === "function")
        mobileMql.addEventListener("change", handleViewportChange)

      handleViewportChange()

      return () => {
        if (typeof desktopMql?.removeEventListener === "function")
          desktopMql.removeEventListener("change", handleViewportChange)
        if (typeof tabletMql?.removeEventListener === "function")
          tabletMql.removeEventListener("change", handleViewportChange)
        if (typeof mobileMql?.removeEventListener === "function")
          mobileMql.removeEventListener("change", handleViewportChange)
      }
    }, [desktopMql, tabletMql, mobileMql])

    return (
      <WrappedComponent
        {...props}
        isDesktop={isDesktop}
        isTablet={isTablet}
        isMobile={isMobile}
      />
    )
  }

  return ComponentWithMql
}
