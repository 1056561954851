import KonoApi from "../KonoApi"

class FetchCurationPostArticlesApi extends KonoApi {
  constructor(data) {
    super(`/curation_posts/${data.curationPostId}/articles`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchCurationPostArticlesApi
