import axios from "axios"
import KonoApi from "../KonoApi"

class LoginApi extends KonoApi {
  constructor() {
    super(`/users/login`)
  }

  call(params) {
    return axios.post(this.url, {
      platform: params.platform,
      account: params.account,
      validator: params.validator,
      ...params.utmParams
    })
  }
}

export default LoginApi
