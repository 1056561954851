import React, { Component } from "react"
import HeaderUI from "components/presentational/HeaderUI"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { MY_FOLLOW_STATE, MY_COLLECT_STATE } from "constants/state"
import { PROFILE_LINK } from "constants/headerMenu"
import { onOpenHeaderMenu, onCloseHeaderMenu } from "actions/header"
import LogoutButton from "components/container/LogoutButton"
import bookmarkIcon from "./assets/images/bookmark.svg"
import followIcon from "./assets/images/follow.svg"
import settingIcon from "./assets/images/setting.svg"
import bookmarkWhiteIcon from "./assets/images/bookmark-white.svg"
import followWhiteIcon from "./assets/images/follow-white.svg"
import settingWhiteIcon from "./assets/images/setting-white.svg"
import { withTranslation } from "react-i18next"
import SmoothCollapse from "react-smooth-collapse"
import { signInOnlyType } from "constants/k4l"
import styled from "styled-components/macro"

const MainWrapper = styled.div`
  position: relative;
`

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  height: 35px;
  border-radius: 17px;
  background-color: ${props =>
    props.focus === "true" ? (props.dark ? "#40403f" : "#f6f3ed") : "none"};
  padding: 0 20px;
  color: ${props => (props.dark ? "#eee9e0" : "#222222")};
  text-decoration: none;
  position: relative;
  white-space: nowrap;

  cursor: pointer;
  &:hover {
    background-color: ${props => (props.dark ? "#40403f" : "#f6f3ed")};
  }
`

const Icon = styled.div`
  width: 38px;
  height: 38px;
  background-size: 38px 38px;
  background-image: url(${props => props.src});
`

const SplitLine = styled.div`
  width: 80%;
  box-sizing: border-box;
  height: 1px;
  background-color: #eee9e0;
  margin: 10px auto;
`

class ProfileLink extends Component {
  constructor() {
    super()
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.props.isMenuOpen
      ? this.props.onCloseHeaderMenu()
      : this.props.onOpenHeaderMenu(PROFILE_LINK)
  }

  get myFollowHref() {
    return "/my-follow"
  }

  get myCollectHref() {
    return "/my-collect"
  }

  get changePasswordHref() {
    return "/change-password"
  }

  renderDropDown() {
    return (
      <HeaderUI.MenuWrapper reverse>
        <SmoothCollapse expanded={this.props.isMenuOpen}>
          <HeaderUI.DropDownMenu dark={this.props.dark}>
            <HeaderUI.MenuColumn minWidth="198px">
              <HeaderUI.LinkWrapper
                sharpRadius
                height="40px"
                dark={this.props.dark}
                to={this.myFollowHref}
                focus={this.props.currentState === MY_FOLLOW_STATE ? 1 : 0}
              >
                <Icon src={this.props.dark ? followWhiteIcon : followIcon} />
                <HeaderUI.NavigationsText dark={this.props.dark}>
                  {this.props.t("MY_FOLLOW")}
                </HeaderUI.NavigationsText>
              </HeaderUI.LinkWrapper>
              <HeaderUI.LinkWrapper
                sharpRadius
                height="40px"
                dark={this.props.dark}
                to={this.myCollectHref}
                focus={this.props.currentState === MY_COLLECT_STATE ? 1 : 0}
              >
                <Icon
                  src={this.props.dark ? bookmarkWhiteIcon : bookmarkIcon}
                />
                <HeaderUI.NavigationsText dark={this.props.dark}>
                  {this.props.t("MY_COLLECT")}
                </HeaderUI.NavigationsText>
              </HeaderUI.LinkWrapper>
              {signInOnlyType.includes(this.props.validatorType) ? null : (
                <HeaderUI.LinkWrapper
                  sharpRadius
                  height="40px"
                  dark={this.props.dark}
                  to={this.changePasswordHref}
                >
                  <Icon
                    src={this.props.dark ? settingWhiteIcon : settingIcon}
                  />
                  <HeaderUI.NavigationsText dark={this.props.dark}>
                    {this.props.t("CHANGE_PASSWORD")}
                  </HeaderUI.NavigationsText>
                </HeaderUI.LinkWrapper>
              )}
              <SplitLine />
              <LogoutButton>
                <HeaderUI.LinkWrapper
                  sharpRadius
                  dark={this.props.dark}
                  padding="0 20px"
                >
                  <HeaderUI.NavigationsText dark={this.props.dark}>
                    {this.props.t("LOG_OUT")}
                  </HeaderUI.NavigationsText>
                </HeaderUI.LinkWrapper>
              </LogoutButton>
            </HeaderUI.MenuColumn>
          </HeaderUI.DropDownMenu>
        </SmoothCollapse>
      </HeaderUI.MenuWrapper>
    )
  }

  render() {
    return (
      <MainWrapper>
        <ProfileWrapper dark={this.props.dark} onClick={this.handleClick}>
          <HeaderUI.NavigationsText
            dark={this.props.dark}
            skin="auth"
            fontSize="15px"
          >
            {this.props
              .t("GREETING")
              .concat(
                " ",
                this.props.currentUser && this.props.currentUser.get("nickname")
              )}
          </HeaderUI.NavigationsText>
        </ProfileWrapper>
        {this.renderDropDown()}
      </MainWrapper>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.getIn(["user", "current"]),
  currentState: state.getIn(["state", "current"]),
  isOpen: state.getIn(["header", "isOpen"]),
  isMenuOpen: state.getIn(["header", "currentOpenMenu"]) === PROFILE_LINK,
  validatorType: state.getIn(["k4lLibraries", "current", "validatorType"])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onOpenHeaderMenu,
      onCloseHeaderMenu
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("container")(ProfileLink))
