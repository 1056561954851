import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import { REQUEST_POPULAR_ARTICLES_SUCCESS } from "constants/actionTypes"

const initialState = fromJS([])

const reducerMap = new Map([
  [
    REQUEST_POPULAR_ARTICLES_SUCCESS,
    (state, action) => {
      return fromJS(
        action.payload.popularArticles.map(
          popularArticle => popularArticle.article_id
        )
      )
    }
  ]
])

export default handleActions(reducerMap, initialState)
