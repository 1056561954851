import {
  REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS,
  REQUEST_LATEST_ISSUE_SUCCESS,
  REQUEST_FOLLOWED_MAGAZINES_SUCCESS
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const arrayReducer = (acc, cur) => {
  acc[cur.title] = cur.bid
  return acc
}

const reducerMap = new Map([
  [
    REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS,
    (state, action) => {
      const issues = action.payload.latestIssues
      const latestIssues = issues.reduce(arrayReducer, {})
      return state.merge(fromJS(latestIssues))
    }
  ],
  [
    REQUEST_LATEST_ISSUE_SUCCESS,
    (state, action) => {
      const magazineId = action.payload.magazineId
      const issue = action.payload.latestIssue
      return state.set(magazineId, issue.bid)
    }
  ],
  [
    REQUEST_FOLLOWED_MAGAZINES_SUCCESS,
    (state, action) => {
      const issues = action.payload.followedIssues
      const latestIssues = issues.reduce(arrayReducer, {})
      return state.merge(fromJS(latestIssues))
    }
  ]
])

export default handleActions(reducerMap, initialState)
