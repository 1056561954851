export const LOG_IN_LOADING = "LOG_IN_LOADING"
export const SIGN_UP_LOADING = "SIGN_UP_LOADING"
export const SMARTONE_SIGN_UP_LOADING = "SMARTONE_SIGN_UP_LOADING"
export const SEARCH_PLATFORM_LOADING = "SEARCH_PLATFORM_LOADING"
export const REMIND_PASSWORD_LOADING = "REMIND_PASSWORD_LOADING"
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING"
export const BIND_APTG_SMS_LOADING = "BIND_APTG_SMS_LOADING"
export const BIND_APTG_TOKEN_LOADING = "BIND_APTG_TOKEN_LOADING"
export const BIND_TSTAR_SMS_LOADING = "BIND_TSTAR_SMS_LOADING"
export const BIND_TSTAR_TOKEN_LOADING = "BIND_TSTAR_TOKEN_LOADING"
export const REQUEST_VIP_PERIODS_LOADING = "REQUEST_VIP_PERIODS_LOADING"
export const REQUEST_USER_INFO_LOADING = "REQUEST_USER_INFO_LOADING"
export const REQUEST_BT_SUBSCRIPTION_LOADING = "REQUEST_BT_SUBSCRIPTION_LOADING"
export const REQUEST_FREE_ARTICLE_QUOTA_LOADING =
  "REQUEST_FREE_ARTICLE_QUOTA_LOADING"
export const REQUEST_BRAINTREE_PAYMENT_METHODS_LOADING =
  "REQUEST_BRAINTREE_PAYMENT_METHODS_LOADING"
export const REQUEST_IOS_RECEIPTS_LOADING = "REQUEST_IOS_RECEIPTS_LOADING"
