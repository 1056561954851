import KonoApi from "../KonoApi"

class FetchGiftingPlansApi extends KonoApi {
  constructor() {
    super("/gifting_plans")
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchGiftingPlansApi
