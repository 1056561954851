import styled from "styled-components/macro"
import media from "utilities/media"

const ContentText = styled.div`
  width: 100%;
  line-height: 1.5em;
  color: #222222;
  ${media.desktop`
    font-size: 14px;
    margin-bottom: ${props => (props.inline ? "0px" : "20px")};
  `};
  ${media.tablet`
    font-size: 12px;
    margin-bottom: ${props => (props.inline ? "0px" : "20px")};
  `};
  ${media.mobile`
    font-size: 13px;
    margin-bottom: ${props => (props.inline ? "0px" : "20px")};
  `};
`

export default ContentText
