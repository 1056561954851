import { REQUEST_IOS_RECEIPTS_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import camelcaseKeys from "camelcase-keys"

const initialState = []

const reducerMap = new Map([
  [
    REQUEST_IOS_RECEIPTS_SUCCESS,
    (state, action) => {
      // we assume the first receipt is actived and we only need it
      const receipts = action.payload.receipts.filter(
        (receipt, idx) => idx === 0
      )
      return fromJS(camelcaseKeys(receipts, { deep: true }))
    }
  ]
])

export default handleActions(reducerMap, initialState)
