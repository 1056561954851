import React, { useMemo } from "react"
import useMeasure from "react-use-measure"
import { ResizeObserver } from "@juggle/resize-observer"
import { useTranslation } from "react-i18next"
import { useSpring, animated } from "react-spring"
import styled from "styled-components/macro"

const Text = styled.div`
  font-size: 14px;
  color: #222222;
`

const DropDownMenu = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  max-height: 280px;
  overflow-y: auto;
  padding-bottom: 8px;
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;
  padding: 0px 12px;
  color: #222222;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: #f6f3ed;
  }
`

function DropDownContent(props) {
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver })
  const { i18n, t } = useTranslation("language")
  const { supportedLngs } = i18n.options
  const { isMenuOpen, handleChangeLanguage, simpleMode } = props

  const menuAppear = useSpring({
    transform: isMenuOpen ? "translate3D(0,0,0)" : "translate3D(0,40px,0)",
    position: "absolute",
    top: `${-1 * (bounds.height + 10)}px`,
    left: simpleMode ? "-70px" : "-12px",
    visibility: isMenuOpen ? "visible" : "hidden"
  })

  const items = useMemo(() => {
    return supportedLngs
      .filter(lng => lng !== "cimode")
      .map(language => (
        <ItemWrapper
          key={language}
          onClick={e => handleChangeLanguage(e, language)}
        >
          <Text>{t(language)}</Text>
        </ItemWrapper>
      ))
  }, [handleChangeLanguage, supportedLngs, t])

  return (
    <animated.div style={menuAppear} ref={ref}>
      <DropDownMenu>{items}</DropDownMenu>
    </animated.div>
  )
}

export default DropDownContent
