import axios from "axios"
import KonoApi from "../KonoApi"

class RemindLibraryUserPasswordApi extends KonoApi {
  constructor() {
    super(`/library_users/remind_password`)
  }

  call(params) {
    return axios.post(this.url, {
      account: params.account,
      platform: params.platform
    })
  }
}

export default RemindLibraryUserPasswordApi
