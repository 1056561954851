import React from "react"
import { withMql } from "utilities/decorators"
import ReactModal from "react-modal"

function KonoModal(props) {
  const sharedStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexFlow: "row wrap",
    backgroundColor: "#ffffff",
    border: "none",
    borderRadius: "7px",
    ...props.shareStyles
  }

  const desktopStyles = {
    width: "620px",
    padding: "70px 0px",
    ...sharedStyles
  }

  const tabletStyles = {
    width: "340px",
    padding: "40px 0px",
    ...sharedStyles
  }

  const mobileStyles = {
    width: "calc(100vw - 40px)",
    padding: "30px 0px",
    ...sharedStyles
  }

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 9999
    },
    content: mql()
  }

  function mql() {
    if (props.isDesktop()) return desktopStyles
    else if (props.isTablet()) return tabletStyles
    else return mobileStyles
  }

  return (
    <ReactModal
      isOpen={props.open}
      onAfterClose={props.onClose}
      onRequestClose={props.onOverlayClick}
      style={styles}
      ariaHideApp={false}
    >
      {props.children}
    </ReactModal>
  )
}

export default withMql(KonoModal)
