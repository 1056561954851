import KonoApi from "../KonoApi"
import store from "../../store"

class FetchRecommendArticlesApi extends KonoApi {
  constructor() {
    super(
      `/users/${store
        .getState()
        .getIn(["user", "current", "id"])}/recommended_articles`
    )
  }

  call() {
    return this.axios.get(this.url, { params: { limit: 20 } })
  }
}

export default FetchRecommendArticlesApi
