import styled from "styled-components/macro"
import media from "utilities/media"
import k4lLogo from "./assets/images/k4l-logo.svg"
import k4lLogoMark from "./assets/images/k4l-logomark.svg"

const K4lLogo = styled.div`
  display: block;
  background-image: url(${k4lLogo});

  ${media.desktop`
    width: 115px;
    height: 30px;
    background-size: 115px 30px;
  `};

  ${media.tablet`
    width: 115px;
    height: 30px;
    background-size: 115px 30px;
  `};

  ${media.mobile`
  background-image: url(${k4lLogoMark});
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
  `};
`

export default K4lLogo
