import K4lQRCode from "./images/k4l_qr_code.svg"
export { K4lQRCode }

export const APP_STORE_LINK =
  "https://itunes.apple.com/us/app/kono-za-zhi-jiao-dian-tian/id477224666"
export const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.kono.reader&referrer=ts%3D2cd29a17-d93d-11e5-979a-f23c91183d62"
// for downloading android apk in China
export const ANDROID_APK_LINK =
  "https://kono-bucket.s3-ap-southeast-1.amazonaws.com/android_apk/apk.html"
export const DOWNLOAD_APP_LINK = "https://bnc.lt/6zsg/LCuCooWZzcb"
export const DOWNLOAD_APP_DYNAMIC_LINK =
  "https://bnc.lt/a/key_live_kkdTLUmz7az1faAKMLg1pbneCxlWEF5r"
export const APP_IN_CHINA_LINK =
  "https://apps.appinchina.com.cn/details/com.kono.reader.life"
export const KONO_LIFE_APP_STORE_LINK =
  "https://apps.apple.com/cn/app/id1472870536"

export const K4L_APP_STORE_LINK = "https://itunes.apple.com/tw/app/id1153913439"
export const K4L_GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.kono.reader.k4l&referrer=ts%3D84d982ba-1404-11e5-b040-f23c9118813d"
export const K4L_DOWNLOAD_APP_LINK = "https://k4l.app.link"
export const K4L_SANDBOX_DOWNLOAD_APP_LINK = "https://k4l.test-app.link"
