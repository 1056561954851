import { combineReducers } from "redux-immutable"
// import { autoRehydrate } from "redux-persist-immutable"
import authentication from "./authentication"
import state from "./state"
import libraries from "./libraries"
import updatedAt from "./updatedAt"
import search from "./search"
import user from "./user"
import {
  LOG_OUT,
  CLEAR_STATE,
  FORCE_UPDATE_VERSION_SUCCESS
} from "constants/actionTypes"
import storage from "redux-persist/es/storage"
// import purgeStoredState from "redux-persist/es/purgeStoredState"
import categoryMagazines from "./categoriesMagazines"
import magazinesLatestIssues from "./magazinesLatestIssues"
import issues from "./issues"
import banners from "./banners"
import followedMagazines from "./followedMagazines"
import magazines from "./magazines"
import magazineYears from "./magazineYears"
import magazineYearIssues from "./magazineYearIssues"
import magazineIssues from "./magazineIssues"
import issueThumbnails from "./issueThumbnails"
import issueArticles from "./issueArticles"
import articles from "./articles"
import allMagazines from "./allMagazines"
import hitArticles from "./hitArticles"
import bookmarks from "./bookmarks"
import popularArticles from "./popularArticles"
import loading from "./loading"
import freeIssues from "./freeIssues"
import curationPosts from "./curationPosts"
import curationPostArticles from "./curationPostArticles"
import curationChannels from "./curationChannels"
import curationChannelPosts from "./curationChannelPosts"
import recommendArticles from "./recommendArticles"
import popularMagazines from "./popularMagazines"
import recommendationTrees from "./recommendationTrees"
import clientInfo from "./clientInfo"
import remindPassword from "./remindPassword"
import resetPassword from "./resetPassword"
import vipPeriods from "./vipPeriods"
import tstar from "./tstar"
import aptg from "./aptg"
import articleAccessTokens from "./articlesAccessTokens"
import articleText from "./articlesText"
import header from "./header"
import articleRelevantArticles from "./articleRelevantArticles"
import braintree from "./braintree"
import ibonPlans from "./ibonPlans"
import giftingPlans from "./giftingPlans"
import sentGifts from "./sentGifts"
import braintreeSubscription from "./braintreeSubscription"
import hamiReadCount from "./hamiReadCount"
import freeArticleQuota from "./freeArticleQuota"
import braintreePaymentMethod from "./braintreePaymentMethod"
import magazinesCategories from "./magazinesCategories"
import referrer from "./referrer"
import categories from "./categories"
import latestIssues from "./latestIssues"
import recommendedMagazines from "./recommendedMagazines"
import webP from "./webP"
import articleReadEvents from "./articleReadEvents"
import version from "./version"
import wechat from "./wechat"
import agreement from "./agreement"
import articleTextV2 from "./articlesTextV2"
import buns from "./buns"
import k4lLibraries from "./k4lLibraries"
import k4l from "./k4l"
import apiSettings from "./apiSettings"
import iosReceipts from "./iosReceipts"
import breadcrumb from "./breadcrumb"
import general from "./general"
import readingProgresses from "./readingProgresses"
import readingStatuses from "./readingStatuses"
import toasts from "./toasts"
import generalBlacklist from "./generalBlacklist"
import subscriptionInfo from "./subscriptionInfo"
import news from "./news"
import about from "./about"
import magazineList from "./magazineList"
import intro from "./intro"

const appReducer = combineReducers({
  updatedAt,
  authentication,
  state,
  libraries,
  search,
  user,
  categoryMagazines,
  magazinesLatestIssues,
  issues,
  banners,
  followedMagazines,
  magazines,
  magazineYears,
  magazineYearIssues,
  magazineIssues,
  issueThumbnails,
  issueArticles,
  articles,
  allMagazines,
  hitArticles,
  bookmarks,
  popularArticles,
  loading,
  freeIssues,
  curationPosts,
  curationPostArticles,
  curationChannels,
  curationChannelPosts,
  recommendArticles,
  popularMagazines,
  recommendationTrees,
  clientInfo,
  remindPassword,
  resetPassword,
  vipPeriods,
  tstar,
  aptg,
  articleAccessTokens,
  articleText,
  header,
  articleRelevantArticles,
  braintree,
  ibonPlans,
  giftingPlans,
  sentGifts,
  braintreeSubscription,
  hamiReadCount,
  freeArticleQuota,
  braintreePaymentMethod,
  magazinesCategories,
  referrer,
  categories,
  latestIssues,
  recommendedMagazines,
  webP,
  articleReadEvents,
  version,
  wechat,
  agreement,
  articleTextV2,
  buns,
  k4lLibraries,
  k4l,
  apiSettings,
  iosReceipts,
  breadcrumb,
  general,
  readingProgresses,
  readingStatuses,
  toasts,
  generalBlacklist,
  subscriptionInfo,
  news,
  about,
  magazineList,
  intro
})

const facebookLogOut = () => {
  window.FB.getLoginStatus(function (response) {
    if (response && response.status === "connected") {
      window.FB.logout(function (response) {
        window.location.reload()
      })
    } else {
      window.location.reload()
    }
  })
}

const func = (state, action) => {
  if (action.type === LOG_OUT) {
    Object.keys(state.toObject()).forEach(key => {
      if (key !== "agreement") storage.removeItem(`reduxPersist:${key}`)
    })
    state = undefined
    if (window.FB) facebookLogOut()
    else window.location.reload()
  }

  if (action.type === FORCE_UPDATE_VERSION_SUCCESS) {
    console.log("force remove local storage")
    Object.keys(state.toObject()).forEach(key => {
      if (key === "version") {
        storage.setItem(
          "reduxPersist:version",
          `"[\\"~#iM\\",[\\"current\\",\\"${action.payload.version}\\"]]"`
        )
      } else if (key !== "agreement") {
        storage.removeItem(`reduxPersist:${key}`)
      }
    })
    console.log("window.location.reload")
    window.location.reload()
  }

  if (action.type === CLEAR_STATE) {
    state = undefined
  }

  return appReducer(state, action)
}

export default func
