import { REQUEST_TEAM_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import camelcaseKeys from "camelcase-keys"

const initialState = fromJS({})

const formatTeamMember = member => {
  return camelcaseKeys(
    {
      name: member.name,
      chineseName: member.c_name,
      title: member.title,
      image: member.image
    },
    { deep: true }
  )
}

const reducerMap = new Map([
  [
    REQUEST_TEAM_SUCCESS,
    (state, action) => {
      const team = action.payload.team
      return fromJS({
        team: team.map(formatTeamMember)
      })
    }
  ]
])

export default handleActions(reducerMap, initialState)
