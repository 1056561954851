import KonoApi from "../KonoApi"

class FetchVipPeriodsApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/vip_periods`)
  }

  call() {
    return this.axios.get(this.url, { params: { previous: 1 } })
  }
}

export default FetchVipPeriodsApi
