import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestBraintreePaymentMethodsSuccess } from "actions/braintreePaymentMethods"
import { REQUEST_BRAINTREE_PAYMENT_METHODS } from "constants/actionTypes"
import FetchBraintreePaymentMethodsApi from "apis/FetchBraintreePaymentMethodsApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { onStartLoading, onEndLoading } from "actions/loading"
import { BRAINTREE_PAYMENT_METHODS_TTL } from "constants/ttl"
import { REQUEST_BRAINTREE_PAYMENT_METHODS_LOADING } from "constants/loadingTypes"

function* fetchBraintreePaymentMethods(action) {
  try {
    const isExisted = yield* isStoreExisted("braintreePaymentMethod")
    const isExpired = yield* isStoreExpired(
      BRAINTREE_PAYMENT_METHODS_TTL,
      "braintreePaymentMethod"
    )
    if (isExisted && !isExpired) {
      return
    }
    yield put(onStartLoading(REQUEST_BRAINTREE_PAYMENT_METHODS_LOADING))
    const { userId } = action.payload
    const api = new FetchBraintreePaymentMethodsApi({ userId })
    const response = yield call(api.call)
    const methods = response.data
    yield put(onRequestBraintreePaymentMethodsSuccess(methods))
    yield put(onEndLoading(REQUEST_BRAINTREE_PAYMENT_METHODS_LOADING))
  } catch (e) {
    console.log(e)
    yield put(onEndLoading(REQUEST_BRAINTREE_PAYMENT_METHODS_LOADING))
  }
}

export function* watchRequestBraintreePaymentMethods() {
  yield takeEvery(
    REQUEST_BRAINTREE_PAYMENT_METHODS,
    fetchBraintreePaymentMethods
  )
}
