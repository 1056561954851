import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestIbonPlansSuccess } from "actions/ibonPlans"
import { REQUEST_IBON_PLANS } from "constants/actionTypes"
import FetchIbonPlansApi from "apis/FetchIbonPlansApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { IBON_PLANS_TTL } from "constants/ttl"

function* fetchIbonPlans(action) {
  try {
    const isExisted = yield* isStoreExisted("ibonPlans")
    const isExpired = yield* isStoreExpired(IBON_PLANS_TTL, "ibonPlans")
    if (isExisted && !isExpired) {
      return
    }
    const fetchIbonPlansApi = new FetchIbonPlansApi()
    const response = yield call(fetchIbonPlansApi.call)
    const ibonPlans = response.data
    yield put(onRequestIbonPlansSuccess(ibonPlans))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestIbonPlans() {
  yield takeEvery(REQUEST_IBON_PLANS, fetchIbonPlans)
}
