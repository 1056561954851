import React from "react"
import { withTranslation } from "react-i18next"
import Text from "../Text"
import styled from "styled-components/macro"
import media from "utilities/media"

const SubtitleWrapper = styled.div`
  width: 100%;
  align-self: flex-start;

  ${media.desktop`
    width: calc(100% - 10px);
    margin: 0 5px;
  `};

  ${media.tablet`
    margin-top: 30px;
  `};

  ${media.mobile`
    margin-top: 30px;
  `};
`

const Mark = styled.mark`
  color: #222222;
  background-color: #ffed9d;
`

function Subtitle(props) {
  return (
    <SubtitleWrapper>
      <Text>
        {props.subtitle1}
        <Mark>{props.mark}</Mark>
        {props.subtitle2}
      </Text>
    </SubtitleWrapper>
  )
}

export default withTranslation("container")(Subtitle)
