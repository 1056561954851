import KonoApi from "../KonoApi"

class UnfollowMagazineApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/followed_titles/${data.magazineId}`)
  }

  call() {
    return this.axios.delete(this.url)
  }
}

export default UnfollowMagazineApi
