import React from "react"
import styled from "styled-components/macro"

const ErrorWrapper = styled.div`
  margin-top: 3px;
  padding-left: 20px;
`

const ErrorText = styled.div`
  font-size: 12px;
  color: #f15700;
`

export default function ErrorMessage(props) {
  return props.error ? (
    <ErrorWrapper>
      <ErrorText>{props.errorMessage}</ErrorText>
    </ErrorWrapper>
  ) : null
}
