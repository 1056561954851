import {
  SET_VERSION,
  FORCE_UPDATE_VERSION,
  FORCE_UPDATE_VERSION_SUCCESS
} from "constants/actionTypes"

export const onSetVersion = version => ({
  type: SET_VERSION,
  payload: { version }
})

export const onForceUpdateVersion = version => ({
  type: FORCE_UPDATE_VERSION,
  payload: { version }
})

export const onForceUpdateVersionSuccess = version => ({
  type: FORCE_UPDATE_VERSION_SUCCESS,
  payload: { version }
})
