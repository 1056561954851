import KonoApi from "../KonoApi"

class FetchRecommendedMagazinesApi extends KonoApi {
  constructor(data) {
    super(`/recommended_titles?size_limit=12&user_id=${data.userId}`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchRecommendedMagazinesApi
