import KonoApi from "../KonoApi"

class SubscriptionInfoApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/subscription_info`)
  }

  call(params) {
    return this.axios.get(this.url)
  }
}

export default SubscriptionInfoApi
