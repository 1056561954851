import {
  REQUEST_ARTICLE_ACCESS_TOKEN_SUCCESS,
  REQUEST_ARTICLE_ACCESS_TOKEN_ERROR
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    REQUEST_ARTICLE_ACCESS_TOKEN_SUCCESS,
    (state, action) => {
      return state.set(
        action.payload.articleId,
        fromJS({
          accessToken: action.payload.accessToken,
          expiredAt: action.payload.expiredAt,
          error: false
        })
      )
    }
  ],
  [
    REQUEST_ARTICLE_ACCESS_TOKEN_ERROR,
    (state, action) => {
      return state.set(
        action.payload.articleId,
        fromJS({
          accessToken: "",
          expiredAt: 0,
          error: true
        })
      )
    }
  ]
])

export default handleActions(reducerMap, initialState)
