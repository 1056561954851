import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestFollowedMagazines } from "actions/followedMagazines"
import { UNFOLLOW_MAGAZINE } from "constants/actionTypes"
import UnfollowMagazineApi from "apis/UnfollowMagazineApi"
import { onOpenToast } from "actions/toasts"
import toastTypes from "constants/toastTypes"

function* unfollowMagazine(action) {
  const { userId, magazineId } = action.payload

  try {
    const unfollowMagazineApi = new UnfollowMagazineApi({ userId, magazineId })
    yield call(unfollowMagazineApi.call)
    yield put(onOpenToast(toastTypes.UNFOLLOWED, { userId, magazineId }))
  } catch (e) {
    if (e.response.status !== 409) {
      yield put(onRequestFollowedMagazines(userId, true))
      console.log(e)
    }
  }
}

export function* watchUnfollowMagazine() {
  yield takeEvery(UNFOLLOW_MAGAZINE, unfollowMagazine)
}
