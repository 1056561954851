import i18n from "i18next"
import { ZH_TW, ZH_CN, EN_US, JA_JP, KO_KR } from "constants/languages"

export const languageMapping = {
  zh_tw: ZH_TW,
  zh_cn: ZH_CN,
  en_us: EN_US,
  ja_jp: JA_JP,
  ko_kr: KO_KR
}

export function addResources(translations) {
  Object.keys(translations).forEach(language => {
    const langKey = languageMapping[language]
    Object.keys(translations[language]).forEach(key => {
      i18n.addResourceBundle(langKey, key, translations[language][key])
    })
  })
}
