import { REQUEST_BANNER, REQUEST_BANNER_SUCCESS } from "constants/actionTypes"

export const onRequestBanners = (platform, libraryId, userId, acceptWebP) => {
  return {
    type: REQUEST_BANNER,
    payload: { platform, libraryId, userId, acceptWebP }
  }
}

export const onRequestBannersSuccess = banners => {
  return {
    type: REQUEST_BANNER_SUCCESS,
    payload: { banners }
  }
}
