import queryString from "query-string"
import parse from "url-parse"
import {
  SIGN_IN_STATE,
  SIGN_UP_STATE,
  REMIND_PASSWORD_STATE,
  RESET_PASSWORD_STATE,
  PAGE,
  SINGLE_ARTICLE,
  CREDIT_CARD_PLANS_STATE,
  COUPON_CODE_STATE,
  GIFTING_WELCOME_STATE,
  GIFTING_PLANS_STATE,
  NOT_FOUND_STATE,
  SMARTONE_SIGN_UP_STATE,
  SMS_APTG_BINDING,
  SMS_TSTAR_BINDING,
  TELECOM_INTRO_STATE,
  T_STAR_INTRO_STATE,
  LANDING_STATE,
  DASHBOARD_STATE
} from "constants/state"
import { fromJS } from "immutable"
import { matchPath } from "react-router"

const STATES_TO_FILTER = [
  SIGN_IN_STATE,
  SIGN_UP_STATE,
  REMIND_PASSWORD_STATE,
  RESET_PASSWORD_STATE,
  NOT_FOUND_STATE,
  SMARTONE_SIGN_UP_STATE,
  SMS_APTG_BINDING,
  SMS_TSTAR_BINDING
]

const STATES_TO_SKIP_OOBE = [
  // Article related
  PAGE,
  SINGLE_ARTICLE,

  // Payment related
  CREDIT_CARD_PLANS_STATE,
  COUPON_CODE_STATE,
  GIFTING_WELCOME_STATE,
  GIFTING_PLANS_STATE,

  //Binding related
  TELECOM_INTRO_STATE,
  T_STAR_INTRO_STATE
]

function filterState(states) {
  return states.filter(state => !STATES_TO_FILTER.includes(state.get("state")))
}

function distinguishHomeUrl(history) {
  return history.state === DASHBOARD_STATE
    ? "home"
    : history.state === LANDING_STATE
    ? "landing"
    : "/"
}

export function addHomeSourceQuery(url, needToAdd) {
  if (needToAdd) {
    const urlParse = parse(url)
    if (urlParse.pathname === "/") {
      const urlFinal = queryString.stringifyUrl({
        url,
        query: {
          home_source: "after-sign"
        }
      })
      return urlFinal
    }
  }
  return url
}

export const latestStateUrl = (states, options = {}) => {
  const { homeSource = false } = options

  const _states = states ? filterState(states) : fromJS([])

  return _states.size > 0
    ? addHomeSourceQuery(_states.getIn([-1, "url"]), homeSource)
    : addHomeSourceQuery("/", homeSource)
}

export const latestStateUrlAfterSignUp = states => {
  const _states = states ? filterState(states) : fromJS([])
  return _states.size > 0 &&
    STATES_TO_SKIP_OOBE.includes(_states.getIn([-1, "state"]))
    ? _states.getIn([-1, "url"])
    : "/oobe"
}

export const trackingLatestStateUrl = states => {
  const _states = states ? filterState(states) : fromJS([])
  // except itself
  return _states.size > 0 ? _states.getIn([-2, "url"]) : ""
}

export const trackingSource = (states, getCurrent, notToFilter) => {
  const _states = states
    ? notToFilter
      ? states
      : filterState(states)
    : fromJS([])
  let source = "url"
  let history = null
  if (_states.size > 0 && getCurrent) {
    history = _states.get(-1).toJS()
  } else if (_states.size > 1) {
    // except itself
    history = _states.get(-2).toJS()
  }

  if (history) {
    // get the fixed part in url as source
    const variables = Object.values(history.params)
    const remain = history.url
      .split("/")
      .filter(pattern => !variables.includes(pattern))
    const sourcePage = remain[remain.length - 1] || distinguishHomeUrl(history)

    const via = history.via
    source = via ? `${sourcePage}.${via}` : sourcePage
  }
  return source
}

export const getUtmParams = locationSearch => {
  if (!locationSearch) return {}
  const params = queryString.parse(locationSearch)

  return {
    utm_source: params.utm_source,
    utm_campaign: params.utm_campaign,
    utm_medium: params.utm_medium,
    utm_term: params.utm_term,
    utm_content: params.utm_content
  }
}

// for amplitude now.
// If multiple components need the rules, should be placed in /constants
// "path" should be consistent to /Routes
const categorizePageRules = [
  {
    name: "CH magazine list page",
    path: "/libraries/:libraryId",
    params: { libraryId: "chinese" }
  },
  {
    name: "JP magazine list page",
    path: "/libraries/:libraryId",
    params: { libraryId: "japanese" }
  },
  {
    name: "Intl magazine list page",
    path: "/libraries/:libraryId",
    params: { libraryId: "worldwide" }
  },
  {
    name: "category page",
    path: "/categories/:categoryId"
  },
  {
    name: "title page",
    path: "/magazines/:magazineId"
  },
  {
    name: "issue page",
    path: "/issues/:issueId"
  },
  {
    name: "article preview page",
    path: "/articles/:articleId"
  },
  {
    name: "landing page",
    path: "/",
    exact: true
  },
  {
    name: "verification page",
    path: [
      "/registration-confirmation/:confirmationToken",
      "/email-confirmation/:confirmationToken"
    ]
  },
  {
    name: "register page",
    path: ["/sign-in", "/sign-up"]
  },
  {
    name: "search page",
    path: "/search"
  },
  {
    name: "Smartone",
    path: "/smartone-signup"
  },
  {
    name: "tstar",
    path: ["/tstar-token-binding/:tstarToken", "/tstar-intro"]
  },
  {
    name: "APTG",
    path: ["/aptg-token-binding/:aptgToken", "/aptg-intro"]
  },
  {
    name: "price plan page",
    path: "/payment/credit-card-plans"
  }
]

// This is only for simple cases
function objEqual(obj1, obj2) {
  return (
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).reduce((acc, cur) => acc && obj1[cur] === obj2[cur], true)
  )
}

export const categorizePage = pathname => {
  const match = categorizePageRules.find(rule => {
    const result = matchPath(pathname, { path: rule.path, exact: rule.exact })
    if (result) {
      if (!rule.params) return true
      else if (objEqual(result.params, rule.params)) return true
      else return false
    }
    return false
  })
  return match?.name || pathname
}
