import KonoApi from "../KonoApi"

class FetchIssueReadingStatusApi extends KonoApi {
  constructor(data) {
    super(`/magazines/${data.issueId}/reading_status`)
  }

  call(params) {
    return this.axios.get(this.url, { params: { user_id: params.userId } })
  }
}

export default FetchIssueReadingStatusApi
