import {
  REQUEST_CURATION_POST,
  REQUEST_CURATION_POST_SUCCESS
} from "constants/actionTypes"

export const onRequestCurationPost = (curationPostId, onError) => {
  return {
    type: REQUEST_CURATION_POST,
    payload: { curationPostId, onError }
  }
}

export const onRequestCurationPostSuccess = curationPost => {
  return {
    type: REQUEST_CURATION_POST_SUCCESS,
    payload: { curationPost }
  }
}
