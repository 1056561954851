import KonoApi from "../KonoApi"

class FetchMagazineListApi extends KonoApi {
  constructor() {
    super(`/magazine_list`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchMagazineListApi
