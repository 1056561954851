import {
  REQUEST_BRAINTREE_PLANS,
  APPLY_BRAINTREE_PROMOTION_CODE,
  CLEAR_BRAINTREE_PROMOTION_CODE,
  REQUEST_BRAINTREE_PLANS_SUCCESS,
  REQUEST_BRAINTREE_PLANS_ERROR
} from "constants/actionTypes"

export const onRequestbraintreePlans = (userId, preserveError = false) => {
  return {
    type: REQUEST_BRAINTREE_PLANS,
    payload: { userId, preserveError }
  }
}

export const applyBraintreePromotionCode = (
  userId,
  promotionCode,
  fallback = false
) => {
  return {
    type: APPLY_BRAINTREE_PROMOTION_CODE,
    payload: { userId, promotionCode, fallback }
  }
}

export const clearBraintreePromotionCode = () => ({
  type: CLEAR_BRAINTREE_PROMOTION_CODE
})

export const onRequestbraintreePlansSuccess = (
  braintreePlans,
  promotionCode,
  preserveError = false
) => {
  return {
    type: REQUEST_BRAINTREE_PLANS_SUCCESS,
    payload: { braintreePlans, promotionCode, preserveError }
  }
}

export const onRequestbraintreePlansError = (errorCode, promotionCode) => {
  return {
    type: REQUEST_BRAINTREE_PLANS_ERROR,
    payload: { errorCode, promotionCode }
  }
}
