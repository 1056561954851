import React, { Component } from "react"
import {
  MY_FOLLOW_STATE,
  MY_COLLECT_STATE,
  CHANGE_PASSWORD_STATE
} from "constants/state"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import SideTabComponent from "components/container/SideTabComponent"
import DropDownButton from "components/presentational/DropDownButton"
import SmoothCollapse from "react-smooth-collapse"
import LogoutButton from "components/container/LogoutButton"
import { Link } from "react-router-dom"
import { signInOnlyType } from "constants/k4l"
import { withTranslation } from "react-i18next"
import styled from "styled-components/macro"

const SideTabUserName = styled.div`
  width: 1px;
  height: 42px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #222222;
  padding: 0px 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`

class SideTabProfileDiv extends Component {
  constructor() {
    super()
    this.state = { isToggle: false }
    this.handleToggle = this.handleToggle.bind(this)
  }

  handleToggle() {
    this.setState(prevState => ({
      isToggle: !prevState.isToggle
    }))
  }

  get myFollowHref() {
    return "/my-follow"
  }

  get myCollectHref() {
    return "/my-collect"
  }

  get changePasswordHref() {
    return "/change-password"
  }

  render() {
    return (
      <SideTabComponent.SideTabDivWrapper>
        <SideTabComponent.SideTabRow onClick={this.handleToggle}>
          <SideTabUserName>
            {this.props
              .t("GREETING")
              .concat(
                " ",
                this.props.currentUser && this.props.currentUser.get("nickname")
              )}
          </SideTabUserName>
          <DropDownButton
            size="48px"
            mode="normal"
            isToggle={this.state.isToggle}
          />
        </SideTabComponent.SideTabRow>
        <SmoothCollapse expanded={this.state.isToggle}>
          <SideTabComponent.SideTabSubMenuWrapper>
            <Link to={this.myFollowHref}>
              <SideTabComponent.SideTabSubMenuItem
                focus={this.props.currentState === MY_FOLLOW_STATE ? 1 : 0}
              >
                {this.props.t("MY_FOLLOW")}
              </SideTabComponent.SideTabSubMenuItem>
            </Link>
            <Link to={this.myCollectHref}>
              <SideTabComponent.SideTabSubMenuItem
                focus={this.props.currentState === MY_COLLECT_STATE ? 1 : 0}
              >
                {this.props.t("MY_COLLECT")}
              </SideTabComponent.SideTabSubMenuItem>
            </Link>
            {signInOnlyType.includes(this.props.validatorType) ? null : (
              <Link to={this.changePasswordHref}>
                <SideTabComponent.SideTabSubMenuItem
                  focus={
                    this.props.currentState === CHANGE_PASSWORD_STATE ? 1 : 0
                  }
                >
                  {this.props.t("CHANGE_PASSWORD")}
                </SideTabComponent.SideTabSubMenuItem>
              </Link>
            )}
            <LogoutButton>
              <SideTabComponent.SideTabSubMenuItem>
                {this.props.t("LOGOUT")}
              </SideTabComponent.SideTabSubMenuItem>
            </LogoutButton>
          </SideTabComponent.SideTabSubMenuWrapper>
        </SmoothCollapse>
      </SideTabComponent.SideTabDivWrapper>
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.getIn(["authentication", "isLoggedIn"]),
  currentUser: state.getIn(["user", "current"]),
  currentState: state.getIn(["state", "current"]),
  validatorType: state.getIn(["k4lLibraries", "current", "validatorType"])
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("container")(SideTabProfileDiv))
