import { REQUEST_CATEGORIES_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import camelcaseKeys from "camelcase-keys"

const initialState = fromJS([])

function sortFunction(a, b) {
  if (a.sequence < b.sequence) return -1
  else if (a.sequence > b.sequence) return 1
  else return 0
}

const reducerMap = new Map([
  [
    REQUEST_CATEGORIES_SUCCESS,
    (state, action) => {
      const { categoryGroups } = action.payload

      const sorted = categoryGroups.sort(sortFunction)

      return fromJS(camelcaseKeys(sorted, { deep: true }))
    }
  ]
])

export default handleActions(reducerMap, initialState)
