import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import SearchBar from "components/container/SearchBar"
import SignLink from "../SignLink"
import ProfileLink from "../ProfileLink"
import HeaderUI from "components/presentational/HeaderUI"
import burgerIcon from "./assets/images/burger.svg"
import searchIcon from "./assets/images/search.svg"
import { onToggleSideTabMenu } from "actions/sideTabMenu"
import { onToggleDeviceSearchBar } from "actions/deviceSearchBar"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { withMql } from "utilities/decorators"
import { signInOnlyType } from "constants/k4l"
import { k4lHomeHref } from "utilities/customByApp"
import { ifDbChangedToProd } from "utilities/sandbox"
import styled from "styled-components/macro"
import media from "utilities/media"

const TopRowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  ${media.desktop`
    padding: 12px 30px 0 40px;
  `};

  ${media.tablet`
    padding: 0 4px;
  `};

  ${media.mobile`
    padding: 0 4px;
  `};
`

const SearchWrapper = styled.div`
  margin-left: 30px;
`

const NavigationsIcon = styled.img`
  height: 48px;
  display: block;
`

const BetterLink = styled(Link)`
  height: ${props => props.height};
`

class TopHeader extends Component {
  constructor(props) {
    super(props)
    this.handleBurgerClick = this.handleBurgerClick.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
  }

  handleBurgerClick() {
    this.props.onToggleSideTabMenu()
  }

  handleSearchClick() {
    this.props.onToggleDeviceSearchBar()
  }

  renderLogo() {
    return this.props.k4lCurrentLibrary ? (
      <BetterLink
        to={k4lHomeHref()}
        height={this.props.isDesktop() ? "36px" : "30px"}
      >
        <img
          alt="logo"
          src={this.props.k4lCurrentLibrary.get("logo")}
          height={this.props.isDesktop() ? "36px" : "30px"}
        />
        {ifDbChangedToProd() ? "prod" : null}
      </BetterLink>
    ) : null
  }

  renderAuthOrProfile() {
    if (this.props.isLoggedIn) {
      return <ProfileLink dark={this.props.dark} />
    } else if (this.props.k4lCurrentLibrary) {
      const validatorType = this.props.k4lCurrentLibrary.get("validatorType")
      return signInOnlyType.includes(validatorType) ? (
        <SignLink
          dark={this.props.dark}
          to="/sign-in"
          word={this.props.t("SIGN_IN")}
          skin="auth"
        />
      ) : (
        <React.Fragment>
          <SignLink
            dark={this.props.dark}
            to="/sign-in"
            word={this.props.t("SIGN_IN")}
          />
          <SignLink
            dark={this.props.dark}
            to="/sign-up"
            word={this.props.t("SIGN_UP")}
            skin="auth"
          />
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  render() {
    return (
      <TopRowWrapper>
        <HeaderUI.NavigationsRow>
          {!this.props.isDesktop() ? (
            <NavigationsIcon
              src={burgerIcon}
              alt="menu"
              onClick={this.handleBurgerClick}
            />
          ) : null}
          {this.props.dark ? null : this.renderLogo()}
          {this.props.isDesktop() ? (
            <SearchWrapper>
              <SearchBar dark={this.props.dark} />
            </SearchWrapper>
          ) : null}
        </HeaderUI.NavigationsRow>
        <HeaderUI.NavigationsRow>
          {this.props.isDesktop() ? (
            this.renderAuthOrProfile()
          ) : (
            <NavigationsIcon
              src={searchIcon}
              alt="search"
              onClick={this.handleSearchClick}
            />
          )}
        </HeaderUI.NavigationsRow>
      </TopRowWrapper>
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.getIn(["authentication", "isLoggedIn"]),
  k4lCurrentLibrary: state.getIn(["k4lLibraries", "current"])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onToggleSideTabMenu, onToggleDeviceSearchBar }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMql(withTranslation("container")(TopHeader)))
