import KonoApi from "../KonoApi"

class FetchArticleReadEventsConfigApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/aws_queues/article_reads`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchArticleReadEventsConfigApi
