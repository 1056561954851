import KonoApi from "../KonoApi"

class FetchClientInfoApi extends KonoApi {
  constructor() {
    super(`/client_info`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchClientInfoApi
