import KonoApi from "../KonoApi"

class FetchBraintreePaymentMethodsApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/braintree_payment_methods`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchBraintreePaymentMethodsApi
