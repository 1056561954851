import React from "react"
import { useSelector } from "react-redux"
import SideTabComponent from "components/container/SideTabComponent"
import SideTabLibraryLink from "../SideTabLibraryLink"

export default function SideTabNavigationsDiv() {
  const libraries = useSelector(state => state.get("libraries"))
  const onlyWorldwide =
    libraries.size === 1 &&
    libraries.find(library => library.get("id") === "worldwide")

  return !onlyWorldwide ? (
    <SideTabComponent.SideTabDivWrapper>
      {libraries.map(library =>
        typeof library.get === "function" ? (
          <SideTabLibraryLink
            libraryName={library.get("id")}
            key={library.get("id")}
          />
        ) : null
      )}
    </SideTabComponent.SideTabDivWrapper>
  ) : null
}
