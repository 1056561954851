const object = {
  BOOKMARKED: "BOOKMARKED",
  UNBOOKMARKED: "UNBOOKMARKED",
  FOLLOWED: "FOLLOWED",
  UNFOLLOWED: "UNFOLLOWED",
  COPIED: "COPIED",
  CONFIRM_MAIL_SUCCESS: "CONFIRM_MAIL_SUCCESS",
  EMAIL_SENT_ERROR: "EMAIL_SENT_ERROR",
  SOMETHING_FAIL: "SOMETHING_FAIL",
  SERVER_ERROR: "SERVER_ERROR",
  PASSWORD_SUCCESS: "PASSWORD_SUCCESS",
  CREDIT_CARD_SUCEESS: "CREDIT_CARD_SUCEESS",
  CREDIT_CARD_FAIL: "CREDIT_CARD_FAIL",
  READING_PROGRESS_DELETED: "READING_PROGRESS_DELETED"
}

export default object
