import React from "react"
import HeaderUI from "components/presentational/HeaderUI"

export default function SignLink(props) {
  return (
    <HeaderUI.LinkWrapper dark={props.dark} to={props.to} wide="true">
      <HeaderUI.NavigationsText
        skin={props.skin}
        fontWeight="600"
        dark={props.dark}
      >
        {props.word}
      </HeaderUI.NavigationsText>
    </HeaderUI.LinkWrapper>
  )
}
