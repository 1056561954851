import React from "react"
import { useSelector } from "react-redux"
import NavigationRow from "./components/NavigationRow"
import BaseInfo from "./components/BaseInfo"
import AppIcons from "./components/AppIcons"
import styled from "styled-components/macro"
import media from "utilities/media"

const FooterOuterWrapper = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  padding: 20px 0;
  z-index: 800;
`

const FooterInnerWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0 auto;

  ${media.desktop`
    width: 960px;
  `}
  ${media.tablet`
    width: calc(100% - 80px);
  `}
  ${media.mobile`
    width: calc(100% - 40px);
  `}
`

const NavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`

export default function K4lFooter(props) {
  const k4lCurrentLibrary = useSelector(state =>
    state.getIn(["k4lLibraries", "current"])
  )

  return (
    <FooterOuterWrapper onClick={props.onClick}>
      <FooterInnerWrapper onClick={props.onLogClick}>
        {k4lCurrentLibrary ? (
          <NavigationWrapper>
            <AppIcons onLogDownloadApp={props.onLogDownloadApp} />
            <NavigationRow />
          </NavigationWrapper>
        ) : null}
        <BaseInfo noMargin={!k4lCurrentLibrary} />
      </FooterInnerWrapper>
    </FooterOuterWrapper>
  )
}
