import styled from "styled-components/macro"

const DropDownMenu = styled.div`
  background-color: ${props => (props.dark ? "#2c2c2c" : "#fafafa")};
  display: flex;
  flex-direction: row;
  max-height: 280px;
  overflow-y: auto;
  padding-bottom: 8px;

  &::-webkit-scrollbar-track {
    padding: 2px 1px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`

export default DropDownMenu
