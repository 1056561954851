import { typecheck } from "../validators"

/**
 * Returns a function which limits original function to be invoked once
 * at most every `wait` milliseconds.
 * @param {function} func original function which need to be executed.
 * @param {number} wait milliseconds to wait.
 * @returns {function} a function which constraints original function
 */
export default function throttle(func, wait = 300) {
  typecheck(func, "function")

  let result,
    previous = 0

  return function(...params) {
    const now = Date.now()
    if (now < previous) previous = now

    if (now - previous >= wait) {
      previous = now
      result = func.apply(this, [...params])
    }
    return result
  }
}
