import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { onLogOut } from "actions/authentication"

class LogoutButton extends Component {
  async logOut() {
    await window.amplitude.getInstance().logEvent("logged out", null)
    await window.amplitude.getInstance().setUserId(null)
    this.props.onLogOut()
  }

  render() {
    return <div onClick={() => this.logOut()}>{this.props.children}</div>
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onLogOut }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LogoutButton)
