import {
  REQUEST_MAGAZINE_LIST,
  REQUEST_MAGAZINE_LIST_SUCCESS
} from "constants/actionTypes"

export const onRequestMagazineList = () => {
  return {
    type: REQUEST_MAGAZINE_LIST
  }
}

export const onRequestMagazineListSuccess = magazineList => {
  return {
    type: REQUEST_MAGAZINE_LIST_SUCCESS,
    payload: { magazineList }
  }
}
