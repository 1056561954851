import styled from "styled-components/macro"

const NavigationsText = styled.div`
  font-size: ${props => props.fontSize || "14px"};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "normal")};
  color: ${props => {
    if (props.skin === "auth") return "#008159"
    else if (props.dark) return "#eee9e0"
    else return props.skin === "black" ? "#222222" : "#222222"
  }};
`

export default NavigationsText
