import { CHANGE_STATE, CHANGE_STATE_VIA } from "constants/actionTypes"

export const onChangeState = (state, stateParams = {}, url = "") => ({
  type: CHANGE_STATE,
  payload: {
    state,
    stateParams,
    url
  }
})

// call before leaving the page / state
export const onChangeStateVia = via => ({
  type: CHANGE_STATE_VIA,
  payload: { via }
})
