import React from "react"
import styled from "styled-components/macro"
import { Link } from "react-router-dom"

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${props => props.height || "35px"};
  border-radius: ${props => (props.sharpradius ? "0" : "999px")};
  background-color: ${props =>
    props.focus ? (props.dark ? "#40403f" : "#f6f3ed") : "none"};
  padding: ${props => (props.wide ? "0 20px" : props.padding || "0 12px")};
  color: ${props => (props.dark ? "#eee9e0" : "#222222")};
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  cursor: pointer;

  &:visited {
    color: ${props => (props.dark ? "#eee9e0" : "#222222")};
  }

  &:hover {
    background-color: ${props =>
      props.dark ? "#40403f" : props.hoverunderline ? "" : "#f6f3ed"};
    ${props => (props.hoverunderline ? "text-decoration: underline" : "")};
    ${props => (props.skin === "auth" ? "text-decoration-color: #008159" : "")};
  }
`

function LinkWrapper(props) {
  const { sharpRadius, hoverUnderline, ...rest } = props
  return (
    <MainWrapper
      as={props.to ? Link : ""}
      sharpradius={sharpRadius ? 1 : 0}
      hoverunderline={hoverUnderline ? 1 : 0}
      {...rest}
    >
      {props.children}
    </MainWrapper>
  )
}

export default LinkWrapper
