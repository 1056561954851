import {
  REQUEST_CLIENT_INFO,
  REQUEST_CLIENT_INFO_SUCCESS
} from "constants/actionTypes"

export const onRequestClientInfo = () => {
  return {
    type: REQUEST_CLIENT_INFO
  }
}

export const onRequestClientInfoSuccess = clientInfo => {
  return {
    type: REQUEST_CLIENT_INFO_SUCCESS,
    payload: { clientInfo }
  }
}
