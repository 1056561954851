import React, { Suspense, useEffect } from "react"
import { Route, Switch, useLocation, Redirect } from "react-router-dom"
import { matchPath } from "react-router"
import content from "components/content"
import K4lContainer from "components/container/K4lContainer"
import K4lChecker from "components/container/K4lChecker"
import { k4lHomeHref } from "utilities/customByApp"

const articleViewerPath = [
  "/articles/:articleId",
  "/issues/:issueId/pages/:pageNumber"
]

const landingPath = ["/"]

const urlWithoutParams = [
  { old: "/library_selector", current: "/library-region" }
]

const urlWithParams = [
  { old: "/:lid/login", current: "/:lid/sign-in" },
  { old: "/:lid/signup", current: "/:lid/sign-up" },
  { old: "/:lid/magazine_list", current: `/:lid${k4lHomeHref()}` },
  { old: "/:lid/articles/:aid", current: "/articles/:aid" },
  {
    old: "/:lid/titles/:tid/magazines/:mid/articles/:aid",
    current: "/articles/:aid"
  },
  {
    old: "/titles/:tid/magazines/:mid/articles/:aid",
    current: "/articles/:aid"
  },
  {
    old: "/:lid/titles/:tid/magazines/:mid",
    current: "/issues/:mid"
  },
  {
    old: "/titles/:tid/magazines/:mid",
    current: "/issues/:mid"
  },
  {
    old: "/:lid/titles/:tid",
    current: "/magazines/:tid"
  },
  {
    old: "/titles/:tid",
    current: "/magazines/:tid"
  }
]

export default function Routes() {
  const { pathname, search } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const articleViewer = matchPath(pathname, {
    path: articleViewerPath
  })

  const isLanding = matchPath(pathname, {
    path: landingPath,
    exact: true
  })

  return (
    <K4lContainer articleViewer={articleViewer} isLanding={isLanding}>
      <Suspense fallback={<div></div>}>
        <Switch>
          {urlWithoutParams.map(url => (
            <Redirect
              key={url.old}
              from={url.old}
              to={{ pathname: url.current, search }}
            />
          ))}
          {urlWithParams.map(url => (
            <Redirect
              key={url.old}
              from={url.old}
              to={{ pathname: url.current, search }}
            />
          ))}
          <Route
            exact
            path="/"
            render={props => <content.K4lLanding {...props} />}
          />
          <Route
            path="/library-region"
            render={props => <content.K4lLibraryRegion {...props} />}
          />
          <Route
            path="/library-list/:regionId"
            render={props => <content.K4lLibraryList {...props} />}
          />
          <Route
            path="/reset-password"
            render={props => <content.ResetPassword {...props} />}
          />
          <Route path="/about" render={props => <content.About {...props} />} />
          <Route
            path="/contact-us"
            render={props => <content.ContactUs {...props} />}
          />
          <Route
            path="/policy"
            render={props => <content.Policy {...props} />}
          />
          <Route
            path="/privacy"
            render={props => <content.Privacy {...props} />}
          />
          <Route path="/faq" render={props => <content.K4lFaq {...props} />} />
          <Route
            path="/451"
            render={props => <content.NotFound {...props} />}
          />
          <Route
            path="/404"
            render={props => <content.NotFound {...props} />}
          />
          <Route
            path={["/sign-up", "/:k4lLibraryId/sign-up"]}
            render={props => (
              <K4lChecker.InformationInUrl
                render={props => <content.K4lSignUp {...props} />}
              />
            )}
          />
          <Route
            path={["/sign-in", "/:k4lLibraryId/sign-in"]}
            render={props => (
              <K4lChecker.InformationInUrl
                render={props => <content.K4lSignIn {...props} />}
              />
            )}
          />
          <Route
            path={["/remind-password", "/:k4lLibraryId/remind-password"]}
            render={props => (
              <K4lChecker.InformationInUrl
                render={props => <content.RemindPassword {...props} />}
              />
            )}
          />
          <Route
            path={["/change-password", "/:k4lLibraryId/change-password"]}
            render={props => (
              <K4lChecker.InformationInUrl
                render={props => <content.ChangePassword {...props} />}
              />
            )}
          />
          <Route
            path={[
              "/libraries/:libraryId",
              "/:k4lLibraryId/libraries/:libraryId"
            ]}
            render={props => (
              <K4lChecker.InformationInUrl
                render={props => <content.Library {...props} />}
              />
            )}
          />
          <Route
            path="/categories/:categoryId"
            render={props => (
              <K4lChecker.InformationNotInUrl
                render={props => <content.SingleCategory {...props} />}
              />
            )}
          />
          <Route
            path={[
              "/magazines/:magazineId",
              "/:k4lLibraryId/magazines/:magazineId"
            ]}
            render={props => (
              <K4lChecker.InformationInUrl
                render={props => <content.SingleMagazine {...props} />}
              />
            )}
          />
          <Route
            path={["/summit", "/:k4lLibraryId/summit"]}
            render={props => (
              <K4lChecker.InformationInUrl
                render={props => <content.Summit {...props} />}
              />
            )}
          />
          <Route
            exact
            path="/issues/:issueId"
            render={props => (
              <K4lChecker.InformationNotInUrl
                render={props => <content.SingleIssue {...props} />}
              />
            )}
          />
          <Route
            path="/articles/:articleId"
            render={props => (
              <K4lChecker.InformationNotInUrl
                render={props => <content.SingleArticle {...props} />}
              />
            )}
          />
          <Route
            path="/issues/:issueId/pages/:pageNumber"
            render={props => (
              <K4lChecker.InformationNotInUrl
                render={props => <content.Page {...props} />}
              />
            )}
          />
          <Route
            path="/my-follow"
            render={props => (
              <K4lChecker.InformationNotInUrl
                render={props => <content.MyFollow {...props} />}
              />
            )}
          />
          <Route
            path="/my-collect"
            render={props => (
              <K4lChecker.InformationNotInUrl
                render={props => <content.MyCollect {...props} />}
              />
            )}
          />
          <Route
            path="/search"
            render={props => (
              <K4lChecker.InformationNotInUrl
                render={props => <content.Search {...props} />}
              />
            )}
          />
          <Route
            path="/sharing"
            render={props => <content.K4lSharing {...props} />}
          />
          <Route render={props => <content.NotFound {...props} />} />
        </Switch>
      </Suspense>
    </K4lContainer>
  )
}
