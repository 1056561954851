import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs"
import { put, takeEvery, call, all } from "redux-saga/effects"
import { delay } from "redux-saga"
import {
  onAddArticleReadEvent,
  clearStoredArticleReadEvents,
  onSendSqsMessages,
  onSendSqsMessagesSuccess,
  onConsumingArticleReadEvents,
  onProducingArticleReadEvents,
  onUpdateCurrentReadingInfo,
  onUpdateArticleReadTrackingHash,
  onClearCurrentReadingInfoAndTrackingHash
} from "actions/articleReadEvents"
import { onLogOpenArticle } from "actions/trackingEvents"
import { onRequestArticleSuccess } from "actions/articles"
import { onRequestIssueArticlesSuccess } from "actions/issues"
import FetchArticleReadEventsConfigApi from "apis/FetchArticleReadEventsConfigApi"
import FetchIssueArticlesApi from "apis/FetchIssueArticlesApi"
import FetchArticleApi from "apis/FetchArticleApi"
import {
  START_CONSUMING_ARTICLE_READ_EVENTS,
  END_CONSUMING_ARTICLE_READ_EVENTS,
  SEND_SQS_MESSAGES,
  CONSUMING_ARTICLE_READ_EVENTS,
  PRODUCING_ARTICLE_READ_EVENTS,
  INIT_CURRENT_READING_INFO,
  CHANGE_PAGE_NUMBERS
} from "constants/actionTypes"
import { selectStore } from "utilities/storeCheck"
import { getNow } from "utilities/time"
import { getReadingPlatform } from "utilities/osCheck"
import { is, fromJS } from "immutable"
import camelcaseKeys from "camelcase-keys"

function* startConsumingArticleReadEvents() {
  yield all([
    put(onProducingArticleReadEvents()),
    put(onConsumingArticleReadEvents())
  ])
}

export function* watchStartConsumingArticleReadEvents() {
  yield takeEvery(
    START_CONSUMING_ARTICLE_READ_EVENTS,
    startConsumingArticleReadEvents
  )
}

function* producingArticleReadEvents() {
  const endConsuming = yield selectStore("articleReadEvents", "endConsuming")
  if (endConsuming) return null

  // the first record should not be sent immediately
  yield delay(3000)
  yield sendArticleReadEvent()

  yield producingArticleReadEvents()
}

export function* watchOnProducingArticleReadEvents() {
  yield takeEvery(PRODUCING_ARTICLE_READ_EVENTS, producingArticleReadEvents)
}

function* consumingArticleReadEvents() {
  const endConsuming = yield selectStore("articleReadEvents", "endConsuming")
  if (endConsuming) return null

  let records = yield selectStore("articleReadEvents", "records")
  yield put(clearStoredArticleReadEvents())

  records = records.toJS()
  const recordsArray = Object.keys(records).map(key => records[key])
  const userId = yield selectStore("user", "current", "id")
  yield put(onSendSqsMessages(recordsArray, userId))

  yield delay(8000)
  yield put(onConsumingArticleReadEvents())
}

export function* watchOnConsumingArticleReadEvents() {
  yield takeEvery(CONSUMING_ARTICLE_READ_EVENTS, consumingArticleReadEvents)
}

function* endConsumingArticleReadEvents() {
  // read and send last messages
  let records = yield selectStore("articleReadEvents", "records")
  yield put(clearStoredArticleReadEvents())

  records = records.toJS()
  const recordsArray = Object.keys(records).map(key => records[key])
  const userId = yield selectStore("user", "current", "id")
  yield put(onSendSqsMessages(recordsArray, userId))
}

export function* watchEndConsumingArticleReadEvents() {
  yield takeEvery(
    END_CONSUMING_ARTICLE_READ_EVENTS,
    endConsumingArticleReadEvents
  )
}

function* sendSqsMessages(action) {
  const { messages, userId } = action.payload
  if (messages.length <= 0) return null

  const prevConfig = yield selectStore("general", "readingEventsConfig")

  let config,
    isPrevValid = false
  if (prevConfig && prevConfig.get("expireAt") >= getNow()) {
    config = prevConfig.toJS()
    isPrevValid = true
  } else {
    const fetchArticleReadEventsConfigApi = new FetchArticleReadEventsConfigApi(
      {
        userId
      }
    )
    const response = yield call(fetchArticleReadEventsConfigApi.call)
    config = camelcaseKeys(response.data, { deep: true })
  }

  const sqsClient = new SQSClient({
    credentials: {
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
      sessionToken: config.sessionToken
    },
    region: config.region
  })

  messages.forEach(message => {
    const sqsParams = {
      MessageBody: JSON.stringify(message),
      QueueUrl: config.queueUrl
    }

    const sqsCommand = new SendMessageCommand(sqsParams)

    sqsClient
      .send(sqsCommand)
      .then(data => {})
      .catch(err => console.log(err))
  })

  if (!isPrevValid) yield put(onSendSqsMessagesSuccess(config))
}

export function* watchSendSqsMessages() {
  yield takeEvery(SEND_SQS_MESSAGES, sendSqsMessages)
}

function* sendArticleReadEvent() {
  let trackingHash = yield selectStore("articleReadEvents", "trackingHash")
  trackingHash = trackingHash.toJS()

  const articleAccessTokens = yield selectStore("articleAccessTokens")
  yield all(
    Object.keys(trackingHash).map(function*(articleId) {
      const accessToken = articleAccessTokens.get(articleId)
      const event = yield getArticleReadEvent(articleId)
      if (
        !!accessToken &&
        !accessToken.get("error") &&
        accessToken.get("expiredAt") > getNow() &&
        document.hasFocus() &&
        event.duration >= 1 &&
        event.duration <= 3600
      ) {
        yield put(onAddArticleReadEvent(event))
      }
    })
  )
}

function* getArticleReadEvent(articleId) {
  const articleReadEvents = yield selectStore("articleReadEvents")
  const readingInfo = yield selectStore(
    "articleReadEvents",
    "currentReadingInfo"
  )
  const trackingHash = yield selectStore("articleReadEvents", "trackingHash")

  const userId = yield selectStore("user", "current", "id")
  const accessToken = yield selectStore(
    "articleAccessTokens",
    articleId,
    "accessToken"
  )
  const sessionId = trackingHash.getIn([articleId, "uuid"])
  const beginAt = trackingHash.getIn([articleId, "timestamp"])
  const duration = getNow() - beginAt
  const platform = getReadingPlatform()
  const readFormat = readingInfo.get("mode")
  const trafficSource = articleReadEvents.get("trafficSource") || "url"
  const issueId = readingInfo.get("issueId")
  const magazineId = yield selectStore("issues", issueId, "magazineId")
  const ip = yield selectStore("clientInfo", "ip")

  return {
    reader_id: userId,
    access_token: accessToken,
    article_id: articleId,
    session_id: sessionId,
    begin_at: beginAt,
    duration: duration,
    platform: platform,
    read_format: readFormat,
    traffic_source: trafficSource,
    title_id: magazineId,
    magazine_id: issueId,
    ip: ip
  }
}

export function* initCurrentReadingInfo(action) {
  const { params } = action.payload
  if (params.mode === "pdf") yield initCurrentReadingInfoPage(params)
  if (params.mode === "fit_reading")
    yield initCurrentReadingInfoSingleArticle(params)
}

function* initCurrentReadingInfoPage(params) {
  const issueId = params.issueId
  const mode = params.mode
  let issueArticles = yield selectStore("issueArticles", issueId)
  if (!issueArticles) {
    // reset current reading info and tracking hash
    yield put(onClearCurrentReadingInfoAndTrackingHash())

    const response = yield call(new FetchIssueArticlesApi({ issueId }).call)
    const articles = response.data.articles
    issueArticles = articles.map(article => ({
      id: article.article_id,
      startPageNumber: article.page_number,
      endPageNumber: article.end_page
    }))
    yield put(onRequestIssueArticlesSuccess(issueId, articles))
  } else {
    issueArticles = issueArticles.toJS()
  }

  const pageNumberMapping = yield getPageNumberMapping(issueArticles)

  const currentArticleId = pageNumberMapping[params.pageNumber0]
  let anotherArticleId = pageNumberMapping[params.pageNumber1]
  if (anotherArticleId === currentArticleId) anotherArticleId = undefined

  const readingInfo = {
    issueId,
    pageNumberMapping,
    currentArticleId,
    anotherArticleId,
    mode
  }

  yield put(onUpdateCurrentReadingInfo(readingInfo))
  yield put(onUpdateArticleReadTrackingHash(currentArticleId, anotherArticleId))
  yield put(onLogOpenArticle({ articleId: currentArticleId, mode: "pdf" }))
}

function getPageNumberMapping(issueArticles) {
  let mapping = {}
  let pageNumber = 1
  issueArticles.forEach(article => {
    for (
      pageNumber = article.startPageNumber;
      pageNumber <= article.endPageNumber;
      pageNumber++
    ) {
      mapping[pageNumber] = article.id
    }
  })
  return mapping
}

function* initCurrentReadingInfoSingleArticle(params) {
  const currentArticleId = params.articleId
  const mode = params.mode

  const article = yield selectStore("articles", currentArticleId)
  let issueId = null

  if (!article) {
    yield put(onClearCurrentReadingInfoAndTrackingHash())

    try {
      const response = yield call(
        new FetchArticleApi({ articleId: currentArticleId }).call
      )
      issueId = response.data.bid
      yield put(onRequestArticleSuccess(response.data))
    } catch (e) {
      console.log(e)
    }
  } else {
    issueId = article.get("issueId")
  }

  const readingInfo = {
    issueId,
    currentArticleId,
    mode
  }
  yield put(onUpdateCurrentReadingInfo(readingInfo))
  yield put(onUpdateArticleReadTrackingHash(currentArticleId))
  yield put(
    onLogOpenArticle({ articleId: currentArticleId, mode: "fit_reading" })
  )
}

export function* watchInitCurrentReadingInfo() {
  yield takeEvery(INIT_CURRENT_READING_INFO, initCurrentReadingInfo)
}

function* changePageNumber(action) {
  const pageNumbers = action.payload.pageNumbers
  // do nothing if no page number mapping
  let pageNumberMapping = yield selectStore(
    "articleReadEvents",
    "currentReadingInfo",
    "pageNumberMapping"
  )
  if (!pageNumberMapping || is(pageNumberMapping, fromJS({}))) return

  pageNumberMapping = pageNumberMapping.toJS()

  const currentArticleId = pageNumberMapping[pageNumbers[0]]
  let anotherArticleId = pageNumberMapping[pageNumbers[1]]
  if (anotherArticleId === currentArticleId) anotherArticleId = undefined

  let readingInfo = yield selectStore("articleReadEvents", "currentReadingInfo")
  readingInfo = readingInfo.toJS()
  const prevArticleId = readingInfo.currentArticleId
  readingInfo.currentArticleId = currentArticleId
  readingInfo.anotherArticleId = anotherArticleId

  yield put(onUpdateCurrentReadingInfo(readingInfo))
  yield put(onUpdateArticleReadTrackingHash(currentArticleId, anotherArticleId))

  if (currentArticleId !== prevArticleId) {
    yield put(onLogOpenArticle({ articleId: currentArticleId, mode: "pdf" }))
  }
}

export function* watchChangePageNumber() {
  yield takeEvery(CHANGE_PAGE_NUMBERS, changePageNumber)
}
