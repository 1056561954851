import React, { Component } from "react"
import HeaderUI from "components/presentational/HeaderUI"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { DOWNLOAD_APP_STATE } from "constants/state"
import {
  K4L_APP_STORE_LINK,
  K4L_GOOGLE_PLAY_LINK,
  K4lQRCode
} from "constants/appLinks"
import { HEADER_DOWNLOAD_APP_LINK } from "constants/headerMenu"
import { onLogDownloadApp } from "actions/trackingEvents"
import { onOpenHeaderMenu, onCloseHeaderMenu } from "actions/header"
import SmoothCollapse from "react-smooth-collapse"
import appStoreLogo from "./assets/images/app_store_logo.svg"
import googlePlayLogo from "./assets/images/google_play_logo.svg"
import appStoreLogoDark from "./assets/images/app_store_logo_dark.svg"
import googlePlayLogoDark from "./assets/images/google_play_logo_dark.svg"
import { withTranslation } from "react-i18next"
import styled from "styled-components/macro"

const MainWrapper = styled.div`
  position: relative;
`

const ExternalLinkWrapper = styled.a`
  display: flex;
  width: 120px;
  height: 40px;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  border-radius: 20px;
  background-color: ${props =>
    props.focus === "true" ? (props.dark ? "#40403f" : "#f6f3ed") : "none"};
  margin-top: 20px;

  &:hover {
    background-color: ${props => (props.dark ? "#40403f" : "#f6f3ed")};
  }
`

const DownloadAppQrCode = styled.img.attrs({ alt: "QR code" })`
  width: 120px;
`

const DownloadAppStoreLogo = styled.img`
  width: 81px;
`

class DownloadAppLink extends Component {
  constructor() {
    super()
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.props.isMenuOpen
      ? this.props.onCloseHeaderMenu()
      : this.props.onOpenHeaderMenu(HEADER_DOWNLOAD_APP_LINK)
  }

  render() {
    return (
      <MainWrapper onClick={this.handleClick}>
        <HeaderUI.LinkWrapper
          dark={this.props.dark}
          focus={this.props.isMenuOpen ? 1 : 0}
        >
          <HeaderUI.NavigationsText dark={this.props.dark}>
            {this.props.t("DOWNLOAD_APP")}
          </HeaderUI.NavigationsText>
        </HeaderUI.LinkWrapper>
        <HeaderUI.MenuWrapper>
          <SmoothCollapse expanded={this.props.isMenuOpen}>
            <HeaderUI.DropDownMenu dark={this.props.dark}>
              <HeaderUI.MenuColumn>
                <DownloadAppQrCode
                  src={
                    this.props.k4lCurrentLibrary &&
                    this.props.k4lCurrentLibrary.get("qrCode")
                      ? this.props.k4lCurrentLibrary.get("qrCode")
                      : K4lQRCode
                  }
                />
                <ExternalLinkWrapper
                  dark={this.props.dark}
                  href={K4L_APP_STORE_LINK}
                >
                  <DownloadAppStoreLogo
                    src={this.props.dark ? appStoreLogoDark : appStoreLogo}
                    alt="app store download"
                    onClick={() => {
                      this.props.onLogDownloadApp()
                    }}
                  />
                </ExternalLinkWrapper>
                <ExternalLinkWrapper
                  dark={this.props.dark}
                  href={K4L_GOOGLE_PLAY_LINK}
                >
                  <DownloadAppStoreLogo
                    src={this.props.dark ? googlePlayLogoDark : googlePlayLogo}
                    alt="google play download"
                    onClick={() => {
                      this.props.onLogDownloadApp()
                    }}
                  />
                </ExternalLinkWrapper>
              </HeaderUI.MenuColumn>
            </HeaderUI.DropDownMenu>
          </SmoothCollapse>
        </HeaderUI.MenuWrapper>
      </MainWrapper>
    )
  }
}

const mapStateToProps = state => ({
  isFocus: state.getIn(["state", "current"]) === DOWNLOAD_APP_STATE,
  isOpen: state.getIn(["header", "isOpen"]),
  isMenuOpen:
    state.getIn(["header", "currentOpenMenu"]) === HEADER_DOWNLOAD_APP_LINK,
  k4lCurrentLibrary: state.getIn(["k4lLibraries", "current"])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onLogDownloadApp,
      onOpenHeaderMenu,
      onCloseHeaderMenu
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("container")(DownloadAppLink))
