import { handleActions } from "redux-actions"
import Immutable, { fromJS } from "immutable"
import { REQUEST_RECOMMEND_ARTICLES_SUCCESS } from "constants/actionTypes"

export const initialState = Immutable.fromJS([])

const formattedRecommend = recommended => {
  return {
    id: recommended.id,
    articleId: recommended.article_id,
    recommendedBy: recommended.recommended_by,
    createdAt: recommended.created_at
  }
}

const reducerMap = new Map([
  [
    REQUEST_RECOMMEND_ARTICLES_SUCCESS,
    (state, action) => {
      const recommend = action.payload.recommendArticles
      const recommendMap = recommend.map(formattedRecommend)
      return fromJS(recommendMap)
    }
  ]
])

export default handleActions(reducerMap, initialState)
