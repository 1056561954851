import {
  REQUEST_BRAINTREE_SUBSCRIPTION,
  REQUEST_BRAINTREE_SUBSCRIPTION_THROTTLING,
  REQUEST_BRAINTREE_SUBSCRIPTION_SUCCESS,
  REQUEST_BRAINTREE_SUBSCRIPTION_ERROR
} from "constants/actionTypes"

export const onRequestBraintreeSubscription = (userId, throttling) => {
  const actionType = throttling
    ? REQUEST_BRAINTREE_SUBSCRIPTION_THROTTLING
    : REQUEST_BRAINTREE_SUBSCRIPTION
  return {
    type: actionType,
    payload: { userId }
  }
}

export const onRequestBraintreeSubscriptionSuccess = subscription => {
  return {
    type: REQUEST_BRAINTREE_SUBSCRIPTION_SUCCESS,
    payload: { subscription }
  }
}

export const onRequestBraintreeSubscriptionError = errorStatus => {
  return {
    type: REQUEST_BRAINTREE_SUBSCRIPTION_ERROR,
    payload: { errorStatus }
  }
}
