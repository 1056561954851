import {
  REQUEST_TSTAR_SMS,
  REQUEST_TSTAR_SMS_ERROR,
  REQUEST_TSTAR_SMS_SUCCESS,
  CLEAR_TSTAR_SMS_SENDER,
  DECREASE_TSTAR_SMS_TIME,
  BIND_TSTAR_SMS,
  BIND_TSTAR_SMS_SUCCESS,
  CLEAR_TSTAR_SMS_BINDER,
  BIND_TSTAR_SMS_ERROR,
  EXIST_TSTAR_TOKEN,
  EXIST_TSTAR_TOKEN_SUCCESS,
  EXIST_TSTAR_TOKEN_ERROR,
  CLEAR_TSTAR_TOKEN,
  BIND_TSTAR_TOKEN,
  BIND_TSTAR_TOKEN_SUCCESS,
  BIND_TSTAR_TOKEN_ERROR,
  CLOSE_TSTAR_VIP_TRANSFER_MODAL
} from "constants/actionTypes"

export const onRequestTstarSms = phoneNumber => ({
  type: REQUEST_TSTAR_SMS,
  payload: { phoneNumber }
})

export const onRequestTstarSmsSuccess = () => ({
  type: REQUEST_TSTAR_SMS_SUCCESS
})

export const onRequestTstarSmsError = (code, cdTime) => ({
  type: REQUEST_TSTAR_SMS_ERROR,
  payload: { code, cdTime }
})

export const onClearSmsSender = () => ({
  type: CLEAR_TSTAR_SMS_SENDER
})

export const onDecreaseSmsTime = () => ({
  type: DECREASE_TSTAR_SMS_TIME
})

export const onBindTstarSms = confirmationCode => ({
  type: BIND_TSTAR_SMS,
  payload: { confirmationCode }
})

export const onBindTstarSmsSuccess = () => ({
  type: BIND_TSTAR_SMS_SUCCESS
})

export const onBindTstarSmsError = code => ({
  type: BIND_TSTAR_SMS_ERROR,
  payload: { code }
})

export const onClearSmsBinder = () => ({
  type: CLEAR_TSTAR_SMS_BINDER
})

export const onExistTstarToken = token => ({
  type: EXIST_TSTAR_TOKEN,
  payload: { token }
})

export const onExistTstarTokenSuccess = () => ({
  type: EXIST_TSTAR_TOKEN_SUCCESS
})

export const onExistTstarTokenError = error => ({
  type: EXIST_TSTAR_TOKEN_ERROR,
  payload: { code: error.data.CODE, status: error.status }
})

export const onClearTstarToken = () => ({
  type: CLEAR_TSTAR_TOKEN
})

export const onBindTstarToken = (userId, token) => ({
  type: BIND_TSTAR_TOKEN,
  payload: { userId, token }
})

export const onBindTstarTokenSuccess = () => ({
  type: BIND_TSTAR_TOKEN_SUCCESS
})

export const onBindTstarTokenError = error => ({
  type: BIND_TSTAR_TOKEN_ERROR,
  payload: { code: error.data.code, status: error.status }
})

export const onCloseTstarVipTransferModal = () => {
  return {
    type: CLOSE_TSTAR_VIP_TRANSFER_MODAL
  }
}
