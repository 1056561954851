import React, { Component } from "react"
import { connect } from "react-redux"

export function withBookListId(WrappedComponent) {
  class BookListId extends Component {
    render() {
      return this.props.currentBookListId ? (
        <WrappedComponent
          {...this.props}
          currentBookListId={this.props.currentBookListId}
        />
      ) : null
    }
  }

  const mapStateToProps = state => ({
    currentBookListId: state.getIn(["apiSettings", "bookListId"])
  })

  return connect(mapStateToProps)(BookListId)
}
