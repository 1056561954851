import React from "react"
import { useDispatch } from "react-redux"
import { onToggleSideTabMenu } from "actions/sideTabMenu"
import closeIcon from "./assets/images/close.svg"
import styled from "styled-components"

const SideTabCloseBtnIcon = styled.div`
  width: 48px;
  height: 48px;
  background-image: url(${closeIcon});
`

const SideTabCloseBtnWrapper = styled.div`
  border-bottom: 1px solid #eee9e0;
`

export default function SideTabCloseButton(props) {
  const dispatch = useDispatch()

  return (
    <SideTabCloseBtnWrapper>
      <SideTabCloseBtnIcon onClick={() => dispatch(onToggleSideTabMenu())} />
    </SideTabCloseBtnWrapper>
  )
}
