import { REQUEST_NEWS_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import camelcaseKeys from "camelcase-keys"

const initialState = fromJS([])

const formatNews = news => {
  return camelcaseKeys(
    {
      id: news.id,
      name: news.name,
      date: news.date,
      content: news.content.map(formatNewsContent)
    },
    { deep: true }
  )
}

const formatNewsContent = content => {
  const contentType = content[content.type]
  const contentValue =
    content.type === "image"
      ? {
          ...contentType[contentType.type],
          caption:
            contentType.caption.length > 0
              ? contentType.caption[0].plain_text
              : ""
        }
      : contentType.rich_text

  return {
    id: content.id,
    type: content.type,
    content: contentValue
  }
}

const reducerMap = new Map([
  [
    REQUEST_NEWS_SUCCESS,
    (state, action) => {
      const news = action.payload.news
      return fromJS(news.map(formatNews))
    }
  ]
])

export default handleActions(reducerMap, initialState)
