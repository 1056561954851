import React from "react"
import { NoStyledH1 } from "components/presentational/Headline"
import { Link } from "react-router-dom"
import Text from "../Text"
import styled from "styled-components/macro"
import media from "utilities/media"

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 11.5px;
  border-bottom: 1px solid #eee9e0;

  ${media.desktop`
    width: calc(100% - 10px);
    margin: 0 5px;
  `};
`

const TitleText = styled.div`
  font-size: 36px;
  color: #222222;
`

const HintWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
`

const LinkText = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  color: #008159;
  padding-left: 5px;
`

export default function Title(props) {
  return (
    <TitleWrapper>
      <TitleText>
        <NoStyledH1>{props.titleText}</NoStyledH1>
      </TitleText>
      {props.descText || props.linkText ? (
        <HintWrapper>
          <Text>{props.descText}</Text>
          <LinkText to={props.href}>{props.linkText}</LinkText>
        </HintWrapper>
      ) : null}
    </TitleWrapper>
  )
}
