// Some events in saga listen to existed actions
// If actions does not exist, add them here

import {
  LOG_SIGN_IN,
  LOG_SIGN_UP,
  LOG_TELECOM_BINDING,
  LOG_OPEN_ARTICLE,
  LOG_OPEN_CURATION_POST,
  LOG_OPEN_CURATION_POST_ARTICLE,
  LOG_OPEN_RECOMMEND_ARTICLE,
  LOG_UPGRADE_TRY_PURCHASE,
  LOG_UPGRADE_PLAN_SELECTED,
  LOG_UPGRADE_SUCCESS,
  LOG_UPGRADE_TRY_USE_PROMOTION_CODE,
  LOG_UPGRADE_COUPON,
  LOG_BANNER_CLICK,
  LOG_SEARCH_RESULT_MAGAZINE_CLICK,
  LOG_SEARCH_RESULT_ARTICLE_CLICK,
  LOG_FREE_MAGAZINE_INTRO_DISPLAY,
  LOG_FREE_MAGAZINE_INTRO_ACTION_CLICK,
  LOG_RECOMMEND_QUESTION_ASKED,
  LOG_RECOMMEND_LIST_ENTER,
  LOG_SHARE_ARTICLE,
  LOG_DOWNLOAD_APP,
  LOG_RESEND_CONFIRMATION_EMAIL,
  LOG_WIDGET_BAR_CLICK,
  LOG_GET_IBON_PINCODE_SUCCESS,
  LOG_PLAY_AUDIO,
  LOG_BUY_GIFTING,
  LOG_HOME_MY_FOLLOW_CLICK,
  LOG_HOME_RECOMMEND_MAGAZINE_CLICK,
  LOG_HOME_WEEKLY_ISSUE_CLICK,
  LOG_HOME_LATEST_ISSUE_CLICK,
  LOG_HOME_CURATION_CHANNEL_CLICK,
  LOG_HOME_CURATION_POST_CLICK,
  LOG_HOME_CURATION_POST_ARTICLE_CLICK,
  LOG_HOME_RECOMMEND_ARTICLE_CLICK,
  LOG_HOME_POPULAR_MAGAZINE_CLICK,
  LOG_HOME_POPULAR_ARTICLE_CLICK,
  LOG_HOME_MY_BOOKMARK_CLICK,
  LOG_HEADER_CLICK,
  LOG_PREVIEW_ARTICLE_DOWNLOAD_APP_CLICK,
  LOG_HEADER_NAVIGATION_CATEGORY_CLICK,
  LOG_HEADER_NAVIGATION_LIBRARY_CLICK,
  LOG_ENTER_HOME,
  LOG_START_TRIAL_SHOW,
  LOG_START_TRIAL_CLICK,
  LOG_OPEN_REPORT,
  LOG_CLICK_SURVEY,
  LOG_VIEW_PRICE_PAGE,
  LOG_CLICK_SUBSCRIBE,
  LOG_VIEW_PAYMENT_PAGE,
  LOG_CONFIRM_SUBSCRIBE,
  LOG_VIEW_THANK_YOU_PAGE
} from "constants/actionTypes"

export const onLogSignIn = user => ({
  type: LOG_SIGN_IN,
  payload: { user }
})

export const onLogSignUp = user => ({
  type: LOG_SIGN_UP,
  payload: { user }
})

export const onLogTelecomBinding = params => ({
  type: LOG_TELECOM_BINDING,
  payload: { params }
})

export const onLogOpenArticle = params => ({
  type: LOG_OPEN_ARTICLE,
  payload: { params }
})

export const onLogOpenCurationPost = postId => ({
  type: LOG_OPEN_CURATION_POST,
  payload: { postId }
})

export const onLogOpenCurationPostArticle = (postId, articleId) => ({
  type: LOG_OPEN_CURATION_POST_ARTICLE,
  payload: { postId, articleId }
})

export const onLogOpenRecommendArticle = (articleId, recommendedBy) => ({
  type: LOG_OPEN_RECOMMEND_ARTICLE,
  payload: { articleId, recommendedBy }
})

export const onLogUpgradeTryPurchase = paymentType => ({
  type: LOG_UPGRADE_TRY_PURCHASE,
  payload: { paymentType }
})

export const onLogUpgradePlanSelected = (paymentType, product) => ({
  type: LOG_UPGRADE_PLAN_SELECTED,
  payload: { paymentType, product }
})

export const onLogUpgradeSuccess = product => ({
  type: LOG_UPGRADE_SUCCESS,
  payload: { product }
})

export const onLogUpgradeTryUsePromotionCode = discountCode => ({
  type: LOG_UPGRADE_TRY_USE_PROMOTION_CODE,
  payload: { discountCode }
})

export const onLogUpgradeCoupon = (coupon, code) => ({
  type: LOG_UPGRADE_COUPON,
  payload: { coupon, code }
})

export const onLogBannerClick = banner => ({
  type: LOG_BANNER_CLICK,
  payload: { banner }
})

export const onLogSearchResultMagazineClick = magazine => ({
  type: LOG_SEARCH_RESULT_MAGAZINE_CLICK,
  payload: { magazine }
})

export const onLogSearchResultArticleClick = articleId => ({
  type: LOG_SEARCH_RESULT_ARTICLE_CLICK,
  payload: { articleId }
})

export const onLogFreeMagazineIntroDisplay = () => ({
  type: LOG_FREE_MAGAZINE_INTRO_DISPLAY
})

export const onLogFreeMagazineIntroActionClick = actionType => ({
  type: LOG_FREE_MAGAZINE_INTRO_ACTION_CLICK,
  payload: { actionType }
})

export const onLogRecommendQuestionAsked = (node, step) => ({
  type: LOG_RECOMMEND_QUESTION_ASKED,
  payload: { node, step }
})

export const onLogRecommendListEnter = recommendList => ({
  type: LOG_RECOMMEND_LIST_ENTER,
  payload: { recommendList }
})

export const onLogShareArticle = () => ({
  type: LOG_SHARE_ARTICLE
})

export const onLogDownloadApp = () => ({
  type: LOG_DOWNLOAD_APP
})

export const onLogResendConfirmationEmail = () => ({
  type: LOG_RESEND_CONFIRMATION_EMAIL
})

export const onLogWidgetBarClick = btnType => ({
  type: LOG_WIDGET_BAR_CLICK,
  payload: { btnType }
})

export const onLogGetIbonPincodeSuccess = product => ({
  type: LOG_GET_IBON_PINCODE_SUCCESS,
  payload: { product }
})

export const onLogPlayAudio = audio => ({
  type: LOG_PLAY_AUDIO,
  payload: { audio }
})

export const onLogBuyGifting = () => ({
  type: LOG_BUY_GIFTING
})

export const onLogHomeMyFollowMagazineClick = magazineId => ({
  type: LOG_HOME_MY_FOLLOW_CLICK,
  payload: { magazineId }
})

export const onLogHomeRecommendMagazineClick = magazineId => ({
  type: LOG_HOME_RECOMMEND_MAGAZINE_CLICK,
  payload: { magazineId }
})

export const onLogHomeWeeklyIssueClick = issueId => ({
  type: LOG_HOME_WEEKLY_ISSUE_CLICK,
  payload: { issueId }
})

export const onLogHomeLatestIssueClick = issueId => ({
  type: LOG_HOME_LATEST_ISSUE_CLICK,
  payload: { issueId }
})

export const onLogHomeCurationChannelClick = channelId => ({
  type: LOG_HOME_CURATION_CHANNEL_CLICK,
  payload: { channelId }
})

export const onLogHomeCurationPostClick = postId => ({
  type: LOG_HOME_CURATION_POST_CLICK,
  payload: { postId }
})

export const onLogHomeCurationPostArticleClick = (postId, articleId) => ({
  type: LOG_HOME_CURATION_POST_ARTICLE_CLICK,
  payload: { postId, articleId }
})

export const onLogHomeRecommendArticleClick = articleId => ({
  type: LOG_HOME_RECOMMEND_ARTICLE_CLICK,
  payload: { articleId }
})

export const onLogHomePopularMagazineClick = magazineId => ({
  type: LOG_HOME_POPULAR_MAGAZINE_CLICK,
  payload: { magazineId }
})

export const onLogHomePopularArticleClick = articleId => ({
  type: LOG_HOME_POPULAR_ARTICLE_CLICK,
  payload: { articleId }
})

export const onLogHomeMyBookmarkClick = articleId => ({
  type: LOG_HOME_MY_BOOKMARK_CLICK,
  payload: { articleId }
})

export const onLogHeaderClick = position => ({
  type: LOG_HEADER_CLICK,
  payload: { position }
})

export const onLogPreviewArticleDownloadAppClick = () => ({
  type: LOG_PREVIEW_ARTICLE_DOWNLOAD_APP_CLICK
})

export const onLogHeaderNavigationCategoryClick = categoryId => ({
  type: LOG_HEADER_NAVIGATION_CATEGORY_CLICK,
  payload: { categoryId }
})

export const onLogHeaderNavigationLibraryClick = libraryId => ({
  type: LOG_HEADER_NAVIGATION_LIBRARY_CLICK,
  payload: { libraryId }
})

export const onLogEnterHome = source => ({
  type: LOG_ENTER_HOME,
  payload: { source }
})

export const onLogStartTrialShow = (userId, source) => ({
  type: LOG_START_TRIAL_SHOW,
  payload: { userId, source }
})

export const onLogStartTrialClick = (userId, plan) => ({
  type: LOG_START_TRIAL_CLICK,
  payload: { userId, plan }
})

export const onLogOpenReport = (userId, width) => ({
  type: LOG_OPEN_REPORT,
  payload: { userId, width }
})

export const onLogClickSurvey = userId => ({
  type: LOG_CLICK_SURVEY,
  payload: { userId }
})

export const onLogViewPricePage = () => ({
  type: LOG_VIEW_PRICE_PAGE
})

export const onLogClickSubscribe = (planFrequency, discountCode) => ({
  type: LOG_CLICK_SUBSCRIBE,
  payload: { planFrequency, discountCode }
})

export const onLogViewPaymentPage = () => ({
  type: LOG_VIEW_PAYMENT_PAGE
})

export const onLogConfirmSubscribe = (planFrequency, discountCode) => ({
  type: LOG_CONFIRM_SUBSCRIBE,
  payload: { planFrequency, discountCode }
})

export const onLogViewThankYouPage = () => ({
  type: LOG_VIEW_THANK_YOU_PAGE
})
