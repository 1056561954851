import {
  REQUEST_FOLLOWED_MAGAZINES_SUCCESS,
  FOLLOW_MAGAZINE,
  UNFOLLOW_MAGAZINE
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { List } from "immutable"

export const initialState = List([])

const reducerMap = new Map([
  [
    REQUEST_FOLLOWED_MAGAZINES_SUCCESS,
    (state, action) => {
      const magazineIds = action.payload.followedIssues.map(
        magazine => magazine.title
      )
      return List(magazineIds)
    }
  ],
  [
    FOLLOW_MAGAZINE,
    (state, action) => {
      const { magazineId } = action.payload
      return state.includes(magazineId) ? state : state.push(magazineId)
    }
  ],
  [
    UNFOLLOW_MAGAZINE,
    (state, action) => {
      const { magazineId } = action.payload
      return state.filter(id => id !== magazineId)
    }
  ]
])

export default handleActions(reducerMap, initialState)
