import KonoApi from "../KonoApi"
import store from "../../store"

class FetchArticleApi extends KonoApi {
  constructor(data) {
    super(`/articles/${data.articleId}`)
  }

  call() {
    const currentUserId = store.getState().getIn(["user", "current", "id"])
    return this.axios.get(this.url, { params: { user_id: currentUserId } })
  }
}

export default FetchArticleApi
