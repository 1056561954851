import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import K4lSideTabMenu from "components/container/K4lSideTabMenu"
import { onChangeStateVia } from "actions/state"
import { VIA_FOOTER } from "constants/stateVia"
import { onLogDownloadApp } from "actions/trackingEvents"
import { onCloseHeaderMenu } from "actions/header"
import { onToggleSideTabMenu, onCloseSideTabMenu } from "actions/sideTabMenu"
import { changeLoadingSuccess } from "actions/loading"
import K4lHeader from "components/container/K4lHeader"
import K4lFooter from "components/container/K4lFooter"
import ArticleReaderAppend from "components/container/ArticleReaderAppend"
import MetaTag from "components/container/MetaTag"
import CookieBanner from "components/container/CookieBanner"
import Loading from "components/presentational/Loading"
import { withMql } from "utilities/decorators"
import styled from "styled-components/macro"

const MainWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  flex: 1;
  background-color: ${props => (props.dark ? "#2b2b2b" : "none")};
`

class K4lContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { showLoading: false }
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.isDesktop() && !prevProps.isDesktop()) {
      this.props.onCloseSideTabMenu()
    }

    if (this.props.loading.get("changeLoading")) {
      this.props.loading.get("loading")
        ? this.showLoadingView()
        : this.hideLoadingView()
    }

    if (this.props.k4lError && !prevProps.k4lError) {
      window.location.replace("/")
    }
  }

  showLoadingView() {
    this.setState({ showLoading: true }, () =>
      this.props.changeLoadingSuccess()
    )
  }

  hideLoadingView() {
    this.setState({ showLoading: false }, () =>
      this.props.changeLoadingSuccess()
    )
  }

  handleClick() {
    if (this.props.isMenuOpen) this.props.onCloseHeaderMenu()
  }

  get darkMode() {
    return this.props.articleViewer && this.props.isDesktop() ? 1 : 0
  }

  get hideFooter() {
    return (
      this.props.hideFooter || this.darkMode || !this.props.appViewShowHeader
    )
  }

  get append() {
    return this.darkMode && this.props.articleViewer ? (
      <ArticleReaderAppend backHref={this.props.bunsBackHref} />
    ) : null
  }

  render() {
    return (
      <React.Fragment>
        <MetaTag />
        <MainWrapper>
          <K4lHeader
            dark={this.darkMode}
            breadcrumb={this.darkMode && this.props.breadcrumb}
            append={this.append}
            isLanding={this.props.isLanding}
          />
          <ContentWrapper dark={this.darkMode} onClick={this.handleClick}>
            <Loading display={this.state.showLoading} />
            {this.props.children}
          </ContentWrapper>
          <CookieBanner />
          {this.hideFooter ? null : (
            <K4lFooter
              onClick={this.handleClick}
              onLogClick={() => this.props.onChangeStateVia(VIA_FOOTER)}
              onLogDownloadApp={this.props.onLogDownloadApp}
            />
          )}
          {this.props.isDesktop() ? null : <K4lSideTabMenu />}
        </MainWrapper>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.get("loading"),
  isMenuOpen: state.getIn(["header", "currentOpenMenu"]) !== null,
  breadcrumb: state.getIn(["breadcrumb", "current"]),
  bunsBackHref: state.getIn(["buns", "backHref"]),
  k4lError: state.getIn(["k4l", "error"]),
  appViewShowHeader: state.getIn(["general", "appView", "showHeader"])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onToggleSideTabMenu,
      onCloseSideTabMenu,
      changeLoadingSuccess,
      onChangeStateVia,
      onLogDownloadApp,
      onCloseHeaderMenu
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMql(K4lContainer))
