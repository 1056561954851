import { REQUEST_RECOMMENDATION_TREE_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS([])

const formatMagazines = magazines => {
  Object.keys(magazines).forEach((key, index) => {
    magazines[key] = magazines[key].map(magazine => {
      return {
        magazineId: magazine.title_id,
        popularity: magazine.popularity
      }
    })
  })
  return magazines
}

const formatTree = tree => {
  return tree.map(category => {
    return {
      name: category.name,
      magazineNum: category.title_num,
      isAdult: category.is_adult,
      library: category.library,
      magazines: formatMagazines(category.titles),
      dictKey: category.dict_key,
      id: category.id,
      coverTitle: category.cover_title,
      description: category.description
    }
  })
}

const reducerMap = new Map([
  [
    REQUEST_RECOMMENDATION_TREE_SUCCESS,
    (state, action) => {
      const tree = formatTree(action.payload.tree)
      return fromJS(tree)
    }
  ]
])

export default handleActions(reducerMap, initialState)
