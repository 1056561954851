import KonoApi from "../KonoApi"

class FetchIosReceiptsApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/ios_receipts`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchIosReceiptsApi
