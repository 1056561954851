import ErrorMessage from "./components/ErrorMessage"
import Title from "./components/Title"
import Subtitle from "./components/Subtitle"
import Text from "./components/Text"

const object =  {
  ErrorMessage,
  Title,
  Subtitle,
  Text
}

export default object
