import { REQUEST_VIP_PERIODS_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS([])

const reducerMap = new Map([
  [
    REQUEST_VIP_PERIODS_SUCCESS,
    (state, action) => {
      return fromJS(
        action.payload.vipPeriods.map(vipPeriod => {
          return {
            id: vipPeriod.id,
            planType: vipPeriod.plan_type,
            paymentType: vipPeriod.payment_type,
            currency: vipPeriod.currency,
            amount: vipPeriod.amount,
            beginAt: vipPeriod.begin_at,
            endAt: vipPeriod.end_at,
            paid: vipPeriod.paid
          }
        })
      )
    }
  ]
])

export default handleActions(reducerMap, initialState)
