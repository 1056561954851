import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import { REQUEST_MAGAZINE_ISSUES_SUCCESS } from "constants/actionTypes"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    REQUEST_MAGAZINE_ISSUES_SUCCESS,
    (state, action) => {
      const magazineId = action.payload.magazineId
      const issues = fromJS(action.payload.issues.map(issue => issue.bid))
      return state.set(magazineId, issues)
    }
  ]
])

export default handleActions(reducerMap, initialState)
