import styled from "styled-components/macro"

const HyperLink = styled.a`
  text-decoration: ${props => (props.underline ? "underline" : "none")};
  color: inherit;

  &:visited {
    text-decoration: none;
    color: inherit;
  }
`

export default HyperLink
