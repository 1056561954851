import { OPEN_TOAST, CLOSE_TOAST } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS([
  {
    isOpen: false,
    type: null,
    id: "",
    args: {}
  }
])

let count = 0

const reducerMap = new Map([
  [
    OPEN_TOAST,
    (state, action) => {
      const { type, args } = action.payload
      return state
        .setIn(["0", "id"], count++)
        .setIn(["0", "isOpen"], true)
        .setIn(["0", "type"], type)
        .setIn(["0", "args"], fromJS(args))
    }
  ],
  [
    CLOSE_TOAST,
    state => {
      return initialState
    }
  ]
])

const toasts = handleActions(reducerMap, initialState)
export default toasts
