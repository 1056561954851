import KonoApi from "../KonoApi"

class FetchCurrentFreeIssueApi extends KonoApi {
  constructor(data) {
    super(`/free_magazines/current`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchCurrentFreeIssueApi
