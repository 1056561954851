import styled from "styled-components/macro"
import media from "utilities/media"

const MainTitle = styled.div`
  font-weight: 500;
  line-height: 1.4;
  color: #222222;

  ${media.desktop`
      font-size: 36px;
  `};
  ${media.tablet`
      font-size: 20px;
  `};
  ${media.mobile`
      font-size: 20px;
  `};
`

export default MainTitle
