import {
  RECORD_USER_ACTIVITY,
  RECORD_USER_ACTIVITY_SUCCESS
} from "constants/actionTypes"

export const recordUserActivity = userId => ({
  type: RECORD_USER_ACTIVITY,
  payload: { userId }
})

export const recordUserActivitySuccess = () => ({
  type: RECORD_USER_ACTIVITY_SUCCESS
})
