import axios from "axios"
import KonoApi from "../KonoApi"

class K4lLoginApi extends KonoApi {
  constructor() {
    super(`/k4l/users/login`)
  }

  call(params) {
    return axios.post(this.url, {
      platform: params.platform,
      account: params.account,
      validator: params.validator,
      card_number: params.cardNumber,
      ...params.utmParams
    })
  }
}

export default K4lLoginApi
