import { REQUEST_MAGAZINE_YEARS_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    REQUEST_MAGAZINE_YEARS_SUCCESS,
    (state, action) => {
      return state.set(action.payload.magazineId, fromJS(action.payload.years))
    }
  ]
])

export default handleActions(reducerMap, initialState)
