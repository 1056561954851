import KonoApi from "../KonoApi"

class FetchIssueApi extends KonoApi {
  constructor(data) {
    super(`/magazines/${data.issueId}`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchIssueApi
