import styled from "styled-components/macro"

const SideTabDivWrapper = styled.div`
  width: 280px;
  padding: ${props => (props.noBottomBorder ? "15px 0 0" : "15px 0px")};
  margin: 0 auto;
  border-bottom: ${props =>
    props.noBottomBorder ? "none" : "1px solid #eee9e0"};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${props => (props.bottom ? "margin-top: auto;" : "")}
`

export default SideTabDivWrapper
