import { takeEvery, throttle } from "redux-saga/effects"
import {
  LOG_SIGN_UP,
  LOG_SIGN_IN,
  AMP_INIT_USER_PROPERTIES,
  REQUEST_VIP_PERIODS_SUCCESS,
  SET_FIRST_LANDING_PAGE,
  CHANGE_STATE,
  LOG_CLICK_OOBE_NEXT_STEP,
  LOG_COMPLETE_OOBE,
  LOG_VIEW_ARTICLE_PREVIEW,
  LOG_CLICK_ARTICLE_PREVIEW_CELL,
  LOG_PLAY_VIDEO,
  LOG_VIEW_HOME,
  LOG_OPEN_ARTICLE,
  REQUEST_FOLLOWED_MAGAZINES_SUCCESS,
  FOLLOW_MAGAZINE,
  UNFOLLOW_MAGAZINE,
  LOG_SEARCH_RESULT,
  LOG_SEARCH_RESULT_CLICK,
  LOG_SHOW_ARTICLE_PREVIEW_MODAL
} from "constants/actionTypes"
import {
  VIEW_OOBE_PAGE,
  CLICK_OOBE_NEXT_STEP,
  COMPLETE_OOBE,
  VIEW_ARTICLE_PREVIEW,
  CLICK_ARTICLE_PREVIEW_CELL,
  PLAY_VIDEO
} from "constants/trackingEvents"
import { OOBE_STATE } from "constants/state"
import { fromJS } from "immutable"
import amplitude from "utilities/amplitude"
import { fetchAndReturnVipPeriods } from "../vipPeriodsSaga"
import { fetchAndReturnFreeArticleQuota } from "../freeArticleQuotaSaga"
import { fetchAndReturnSubscriptionInfo } from "../subscriptionInfoSaga"
import { getTrackingVipStatus } from "utilities/vipPeriodMap"
import { selectStore } from "utilities/storeCheck"
import { categorizePage, trackingSource } from "utilities/history"
import { getNow, secondToDate } from "utilities/time"

function* getUserSubscriptionInfo() {
  const subscriptionInfo = yield fetchAndReturnSubscriptionInfo()

  return subscriptionInfo
}

function* getUserVipStatus() {
  const vipPeriods = yield fetchAndReturnVipPeriods()
  const freeArticleQuota = yield fetchAndReturnFreeArticleQuota()

  return getTrackingVipStatus(
    fromJS(vipPeriods || []),
    fromJS(freeArticleQuota || {})
  )
}

function* initUserPropertiesEvents(action) {
  const userId = yield selectStore("user", "current", "id")
  if (!userId) return
  const platform = yield selectStore("user", "current", "platform")
  const subscriptionInfo = yield getUserSubscriptionInfo()
  const { trial: alreadyTrial, inTrial } = subscriptionInfo
  amplitude.ampInitUserProperties(userId, platform, alreadyTrial, inTrial)

  const readerVipStatus = yield getUserVipStatus()
  amplitude.setUserProperties({ paying: readerVipStatus })

  const createdAt = yield selectStore("user", "current", "createdAt")
  amplitude.setUserProperties({
    "account creation date": secondToDate(createdAt)
  })

  if (process.env.REACT_APP_PRODUCT === "k4l") {
    const k4lCurrentLibrary = yield selectStore("k4lLibraries", "current")
    if (!k4lCurrentLibrary) return
    const libraryId = k4lCurrentLibrary.get("id")
    amplitude.setUserProperties({ "library id": libraryId })
  }
}

export function* watchInitUserPropertiesEvent() {
  yield takeEvery(AMP_INIT_USER_PROPERTIES, initUserPropertiesEvents)
}

function* signUpEvent(action) {
  const { user } = action.payload
  if (!user?.kid) return

  amplitude.setUserId(user.kid)

  const { platform } = user
  let eventProperties = {
    "account type": platform
  }

  const k4lCurrentLibrary = yield selectStore("k4lLibraries", "current")
  if (process.env.REACT_APP_PRODUCT === "k4l" && k4lCurrentLibrary) {
    const libraryId = k4lCurrentLibrary.get("id")
    eventProperties["library id"] = libraryId
  }
  amplitude.logEvent("account created", eventProperties)
}

export function* watchSignUpEventV2() {
  yield takeEvery(LOG_SIGN_UP, signUpEvent)
}

function* signInEvent(action) {
  const { user } = action.payload
  if (!user?.kid) return

  amplitude.setUserId(user.kid)

  const { platform } = user
  let eventProperties = {
    "account type": platform
  }

  const k4lCurrentLibrary = yield selectStore("k4lLibraries", "current")
  if (process.env.REACT_APP_PRODUCT === "k4l" && k4lCurrentLibrary) {
    const libraryId = k4lCurrentLibrary.get("id")
    eventProperties["library id"] = libraryId
  }
  amplitude.logEvent("logged in", eventProperties)
}

export function* watchSignInEventV2() {
  yield takeEvery(LOG_SIGN_IN, signInEvent)
}

function* signEvent(action) {
  const { user } = action.payload
  if (!user?.kid) return

  const { kid: userId, platform } = user
  const subscriptionInfo = yield getUserSubscriptionInfo()
  const { trial: alreadyTrial, inTrial } = subscriptionInfo
  amplitude.ampInitUserProperties(userId, platform, alreadyTrial, inTrial)

  const readerVipStatus = yield getUserVipStatus()
  amplitude.setUserProperties({ paying: readerVipStatus })
}

export function* watchSignEvent() {
  yield takeEvery([LOG_SIGN_UP, LOG_SIGN_IN], signEvent)
}

function viewHomeEvent(action) {
  amplitude.logEvent("home page viewed")
}

export function* watchViewHomeEvent() {
  yield takeEvery(LOG_VIEW_HOME, viewHomeEvent)
}

function* requestVipPeriodsEvent(action) {
  const readerVipStatus = yield getUserVipStatus()
  amplitude.setUserProperties({ paying: readerVipStatus })
}

export function* watchRequestVipPeriodsEvent() {
  yield takeEvery(REQUEST_VIP_PERIODS_SUCCESS, requestVipPeriodsEvent)
}

function setFirstLandingPageEvent(action) {
  const { pathname } = action.payload
  const pageCategory = categorizePage(pathname)
  amplitude.setOnce("first landing page", pageCategory)
}

export function* watchSetFirstLandingPageEvent() {
  yield takeEvery(SET_FIRST_LANDING_PAGE, setFirstLandingPageEvent)
}

function* changeStateEvent(action) {
  const { state } = action.payload
  const historyState = yield selectStore("state", "history")
  const source = trackingSource(historyState, false, true)

  switch (state) {
    case OOBE_STATE:
      const value = source === "sign-up" ? "registration" : "others"
      amplitude.logEvent(VIEW_OOBE_PAGE, { source: value })
      break
    default:
      break
  }
}

export function* watchChangeStateEvent() {
  yield takeEvery(CHANGE_STATE, changeStateEvent)
}

function clickOobeNextStepEvent(action) {
  const { categoryNames } = action.payload
  const eventProperties = { category: categoryNames }
  amplitude.logEvent(CLICK_OOBE_NEXT_STEP, eventProperties)
}

export function* watchClickOobeNextStepEvent() {
  yield takeEvery(LOG_CLICK_OOBE_NEXT_STEP, clickOobeNextStepEvent)
}

function completeOobeEvent(action) {
  const { recommendCount, pickUpCount } = action.payload
  const eventProperties = {
    total_recommend_count: recommendCount,
    total_pickup_count: pickUpCount
  }
  amplitude.logEvent(COMPLETE_OOBE, eventProperties)
}

export function* watchCompleteOobeEvent() {
  yield takeEvery(LOG_COMPLETE_OOBE, completeOobeEvent)
}

function viewArticlePreviewEvent(action) {
  amplitude.logEvent(VIEW_ARTICLE_PREVIEW)
}

export function* watchViewArticlePreviewEvent() {
  yield takeEvery(LOG_VIEW_ARTICLE_PREVIEW, viewArticlePreviewEvent)
}

function clickArticlePreviewCellEvent(action) {
  const { source } = action.payload
  const eventProperties = {
    source: source
  }
  amplitude.logEvent(CLICK_ARTICLE_PREVIEW_CELL, eventProperties)
}

export function* watchClickArticlePreviewCellEvent() {
  yield takeEvery(LOG_CLICK_ARTICLE_PREVIEW_CELL, clickArticlePreviewCellEvent)
}

function* playVideoEvent(action) {
  const { video } = action.payload
  const videoName = video.get("title")

  const articleId = yield selectStore(
    "articleReadEvents",
    "currentReadingInfo",
    "currentArticleId"
  )
  const issueId = yield selectStore("articles", articleId, "issueId")
  const articleName = yield selectStore("articles", articleId, "title")
  const issueName = yield selectStore("issues", issueId, "name")
  const titleName = yield selectStore("issues", issueId, "magazineName")
  const title = yield selectStore("issues", issueId, "magazineId")
  const eventProperties = {
    article_name: articleName,
    article_id: articleId,
    issue_name: issueName,
    issue_id: issueId,
    title_name: titleName,
    title: title,
    video_name: videoName
  }
  amplitude.logEvent(PLAY_VIDEO, eventProperties)
}

export function* watchPlayVideoEvent() {
  yield takeEvery(LOG_PLAY_VIDEO, playVideoEvent)
}

function* viewArticleEvent(action) {
  const { params } = action.payload
  const { articleId } = params

  const articleName = yield selectStore("articles", articleId, "title")
  const issueId = yield selectStore("articles", articleId, "issueId")
  const author = yield selectStore("articles", articleId, "author")

  const issueName = yield selectStore("issues", issueId, "name")
  const magazineId = yield selectStore("issues", issueId, "magazineId")
  const magazineName = yield selectStore("issues", issueId, "magazineName")
  const isLoggedIn = yield selectStore("authentication", "isLoggedIn")
  const trafficSource = yield selectStore("articleReadEvents", "trafficSource")
  const cta = trafficSource

  const magazinesCategories = yield selectStore(
    "magazinesCategories",
    magazineId,
    "category"
  )
  const categoryIds = magazinesCategories
    ? Array.from(magazinesCategories).map(category => category.get("id"))
    : []

  const eventProperties = {
    "article id": articleId,
    "article name": articleName,
    "issue id": issueId,
    "issue name": issueName,
    "magazine id": magazineId,
    "magazine name": magazineName,
    "reading mode": params.mode,
    CTA: cta,
    "reader is login": isLoggedIn,
    "category id": categoryIds,
    author: author
  }

  amplitude.logEvent("article viewed", eventProperties)
  amplitude.setOnce("first article session date", secondToDate(getNow()))
  amplitude.setUserProperties({
    "last article session date": secondToDate(getNow())
  })
}

export function* watchViewArticleEvent() {
  yield takeEvery(LOG_OPEN_ARTICLE, viewArticleEvent)
}

function* followMagazinesEvent(action) {
  const followedMagazines = yield selectStore("followedMagazines")

  amplitude.setUserProperties({
    "magazine followed": followedMagazines.toJS(),
    "total magazines followed": followedMagazines.size
  })
}

export function* watchFollowMagazinesEvent() {
  yield takeEvery(
    [REQUEST_FOLLOWED_MAGAZINES_SUCCESS, FOLLOW_MAGAZINE, UNFOLLOW_MAGAZINE],
    followMagazinesEvent
  )
}

function searchResultEvent(action) {
  const { params } = action.payload
  const { keyword, searchMagazineResult, searchArticleResult } = params

  const eventProperties = {
    keywords: keyword,
    "number of search magazine results": searchMagazineResult,
    "number of search article results": searchArticleResult
  }

  amplitude.logEvent("search completed", eventProperties)
}

export function* watchSearchResultEvent() {
  yield takeEvery(LOG_SEARCH_RESULT, searchResultEvent)
}

function* searchResultClickEvent(action) {
  const { params } = action.payload
  const { articleId, issue, rank } = params

  let articleName
  let issueId
  let issueName
  let magazineId
  let magazineName
  let contentType

  if (issue) {
    issueId = issue.get("id")
    issueName = issue.get("name")
    magazineId = issue.get("magazineId")
    magazineName = issue.get("magazineName")
    contentType = "magazine"
  } else {
    articleName = yield selectStore("articles", articleId, "title")
    issueId = yield selectStore("articles", articleId, "issueId")
    issueName = yield selectStore("issues", issueId, "name")
    magazineId = yield selectStore("issues", issueId, "magazineId")
    magazineName = yield selectStore("issues", issueId, "magazineName")
    contentType = "article"
  }

  const magazinesCategories = yield selectStore(
    "magazinesCategories",
    magazineId,
    "category"
  )
  const categoryIds = magazinesCategories
    ? Array.from(magazinesCategories).map(category => category.get("id"))
    : []

  const eventProperties = {
    "search rank": rank,
    "article id": articleId,
    "article name": articleName,
    "issue id": issueId,
    "issue name": issueName,
    "magazine id": magazineId,
    "magazine name": magazineName,
    "category id": categoryIds,
    "content type": contentType
  }

  amplitude.logEvent("search result clicked", eventProperties)
}

export function* watchSearchResultClickEvent() {
  yield takeEvery(LOG_SEARCH_RESULT_CLICK, searchResultClickEvent)
}

function* showArticlePreviewModalEvent(action) {
  const { eventType } = action.payload

  const articleId = yield selectStore(
    "articleReadEvents",
    "currentReadingInfo",
    "currentArticleId"
  )
  if (!articleId) return

  const articleName = yield selectStore("articles", articleId, "title")
  const issueId = yield selectStore("articles", articleId, "issueId")
  const author = yield selectStore("articles", articleId, "author")

  const issueName = yield selectStore("issues", issueId, "name")
  const magazineId = yield selectStore("issues", issueId, "magazineId")
  const magazineName = yield selectStore("issues", issueId, "magazineName")
  if (!magazineId) return

  const trafficSource = yield selectStore("articleReadEvents", "trafficSource")
  const cta = trafficSource

  const magazinesCategories = yield selectStore(
    "magazinesCategories",
    magazineId,
    "category"
  )
  const categoryIds = magazinesCategories
    ? Array.from(magazinesCategories).map(category => category.get("id"))
    : []
  const mode = yield selectStore(
    "articleReadEvents",
    "currentReadingInfo",
    "mode"
  )

  const eventProperties = {
    "wall type": eventType,
    "article id": articleId,
    "article name": articleName,
    "issue id": issueId,
    "issue name": issueName,
    "magazine id": magazineId,
    "magazine name": magazineName,
    "reading mode": mode,
    CTA: cta,
    "category id": categoryIds,
    author: author
  }

  amplitude.logEvent("wall encountered", eventProperties)
}

export function* watchShowArticlePreviewModalEvent() {
  yield throttle(
    1000,
    LOG_SHOW_ARTICLE_PREVIEW_MODAL,
    showArticlePreviewModalEvent
  )
}
