import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import { onChangeSearchKey } from "actions/search"
import { withTranslation } from "react-i18next"
import styled from "styled-components/macro"
import media from "utilities/media"
import { onLogOut } from "actions/authentication"
import { setProdEndPoint, removeEndPoint } from "utilities/sandbox"
import searchIcon from "./assets/images/nav_search.svg"
import searchIconDark from "./assets/images/nav_search_dark.svg"
import { onUpdateTaskRecords } from "actions/taskRecords"
import { taskIds } from "constants/taskRecords"

const SearchBarWrapper = styled.form`
  display: flex;

  ${media.desktop`
    height: 46px;
  `};

  ${media.tablet`width: 100%;`};
  ${media.mobile`width: 100%;`};
`

const SearchBarInput = styled.input`
  height: 46px;
  font-size: 15px;
  border-radius: 0 100px 100px 0;
  border-width: 1px 1px 1px 0px;
  padding: 0px 10px;

  color: ${props => (props.dark ? "#eee9e0" : "#222222")};
  background-color: ${props => (props.dark ? "#222222" : "#f5f5f5")};
  border-style: none;
  border-color: ${props =>
    props.focus
      ? props.dark
        ? "#40403f"
        : "#919191"
      : props.dark
      ? "#3d3d3d"
      : "#eee9e0"};
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;

  &::placeholder {
    color: ${props => (props.dark ? "#7d7d7d" : "#a7a398")};
    font-size: 14px;
    font-weight: 500;
  }

  ${media.desktop`
    width: 330px;
  `};

  ${media.tablet`
    width: 240px;
  `};

  ${media.mobile`
    width: 100%;
  `};
`

const SearchBarSubmit = styled.button.attrs({ "aria-label": "search" })`
  display: flex;
  width: 50px;
  height: 46px;
  border-radius: 100px 0px 0px 100px;
  background-color: ${props => (props.dark ? "#222222" : "#f5f5f5")};
  border-style: none;
  border-color: ${props =>
    props.focus
      ? props.dark
        ? "#40403f"
        : "#919191"
      : props.dark
      ? "#3d3d3d"
      : "#eee9e0"};
  border-width: 1px 0 1px 1px;
  background-image: url(${props => (props.dark ? searchIconDark : searchIcon)});
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: 20px center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = { focus: false, searchKey: "" }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()

    const q = this.state.searchKey.trim()
    if (q) {
      this.detectChangeDb(q)

      this.setState({ searchKey: q })
      this.props.onChangeSearchKey(q)
      if (this.props.userId)
        this.props.onUpdateTaskRecords(this.props.userId, taskIds.SEARCH)
      this.props.history.push({
        pathname: "/search",
        search: `?q=${q}`
      })
    }
  }

  detectChangeDb(q) {
    if (q === "dIRK") {
      setProdEndPoint(this.props.onLogOut)
    } else if (q === "nOWITZKI") {
      removeEndPoint(this.props.onLogOut)
    }
  }

  componentDidMount() {
    const { pathname, search } = this.props.location
    const q = queryString.parse(search).q
    if (pathname === "/search" && q) {
      this.setState({ searchKey: decodeURI(q) })
    }
  }

  handleChange(event) {
    this.setState({ searchKey: event.target.value })
  }

  handleFocus() {
    this.setState({ focus: true })
  }

  handleBlur() {
    this.setState({ focus: false })
  }

  render() {
    return (
      <SearchBarWrapper onSubmit={this.handleSubmit}>
        <SearchBarSubmit
          dark={this.props.dark}
          focus={this.state.focus}
          onClick={this.handleSubmit}
        />
        <SearchBarInput
          type="search"
          dark={this.props.dark}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          focus={this.state.focus}
          value={this.state.searchKey}
          onChange={this.handleChange}
          placeholder={this.props.t(
            "SEARCH_MAGAZINE_ARTICLE",
            process.env.REACT_APP_PRODUCT === "k4l" ? { context: "K4L" } : null
          )}
        />
      </SearchBarWrapper>
    )
  }
}

const mapStateToProps = state => ({
  userId: state.getIn(["user", "current", "id"])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { onChangeSearchKey, onLogOut, onUpdateTaskRecords },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation("container")(SearchBar)))
