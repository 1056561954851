import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import SideTabComponent from "components/container/SideTabComponent"
import { k4lSummitHref } from "utilities/customByApp"

const SideTabRowExternalLink = styled.div`
  font-size: 16px;
  color: #222222;
  height: 48px;
  display: flex;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 16px;
`

function SideTabSummitLink(props) {
  const summitCategory = 249
  const categories = useSelector(state => state.get("categories"))
  const category =
    categories &&
    categories.find(category => category.get("id") === summitCategory)

  return category ? (
    <SideTabComponent.SideTabDivWrapper>
      <SideTabComponent.SideTabRow>
        <Link to={k4lSummitHref}>
          <SideTabRowExternalLink>
            {props.t(`category:${category.get("dictKey")}`)}
          </SideTabRowExternalLink>
        </Link>
      </SideTabComponent.SideTabRow>
    </SideTabComponent.SideTabDivWrapper>
  ) : null
}

export default withTranslation("category")(SideTabSummitLink)
