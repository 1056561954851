import { put, takeEvery, call } from "redux-saga/effects"
import {
  onRequestLatestIssueSuccess,
  onRequestLatestIssuesSuccess
} from "actions/issues"
import {
  REQUEST_LATEST_ISSUE,
  REQUEST_LATEST_ISSUES
} from "constants/actionTypes"
import FetchLatestIssueApi from "apis/FetchLatestIssueApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { LATEST_ISSUES_TTL } from "constants/ttl"
import FetchLatestIssuesApi from "apis/FetchLatestIssuesApi"

function* fetchLatestIssue(action) {
  const { magazineId } = action.payload
  try {
    const isExisted = yield* isStoreExisted("magazinesLatestIssues", magazineId)
    const isExpired = yield* isStoreExpired(
      LATEST_ISSUES_TTL,
      "magazinesLatestIssues",
      magazineId
    )
    if (isExisted && !isExpired) {
      return
    }

    const response = yield call(new FetchLatestIssueApi({ magazineId }).call)
    yield put(onRequestLatestIssueSuccess(magazineId, response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestfetchLatestIssue() {
  yield takeEvery(REQUEST_LATEST_ISSUE, fetchLatestIssue)
}

function* fetchLatestIssues() {
  try {
    const response = yield call(new FetchLatestIssuesApi().call)
    yield put(onRequestLatestIssuesSuccess(response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestfetchLatestIssues() {
  yield takeEvery(REQUEST_LATEST_ISSUES, fetchLatestIssues)
}
