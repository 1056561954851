import {
  REQUEST_MAGAZINE_YEAR_READING_STATUSES,
  REQUEST_MAGAZINE_YEAR_READING_STATUSES_SUCCESS,
  REQUEST_ISSUE_READING_STATUS,
  REQUEST_ISSUE_READING_STATUS_SUCCESS
} from "constants/actionTypes"

export const onRequestMagazineYearReadingStatuses = (
  userId,
  magazineId,
  year
) => ({
  type: REQUEST_MAGAZINE_YEAR_READING_STATUSES,
  payload: { userId, magazineId, year }
})

export const onRequestMagazineYearReadingStatusesSuccess = readingStatuses => ({
  type: REQUEST_MAGAZINE_YEAR_READING_STATUSES_SUCCESS,
  payload: { readingStatuses }
})

export const onRequestIssueReadingStatus = (userId, issueId) => ({
  type: REQUEST_ISSUE_READING_STATUS,
  payload: { userId, issueId }
})

export const onRequestIssueReadingStatusSuccess = readingStatus => ({
  type: REQUEST_ISSUE_READING_STATUS_SUCCESS,
  payload: { readingStatus }
})
