import {
  LOG_IN,
  LOG_OUT,
  FORCE_UPDATE_VERSION_SUCCESS,
  LOG_IN_SUCCESS,
  LOG_IN_FAIL,
  CLEAR_AUTHENTICATION_ERROR,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,
  TEMPORARY_LOGIN_SUCCESS,
  TEMPORARY_LOGIN_FAIL
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({
  isLoggedIn: false,
  error: null
})

const reducerMap = new Map([
  [
    LOG_IN,
    state => {
      return state.set("error", null)
    }
  ],
  [
    LOG_IN_SUCCESS,
    state => {
      return state.set("isLoggedIn", true).set("error", null)
    }
  ],
  [
    FORCE_UPDATE_VERSION_SUCCESS,
    state => {
      return state.set("isLoggedIn", false).set("error", null)
    }
  ],
  [
    LOG_OUT,
    state => {
      return state.set("isLoggedIn", false).set("error", null)
    }
  ],
  [
    LOG_IN_FAIL,
    (state, action) => {
      return state.set("isLoggedIn", false).set("error", action.payload.reason)
    }
  ],
  [
    CLEAR_AUTHENTICATION_ERROR,
    state => {
      return state.set("error", null)
    }
  ],
  [
    SIGN_UP,
    state => {
      return state.set("error", null)
    }
  ],
  [
    SIGN_UP_SUCCESS,
    (state, action) => {
      return state.set("isLoggedIn", action.payload.goSignIn).set("error", null)
    }
  ],
  [
    SIGN_UP_FAIL,
    (state, action) => {
      return state.set("isLoggedIn", false).set("error", action.payload.reason)
    }
  ],
  [
    TEMPORARY_LOGIN_SUCCESS,
    (state, action) => {
      return state.set("isLoggedIn", true).set("error", null)
    }
  ],
  [
    TEMPORARY_LOGIN_FAIL,
    (state, action) => {
      return state
        .set("isLoggedIn", false)
        .set("error", action.payload.errorCode)
    }
  ]
])

const authentication = handleActions(reducerMap, initialState)
export default authentication
