import { put, takeEvery, call } from "redux-saga/effects"
import {
  onRequestArticleSuccess,
  onRequestSearchArticlesSuccess,
  onRequestPopularArticlesSuccess,
  onRequestRecommendArticlesSuccess,
  onRequestArticleAccessTokenSuccess,
  onRequestArticleAccessTokenError,
  onRequestArticleTextSuccess,
  onRequestArticleTextError,
  onRequestArticleRelevantArticlesSuccess,
  onRequestArticleTextV2Success
} from "actions/articles"
import {
  REQUEST_ARTICLE,
  REQUEST_SEARCH_ARTICLES,
  REQUEST_POPULAR_ARTICLES,
  REQUEST_RECOMMEND_ARTICLES,
  REQUEST_ARTICLE_ACCESS_TOKEN,
  REQUEST_ARTICLE_TEXT,
  REQUEST_ARTICLE_RELEVANT_ARTICLES
} from "constants/actionTypes"
import FetchArticleApi from "apis/FetchArticleApi"
import {
  isStoreExisted,
  isStoreExpired,
  selectStore
} from "utilities/storeCheck"
import {
  ARTICLE_TTL,
  POPULAR_ARTICLES_TTL,
  ARTICLE_ACCESSS_TOKEN_TTL
} from "constants/ttl"
import SearchArticlesApi from "apis/SearchArticlesApi"
import FetchPopularArticlesApi from "apis/FetchPopularArticlesApi"
import FetchRecommendArticlesApi from "apis/FetchRecommendArticlesApi"
import FetchArticleAccessTokenApi from "apis/FetchArticleAccessTokenApi"
import FetchArticleTextApi from "apis/FetchArticleTextApi"
import FetchArticleTextV2UrlApi from "apis/FetchArticleTextV2UrlApi"
import FetchArticleRelevantArticlesApi from "apis/FetchArticleRelevantArticlesApi"
import { withLock } from "utilities/ApiLock"
import i18n from "i18next"
import { ZH_CN } from "constants/languages"
import SimplifiedConverterApi from "apis/SimplifiedConverterApi"

function* fetchArticle(action) {
  const { articleId } = action.payload

  try {
    const isExisted = yield* isStoreExisted("articles", articleId)
    const isExpired = yield* isStoreExpired(ARTICLE_TTL, "articles", articleId)
    if (isExisted && !isExpired) {
      return
    }
    const response = yield call(new FetchArticleApi({ articleId }).call)
    yield put(onRequestArticleSuccess(response.data))
  } catch (e) {
    const { onError } = action.payload
    onError ? onError(e) : console.log(e)
  }
}

export function* watchArticle() {
  yield takeEvery(REQUEST_ARTICLE, withLock(fetchArticle))
}

function* searchArticles(action) {
  const { bookListId, q } = action.payload
  try {
    const searchArticlesApi = new SearchArticlesApi()
    const response = yield call(searchArticlesApi.call, { bookListId, q })
    const hitArticles = response.data
    yield put(onRequestSearchArticlesSuccess(hitArticles, "default"))
  } catch (e) {
    yield put(onRequestSearchArticlesSuccess(null, "default"))
    console.log(e)
  }
}

export function* watchSearchArticles() {
  yield takeEvery(REQUEST_SEARCH_ARTICLES, searchArticles)
}

function* fetchPopularArticles() {
  try {
    const isExisted = yield* isStoreExisted("popularArticles")
    const isExpired = yield* isStoreExpired(
      POPULAR_ARTICLES_TTL,
      "popularArticles"
    )
    if (isExisted && !isExpired) {
      return
    }
    const country = yield* selectStore("clientInfo", "countryCode") || "TW"
    const response = yield call(new FetchPopularArticlesApi().call, {
      country,
      limit: 5
    })
    yield put(onRequestPopularArticlesSuccess(response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestPopularArticles() {
  yield takeEvery(REQUEST_POPULAR_ARTICLES, fetchPopularArticles)
}

function* fetchRecommendArticles() {
  try {
    const response = yield call(new FetchRecommendArticlesApi().call)
    yield put(onRequestRecommendArticlesSuccess(response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestRecommendArticles() {
  yield takeEvery(REQUEST_RECOMMEND_ARTICLES, fetchRecommendArticles)
}

function* fetchArticleAccessToken(action) {
  const { articleId } = action.payload

  try {
    const isExisted = yield* isStoreExisted("articleAccessTokens", articleId)
    const isExpired = yield* isStoreExpired(
      ARTICLE_ACCESSS_TOKEN_TTL,
      "articleAccessTokens",
      articleId
    )
    if (isExisted && !isExpired) {
      return
    }

    const api = new FetchArticleAccessTokenApi({ articleId })
    const response = yield call(api.call)
    const { token, expired_at } = response.data
    yield put(onRequestArticleAccessTokenSuccess(articleId, token, expired_at))
  } catch (e) {
    yield put(onRequestArticleAccessTokenError(articleId))
  }
}

export function* watchArticleAccessToken() {
  yield takeEvery(
    REQUEST_ARTICLE_ACCESS_TOKEN,
    withLock(fetchArticleAccessToken)
  )
}

function* convertTextToSimplified(textJson) {
  const elements = textJson.elements
    .filter(elementCell => elementCell.element.dtype === "TEXT")
    .map(elementCell => ({
      id: elementCell.element.id,
      content: elementCell.element.content
    }))
  const simplifiedConverterApi = new SimplifiedConverterApi()
  const params = { elements }
  const response = yield call(simplifiedConverterApi.call, params)
  const translations = response.data?.elements.reduce((acc, cur) => {
    acc[cur.id] = cur.content
    return acc
  }, {})

  return textJson.elements.map(function (elementCell) {
    const elementId = elementCell.element.id
    if (translations[elementId]) {
      elementCell.element.content = translations[elementId]
    }

    return elementCell
  })
}

function* fetchArticleText(action) {
  const { articleId, accessToken } = action.payload
  try {
    const api = new FetchArticleTextV2UrlApi({ articleId, accessToken })
    const urlResponse = yield call(api.call)
    const fetchArticleTextApi = new FetchArticleTextApi({
      url: urlResponse.data.url
    })
    let textResponse = yield call(fetchArticleTextApi.call)

    if (textResponse.data.hasOwnProperty("version")) {
      if (process.env.REACT_APP_PRODUCT === "k4l" && i18n.language === ZH_CN) {
        yield call(convertTextToSimplified, textResponse.data)
      }
      yield put(onRequestArticleTextV2Success(articleId, textResponse.data))
    } else {
      yield put(onRequestArticleTextSuccess(articleId, textResponse.data))
    }
  } catch (e) {
    console.log(e)
    yield put(onRequestArticleTextError(articleId))
  }
}

export function* watchArticleText() {
  yield takeEvery(REQUEST_ARTICLE_TEXT, withLock(fetchArticleText, true))
}

function* fetchArticleRelevantArticles(action) {
  const { articleId, userId } = action.payload
  if (!articleId) return

  try {
    const api = new FetchArticleRelevantArticlesApi()
    const response = yield call(api.call, { articleId, userId })
    const allMagazines = yield* selectStore("allMagazines")
    const magazineIds = allMagazines.toJS()
    yield put(
      onRequestArticleRelevantArticlesSuccess(
        articleId,
        magazineIds,
        response.data
      )
    )
  } catch (e) {
    console.log(e)
  }
}

export function* watchArticleRelevantArticles() {
  yield takeEvery(
    REQUEST_ARTICLE_RELEVANT_ARTICLES,
    withLock(fetchArticleRelevantArticles)
  )
}
