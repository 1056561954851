import React from "react"
import styled from "styled-components/macro"
import smallBlackBtnImage from "./assets/images/small-black.svg"
import normalBtnImage from "./assets/images/normal.svg"
import blackBtnImage from "./assets/images/black.svg"
import whiteBtnImage from "./assets/images/white.svg"

const DropDownButtonWrapper = styled.div`
  height: ${props => props.size || "35px"};
  width: ${props => props.size || "35px"};
  background-size: contain;
  background-repeat: no-repeat;
  transition-duration: 0.4s;
  transform: ${props => (props.upSideDown ? "rotate(-180deg)" : "")};
  ${props =>
    props.mode === "white"
      ? `background-image: url(${whiteBtnImage})`
      : props.mode === "black"
      ? `background-image: url(${blackBtnImage})`
      : props.mode === "small black"
      ? `background-image: url(${smallBlackBtnImage})`
      : `background-image: url(${normalBtnImage})`};

  &.toggled {
    transform: ${props =>
      props.upSideDown ? "rotate(360deg)" : "rotate(-180deg)"};
  }
`

export default function DropDownButton(props) {
  return (
    <DropDownButtonWrapper
      size={props.size}
      mode={props.mode}
      onClick={props.onClick}
      className={props.isToggle ? "toggled" : ""}
      upSideDown={props.upSideDown}
    />
  )
}
