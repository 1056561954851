import { put, throttle } from "redux-saga/effects"
import { FORCE_UPDATE_VERSION } from "constants/actionTypes"
import { onForceUpdateVersionSuccess } from "actions/version"

function* forceUpdateVersion(action) {
  try {
    yield put(onForceUpdateVersionSuccess(action.payload.version))
  } catch (e) {
    console.log(e)
  }
}

export function* watchForceUpdateVersion() {
  yield throttle(3000, FORCE_UPDATE_VERSION, forceUpdateVersion)
}
