import KonoApi from "../KonoApi"

class FollowMagazineApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/followed_titles`)
    this.title = data.magazineId
  }

  call() {
    return this.axios.post(this.url, { title: this.title })
  }
}

export default FollowMagazineApi
