import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import { REQUEST_MAGAZINES_SUCCESS } from "constants/actionTypes"

const initialState = fromJS([])

const reducerMap = new Map([
  [
    REQUEST_MAGAZINES_SUCCESS,
    (state, action) => {
      return fromJS(
        action.payload.magazines.map(magazine => {
          return magazine.title
        })
      )
    }
  ]
])

export default handleActions(reducerMap, initialState)
