import styled from "styled-components/macro"

const SideTabSubMenuWrapper = styled.div`
  width: 280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0px;
`

export default SideTabSubMenuWrapper
