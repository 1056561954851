import styled from "styled-components/macro"

const SideTabRow = styled.div`
  width: 280px;
  height: 48px;
  display: flex;
  border-radius: 999px;
  background-color: ${props => (props.focus ? "#f6f3ed" : "")};

  &:active {
    background-color: #f6f3ed;
  }
`

export default SideTabRow
