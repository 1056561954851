import axios from "axios"
import KonoApi from "../KonoApi"

class WechatAccessTokenApi extends KonoApi {
  constructor() {
    super(`/users/wechat/access_token`)
  }

  call(params) {
    return axios.get(this.url, {
      params: {
        appid: params.appId,
        secret: params.secret,
        code: params.code,
        grant_type: "authorization_code"
      }
    })
  }
}

export default WechatAccessTokenApi
