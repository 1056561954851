import KonoApi from "../KonoApi"
import store from "../../store"

class FetchSentGiftsApi extends KonoApi {
  constructor() {
    super(
      `/users/${store.getState().getIn(["user", "current", "id"])}/sent_gifts`
    )
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchSentGiftsApi
