import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import { REQUEST_FREE_ARTICLE_QUOTA_SUCCESS } from "constants/actionTypes"

const initialState = fromJS({})

const reduceMap = new Map([
  [
    REQUEST_FREE_ARTICLE_QUOTA_SUCCESS,
    (state, action) => {
      return fromJS({
        quota: action.payload.quota.quota,
        active: action.payload.quota.active
      })
    }
  ]
])

export default handleActions(reduceMap, initialState)
