import styled from "styled-components/macro"

const MenuWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 43px;
  ${props => props.position || (props.reverse ? "right: 0px;" : "left: 0px;")}
`

export default MenuWrapper
