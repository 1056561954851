import {
  REQUEST_CURATION_CHANNEL_SUCCESS,
  REQUEST_CURATION_CHANNELS_SUCCESS
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const formatCurationChannel = curationChannel => {
  return {
    id: curationChannel.id,
    name: curationChannel.channel_name,
    description: curationChannel.description,
    dictKey: curationChannel.dict_key,
    layout: curationChannel.layout,
    color: "#" + curationChannel.background_color
  }
}

const arrayReducer = (acc, cur) => {
  acc[cur.id] = formatCurationChannel(cur)
  return acc
}

const reducerMap = new Map([
  [
    REQUEST_CURATION_CHANNELS_SUCCESS,
    (state, action) => {
      const curationChannels = action.payload.curationChannels
      const curationChannelsMap = curationChannels.reduce(arrayReducer, {})
      return fromJS(curationChannelsMap)
    }
  ],
  [
    REQUEST_CURATION_CHANNEL_SUCCESS,
    (state, action) => {
      const curationChannel = formatCurationChannel(
        action.payload.curationChannel
      )
      return state.setIn([`${curationChannel.id}`], fromJS(curationChannel))
    }
  ]
])

export default handleActions(reducerMap, initialState)
