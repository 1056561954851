import { REQUEST_CLIENT_INFO_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    REQUEST_CLIENT_INFO_SUCCESS,
    (state, action) => {
      const clientInfo = action.payload.clientInfo
      return fromJS({
        ip: clientInfo.ip,
        countryCode: clientInfo.country_code,
        acceptLanguages: clientInfo.accept_languages,
        isGooglebot: clientInfo.is_googlebot,
        inChina: clientInfo.country_code === "CN"
      })
    }
  ]
])

export default handleActions(reducerMap, initialState)
