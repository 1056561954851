import {
  REQUEST_CATEGORY_LATEST_ISSUES,
  REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS,
  REQUEST_LATEST_ISSUE,
  REQUEST_LATEST_ISSUE_SUCCESS,
  REQUEST_ISSUE,
  REQUEST_ISSUE_SUCCESS,
  REQUEST_ISSUE_THUMBNAILS,
  REQUEST_ISSUE_THUMBNAILS_SUCCESS,
  REQUEST_ISSUE_ARTICLES,
  REQUEST_ISSUE_ARTICLES_SUCCESS,
  REQUEST_LATEST_ISSUES,
  REQUEST_LATEST_ISSUES_SUCCESS
} from "constants/actionTypes"

export const onRequestCategoryLatestIssues = (categoryId, bookListId) => {
  return {
    type: REQUEST_CATEGORY_LATEST_ISSUES,
    payload: { categoryId, bookListId }
  }
}

export const onRequestCategoryLatestIssuesSuccess = (categoryId, issues) => {
  return {
    type: REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS,
    payload: { categoryId, latestIssues: issues }
  }
}

export const onRequestLatestIssue = magazineId => {
  return {
    type: REQUEST_LATEST_ISSUE,
    payload: { magazineId }
  }
}

export const onRequestLatestIssueSuccess = (magazineId, issue) => {
  return {
    type: REQUEST_LATEST_ISSUE_SUCCESS,
    payload: { magazineId, latestIssue: issue }
  }
}

export const onRequestIssue = (issueId, onError) => {
  return {
    type: REQUEST_ISSUE,
    payload: { issueId, onError }
  }
}

export const onRequestIssueSuccess = issue => {
  return {
    type: REQUEST_ISSUE_SUCCESS,
    payload: { issue }
  }
}

export const onRequestIssueThumbnails = issueId => {
  return {
    type: REQUEST_ISSUE_THUMBNAILS,
    payload: { issueId }
  }
}

export const onRequestIssueThumbnailsSuccess = (issueId, thumbnails) => {
  return {
    type: REQUEST_ISSUE_THUMBNAILS_SUCCESS,
    payload: { issueId, thumbnails }
  }
}

export const onRequestIssueArticles = issueId => {
  return {
    type: REQUEST_ISSUE_ARTICLES,
    payload: { issueId }
  }
}

export const onRequestIssueArticlesSuccess = (issueId, articles) => {
  return {
    type: REQUEST_ISSUE_ARTICLES_SUCCESS,
    payload: { issueId, articles }
  }
}

export const onRequestLatestIssues = () => {
  return {
    type: REQUEST_LATEST_ISSUES
  }
}

export const onRequestLatestIssuesSuccess = issues => {
  return {
    type: REQUEST_LATEST_ISSUES_SUCCESS,
    payload: { latestIssues: issues }
  }
}
