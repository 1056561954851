import KonoApi from "../KonoApi"

class FetchUserInfoApi extends KonoApi {
  constructor() {
    super(`/me`)
  }
  call(params) {
    return this.axios.get(this.url, {
      params: { kid: params.userId }
    })
  }
}

export default FetchUserInfoApi
