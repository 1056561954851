import {
  AMP_INIT_USER_PROPERTIES,
  SET_FIRST_LANDING_PAGE,
  LOG_CLICK_OOBE_NEXT_STEP,
  LOG_COMPLETE_OOBE,
  LOG_VIEW_ARTICLE_PREVIEW,
  LOG_CLICK_ARTICLE_PREVIEW_CELL,
  LOG_PLAY_VIDEO,
  LOG_VIEW_HOME,
  LOG_SEARCH_RESULT,
  LOG_SEARCH_RESULT_CLICK,
  LOG_SHOW_ARTICLE_PREVIEW_MODAL
} from "constants/actionTypes"

export const onAmpInitUserProperties = user => ({
  type: AMP_INIT_USER_PROPERTIES,
  payload: { user }
})

export const onSetFirstLandingPage = pathname => ({
  type: SET_FIRST_LANDING_PAGE,
  payload: { pathname }
})

export const onLogOobeClickNextStep = categoryNames => ({
  type: LOG_CLICK_OOBE_NEXT_STEP,
  payload: { categoryNames }
})

export const onLogCompleteOobe = (recommendCount, pickUpCount) => ({
  type: LOG_COMPLETE_OOBE,
  payload: { recommendCount, pickUpCount }
})

export const onLogViewArticlePreview = () => ({
  type: LOG_VIEW_ARTICLE_PREVIEW
})

export const onLogClickArticlePreviewCell = source => ({
  type: LOG_CLICK_ARTICLE_PREVIEW_CELL,
  payload: { source }
})

export const onLogPlayVideo = video => ({
  type: LOG_PLAY_VIDEO,
  payload: { video }
})

export const onLogViewHome = () => ({
  type: LOG_VIEW_HOME
})

export const onLogSearchResult = params => {
  return {
    type: LOG_SEARCH_RESULT,
    payload: { params }
  }
}

export const onLogSearchResultClick = params => ({
  type: LOG_SEARCH_RESULT_CLICK,
  payload: { params }
})

export const onLogShowArticlePreviewModal = eventType => ({
  type: LOG_SHOW_ARTICLE_PREVIEW_MODAL,
  payload: { eventType }
})
