import styled from "styled-components/macro"
import media from "utilities/media"

const Button = styled.button`
  width: 100%;
  color: ${props => props.color || "white"};
  font-weight: ${props => props.fontWeight || "normal"};
  background-color: ${props => props.backgroundColor || "#00a270"};
  text-align: center;
  border: ${props => props.border || "none"};
  border-radius: ${props => props.borderRadius || "500px"};
  cursor: pointer;
  outline: 0;

  ${media.desktop`
    font-size: ${props => props.lgFontSize || props.fontSize || "16px"};
    height: ${props => props.lgHeight || props.height || "52px"};
  `};
  ${media.tablet`
    font-size: ${props => props.mdFontSize || props.fontSize || "14px"};
    height: ${props => props.mdHeight || props.height || "52px"};
  `};
  ${media.mobile`
    font-size: ${props => props.smFontSize || props.fontSize || "14px"};
    height: ${props => props.smHeight || props.height || "44px"};
  `};

  &:hover {
    background-color: ${props =>
      props.backgroundColorHover || props.backgroundColor || "#00a270"};
  }
`

export default Button
