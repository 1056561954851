import axios from "axios"

class SimplifiedConverterApi {
  constructor() {
    this.url = `https://zi2mwzzkql.execute-api.ap-northeast-1.amazonaws.com/prod/simplified_converter`
    this.call = this.call.bind(this)
  }

  call(params) {
    return axios.post(this.url, params)
  }
}

export default SimplifiedConverterApi
