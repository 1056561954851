import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestSentGiftsSuccess } from "actions/sentGifts"
import { REQUEST_SENT_GIFTS } from "constants/actionTypes"
import FetchSentGiftsApi from "apis/FetchSentGiftsApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { SENT_GIFTS_TTL } from "constants/ttl"

function* fetchSentGifts(action) {
  try {
    const isExisted = yield* isStoreExisted("sentGifts")
    const isExpired = yield* isStoreExpired(SENT_GIFTS_TTL, "sentGifts")
    if (isExisted && !isExpired) {
      return
    }

    const fetchSentGiftsApi = new FetchSentGiftsApi()
    const response = yield call(fetchSentGiftsApi.call)
    const sentGifts = response.data
    yield put(onRequestSentGiftsSuccess(sentGifts))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestSentGifts() {
  yield takeEvery(REQUEST_SENT_GIFTS, fetchSentGifts)
}
