import KonoApi from "../KonoApi"

class FetchUserPictureApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/picture`)
  }

  call(params) {
    return this.axios.get(this.url, {
      params: { access_token: params.accessToken, redirect: "0" }
    })
  }
}

export default FetchUserPictureApi
