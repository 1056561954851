import {
  REQUEST_SENT_GIFTS,
  REQUEST_SENT_GIFTS_SUCCESS
} from "constants/actionTypes"

export const onRequestSentGifts = () => {
  return {
    type: REQUEST_SENT_GIFTS
  }
}

export const onRequestSentGiftsSuccess = sentGifts => {
  return {
    type: REQUEST_SENT_GIFTS_SUCCESS,
    payload: { sentGifts }
  }
}
