// from vip period payment type
export const BEFORE_AYCR = "BEFORE_AYCR"
export const COUPON = "COUPON"
export const IOS_SUBSCRIPTION = "IOS_SUBSCRIPTION"
export const BRAINTREE_SUBSCRIPTION = "BRAINTREE_SUBSCRIPTION"
export const TRIAL = "TRIAL"
export const SAMSUNG = "SAMSUNG"
export const IBON = "IBON"
export const REFERRAL = "REFERRAL"
export const PACKAGE_PLAN = "PACKAGE_PLAN"
export const LIBRARY = "LIBRARY"
export const BRAINTREE_GRACE_PERIOD = "BRAINTREE_GRACE_PERIOD"
export const BRAINTREE_SUBSCRIPTION_TRAIL = "BRAINTREE_SUBSCRIPTION_TRAIL"
export const ACHIEVEMENT_SIGN_UP = "ACHIEVEMENT_SIGN_UP"
export const ACHIEVEMENT_DOWNLOAD_APP = "ACHIEVEMENT_DOWNLOAD_APP"
export const TSTAR = "TSTAR"
export const ACHIEVEMENT_COME_BACK = "ACHIEVEMENT_COME_BACK"
export const SMARTONE = "SMARTONE"
export const REFEREE = "REFEREE"
export const APTG = "APTG"
export const WECHAT = "WECHAT"
export const ALI = "ALI"
export const KRS = "KRS"
export const PAST_DUE_GRACE_PERIOD = "PAST_DUE_GRACE_PERIOD"
export const TRIAL_VIP = "TRIAL_VIP"
export const GOOGLE = "GOOGLE"
export const VOUCHER = "VOUCHER"

// other
export const HAMI = "HAMI"
export const FREE = "FREE"

const vipStatus = {
  BEFORE_AYCR,
  COUPON,
  IOS_SUBSCRIPTION,
  BRAINTREE_SUBSCRIPTION,
  TRIAL,
  SAMSUNG,
  IBON,
  REFERRAL,
  PACKAGE_PLAN,
  LIBRARY,
  BRAINTREE_GRACE_PERIOD,
  BRAINTREE_SUBSCRIPTION_TRAIL,
  ACHIEVEMENT_SIGN_UP,
  ACHIEVEMENT_DOWNLOAD_APP,
  TSTAR,
  ACHIEVEMENT_COME_BACK,
  SMARTONE,
  REFEREE,
  APTG,
  WECHAT,
  ALI,
  KRS,
  PAST_DUE_GRACE_PERIOD,
  TRIAL_VIP,
  GOOGLE,
  VOUCHER,
  HAMI,
  FREE
}

export default vipStatus
