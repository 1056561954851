import uuid from "uuid"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import {
  ADD_ARTICLE_READ_EVENT,
  CLEAR_STORED_ARTICLE_READ_EVENTS,
  END_CONSUMING_ARTICLE_READ_EVENTS,
  START_CONSUMING_ARTICLE_READ_EVENTS,
  CHANGE_TRAFFIC_SOURCE,
  UPDATE_CURRENT_READING_INFO,
  UPDATE_ARTICLE_READ_TRACKING_HASH,
  CLEAR_CURRENT_READING_INFO_AND_TRACKING_HASH
} from "constants/actionTypes"
import { DEFAULT_SOURCE } from "constants/trafficSource"
import { getNow } from "utilities/time"

const initialState = fromJS({
  trafficSource: DEFAULT_SOURCE,
  endConsuming: false,
  records: {},
  currentReadingInfo: {
    issueId: null,
    pageNumberMapping: {},
    currentArticleId: null,
    anotherArticleId: null,
    mode: null
  },

  // used only in pdf mode
  // format: {[articleId]: {uuid, timestamp}}
  trackingHash: {}
})

const reducerMap = new Map([
  [
    ADD_ARTICLE_READ_EVENT,
    (state, action) => {
      const event = action.payload.articleReadEvent
      const sessionId = event.session_id
      return state.setIn(["records", sessionId], event)
    }
  ],
  [
    CLEAR_STORED_ARTICLE_READ_EVENTS,
    state => {
      return state.set("records", fromJS({}))
    }
  ],
  [
    START_CONSUMING_ARTICLE_READ_EVENTS,
    state => {
      return state.set("endConsuming", false)
    }
  ],
  [
    END_CONSUMING_ARTICLE_READ_EVENTS,
    state => {
      return state.set("endConsuming", true)
    }
  ],
  [
    CHANGE_TRAFFIC_SOURCE,
    (state, action) => {
      const source = action.payload.trafficSource
      return state.set("trafficSource", source)
    }
  ],
  [
    UPDATE_CURRENT_READING_INFO,
    (state, action) => {
      const readingInfo = action.payload.readingInfo
      return state.set("currentReadingInfo", fromJS(readingInfo))
    }
  ],
  [
    UPDATE_ARTICLE_READ_TRACKING_HASH,
    (state, action) => {
      const articleIds = action.payload.articleIds
      let newTrackingHash = {}

      const getResetHash = () => ({
        timestamp: getNow(),
        uuid: uuid()
      })

      articleIds.forEach(articleId => {
        // another article id may be undefined or null
        if (articleId) {
          newTrackingHash[articleId] = state.getIn(["trackingHash", articleId])
            ? state.getIn(["trackingHash", articleId]).toJS()
            : getResetHash()
        }
      })

      return state.set("trackingHash", fromJS(newTrackingHash))
    }
  ],
  [
    CLEAR_CURRENT_READING_INFO_AND_TRACKING_HASH,
    state => {
      return state
        .set("currentReadingInfo", initialState.get("currentReadingInfo"))
        .set("trackingHash", initialState.get("trackingHash"))
    }
  ]
])

export default handleActions(reducerMap, initialState)
