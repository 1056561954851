import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { ZH_TW, ZH_CN, EN_US, JA_JP, KO_KR } from "constants/languages"
import zhTW from "translations/zhTW.json"
import zhCN from "translations/zhCN.json"
import enUS from "translations/enUS.json"
import jaJP from "translations/jaJP.json"
import koKR from "translations/koKR.json"

const options = {
  order: ["localStorage", "navigator", "cookie", "sessionStorage"]
}

const aycrSupportedLngs = [ZH_TW, ZH_CN, EN_US, JA_JP]
const allSupportedLngs = [EN_US, ZH_TW, ZH_CN, JA_JP, KO_KR]

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources: {
      [ZH_TW]: zhTW,
      [ZH_CN]: zhCN,
      [EN_US]: enUS,
      [JA_JP]: jaJP,
      [KO_KR]: koKR
    },
    supportedLngs:
      process.env.REACT_APP_PRODUCT === "aycr"
        ? aycrSupportedLngs
        : allSupportedLngs,
    fallbackLng: false,
    load: "currentOnly",
    debug: process.env.REACT_APP_CONFIG_FILE !== "production",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
