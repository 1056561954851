import {
  REQUEST_CATEGORIES_SUCCESS,
  REQUEST_CATEGORY_SUCCESS
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const formatCategory = category => {
  return {
    id: category.id,
    name: category.name,
    isAdult: category.is_adult,
    dictKey: category.dict_key,
    library: category.library
  }
}

const arrayReducer = (acc, cur) => {
  acc[cur.id] = formatCategory(cur)
  return acc
}

const reducerMap = new Map([
  [
    REQUEST_CATEGORIES_SUCCESS,
    (state, action) => {
      const { categoryGroups } = action.payload
      categoryGroups.forEach(group => {
        const categories = group.categories.reduce(arrayReducer, {})
        state = state.merge(fromJS(categories))
      })
      return state
    }
  ],
  [
    REQUEST_CATEGORY_SUCCESS,
    (state, action) => {
      const { category } = action.payload
      return state.set(category.id, fromJS(formatCategory(category)))
    }
  ]
])

export default handleActions(reducerMap, initialState)
