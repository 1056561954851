import KonoApi from "../KonoApi"

class FetchReadingProgressesConfigApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/aws_queues/reading_progresses`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchReadingProgressesConfigApi
