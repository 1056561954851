import KonoApi from "../KonoApi"

class DeleteReadingProgressApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/reading_progresses`)
    this.issueId = data.issueId
  }

  call() {
    return this.axios.delete(this.url, {
      data: { issue_id: this.issueId }
    })
  }
}

export default DeleteReadingProgressApi
