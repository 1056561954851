import { typecheck } from "../validators"

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * `delay` milliseconds.
 * @param {function} func original function which need to be executed.
 * @param {number} delay milliseconds to wait.
 * @returns {function} a function which constraints original function
 */
export default function debounce(func, delay = 300) {
  typecheck(func, "function")

  let timeout

  return function(...params) {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      timeout = null
      func.apply(this, [...params])
    }, delay)
  }
}
