import React, { Suspense } from "react"
import { Route, Switch } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import dictionary from "./assets/dictionary"
import { concatTitle } from "utilities/helmet"
import { lazy } from "react"
import retry from "utilities/retry"

function lazyRetry(func) {
  return lazy(() => retry(() => func()))
}

const ReadingProgressHelmet = lazyRetry(() =>
  import("components/content/ReadingProgress/Helmet")
)
const LibraryHelmet = lazyRetry(() =>
  import("components/content/Library/Helmet")
)
const SingleCategoryHelmet = lazyRetry(() =>
  import("components/content/SingleCategory/Helmet")
)
const SingleMagazineHelmet = lazyRetry(() =>
  import("components/content/SingleMagazine/Helmet")
)
const SingleIssueHelmet = lazyRetry(() =>
  import("components/content/SingleIssue/Helmet")
)
const SingleArticleHelmet = lazyRetry(() =>
  import("components/content/SingleArticle/Helmet")
)
const PageHelmet = lazyRetry(() => import("components/content/Page/Helmet"))
const CurationPostHelmet = lazyRetry(() =>
  import("components/content/CurationPost/Helmet")
)
const CurationChannelHelmet = lazyRetry(() =>
  import("components/content/CurationChannel/Helmet")
)
const CreditCardPlansHelmet = lazyRetry(() =>
  import("components/content/CreditCardPlans/Helmet")
)
const CouponHelmet = lazyRetry(() => import("components/content/Coupon/Helmet"))
const SignInHelmet = lazyRetry(() => import("components/content/SignIn/Helmet"))
const SignUpHelmet = lazyRetry(() => import("components/content/SignUp/Helmet"))
const AboutHelmet = lazyRetry(() => import("components/content/About/Helmet"))
const JobsHelmet = lazyRetry(() => import("components/content/Jobs/Helmet"))
const NewsHelmet = lazyRetry(() => import("components/content/News/Helmet"))
const ContactUsHelmet = lazyRetry(() =>
  import("components/content/ContactUs/Helmet")
)
const KonoMagazineIntroHelmet = lazyRetry(() =>
  import("components/content/KonoMagazineIntro/Helmet")
)
const SearchHelmet = lazyRetry(() => import("components/content/Search/Helmet"))
const MyFollowHelmet = lazyRetry(() =>
  import("components/content/MyFollow/Helmet")
)
const MyCollectHelmet = lazyRetry(() =>
  import("components/content/MyCollect/Helmet")
)
const WeeklyIssueHelmet = lazyRetry(() =>
  import("components/content/WeeklyIssue/Helmet")
)
const OobeHelmet = lazyRetry(() => import("components/content/Oobe/Helmet"))
const RemindPasswordHelmet = lazyRetry(() =>
  import("components/content/RemindPassword/Helmet")
)
const ResetPasswordHelmet = lazyRetry(() =>
  import("components/content/ResetPassword/Helmet")
)
const ChangePasswordHelmet = lazyRetry(() =>
  import("components/content/ChangePassword/Helmet")
)
const TStarIntroHelmet = lazyRetry(() =>
  import("components/content/TStarIntro/Helmet")
)
const TStarBindingHelmet = lazyRetry(() =>
  import("components/content/TStarBinding/Helmet")
)
const TStarSuccessHelmet = lazyRetry(() =>
  import("components/content/TStarSuccess/Helmet")
)
const TelecomIntroHelmet = lazyRetry(() =>
  import("components/content/TelecomIntro/Helmet")
)
const TelecomBindingHelmet = lazyRetry(() =>
  import("components/content/TelecomBinding/Helmet")
)
const TelecomSuccessHelmet = lazyRetry(() =>
  import("components/content/TelecomSuccess/Helmet")
)
const ThankYouHelmet = lazyRetry(() =>
  import("components/content/ThankYou/Helmet")
)
const GiftingWelcomeHelmet = lazyRetry(() =>
  import("components/content/GiftingWelcome/Helmet")
)
const GiftingPlansHelmet = lazyRetry(() =>
  import("components/content/GiftingPlans/Helmet")
)
const MembershipHelmet = lazyRetry(() =>
  import("components/content/Membership/Helmet")
)
const SmartoneSignUpHelmet = lazyRetry(() =>
  import("components/content/SmartoneSignUp/Helmet")
)
const RefereeHelmet = lazyRetry(() =>
  import("components/content/Referee/Helmet")
)
const AptgTokenBindingHelmet = lazyRetry(() =>
  import("components/content/AptgTokenBinding/Helmet")
)
const TstarTokenBindingHelmet = lazyRetry(() =>
  import("components/content/TstarTokenBinding/Helmet")
)
const CancelVipHelmet = lazyRetry(() =>
  import("components/content/CancelVip/Helmet")
)
const SubscriptionManagementHelmet = lazyRetry(() =>
  import("components/content/SubscriptionManagement/Helmet")
)
const RecommendHelmet = lazyRetry(() =>
  import("components/content/Recommend/Helmet")
)
const NotFoundHelmet = lazyRetry(() =>
  import("components/content/NotFound/Helmet")
)
const PolicyHelmet = lazyRetry(() => import("components/content/Policy/Helmet"))
const PrivacyHelmet = lazyRetry(() =>
  import("components/content/Privacy/Helmet")
)
const HomeHelmet = lazyRetry(() => import("components/content/Home/Helmet"))
const K4lLandingHelmet = lazyRetry(() =>
  import("components/content/K4lLanding/Helmet")
)
const MagazineListHelmet = lazyRetry(() =>
  import("components/content/MagazineList/Helmet")
)

const isK4l = process.env.REACT_APP_PRODUCT === "k4l"

const DefaultMetaTag = () => (
  <Helmet>
    <title>{concatTitle(dictionary["HOME_TITLE"])}</title>
    <meta name="description" content={dictionary["HOME_DESC"]} />
    {isK4l &&
    // staging and development are set in index.html
    process.env.REACT_APP_CONFIG_FILE === "production" &&
    window.location.pathname !== "/" ? (
      <meta name="robots" content="noindex" />
    ) : null}
  </Helmet>
)

function HelmetData() {
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route
          exact
          path="/"
          component={isK4l ? K4lLandingHelmet : HomeHelmet}
        />
        <Route
          path={["/sign-in", "/:k4lLibraryId/sign-in"]}
          component={SignInHelmet}
        />
        <Route
          exact
          path={["/sign-up", "/:k4lLibraryId/sign-up"]}
          component={SignUpHelmet}
        />
        <Route
          path="/sign-up/referee/:referrerToken"
          component={SignUpHelmet}
        />
        <Route
          path={[
            "/libraries/:libraryId",
            "/:k4lLibraryId/libraries/:libraryId"
          ]}
          component={LibraryHelmet}
        />
        <Route
          path="/categories/:categoryId"
          component={SingleCategoryHelmet}
        />
        <Route path="/magazines/:magazineId" component={SingleMagazineHelmet} />
        <Route exact path="/issues/:issueId" component={SingleIssueHelmet} />
        <Route path="/articles/:articleId" component={SingleArticleHelmet} />
        <Route
          path="/issues/:issueId/pages/:pageNumber"
          component={PageHelmet}
        />
        <Route path="/about" component={AboutHelmet} />
        <Route path="/jobs" component={JobsHelmet} />
        <Route path="/news" component={NewsHelmet} />
        <Route path="/contact-us" component={ContactUsHelmet} />
        <Route
          path="/kono-magazine-intro"
          component={KonoMagazineIntroHelmet}
        />
        <Route path="/search" component={SearchHelmet} />
        <Route path="/my-follow" component={MyFollowHelmet} />
        <Route path="/my-collect" component={MyCollectHelmet} />
        <Route path="/weekly-issue" component={WeeklyIssueHelmet} />
        <Route
          path="/curation-posts/:curationPostId"
          component={CurationPostHelmet}
        />
        <Route
          path="/curation-channels/:curationChannelId"
          component={CurationChannelHelmet}
        />
        <Route path="/oobe" component={OobeHelmet} />
        <Route
          path={["/remind-password", "/:k4lLibraryId/remind-password"]}
          component={RemindPasswordHelmet}
        />
        <Route path="/reset-password" component={ResetPasswordHelmet} />
        <Route
          path={["/change-password", "/:k4lLibraryId/change-password"]}
          component={ChangePasswordHelmet}
        />
        <Route path="/tstar-intro" component={TStarIntroHelmet} />
        <Route path="/tstar-binding" component={TStarBindingHelmet} />
        <Route path="/tstar-success" component={TStarSuccessHelmet} />
        <Route path="/aptg-intro" component={TelecomIntroHelmet} />
        <Route path="/aptg-binding" component={TelecomBindingHelmet} />
        <Route path="/aptg-success" component={TelecomSuccessHelmet} />
        <Route path="/thank-you" component={ThankYouHelmet} />
        <Route
          path="/payment/credit-card-plans"
          component={CreditCardPlansHelmet}
        />
        <Route path="/payment/coupon" component={CouponHelmet} />
        <Route path="/gifting-welcome" component={GiftingWelcomeHelmet} />
        <Route path="/gifting-plans" component={GiftingPlansHelmet} />
        <Route path="/membership" component={MembershipHelmet} />
        <Route path="/smartone-signup" component={SmartoneSignUpHelmet} />
        <Route path="/referrals/:referrerToken" component={RefereeHelmet} />
        <Route
          path="/aptg-token-binding/:aptgToken"
          component={AptgTokenBindingHelmet}
        />
        <Route
          path="/tstar-token-binding/:tstarToken"
          component={TstarTokenBindingHelmet}
        />
        <Route path="/cancel-vip" component={CancelVipHelmet} />
        <Route
          path="/subscription-management"
          component={SubscriptionManagementHelmet}
        />
        <Route path="/recommend" component={RecommendHelmet} />
        <Route path="/privacy" component={PrivacyHelmet} />
        <Route path="/policy" component={PolicyHelmet} />
        <Route path="/magazine-list" component={MagazineListHelmet} />
        <Route path={["/451", "/404"]} component={NotFoundHelmet} />
        <Route path="/recently-read" component={ReadingProgressHelmet} />
        <Route component={null} />
      </Switch>
    </Suspense>
  )
}

function MetaTag() {
  return (
    <React.Fragment>
      <DefaultMetaTag />
      {/* overwrite default meta tag if possible */}
      <HelmetData />
    </React.Fragment>
  )
}

export default MetaTag
