import React from "react"
import styled from "styled-components/macro"
import media from "utilities/media"
import errorImage from "./assets/images/error.png"
import store from "store"
import { LOG_OUT } from "constants/actionTypes"

import { withTranslation } from "react-i18next"

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.tablet`padding: 50px 0;`}
  ${media.mobile`padding: 30px 0;`}
  ${media.desktop`
    flex-direction: row;
    align-items: flex-start;
    padding: 85px 0;
  `}
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tablet`margin-bottom: 40px;`}
  ${media.mobile`margin-bottom: 30px;`}
  ${media.desktop`
    width: 310px;
    padding-top: 50px;
    align-items: flex-start;
  `}
`

const ErrorImage = styled.div`
  background-image: url(${errorImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  width: 380px;
  height: 300px;

  ${media.mobile`
    width: 290px;
    height: 210px;
  `}
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: 500;
  line-height: 1;
  color: #000000;
  margin-bottom: 10px;

  ${media.desktop`
    justify-content: flex-start;
    font-size: 40px;
    margin-bottom: 15px;
  `}
`

const MessageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: #000000;
  line-height: 1;
  font-size: 16px;
  padding-top: 8px;

  ${media.desktop`
    justify-content: flex-start;
    font-size: 18px;
    padding-top: 10px;
  `};
`

const LoginText = styled.span`
  cursor: pointer;
  color: #00a270;
  border-bottom: 1px solid #00a270;
  padding-bottom: 3px;
`

function ErrorPage(props) {
  const onClick = () => {
    store.dispatch({ type: LOG_OUT })
    window.location.href = "/sign-in"
  }

  return (
    <MainWrapper>
      <TextWrapper>
        <TitleWrapper>{props.t("PAGE_ERROR")}</TitleWrapper>
        <MessageWrapper>{props.t("TEXT")}</MessageWrapper>
        <MessageWrapper>
          {props.t("OR")}
          <LoginText onClick={onClick}>{props.t("RE_LOGIN")}</LoginText>
        </MessageWrapper>
        {props.error}
        <br />
        {props.info?.split("\n").slice(0, 2)}
      </TextWrapper>
      <ErrorImage />
    </MainWrapper>
  )
}

export default withTranslation("container")(ErrorPage)
