import {
  OPEN_HEADER,
  CLOSE_HEADER,
  OPEN_HEADER_MENU,
  CLOSE_HEADER_MENU
} from "constants/actionTypes"

export const onOpenHeader = () => ({
  type: OPEN_HEADER
})

export const onCloseHeader = () => ({
  type: CLOSE_HEADER
})

export const onOpenHeaderMenu = menu => ({
  type: OPEN_HEADER_MENU,
  payload: { menu }
})

export const onCloseHeaderMenu = () => ({
  type: CLOSE_HEADER_MENU
})
