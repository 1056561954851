import { REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS,
    (state, action) => {
      const magazineId = action.payload.magazineId
      const year = action.payload.year
      const issues = fromJS(action.payload.issues.map(issue => issue.bid))
      return state.setIn([magazineId, year], issues)
    }
  ]
])

export default handleActions(reducerMap, initialState)
