import { SET_BREADCRUMB } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({ current: undefined })

const reducerMap = new Map([
  [
    SET_BREADCRUMB,
    (state, action) => {
      return state.set("current", action.payload.breadcrumb)
    }
  ]
])

export default handleActions(reducerMap, initialState)
