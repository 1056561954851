import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import { REQUEST_CURRENT_FREE_ISSUE_SUCCESS } from "constants/actionTypes"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    REQUEST_CURRENT_FREE_ISSUE_SUCCESS,
    (state, action) => {
      return state.set("current", fromJS(action.payload.freeIssue.magazine.bid))
    }
  ]
])

export default handleActions(reducerMap, initialState)
