export const REGISTER = "REGISTER"
export const LOGIN = "LOGIN"
export const FOLLOW_TITLE = "FOLLOW_TITLE"
export const UNFOLLOW_TITLE = "UNFOLLOW_TITLE"
export const BOOKMARK_ACTION = "BOOKMARK_ACTION"
export const UNBOOKMARK_ACTION = "UNBOOKMARK_ACTION"
export const APTG_BINDING = "APTG_BINDING"
export const TSTAR_BINDING = "TSTAR_BINDING"
export const SMARTONE_BINDING = "SMARTONE_BINDING"
export const OPEN_ARTICLE = "OPEN_ARTICLE"
export const OPEN_PREVIEW_ARTICLE = "OPEN_PREVIEW_ARTICLE"
export const OPEN_CURATION_POST = "OPEN_CURATION_POST"
export const OPEN_CURATION_POST_ARTICLE = "OPEN_CURATION_POST_ARTICLE"
export const OPEN_RECOMMEND_ARTICLE = "OPEN_RECOMMEND_ARTICLE"
export const UPGRADE_REFERRAL = "UPGRADE_REFERRAL"
export const REFERRAL_SHARE_LINK = "REFERRAL_SHARE_LINK"
export const UPGRADE_TRY_PURCHASE = "UPGRADE_TRY_PURCHASE"
export const UPGRADE_PLAN_SELECTED = "UPGRADE_PLAN_SELECTED"
export const UPGRADE_SUCCESS = "UPGRADE_SUCCESS"
export const UPGRADE_TRY_USE_PROMOTION_CODE = "UPGRADE_TRY_USE_PROMOTION_CODE"
export const UPGRADE_COUPON = "UPGRADE_COUPON"
export const BANNER_CLICK = "BANNER_CLICK"
export const SEARCH = "SEARCH"
export const SEARCH_RESULT_MAGAZINE_CLICK = "SEARCH_RESULT_MAGAZINE_CLICK"
export const SEARCH_RESULT_ARTICLE_CLICK = "SEARCH_RESULT_ARTICLE_CLICK"
export const FREE_MAGAZINE_INTRO_DISPLAY = "FREE_MAGAZINE_INTRO_DISPLAY"
export const FREE_MAGAZINE_INTRO_ACTION_CLICK =
  "FREE_MAGAZINE_INTRO_ACTION_CLICK"
export const RECOMMEND_QUESTION_ASKED = "RECOMMEND_QUESTION_ASKED"
export const RECOMMEND_LIST_ENTER = "RECOMMEND_LIST_ENTER"
export const SHARE_ARTICLE = "SHARE_ARTICLE"
export const ENTER_SIGN_UP = "ENTER_SIGN_UP"
export const ENTER_SIGN_IN = "ENTER_SIGN_IN"
export const ENTER_PRICE = "ENTER_PRICE" // no use
export const ENTER_MY_COLLECT = "ENTER_MY_COLLECT"
export const ENTER_SINGLE_CATEGORY = "ENTER_SINGLE_CATEGORY"
export const ENTER_SINGLE_ISSUE = "ENTER_SINGLE_ISSUE"
export const ENTER_SINGLE_MAGAZINE = "ENTER_SINGLE_MAGAZINE"
export const DOWNLOAD_APP = "DOWNLOAD_APP"
export const RESEND_CONFIRMATION_EMAIL = "RESEND_CONFIRMATION_EMAIL"
export const RESCALE_PDF_PAGE = "RESCALE_PDF_PAGE"
export const RESIZE_ARTICLE_FONT = "RESIZE_ARTICLE_FONT"
export const FULL_SCREEN_READING = "FULL_SCREEN_READING"
export const OPEN_TOC = "OPEN_TOC"
export const GET_IBON_PINCODE_SUCCESS = "GET_IBON_PINCODE_SUCCESS"
export const PLAY_AUDIO = "PLAY_AUDIO"
export const SET_USER_PROPERTIES = "SET_USER_PROPERTIES"
export const BUY_GIFTING = "BUY_GIFTING"
export const HOME_OPEN_MY_FOLLOW_TITLE = "HOME_OPEN_MY_FOLLOW_TITLE"
export const HOME_OPEN_RECOMMEND_TITLE = "HOME_OPEN_RECOMMEND_TITLE"
export const HOME_OPEN_WEEKLY_ISSUE = "HOME_OPEN_WEEKLY_ISSUE"
export const HOME_OPEN_LATEST_ISSUE = "HOME_OPEN_LATEST_ISSUE"
export const HOME_OPEN_CURATION_CHANNEL = "HOME_OPEN_CURATION_CHANNEL"
export const HOME_OPEN_CURATION_POST = "HOME_OPEN_CURATION_POST"
export const HOME_OPEN_CURATION_POST_ARTICLE = "HOME_OPEN_CURATION_POST_ARTICLE"
export const HOME_OPEN_RECOMMEND_ARTICLE = "HOME_OPEN_RECOMMEND_ARTICLE"
export const HOME_OPEN_POPULAR_TITLE = "HOME_OPEN_POPULAR_TITLE"
export const HOME_OPEN_POPULAR_ARTICLE = "HOME_OPEN_POPULAR_ARTICLE"
export const HOME_OPEN_MY_BOOKMARK = "HOME_OPEN_MY_BOOKMARK"
export const HEADER_POPULAR_MAGAZINE_CLICK = "HEADER_POPULAR_MAGAZINE_CLICK"
export const HEADER_HK_MAGAZINE_CLICK = "HEADER_HK_MAGAZINE_CLICK"
export const HEADER_REDEEM_CLICK = "HEADER_REDEEM_CLICK"
export const HEADER_SUBSCRIBE_CLICK = "HEADER_SUBSCRIBE_CLICK"
export const HEADER_GIFTING_CLICK = "HEADER_GIFTING_CLICK"
export const HEADER_PROFILE_MY_FOLLOW_CLICK = "HEADER_PROFILE_MY_FOLLOW_CLICK"
export const HEADER_PROFILE_BOOKMARK_CLICK = "HEADER_PROFILE_BOOKMARK_CLICK"
export const HEADER_PROFILE_RECENT_READ_CLICK =
  "HEADER_PROFILE_RECENT_READ_CLICK"
export const HEADER_PROFILE_SUBSCRIBE_CLICK = "HEADER_PROFILE_SUBSCRIBE_CLICK"
export const HEADER_PROFILE_GIFTING_CLICK = "HEADER_PROFILE_GIFTING_CLICK"
export const HEADER_PROFILE_REFERRAL_CLICK = "HEADER_PROFILE_REFERRAL_CLICK"
export const HEADER_PROFILE_MEMBERSHIP_CLICK = "HEADER_PROFILE_MEMBERSHIP_CLICK"
export const HEADER_PROFILE_LOGOUT_CLICK = "HEADER_PROFILE_LOGOUT_CLICK"
export const PREVIEW_ARTICLE_DOWNLOAD_APP_CLICK =
  "PREVIEW_ARTICLE_DOWNLOAD_APP_CLICK"
export const HEADER_NAVIGATION_CATEGORY_CLICK =
  "HEADER_NAVIGATION_CATEGORY_CLICK"
export const HEADER_NAVIGATION_LIBRARY_CLICK = "HEADER_NAVIGATION_LIBRARY_CLICK"
export const ENTER_HOME = "ENTER_HOME"
export const START_TRIAL_SHOW = "START_TRIAL_SHOW"
export const START_TRIAL_CLICK = "START_TRIAL_CLICK"
export const OPEN_REPORT = "OPEN_REPORT"
export const CLICK_SURVEY = "CLICK_SURVEY"
export const VIEW_PRICE_PAGE = "VIEW_PRICE_PAGE"
export const CLICK_SUBSCRIBE = "CLICK_SUBSCRIBE"
export const VIEW_PAYMENT_PAGE = "VIEW_PAYMENT_PAGE"
export const CONFIRM_SUBSCRIBE = "CONFIRM_SUBSCRIBE"
export const VIEW_THANK_YOU_PAGE = "VIEW_THANK_YOU_PAGE"

// tracking events V2
export const VIEW_OOBE_PAGE = "VIEW_OOBE_PAGE"
export const CLICK_OOBE_NEXT_STEP = "CLICK_OOBE_NEXT_STEP"
export const COMPLETE_OOBE = "COMPLETE_OOBE"
export const VIEW_ARTICLE_PREVIEW = "VIEW_ARTICLE_PREVIEW"
export const CLICK_ARTICLE_PREVIEW_CELL = "CLICK_ARTICLE_PREVIEW_CELL"
export const PLAY_VIDEO = "PLAY_VIDEO"
