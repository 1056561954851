import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { onToggleSideTabMenu } from "actions/sideTabMenu"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import SideTabComponent from "components/container/SideTabComponent"
import styled from "styled-components/macro"

const SideTabAuthBtnWrapper = styled.div`
  width: ${props => props.width || "150px"};
  height: 48px;
  border-radius: 8px;
  color: ${props => (props.whiteBackground ? "#008159" : "white")};
  font-size: 16px;
  font-weight: 500;
  background-color: ${props => (props.whiteBackground ? "white" : "#00a270")};
  border: ${props => (props.whiteBackground ? "solid 1px #5e9519" : "none")};
  display: flex;
  justify-content: center;
  align-items: center;
`

class SideTabAuthDiv extends Component {
  render() {
    return (
      <SideTabComponent.SideTabDivWrapper noBottomBorder>
        {this.props.hasSignUp ? (
          <Link to="/sign-up">
            <SideTabAuthBtnWrapper onClick={this.props.onToggleSideTabMenu}>
              {this.props.t("SIGN_UP")}
            </SideTabAuthBtnWrapper>
          </Link>
        ) : null}
        <Link to="/sign-in">
          <SideTabAuthBtnWrapper
            onClick={this.props.onToggleSideTabMenu}
            whiteBackground={this.props.hasSignUp}
            width={this.props.hasSignUp ? "108px" : "268px"}
          >
            {this.props.t("SIGN_IN")}
          </SideTabAuthBtnWrapper>
        </Link>
      </SideTabComponent.SideTabDivWrapper>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onToggleSideTabMenu }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("container")(SideTabAuthDiv))
