import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestIosReceiptsSuccess } from "actions/iosReceipts"
import { REQUEST_IOS_RECEIPTS } from "constants/actionTypes"
import { onStartLoading, onEndLoading } from "actions/loading"
import { REQUEST_IOS_RECEIPTS_LOADING } from "constants/loadingTypes"
import FetchIosReceiptsApi from "apis/FetchIosReceiptsApi"

function* onFetchIosReceipts(action) {
  try {
    yield put(onStartLoading(REQUEST_IOS_RECEIPTS_LOADING))
    const { userId } = action.payload
    const fetchIosReceipts = new FetchIosReceiptsApi({ userId })
    const response = yield call(fetchIosReceipts.call)
    yield put(onRequestIosReceiptsSuccess(response.data))
    yield put(onEndLoading(REQUEST_IOS_RECEIPTS_LOADING))
  } catch (e) {
    console.log(e)
    yield put(onEndLoading(REQUEST_IOS_RECEIPTS_LOADING))
  }
}

export function* watchRequestIosReceipts() {
  yield takeEvery(REQUEST_IOS_RECEIPTS, onFetchIosReceipts)
}
