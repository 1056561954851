import React from "react"
import styled from "styled-components"

const Lay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 8900;
  visibility: ${props => (props.show ? "visible" : "hidden")};
  transition: visibility ${props => props.s} ease-out;
`

export default function Overlay(props) {
  return <Lay onClick={props.onClick} show={props.show} s={props.s} />
}
