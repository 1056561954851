import others from "./images/others.svg"
import twCorporate from "./images/tw-corporate.svg"
import twPublic from "./images/tw-public.svg"
import twSchool from "./images/tw-school.svg"
import usLibrary from "./images/us-library.svg"

const regionData = [
  {
    id: 1,
    key: "US_LIBRARIES",
    name: "US Libraries",
    image: usLibrary,
    region: "us"
  },
  {
    id: 2,
    key: "OTHER_LIBRARIES",
    name: "Other Areas",
    image: others,
    region: "others"
  },
  {
    id: 4,
    key: "TW_SCHOOL_LIBRARIES",
    name: "TW School Libraries",
    image: twSchool,
    region: "tw"
  },
  {
    id: 3,
    key: "TW_PUBLIC_LIBRARIES",
    name: "TW Public Libraries",
    image: twPublic,
    region: "tw"
  },
  {
    id: 5,
    key: "TW_CORPORATE_LIBRARIES",
    name: "TW Corporate Libraries",
    image: twCorporate,
    region: "tw"
  }
]

export default regionData
