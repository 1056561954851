import KonoApi from "../KonoApi"
import store from "../../store"

class UpdateUserApi extends KonoApi {
  constructor() {
    super(`/users/${store.getState().getIn(["user", "current", "id"])}`)
  }

  call(params) {
    return this.axios.put(this.url, {
      nickname: params.nickname,
      adult: params.adult
    })
  }
}

export default UpdateUserApi
