import KonoApi from "../KonoApi"

class AptgExistTokenApi extends KonoApi {
  constructor() {
    super(`/aptg_orders/exist`)
  }

  call(params) {
    return this.axios.get(this.url, {
      params: {
        aptg_token: params.token
      }
    })
  }
}

export default AptgExistTokenApi
