import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestNewsSuccess } from "actions/news"
import { REQUEST_NEWS } from "constants/actionTypes"
import FetchNewsApi from "apis/FetchNewsApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { NEWS_TTL } from "constants/ttl"

function* fetchNews() {
  try {
    const isExisted = yield* isStoreExisted("news")
    const isExpired = yield* isStoreExpired(NEWS_TTL, "news")
    if (isExisted && !isExpired) {
      return
    }
    const fetchNewsApi = new FetchNewsApi()
    const response = yield call(fetchNewsApi.call, { type: "news" })
    const news = response.data
    yield put(onRequestNewsSuccess(news))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestNews() {
  yield takeEvery(REQUEST_NEWS, fetchNews)
}
