import { REQUEST_SENT_GIFTS_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS([])

const formatSentGift = gift => {
  return {
    id: gift.id,
    recipientName: gift.recipient_name,
    recipientEmail: gift.recipient_email,
    message: gift.message,
    createdAt: gift.created_at,
    updatedAt: gift.updated_at,
    completedAt: gift.completed_at
  }
}

const reducerMap = new Map([
  [
    REQUEST_SENT_GIFTS_SUCCESS,
    (state, action) => {
      const gifts = action.payload.sentGifts.map(formatSentGift)
      return fromJS(gifts)
    }
  ]
])

export default handleActions(reducerMap, initialState)
