import {
  REQUEST_CURATION_POST_ARTICLES,
  REQUEST_CURATION_POST_ARTICLES_SUCCESS
} from "constants/actionTypes"

export const onRequestCurationPostArticles = curationPostId => {
  return {
    type: REQUEST_CURATION_POST_ARTICLES,
    payload: { curationPostId }
  }
}

export const onRequestCurationPostArticlesSuccess = (articles, postId) => {
  return {
    type: REQUEST_CURATION_POST_ARTICLES_SUCCESS,
    payload: { articles, postId }
  }
}
