const footerLinks = [
  {
    groupName: "1",
    links: [
      { to: "/libraries/chinese", external: false, text: "MAGAZINE_LIST" },
      { to: "/about", external: false, text: "TEAM" },
      { to: "/contact-us", external: false, text: "CONTACT_US" }
    ]
  },
  {
    groupName: "2",
    links: [
      { to: "/policy", external: false, text: "TEAM_OF_SERVICE" },
      { to: "/privacy", external: false, text: "PRIVACY" },
      {
        to: "/faq",
        external: false,
        text: "FAQ"
      }
    ]
  }
]

export default footerLinks
