import React from "react"
import { useDispatch } from "react-redux"
import { onToggleSideTabMenu } from "actions/sideTabMenu"
import styled from "styled-components"

const MainWrapper = styled.div`
  width: 240px;
  height: 48px;
  display: flex;
  align-items: center;
  color: #222222;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 24px;
  cursor: pointer;
  border-radius: 24px;
  background-color: ${props => (props.focus ? "#f6f3ed" : "")};
`

function SideTabSubMenuItem(props) {
  const dispatch = useDispatch()

  return (
    <MainWrapper
      onClick={() => dispatch(onToggleSideTabMenu())}
      focus={props.focus}
    >
      {props.children}
    </MainWrapper>
  )
}

export default SideTabSubMenuItem
