import React from "react"
import styled from "styled-components/macro"
import NavigationGroup from "../NavigationGroup"
import footerLinks from "./assets/footerLinks"
import media from "utilities/media"

const NavigationRowWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 50px;

  ${media.mobile`
    width: 100%;
    justify-content: flex-start;
    order: 1;
    margin-left: 0;
  `}
`

export default function NavigationRow() {
  function renderNavigationGroup(linksGroup) {
    return (
      <NavigationGroup
        groupName={linksGroup.groupName}
        links={linksGroup.links}
        key={linksGroup.groupName}
      />
    )
  }

  return (
    <NavigationRowWrapper>
      {footerLinks.map(renderNavigationGroup)}
    </NavigationRowWrapper>
  )
}
