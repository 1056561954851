import Overlay from "./components/Overlay"
import SideTabMenuWrapper from "./components/SideTabMenuWrapper"
import SideTabCloseButton from "./components/SideTabCloseButton"
import SideTabDivWrapper from "./components/SideTabDivWrapper"
import SideTabRow from "./components/SideTabRow"
import SideTabDownloadApp from "./components/SideTabDownloadApp"
import SideTabSubMenuWrapper from "./components/SideTabSubMenuWrapper"
import SideTabSubMenuItem from "./components/SideTabSubMenuItem"

const object = {
  second: "0.6s",
  Overlay,
  SideTabMenuWrapper,
  SideTabCloseButton,
  SideTabDivWrapper,
  SideTabRow,
  SideTabDownloadApp,
  SideTabSubMenuWrapper,
  SideTabSubMenuItem
}

export default object
