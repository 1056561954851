import { put, throttle, call } from "redux-saga/effects"
import { recordUserActivitySuccess } from "actions/userActivity"
import { RECORD_USER_ACTIVITY } from "constants/actionTypes"
import { USER_ACTIVITY_TTL } from "constants/ttl"
import { isStoreExpired } from "utilities/storeCheck"
import RecordUserActivityApi from "apis/RecordUserActivityApi"

function* recordUserActivity(action) {
  try {
    const isExpired = yield* isStoreExpired(USER_ACTIVITY_TTL, "lastActivity")
    if (!isExpired) return
    const { userId } = action.payload
    const api = new RecordUserActivityApi({ userId })
    yield call(api.call)
    yield put(recordUserActivitySuccess())
  } catch (e) {
    console.log(e)
  }
}

export function* watchRecordUserActivity() {
  yield throttle(3600000, RECORD_USER_ACTIVITY, recordUserActivity)
}
