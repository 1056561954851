import {
  REQUEST_VIP_PERIODS,
  REQUEST_VIP_PERIODS_THROTTLING,
  REQUEST_VIP_PERIODS_SUCCESS,
  REQUEST_VIP_STATUS
} from "constants/actionTypes"

export const onRequestVipPeriods = (userId, throttling) => {
  const type = throttling ? REQUEST_VIP_PERIODS_THROTTLING : REQUEST_VIP_PERIODS
  return { type, payload: { userId } }
}

export const onRequestVipPeriodsSuccess = vipPeriods => {
  return {
    type: REQUEST_VIP_PERIODS_SUCCESS,
    payload: { vipPeriods }
  }
}

export const onRequestVipStatus = (userId, throttling) => {
  return {
    type: REQUEST_VIP_STATUS,
    payload: { userId, throttling }
  }
}
