import KonoApi from "../KonoApi"

class FetchMagazineApi extends KonoApi {
  constructor(data) {
    super(`/titles/${data.magazineId}`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchMagazineApi
