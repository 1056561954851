// This saga is for watching action and then sending gtm events
// It includes most of GA events and some amplitude events
// Some amplitude events keep more steps so will be implemented in components

import { takeEvery, call, take, put, throttle, all } from "redux-saga/effects"
import { fromJS } from "immutable"
import FetchArticleAccessTokenApi from "apis/FetchArticleAccessTokenApi"
import { onRequestMagazine } from "actions/magazines"
import {
  LOG_SIGN_UP,
  LOG_SIGN_IN,
  LOG_TELECOM_BINDING,
  FOLLOW_MAGAZINE,
  UNFOLLOW_MAGAZINE,
  CREATE_BOOKMARK,
  DELETE_BOOKMARK,
  LOG_OPEN_ARTICLE,
  LOG_OPEN_CURATION_POST,
  LOG_OPEN_CURATION_POST_ARTICLE,
  LOG_OPEN_RECOMMEND_ARTICLE,
  LOG_UPGRADE_TRY_PURCHASE,
  LOG_UPGRADE_PLAN_SELECTED,
  LOG_UPGRADE_SUCCESS,
  LOG_UPGRADE_TRY_USE_PROMOTION_CODE,
  LOG_UPGRADE_COUPON,
  LOG_BANNER_CLICK,
  CHANGE_SEARCH_KEY,
  LOG_SEARCH_RESULT_MAGAZINE_CLICK,
  LOG_SEARCH_RESULT_ARTICLE_CLICK,
  LOG_FREE_MAGAZINE_INTRO_DISPLAY,
  LOG_FREE_MAGAZINE_INTRO_ACTION_CLICK,
  LOG_RECOMMEND_QUESTION_ASKED,
  LOG_RECOMMEND_LIST_ENTER,
  LOG_SHARE_ARTICLE,
  CHANGE_STATE,
  REQUEST_CURATION_CHANNELS_SUCCESS,
  REQUEST_CURATION_CHANNEL_SUCCESS,
  REQUEST_CURATION_POST_SUCCESS,
  REQUEST_CURATION_CHANNEL_POSTS_SUCCESS,
  REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS,
  REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS,
  REQUEST_ISSUE_SUCCESS,
  REQUEST_MAGAZINE_ISSUES_SUCCESS,
  REQUEST_LATEST_ISSUE_SUCCESS,
  REQUEST_BOOKMARKED_ARTICLES_SUCCESS,
  REQUEST_LATEST_ISSUES_SUCCESS,
  REQUEST_RECOMMEND_ARTICLES_SUCCESS,
  REQUEST_ISSUE_ARTICLES_SUCCESS,
  REQUEST_ARTICLE_SUCCESS,
  CREATE_BOOKMARK_SUCCESS,
  CREATE_BOOKMARK_ERROR,
  DELETE_BOOKMARK_SUCCESS,
  DELETE_BOOKMARK_ERROR,
  REQUEST_CURATION_POST_ARTICLES_SUCCESS,
  REQUEST_CURRENT_FREE_ISSUE_SUCCESS,
  REQUEST_MAGAZINE_SUCCESS,
  REQUEST_MAGAZINES_SUCCESS,
  REQUEST_RECOMMENDED_MAGAZINES_SUCCESS,
  LOG_DOWNLOAD_APP,
  LOG_RESEND_CONFIRMATION_EMAIL,
  LOG_WIDGET_BAR_CLICK,
  LOG_GET_IBON_PINCODE_SUCCESS,
  LOG_PLAY_AUDIO,
  LOG_BUY_GIFTING,
  LOG_HEADER_CLICK,
  LOG_PREVIEW_ARTICLE_DOWNLOAD_APP_CLICK,
  LOG_HEADER_NAVIGATION_CATEGORY_CLICK,
  LOG_HEADER_NAVIGATION_LIBRARY_CLICK,
  LOG_START_TRIAL_SHOW,
  LOG_START_TRIAL_CLICK,
  LOG_OPEN_REPORT,
  LOG_CLICK_SURVEY,
  LOG_VIEW_PRICE_PAGE,
  LOG_CLICK_SUBSCRIBE,
  LOG_VIEW_PAYMENT_PAGE,
  LOG_CONFIRM_SUBSCRIBE,
  LOG_VIEW_THANK_YOU_PAGE
} from "constants/actionTypes"
import {
  SIGN_IN_STATE,
  SIGN_UP_STATE,
  SINGLE_CATEGORY,
  SINGLE_ISSUE,
  SINGLE_MAGAZINE,
  MY_COLLECT_STATE
} from "constants/state"
import {
  HEADER_POPULAR_MAGAZINE,
  HEADER_HK_MAGAZINE,
  HEADER_REDEEM,
  HEADER_SUBSCRIBE,
  HEADER_GIFTING,
  HEADER_PROFILE_MY_FOLLOW,
  HEADER_PROFILE_BOOKMARK,
  HEADER_PROFILE_RECENT_READ,
  HEADER_PROFILE_SUBSCRIBE,
  HEADER_PROFILE_GIFTING,
  HEADER_PROFILE_MEMBERSHIP,
  HEADER_PROFILE_LOGOUT
} from "constants/headerPositions"
import * as gtmEvents from "constants/trackingEvents"
import { selectStore } from "utilities/storeCheck"
import {
  updateGtmUserId,
  gtmLogEvent,
  gtmSetUserProperties
} from "utilities/gtmDataLayer"
import {
  logBranchSignUp,
  logBranchViewItem,
  logBranchPurchase
} from "utilities/branch"
import { getUtmParams, trackingSource } from "utilities/history"
import { getTrackingVipStatus } from "utilities/vipPeriodMap"
import { getNow } from "utilities/time"
import { getMql, getDeviceWidth } from "utilities/media"
import { fetchAndReturnVipPeriods } from "../vipPeriodsSaga"
import { fetchAndReturnFreeArticleQuota } from "../freeArticleQuotaSaga"

const issuesArticlesPattern = [
  REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS,
  REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS,
  REQUEST_ISSUE_SUCCESS,
  REQUEST_MAGAZINE_ISSUES_SUCCESS,
  REQUEST_LATEST_ISSUE_SUCCESS,
  REQUEST_LATEST_ISSUES_SUCCESS,
  REQUEST_ISSUE_ARTICLES_SUCCESS,
  REQUEST_ARTICLE_SUCCESS,
  CREATE_BOOKMARK,
  CREATE_BOOKMARK_SUCCESS,
  CREATE_BOOKMARK_ERROR,
  DELETE_BOOKMARK,
  DELETE_BOOKMARK_SUCCESS,
  DELETE_BOOKMARK_ERROR,
  REQUEST_BOOKMARKED_ARTICLES_SUCCESS,
  REQUEST_CURATION_POST_ARTICLES_SUCCESS,
  REQUEST_RECOMMEND_ARTICLES_SUCCESS,
  REQUEST_CURRENT_FREE_ISSUE_SUCCESS
]

const issuesPattern = [
  REQUEST_CATEGORY_LATEST_ISSUES_SUCCESS,
  REQUEST_MAGAZINE_YEAR_ISSUES_SUCCESS,
  REQUEST_ISSUE_SUCCESS,
  REQUEST_MAGAZINE_ISSUES_SUCCESS,
  REQUEST_LATEST_ISSUE_SUCCESS,
  REQUEST_BOOKMARKED_ARTICLES_SUCCESS,
  REQUEST_ARTICLE_SUCCESS,
  REQUEST_LATEST_ISSUES_SUCCESS,
  REQUEST_RECOMMEND_ARTICLES_SUCCESS,
  REQUEST_CURATION_POST_ARTICLES_SUCCESS,
  REQUEST_CURRENT_FREE_ISSUE_SUCCESS
]

const magazinesPattern = [
  REQUEST_MAGAZINE_SUCCESS,
  REQUEST_MAGAZINES_SUCCESS,
  REQUEST_RECOMMENDED_MAGAZINES_SUCCESS
]

function* signUpEvent(action) {
  const { user } = action.payload
  updateGtmUserId(user.kid)

  const state = yield selectStore("state", "history")
  const source = trackingSource(state)
  const locationSearch = yield selectStore("state", "locationSearch")
  const utmParams = getUtmParams(locationSearch)

  const eventParams = { source, ...utmParams }
  gtmSetUserProperties(user)
  gtmLogEvent(gtmEvents.REGISTER, eventParams)
  logBranchSignUp()
}

export function* watchSignUpEvent() {
  yield takeEvery(LOG_SIGN_UP, signUpEvent)
}

function* signInEvent(action) {
  const { user } = action.payload
  updateGtmUserId(user.kid)

  const state = yield selectStore("state", "history")
  const source = trackingSource(state)

  const locationSearch = yield selectStore("state", "locationSearch")
  const utmParams = getUtmParams(locationSearch)

  const eventParams = { source, ...utmParams }
  gtmSetUserProperties(user)
  gtmLogEvent(gtmEvents.LOGIN, eventParams)
}

export function* watchSignInEvent() {
  yield takeEvery(LOG_SIGN_IN, signInEvent)
}

function* followTitleEvent(action) {
  const { magazineId, options } = action.payload
  const { source: optionSource } = options
  const magazineName = yield selectStore("magazines", magazineId, "name")
  const history = yield selectStore("state", "history")
  const source = optionSource || trackingSource(history, true)
  const eventParams = {
    title: magazineId,
    title_name: magazineName,
    source
  }
  gtmLogEvent(gtmEvents.FOLLOW_TITLE, eventParams)
}

export function* watchFollowTitleEvent() {
  yield takeEvery(FOLLOW_MAGAZINE, followTitleEvent)
}

function* unfollowTitleEvent(action) {
  const { magazineId, options } = action.payload
  const { source: optionSource } = options
  const magazineName = yield selectStore("magazines", magazineId, "name")
  const history = yield selectStore("state", "history")
  const source = optionSource || trackingSource(history, true)
  const eventParams = {
    title: magazineId,
    title_name: magazineName,
    source
  }
  gtmLogEvent(gtmEvents.UNFOLLOW_TITLE, eventParams)
}

export function* watchUnfollowTitleEvent() {
  yield takeEvery(UNFOLLOW_MAGAZINE, unfollowTitleEvent)
}

function* bookmarkEvent(action, count = 10) {
  if (count <= 0) return

  const { articleId } = action.payload
  const articleName = yield selectStore("articles", articleId, "title")
  const issueId = yield selectStore("articles", articleId, "issueId")
  const magazineName = yield selectStore("issues", issueId, "magazineName")

  if (magazineName) {
    const trackingArticle = yield selectStore(
      "articleReadEvents",
      "trackingHash",
      articleId
    )
    let intention = ""
    if (trackingArticle) {
      const timestamp = trackingArticle.get("timestamp")
      intention = getNow() - timestamp >= 4 ? "collect" : "read_later"
    }

    const history = yield selectStore("state", "history")
    const source = trackingSource(history, true)
    let readingMode = "not_in_article"
    readingMode = source === "pages" ? "pdf" : readingMode
    readingMode = source === "articles" ? "fit_reading" : readingMode

    const eventParams = {
      title_name: magazineName,
      reading_mode: readingMode,
      article_id: articleId,
      article_name: articleName,
      intention,
      source
    }
    gtmLogEvent(gtmEvents.BOOKMARK_ACTION, eventParams)
  } else {
    yield take(issuesArticlesPattern)
    yield bookmarkEvent(action, count - 1)
  }
}

export function* watchBookmarkEvent() {
  yield takeEvery(CREATE_BOOKMARK, bookmarkEvent)
}

function* unbookmarkEvent(action, count = 10) {
  if (count <= 0) return

  const { articleId } = action.payload
  const issueId = yield selectStore("articles", articleId, "issueId")
  const magazineName = yield selectStore("issues", issueId, "magazineName")
  const eventParams = {
    title: magazineName
  }
  if (magazineName) {
    gtmLogEvent(gtmEvents.UNBOOKMARK_ACTION, eventParams)
  } else {
    yield take(issuesArticlesPattern)
    yield unbookmarkEvent(action, count - 1)
  }
}

export function* watchUnbookmarkEvent() {
  yield takeEvery(DELETE_BOOKMARK, unbookmarkEvent)
}

function telecomBindingEvent(action) {
  const viaLut = {
    smsConfirmationCode: "簡訊驗證碼",
    smsLinkToken: "簡訊連結"
  }
  const { telecom } = action.payload.params
  const via = viaLut[action.payload.params.via]
  const eventParams = { bindingVia: via }

  switch (telecom) {
    case "aptg":
      gtmLogEvent(gtmEvents.APTG_BINDING, eventParams)
      break
    case "tstar":
      gtmLogEvent(gtmEvents.TSTAR_BINDING, eventParams)
      break
    case "smartone":
      gtmLogEvent(gtmEvents.SMARTONE_BINDING)
      break
    default:
      break
  }
}

export function* watchTelecomBindingEvent() {
  yield takeEvery(LOG_TELECOM_BINDING, telecomBindingEvent)
}

function* openArticleEvent(action, count = 10) {
  if (count <= 0) return

  const { params } = action.payload
  const { articleId } = params
  const issueId = yield selectStore("articles", articleId, "issueId")
  params.issueId = issueId

  const issue = yield selectStore("issues", issueId)
  if (issue) {
    const previewReason = yield articleNeedPreviewReason(articleId, issueId)
    if (previewReason) {
      yield sendOpenPreviewArticleEvent(params, previewReason)
    } else {
      yield all([sendOpenArticleEvent(params), call(logBranchViewItem)])
    }
  } else {
    yield take([
      REQUEST_ISSUE_SUCCESS,
      REQUEST_ARTICLE_SUCCESS,
      REQUEST_CURATION_POST_ARTICLES_SUCCESS,
      REQUEST_ISSUE_ARTICLES_SUCCESS
    ])
    yield openArticleEvent(action, count - 1)
  }
}

export function* watchOpenArticleEvent() {
  yield takeEvery(LOG_OPEN_ARTICLE, openArticleEvent)
}

function* sendOpenArticleEvent(params) {
  const eventParams = yield getArticleEventParams(params)
  gtmLogEvent(gtmEvents.OPEN_ARTICLE, eventParams)
}

function* sendOpenPreviewArticleEvent(params, previewReason) {
  const articleEventParams = yield getArticleEventParams(params)
  const eventParams = { ...articleEventParams, preview_reason: previewReason }
  gtmLogEvent(gtmEvents.OPEN_PREVIEW_ARTICLE, eventParams)
}

function* articleNeedPreviewReason(articleId, issueId) {
  const isDesktop = getMql("desktop").matches
  if (!isDesktop) return "download app"

  const user = yield selectStore("user", "current")
  if (!user) return "not log in"

  const isAdultIssue = yield selectStore("issues", issueId, "isAdult")
  if (user.get("adult") !== 1 && isAdultIssue) return "adult content"

  let articleAccessToken = yield selectStore("articleAccessTokens", articleId)
  let validToken = false
  if (!articleAccessToken) {
    try {
      const api = new FetchArticleAccessTokenApi({ articleId })
      const response = yield call(api.call)
      const { token } = response.data
      validToken = token ? true : false
    } catch (e) {
      validToken = false
    }
  } else {
    validToken =
      !articleAccessToken.get("error") &&
      articleAccessToken.get("expiredAt") > getNow()
  }
  if (!validToken) {
    return user.get("emailConfirmation") ? "not vip" : "not email confirmed"
  }

  return null
}

function* getArticleEventParams(params) {
  const { articleId, issueId } = params
  const readingMode = params.mode
  const history = yield selectStore("state", "history")
  const source = trackingSource(history)

  const articleParams = yield getArticleParams(articleId, issueId)
  const readerParams = yield getReaderParams()
  const locationSearch = yield selectStore("state", "locationSearch")
  const utmParams = getUtmParams(locationSearch)
  return {
    source,
    reading_mode: readingMode,
    ...articleParams,
    ...readerParams,
    ...utmParams
  }
}

function* getUserVipStatus() {
  const vipPeriods = yield fetchAndReturnVipPeriods()
  const freeArticleQuota = yield fetchAndReturnFreeArticleQuota()

  return getTrackingVipStatus(
    fromJS(vipPeriods || []),
    fromJS(freeArticleQuota || {})
  )
}

// need to make sure article and issue object exists
function* getArticleParams(articleId, issueId) {
  const articleName = yield selectStore("articles", articleId, "title")
  const issueName = yield selectStore("issues", issueId, "name")
  const titleName = yield selectStore("issues", issueId, "magazineName")
  const title = yield selectStore("issues", issueId, "magazineId")

  return {
    article_name: articleName,
    article_id: articleId,
    issue_name: issueName,
    issue_id: issueId,
    title_name: titleName,
    title: title
  }
}

function* getReaderParams() {
  const readerVipStatus = yield getUserVipStatus()
  const readerIsLogin = yield selectStore("user", "current")
  return {
    reader_vip_status: readerVipStatus,
    reader_is_login: readerIsLogin ? true : false
  }
}

// noted that types of curation post id and curation channel id are differrnt
function* openCurationPostEvent(action, count = 10) {
  if (count <= 0) return

  let { postId } = action.payload
  postId = parseInt(postId, 10)

  const channelId = yield selectStore(
    "curationPosts",
    postId,
    "curationChannelId"
  )

  const channel = channelId
    ? yield selectStore("curationChannels", channelId.toString())
    : undefined

  if (channelId && channel) {
    const curationParams = yield getCurationPostParams(postId)
    const readerParams = yield getReaderParams()
    const eventParams = { ...curationParams, ...readerParams }
    gtmLogEvent(gtmEvents.OPEN_CURATION_POST, eventParams)
  } else {
    yield take([
      REQUEST_CURATION_POST_SUCCESS,
      REQUEST_CURATION_CHANNEL_SUCCESS,
      REQUEST_CURATION_CHANNELS_SUCCESS,
      REQUEST_CURATION_CHANNEL_POSTS_SUCCESS
    ])
    yield openCurationPostEvent(action, count - 1)
  }
}

export function* watchOpenCurationPostEvent() {
  yield takeEvery(LOG_OPEN_CURATION_POST, openCurationPostEvent)
}

function* openCurationPostArticleEvent(action, count = 10) {
  if (count <= 0) return

  let { postId, articleId } = action.payload
  postId = parseInt(postId, 10)

  const issueId = yield selectStore("articles", articleId, "issueId")
  const issue = issueId ? yield selectStore("issues", issueId) : undefined
  const channelId = yield selectStore(
    "curationPosts",
    postId,
    "curationChannelId"
  )

  const channel = channelId
    ? yield selectStore("curationChannels", channelId.toString())
    : undefined

  if (issue && channel) {
    const curationParams = yield getCurationPostParams(postId)
    const articleParams = yield getArticleParams(articleId, issueId)
    const readerParams = yield getReaderParams()
    const eventParams = { ...curationParams, ...articleParams, ...readerParams }
    gtmLogEvent(gtmEvents.OPEN_CURATION_POST_ARTICLE, eventParams)
  } else {
    yield take([
      REQUEST_CURATION_POST_SUCCESS,
      REQUEST_CURATION_CHANNEL_SUCCESS,
      REQUEST_CURATION_CHANNELS_SUCCESS,
      REQUEST_CURATION_CHANNEL_POSTS_SUCCESS,
      REQUEST_CURATION_POST_ARTICLES_SUCCESS,
      REQUEST_ARTICLE_SUCCESS,
      REQUEST_ISSUE_SUCCESS
    ])
    yield openCurationPostArticleEvent(action, count - 1)
  }
}

export function* watchOpenCurationPostArticleEvent() {
  yield takeEvery(LOG_OPEN_CURATION_POST_ARTICLE, openCurationPostArticleEvent)
}

// need to make sure curation post and curation channel object exists
function* getCurationPostParams(postId) {
  const channelId = yield selectStore(
    "curationPosts",
    postId,
    "curationChannelId"
  )
  const channelName = yield selectStore(
    "curationChannels",
    `${channelId}`,
    "name"
  )
  const postTitle = yield selectStore("curationPosts", postId, "title")

  return {
    channel_id: channelId,
    channel_name: channelName,
    post_id: postId,
    post_title: postTitle
  }
}

function* openRecommendArticleEvent(action, count = 10) {
  if (count <= 0) return

  const { articleId, recommendedBy } = action.payload
  const issueId = yield selectStore("articles", articleId, "issueId")
  const issue = yield selectStore("issues", issueId)
  if (issue) {
    const articleParams = yield getArticleParams(articleId, issueId)
    const eventParams = { ...articleParams, recommended_by: recommendedBy }
    gtmLogEvent(gtmEvents.OPEN_RECOMMEND_ARTICLE, eventParams)
  } else {
    yield take([
      REQUEST_ISSUE_SUCCESS,
      REQUEST_ARTICLE_SUCCESS,
      REQUEST_CURATION_POST_ARTICLES_SUCCESS,
      REQUEST_ISSUE_ARTICLES_SUCCESS
    ])
    yield openRecommendArticleEvent(action, count - 1)
  }
}

export function* watchOpenRecommendArticleEvent() {
  yield takeEvery(LOG_OPEN_RECOMMEND_ARTICLE, openRecommendArticleEvent)
}

function* upgradeTryPurchaseEvent(action) {
  const { paymentType } = action.payload
  const state = yield selectStore("state", "history")
  const source = trackingSource(state)
  const locationSearch = yield selectStore("state", "locationSearch")
  const utmParams = getUtmParams(locationSearch)
  const eventParams = {
    source,
    ...utmParams,
    payment_type: paymentType
  }
  gtmLogEvent(gtmEvents.UPGRADE_TRY_PURCHASE, eventParams)
}

export function* watchUpgradeTryPurchaseEvent() {
  yield takeEvery(LOG_UPGRADE_TRY_PURCHASE, upgradeTryPurchaseEvent)
}

function* upgradePlanSelectedEvent(action) {
  const { paymentType, product } = action.payload
  const params = yield getProductParams(product)
  const eventParams = { ...params, payment_type: paymentType }
  gtmLogEvent(gtmEvents.UPGRADE_PLAN_SELECTED, eventParams)
}

export function* watchUpgradePlanSelectedEvent() {
  yield takeEvery(LOG_UPGRADE_PLAN_SELECTED, upgradePlanSelectedEvent)
}

function* upgradeSuccessEvent(action) {
  const { product } = action.payload
  const state = yield selectStore("state", "history")
  const source = trackingSource(state)
  const locationSearch = yield selectStore("state", "locationSearch")
  const utmParams = getUtmParams(locationSearch)
  const productParams = yield getProductParams(product)
  const eventParams = {
    upgrade_plan: product.name,
    source,
    ...utmParams,
    ...productParams
  }
  gtmLogEvent(gtmEvents.UPGRADE_SUCCESS, eventParams)

  logBranchPurchase(product)
}

export function* watchUpgradeSuccessEvent() {
  yield takeEvery(LOG_UPGRADE_SUCCESS, upgradeSuccessEvent)
}

function getProductParams(product) {
  const plan = product.name
  const cost = product.avgPrice
  const discountCode = product.hasPromotionCodes
    ? product._braintreePlan
        .get("promotionCodes")
        .toJS()
        .map(code => code.id)
        .join()
    : ""
  return {
    plan,
    cost,
    discount_code: discountCode
  }
}

function upgradeTryUsePromotionCodeEvent(action) {
  const { discountCode } = action.payload
  const eventParams = { discount_code: discountCode }
  gtmLogEvent(eventParams.UPGRADE_TRY_USE_PROMOTION_CODE, eventParams)
}

export function* watchUpgradeTryUsePromotionCodeEvent() {
  yield takeEvery(
    LOG_UPGRADE_TRY_USE_PROMOTION_CODE,
    upgradeTryUsePromotionCodeEvent
  )
}

function* upgradeCouponCodeEvent(action) {
  const { coupon, code } = action.payload
  const state = yield selectStore("state", "history")
  const source = trackingSource(state)
  const vipLength = Math.round((coupon.end_at - coupon.begin_at) / 86400)
  const eventParams = {
    upgrade_plan: vipLength,
    coupon_code: code,
    source
  }
  gtmLogEvent(gtmEvents.UPGRADE_COUPON, eventParams)
}

export function* watchUpgradeCouponCodeEvent() {
  yield takeEvery(LOG_UPGRADE_COUPON, upgradeCouponCodeEvent)
}

function* bannerClickEvent(action) {
  const { banner } = action.payload
  const state = yield selectStore("state", "history")
  const source = trackingSource(state, true)
  const eventParams = {
    action_type: banner.get("actionType") || "no_action",
    banner_id: banner.get("id"),
    utm_campaign: banner.get("utmCampaign") || undefined,
    utm_source: banner.get("utmSource") || undefined,
    utm_medium: banner.get("utmMedium") || undefined,
    source
  }

  gtmLogEvent(gtmEvents.BANNER_CLICK, eventParams)
}

export function* watchBannerClickEvent() {
  yield takeEvery(LOG_BANNER_CLICK, bannerClickEvent)
}

function* searchEvent(action) {
  const { key } = action.payload
  const prevKey = yield selectStore("search", "prevKey")
  const eventParams = { term: key }
  if (key !== prevKey) {
    gtmLogEvent(gtmEvents.SEARCH, eventParams)
  }
}

export function* watchSearchEvent() {
  yield takeEvery(CHANGE_SEARCH_KEY, searchEvent)
}

function searchResultMagazineClickEvent(action) {
  const { magazine } = action.payload
  const magazineName = magazine.get("name")
  const eventParams = { magazine_name: magazineName }
  gtmLogEvent(gtmEvents.SEARCH_RESULT_MAGAZINE_CLICK, eventParams)
}

export function* watchSearchResultMagazineClickEvent() {
  yield takeEvery(
    LOG_SEARCH_RESULT_MAGAZINE_CLICK,
    searchResultMagazineClickEvent
  )
}

function* searchResultArticleClickEvent(action, count = 10) {
  if (count <= 0) return

  const { articleId } = action.payload
  const issueId = yield selectStore("articles", articleId, "issueId")
  const articleTitle = yield selectStore("articles", articleId, "title")
  const issue = yield selectStore("issues", issueId)
  if (issue) {
    const eventParams = {
      magazine_name: issue.get("magazineName"),
      article_title: articleTitle
    }
    gtmLogEvent(gtmEvents.SEARCH_RESULT_ARTICLE_CLICK, eventParams)
  } else {
    yield take([
      REQUEST_ISSUE_SUCCESS,
      REQUEST_ARTICLE_SUCCESS,
      REQUEST_ISSUE_ARTICLES_SUCCESS
    ])
    yield searchResultArticleClickEvent(action, count - 1)
  }
}

export function* watchSearchResultArticleClickEvent() {
  yield takeEvery(
    LOG_SEARCH_RESULT_ARTICLE_CLICK,
    searchResultArticleClickEvent
  )
}

function* freeMagazineIntroDisplayEvent(action, count = 10) {
  if (count <= 0) return

  const currentFreeIssueId = yield selectStore("freeIssues", "current")
  const magazineName = yield selectStore(
    "issues",
    currentFreeIssueId,
    "magazineName"
  )

  if (currentFreeIssueId && magazineName) {
    const state = yield selectStore("state", "history")
    const source = trackingSource(state)
    const eventParams = {
      issue_id: currentFreeIssueId,
      title_name: magazineName,
      source: source
    }
    gtmLogEvent(gtmEvents.FREE_MAGAZINE_INTRO_DISPLAY, eventParams)
  } else {
    yield take([
      REQUEST_CURRENT_FREE_ISSUE_SUCCESS,
      REQUEST_ISSUE_SUCCESS,
      REQUEST_ARTICLE_SUCCESS,
      REQUEST_ISSUE_ARTICLES_SUCCESS
    ])
    yield freeMagazineIntroDisplayEvent(action, count - 1)
  }
}

export function* watchFreeMagazineIntroDisplayEvent() {
  yield takeEvery(
    LOG_FREE_MAGAZINE_INTRO_DISPLAY,
    freeMagazineIntroDisplayEvent
  )
}

function* freeMagazineIntroActionClickEvent(action) {
  const { actionType } = action.payload
  const currentFreeIssueId = yield selectStore("freeIssues", "current")
  const eventParams = {
    issue_id: currentFreeIssueId,
    action_type: actionType
  }
  gtmLogEvent(gtmEvents.FREE_MAGAZINE_INTRO_ACTION_CLICK, eventParams)
}

export function* watchFreeMagazineIntroActionClickEvent() {
  yield takeEvery(
    LOG_FREE_MAGAZINE_INTRO_ACTION_CLICK,
    freeMagazineIntroActionClickEvent
  )
}

function* recommendQuestionAskedEvent(action, count = 5) {
  if (count <= 0) return

  const { node, step } = action.payload
  const magazineId = node.get("magazineId")
  yield put(onRequestMagazine(magazineId))
  const magazineName = yield selectStore("magazines", magazineId, "name")
  if (magazineName) {
    const state = yield selectStore("state", "history")
    const source = trackingSource(state, false, true)
    const eventParams = {
      title_name: magazineName,
      tier: step,
      source
    }
    gtmLogEvent(gtmEvents.RECOMMEND_QUESTION_ASKED, eventParams)
  } else {
    yield take(magazinesPattern)
    yield recommendQuestionAskedEvent(action, count - 1)
  }
}

export function* watchRecommendQuestionAskedEvent() {
  yield takeEvery(LOG_RECOMMEND_QUESTION_ASKED, recommendQuestionAskedEvent)
}

function recommendListEnterEvent(action) {
  let { recommendList } = action.payload
  recommendList = recommendList ? recommendList.toJS() : []
  const eventParams = { recommend_list: recommendList }
  gtmLogEvent(gtmEvents.RECOMMEND_LIST_ENTER, eventParams)
}

export function* watchRecommendListEnterEvent() {
  yield takeEvery(LOG_RECOMMEND_LIST_ENTER, recommendListEnterEvent)
}

function* shareArticleEvent(action, count = 10) {
  if (count <= 0) return

  const articleId = yield selectStore(
    "articleReadEvents",
    "currentReadingInfo",
    "currentArticleId"
  )
  const articleName = yield selectStore("articles", articleId, "title")
  const issueId = yield selectStore("articles", articleId, "issueId")
  const magazineId = yield selectStore("issues", issueId, "magazineId")
  const magazineName = yield selectStore("issues", issueId, "magazineName")
  if (magazineId) {
    const state = yield selectStore("state", "history")
    const source = trackingSource(state)
    const eventParams = {
      title: magazineId,
      title_name: magazineName,
      article_id: articleId,
      article_name: articleName,
      source
    }
    gtmLogEvent(gtmEvents.SHARE_ARTICLE, eventParams)
  } else {
    yield take(issuesArticlesPattern)
    yield shareArticleEvent(action, count - 1)
  }
}

export function* watchShareArticleEvent() {
  yield takeEvery(LOG_SHARE_ARTICLE, shareArticleEvent)
}

function* changeStateEvent(action) {
  const { state } = action.payload
  const historyState = yield selectStore("state", "history")
  let currentParams = yield selectStore("state", "currentParams")
  currentParams = currentParams || {}
  const source = trackingSource(historyState)

  const locationSearch = yield selectStore("state", "locationSearch")
  const utmParams = getUtmParams(locationSearch)
  let eventParams = {}

  switch (state) {
    case SIGN_UP_STATE:
      eventParams = { source, ...utmParams }
      gtmLogEvent(gtmEvents.ENTER_SIGN_UP, eventParams)
      break
    case SIGN_IN_STATE:
      eventParams = { source, ...utmParams }
      gtmLogEvent(gtmEvents.ENTER_SIGN_IN, eventParams)
      break
    case SINGLE_CATEGORY:
      yield addEnterSingleCategoryEvent(currentParams.categoryId, source)
      break
    case SINGLE_ISSUE:
      yield addEnterSingleIssueEvent(currentParams.issueId, source)
      break
    case SINGLE_MAGAZINE:
      yield addEnterSingleMagazineEvent(currentParams.magazineId, source)
      break
    case MY_COLLECT_STATE:
      eventParams = { source }
      gtmLogEvent(gtmEvents.ENTER_MY_COLLECT, eventParams)
      break
    default:
      break
  }
}

function* addEnterSingleCategoryEvent(categoryId, source) {
  const library = yield selectStore("categories", categoryId, "library")
  const category = yield selectStore("categories", categoryId, "name")
  // need not to retry because componentDidUpdate will handle undefined case and retry
  if (category) {
    const eventParams = { source, library, category }
    gtmLogEvent(gtmEvents.ENTER_SINGLE_CATEGORY, eventParams)
  }
}

function* addEnterSingleIssueEvent(issueId, source, count = 10) {
  if (count <= 0) return

  const issueName = yield selectStore("issues", issueId, "name")
  const magazineId = yield selectStore("issues", issueId, "magazineId")
  const magazineName = yield selectStore("issues", issueId, "magazineName")
  if (issueName) {
    const eventParams = {
      issue_id: issueId,
      issue_name: issueName,
      title_id: magazineId,
      title_name: magazineName,
      source
    }
    gtmLogEvent(gtmEvents.ENTER_SINGLE_ISSUE, eventParams)
  } else {
    yield take(issuesPattern)
    yield addEnterSingleIssueEvent(issueId, source, count - 1)
  }
}

function* addEnterSingleMagazineEvent(magazineId, source, count = 10) {
  if (count <= 0) return

  const magazineName = yield selectStore("magazines", magazineId, "name")
  if (magazineName) {
    const eventParams = {
      title_id: magazineId,
      title_name: magazineName,
      source
    }
    gtmLogEvent(gtmEvents.ENTER_SINGLE_MAGAZINE, eventParams)
  } else {
    yield take(magazinesPattern)
    yield addEnterSingleMagazineEvent(magazineId, source, count - 1)
  }
}

export function* watchChangeStateV0Event() {
  yield takeEvery(CHANGE_STATE, changeStateEvent)
}

function* downloadAppEvent() {
  const state = yield selectStore("state", "history")
  const source = trackingSource(state, true, true)

  const deviceWidth = getDeviceWidth()

  const eventParams = { source, device_width: deviceWidth }
  gtmLogEvent(gtmEvents.DOWNLOAD_APP, eventParams)
}

export function* watchDownloadAppEvent() {
  yield takeEvery(LOG_DOWNLOAD_APP, downloadAppEvent)
}

function* resendConfirmationEmailEvent() {
  const state = yield selectStore("state", "history")
  const source = trackingSource(state, true, true)
  const eventParams = { source }
  gtmLogEvent(gtmEvents.RESEND_CONFIRMATION_EMAIL, eventParams)
}

export function* watchResendConfirmationEmailEvent() {
  yield takeEvery(LOG_RESEND_CONFIRMATION_EMAIL, resendConfirmationEmailEvent)
}

function* widgetBarClickEvent(action, count = 10) {
  if (count <= 0) return

  const { btnType } = action.payload
  const articleId = yield selectStore(
    "articleReadEvents",
    "currentReadingInfo",
    "currentArticleId"
  )
  const issueId = yield selectStore("articles", articleId, "issueId")
  if (articleId && issueId) {
    let eventParams = yield getArticleParams(articleId, issueId)
    const fitReading = yield selectStore("articles", articleId, "hasFitReading")

    // RESCALE_PDF_PAGE, RESIZE_ARTICLE_FONT, FULL_SCREEN_READING, OPEN_TOC
    if (btnType === "RESCALE_PDF_PAGE") {
      eventParams = { ...eventParams, has_fit_reading: fitReading }
    }
    gtmLogEvent(btnType, eventParams)
  } else {
    yield take(issuesArticlesPattern)
    yield widgetBarClickEvent(action, count - 1)
  }
}

export function* watchWidgetBarClickEvent() {
  yield takeEvery(LOG_WIDGET_BAR_CLICK, widgetBarClickEvent)
}

function* getIbonPincodeSuccessEvent(action) {
  const { product } = action.payload
  if (!product) return

  const plan = product.get("name")
  let price = parseFloat(product.get("amount"))
  const freq = parseFloat(product.get("billingFrequency"))
  const cost = Math.round(price / freq)

  const locationSearch = yield selectStore("state", "locationSearch")
  const utmParams = getUtmParams(locationSearch)

  const state = yield selectStore("state", "history")
  const source = trackingSource(state)

  const eventParams = {
    source,
    plan,
    cost,
    ...utmParams,
    upgrade_plan: plan
  }

  gtmLogEvent(gtmEvents.GET_IBON_PINCODE_SUCCESS, eventParams)
}

export function* watchGetIbonPincodeSuccessEvent() {
  yield takeEvery(LOG_GET_IBON_PINCODE_SUCCESS, getIbonPincodeSuccessEvent)
}

function* playAudioEvent(action) {
  const { audio } = action.payload
  const audioName = audio.get("title")

  const articleId = yield selectStore(
    "articleReadEvents",
    "currentReadingInfo",
    "currentArticleId"
  )
  const issueId = yield selectStore("articles", articleId, "issueId")
  const articleParams = yield getArticleParams(articleId, issueId)
  const eventParams = { ...articleParams, audio_name: audioName }
  gtmLogEvent(gtmEvents.PLAY_AUDIO, eventParams)
}

export function* watchPlayAudioEvent() {
  yield takeEvery(LOG_PLAY_AUDIO, playAudioEvent)
}

function buyGiftingEvent() {
  gtmLogEvent(gtmEvents.BUY_GIFTING)
}

export function* watchBuyGiftingEvent() {
  yield takeEvery(LOG_BUY_GIFTING, buyGiftingEvent)
}

function* headerClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()
  const eventParams = {
    reader_vip_status: readerVipStatus
  }

  const { position } = action.payload
  switch (position) {
    case HEADER_POPULAR_MAGAZINE:
      gtmLogEvent(gtmEvents.HEADER_POPULAR_MAGAZINE_CLICK, eventParams)
      break
    case HEADER_HK_MAGAZINE:
      gtmLogEvent(gtmEvents.HEADER_HK_MAGAZINE_CLICK, eventParams)
      break
    case HEADER_REDEEM:
      gtmLogEvent(gtmEvents.HEADER_REDEEM_CLICK, eventParams)
      break
    case HEADER_SUBSCRIBE:
      gtmLogEvent(gtmEvents.HEADER_SUBSCRIBE_CLICK, eventParams)
      break
    case HEADER_GIFTING:
      gtmLogEvent(gtmEvents.HEADER_GIFTING_CLICK, eventParams)
      break
    case HEADER_PROFILE_MY_FOLLOW:
      gtmLogEvent(gtmEvents.HEADER_PROFILE_MY_FOLLOW_CLICK, eventParams)
      break
    case HEADER_PROFILE_BOOKMARK:
      gtmLogEvent(gtmEvents.HEADER_PROFILE_BOOKMARK_CLICK, eventParams)
      break
    case HEADER_PROFILE_RECENT_READ:
      gtmLogEvent(gtmEvents.HEADER_PROFILE_RECENT_READ_CLICK, eventParams)
      break
    case HEADER_PROFILE_SUBSCRIBE:
      gtmLogEvent(gtmEvents.HEADER_PROFILE_SUBSCRIBE_CLICK, eventParams)
      break
    case HEADER_PROFILE_GIFTING:
      gtmLogEvent(gtmEvents.HEADER_PROFILE_GIFTING_CLICK, eventParams)
      break
    case HEADER_PROFILE_MEMBERSHIP:
      gtmLogEvent(gtmEvents.HEADER_PROFILE_MEMBERSHIP_CLICK, eventParams)
      break
    case HEADER_PROFILE_LOGOUT:
      gtmLogEvent(gtmEvents.HEADER_PROFILE_LOGOUT_CLICK, eventParams)
      break
    default:
      break
  }
}

export function* watchHeaderClickEvent() {
  yield throttle(1000, LOG_HEADER_CLICK, headerClickEvent)
}

function* previewArticleDownloadAppClickEvent() {
  const readerVipStatus = yield getUserVipStatus()

  const state = yield selectStore("state", "history")
  const source = trackingSource(state, true, true)

  const locationSearch = yield selectStore("state", "locationSearch")
  const utmParams = getUtmParams(locationSearch)

  const eventParams = {
    reader_vip_status: readerVipStatus,
    source,
    ...utmParams
  }
  gtmLogEvent(gtmEvents.PREVIEW_ARTICLE_DOWNLOAD_APP_CLICK, eventParams)
}

export function* watchPreviewArticleDownloadAppClickEvent() {
  yield takeEvery(
    LOG_PREVIEW_ARTICLE_DOWNLOAD_APP_CLICK,
    previewArticleDownloadAppClickEvent
  )
}

function* headerNavigationCategoryClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()
  const readerIsLogin = yield selectStore("user", "current")

  const { categoryId } = action.payload
  const categoryName = yield selectStore("categories", categoryId, "name")
  const library = yield selectStore("categories", categoryId, "library")

  const eventParams = {
    reader_vip_status: readerVipStatus,
    reader_is_login: readerIsLogin ? true : false,
    category_id: categoryId,
    category_name: library + "-" + categoryName
  }

  gtmLogEvent(gtmEvents.HEADER_NAVIGATION_CATEGORY_CLICK, eventParams)
}

export function* watchHeaderNavigationCategoryClickEvent() {
  yield throttle(
    1000,
    LOG_HEADER_NAVIGATION_CATEGORY_CLICK,
    headerNavigationCategoryClickEvent
  )
}

function* headerNavigationLibraryClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()
  const readerIsLogin = yield selectStore("user", "current")

  const { libraryId } = action.payload
  const libraries = yield selectStore("libraries")
  const libraryName = libraries
    .find(library => library.get("id") === libraryId)
    ?.get("name")

  const eventParams = {
    reader_vip_status: readerVipStatus,
    reader_is_login: readerIsLogin ? true : false,
    library_id: libraryId,
    library_name: libraryName
  }

  gtmLogEvent(gtmEvents.HEADER_NAVIGATION_LIBRARY_CLICK, eventParams)
}

export function* watchHeaderNavigationLibraryClickEvent() {
  yield throttle(
    1000,
    LOG_HEADER_NAVIGATION_LIBRARY_CLICK,
    headerNavigationLibraryClickEvent
  )
}

function startTrialShowEvent(action) {
  const { userId, source } = action.payload
  const eventParams = { user_id: userId, source }
  gtmLogEvent(gtmEvents.START_TRIAL_SHOW, eventParams)
}

export function* watchStartTrialShowEvent() {
  yield throttle(1000, LOG_START_TRIAL_SHOW, startTrialShowEvent)
}

function startTrialClickEvent(action) {
  const { userId, plan } = action.payload
  const eventParams = { user_id: userId, plan }
  gtmLogEvent(gtmEvents.START_TRIAL_CLICK, eventParams)
}

export function* watchStartTrialClickEvent() {
  yield throttle(1000, LOG_START_TRIAL_CLICK, startTrialClickEvent)
}

function openReportEvent(action) {
  const { userId, width } = action.payload
  const eventParams = { user_id: userId, width }
  gtmLogEvent(gtmEvents.OPEN_REPORT, eventParams)
}

export function* watchOpenReportEvent() {
  yield throttle(1000, LOG_OPEN_REPORT, openReportEvent)
}

function clickSurveyEvent(action) {
  const { userId } = action.payload
  const eventParams = { user_id: userId }
  gtmLogEvent(gtmEvents.CLICK_SURVEY, eventParams)
}

export function* watchClickSurveyEvent() {
  yield throttle(1000, LOG_CLICK_SURVEY, clickSurveyEvent)
}

function* viewPricePage(action) {
  const locationSearch = yield selectStore("state", "locationSearch")
  const utmParams = getUtmParams(locationSearch)

  const eventParams = { ...utmParams }
  gtmLogEvent(gtmEvents.VIEW_PRICE_PAGE, eventParams)
}

export function* watchViewPricePageEvent() {
  yield throttle(1000, LOG_VIEW_PRICE_PAGE, viewPricePage)
}

function clickSubscribe(action) {
  const { planFrequency, discountCode } = action.payload
  const eventParams = { plan: planFrequency, discount_code: discountCode }
  gtmLogEvent(gtmEvents.CLICK_SUBSCRIBE, eventParams)
}

export function* watchClickSubscribeEvent() {
  yield throttle(1000, LOG_CLICK_SUBSCRIBE, clickSubscribe)
}

function viewPaymentPage(action) {
  const eventParams = {}
  gtmLogEvent(gtmEvents.VIEW_PAYMENT_PAGE, eventParams)
}

export function* watchViewPaymentPageEvent() {
  yield throttle(1000, LOG_VIEW_PAYMENT_PAGE, viewPaymentPage)
}

function* confirmSubscribe(action) {
  const locationSearch = yield selectStore("state", "locationSearch")
  const utmParams = getUtmParams(locationSearch)
  const { planFrequency, discountCode } = action.payload
  const eventParams = {
    ...utmParams,
    plan: planFrequency,
    discount_code: discountCode
  }
  gtmLogEvent(gtmEvents.CONFIRM_SUBSCRIBE, eventParams)
}

export function* watchConfirmSubscribeEvent() {
  yield throttle(1000, LOG_CONFIRM_SUBSCRIBE, confirmSubscribe)
}

function viewThankYouPage(action) {
  const eventParams = {}
  gtmLogEvent(gtmEvents.VIEW_THANK_YOU_PAGE, eventParams)
}

export function* watchViewThankYouPageEvent() {
  yield throttle(1000, LOG_VIEW_THANK_YOU_PAGE, viewThankYouPage)
}
