import {
  REQUEST_BRAINTREE_PAYMENT_METHODS,
  REQUEST_BRAINTREE_PAYMENT_METHODS_SUCCESS,
  CHANGE_BRAINTREE_PAYMENT_METHODS,
  CHANGE_BRAINTREE_PAYMENT_METHODS_SUCCESS,
  CHANGE_BRAINTREE_PAYMENT_METHODS_ERROR
} from "constants/actionTypes"

export const onRequestBraintreePaymentMethods = userId => {
  return {
    type: REQUEST_BRAINTREE_PAYMENT_METHODS,
    payload: { userId }
  }
}

export const onRequestBraintreePaymentMethodsSuccess = paymentMethods => {
  return {
    type: REQUEST_BRAINTREE_PAYMENT_METHODS_SUCCESS,
    payload: { paymentMethods }
  }
}

export const onChangeBraintreePaymentMethods = (userId, nonce) => {
  return {
    type: CHANGE_BRAINTREE_PAYMENT_METHODS,
    payload: { userId, nonce }
  }
}

export const onChangeBraintreePaymentMethodsSuccess = paymentMethod => {
  return {
    type: CHANGE_BRAINTREE_PAYMENT_METHODS_SUCCESS,
    payload: { paymentMethod }
  }
}

export const onChangeBraintreePaymentMethodsError = error => {
  return {
    type: CHANGE_BRAINTREE_PAYMENT_METHODS_ERROR,
    payload: { error }
  }
}
