const baseUrl = window.apiBase
  ? window.apiBase
  : process.env.REACT_APP_KPI_ENDPOINT

export const getMainImageSrc = (articleId, size = "medium") => {
  return `${baseUrl}/articles/${articleId}/main_image?size=${size}&crop=9:5`
}

export const getImageSrc = (imageId, size = "medium") => {
  return `${baseUrl}/images/${imageId}/${size}`
}

export const getVideoSrc = (video, accessToken, userId) => {
  const videoId = video.get("id")
  return `${baseUrl}/videos/${videoId}/playback?reader_id=${userId}&access_token=${accessToken}`
}

export const getAudioSrc = (audio, accessToken, userId) => {
  const audioId = audio.get("id")
  return `${baseUrl}/audios/${audioId}/playback?reader_id=${userId}&access_token=${accessToken}`
}

export const getVideoThumbnail = video => {
  const videoId = video.get("id")
  return `${baseUrl}/videos/${videoId}/thumbnail/medium`
}

export const getIssueCover = (issueId, size = "medium") => {
  return `${baseUrl}/magazines/${issueId}/cover/${size}`
}

export const getMagazineLatestIssueCover = (magazineId, size = "medium") => {
  return `${baseUrl}/titles/${magazineId}/current_issue/cover/${size}`
}
