import axios from "axios"
import store from "../../store"
import { LOG_OUT } from "constants/actionTypes"

class KonoApi {
  constructor(subUrl = "") {
    this.baseUrl = window.apiBase
      ? window.apiBase
      : process.env.REACT_APP_KPI_ENDPOINT
    this.subUrl = subUrl
    this.call = this.call.bind(this)

    const token = store.getState().getIn(["user", "current", "token"])
    let headers = token ? { "X-Kono-Token": token } : {}

    if (store.getState().getIn(["webP", "acceptWebP"])) {
      headers["X-Kono-WebP"] = 1
    }
    this.axios = axios.create({ headers })
    this.axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          console.log("HTTP status 401")
          store.dispatch({ type: LOG_OUT })
        } else {
          throw error
        }
      }
    )
  }

  get url() {
    return this.baseUrl + this.subUrl
  }

  call() {}
}

export default KonoApi
