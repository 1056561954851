import styled from "styled-components/macro"

export const NoStyledH1 = styled.h1`
  font-weight: inherit;
  margin: 0;
  font-size: inherit;
`

export const NoStyledH2 = styled.h2`
  font-weight: inherit;
  margin: 0;
  font-size: inherit;
`
