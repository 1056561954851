import axios from "axios"

class FetchRecommendationTreeApi {
  call() {
    const treeUrl =
      "https://d3xwm9x3ez74b.cloudfront.net/follow_recommend/oobe_category_based.json"

    return axios.get(treeUrl)
  }
}

export default FetchRecommendationTreeApi
