import { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { onSetK4lError } from "actions/k4l"

class InformationNotInUrl extends Component {
  componentDidMount() {
    this.init()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.init()
    }
  }

  init() {
    if (!this.props.k4lCurrentLibrary) {
      this.props.onSetK4lError(true)
      this.props.history.replace("/")
    }
  }

  render() {
    return this.props.k4lCurrentLibrary
      ? this.props.render({ ...this.props })
      : null
  }
}

const mapStateToProps = state => ({
  k4lCurrentLibrary: state.getIn(["k4lLibraries", "current"])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onSetK4lError }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InformationNotInUrl))
