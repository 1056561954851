import KonoApi from "../KonoApi"

class FetchMagazineYearReadingStatusesApi extends KonoApi {
  constructor(data) {
    super(`/titles/${data.magazineId}/years/${data.year}/reading_statuses`)
  }

  call(params) {
    return this.axios.get(this.url, { params: { user_id: params.userId } })
  }
}

export default FetchMagazineYearReadingStatusesApi
