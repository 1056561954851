import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs"
import { takeEvery, call } from "redux-saga/effects"
import { UPDATE_TASK_RECORDS } from "constants/actionTypes"
import FetchTaskRecordsConfigApi from "apis/FetchTaskRecordsConfigApi"

function* updateTaskRecords(action) {
  const { userId, taskId } = action.payload
  if (!userId || !taskId) return

  try {
    const fetchTaskRecordsConfigApi = new FetchTaskRecordsConfigApi({
      userId
    })

    const response = yield call(fetchTaskRecordsConfigApi.call)
    const config = response.data

    const sqsClient = new SQSClient({
      credentials: {
        accessKeyId: config.access_key_id,
        secretAccessKey: config.secret_access_key,
        sessionToken: config.session_token
      },
      region: config.region
    })

    const taskRecordsMessage = {
      user_id: userId,
      task_id: taskId,
      count: 1
    }

    const sqsParams = {
      MessageBody: JSON.stringify(taskRecordsMessage),
      QueueUrl: config.queue_url
    }

    const sqsCommand = new SendMessageCommand(sqsParams)

    sqsClient
      .send(sqsCommand)
      .then(data => {})
      .catch(err => console.log(err))
  } catch (e) {
    console.log(e)
  }
}

export function* watchOnUpdateTaskRecords() {
  yield takeEvery(UPDATE_TASK_RECORDS, updateTaskRecords)
}
