import KonoApi from "../KonoApi"
import store from "../../store"

class FetchArticleTextV2UrlApi extends KonoApi {
  constructor(data) {
    super(`/articles/${data.articleId}/text.json`)
    this.accessToken = data.accessToken
  }

  call() {
    const currentUserId = store.getState().getIn(["user", "current", "id"])
    return this.axios.get(this.url, {
      params: {
        reader_id: currentUserId,
        access_token: this.accessToken,
        redirect: 0
      }
    })
  }
}

export default FetchArticleTextV2UrlApi
