import KonoApi from "../KonoApi"

class TstarBindTokenApi extends KonoApi {
  constructor() {
    super(`/tstar_orders/bind`)
  }

  call(params) {
    return this.axios.post(this.url, {
      user_id: params.userId,
      tstar_token: params.token
    })
  }
}

export default TstarBindTokenApi
