import {
  REQUEST_READING_PROGRESSES,
  REQUEST_READING_PROGRESSES_SUCCESS,
  DELETE_READING_PROGRESS,
  DELETE_READING_PROGRESS_SUCCESS,
  DELETE_READING_PROGRESS_ERROR,
  DELETE_READING_PROGRESSES,
  DELETE_READING_PROGRESSES_SUCCESS,
  DELETE_READING_PROGRESSES_ERROR,
  UPDATE_READING_PROGRESS,
  REQUEST_ISSUE_READING_PROGRESSES,
  REQUEST_ISSUE_READING_PROGRESSES_SUCCESS,
  RESET_READING_PROGRESSES_FETCHING_STATUS
} from "constants/actionTypes"

export const onRequestReadingProgresses = (userId, limit = 20) => ({
  type: REQUEST_READING_PROGRESSES,
  payload: { userId, limit }
})

export const onRequestReadingProgressesSuccess = readingProgresses => ({
  type: REQUEST_READING_PROGRESSES_SUCCESS,
  payload: { readingProgresses }
})

export const onDeleteReadingProgress = (userId, issueId) => ({
  type: DELETE_READING_PROGRESS,
  payload: { userId, issueId }
})

export const onDeleteReadingProgressSuccess = issueId => ({
  type: DELETE_READING_PROGRESS_SUCCESS,
  payload: { issueId }
})

export const onDeleteReadingProgressError = issueId => ({
  type: DELETE_READING_PROGRESS_ERROR,
  payload: { issueId }
})

export const onDeleteReadingProgresses = userId => ({
  type: DELETE_READING_PROGRESSES,
  payload: { userId }
})

export const onDeleteReadingProgressesSuccess = () => ({
  type: DELETE_READING_PROGRESSES_SUCCESS
})

export const onDeleteReadingProgressesError = () => ({
  type: DELETE_READING_PROGRESSES_ERROR
})

export const onUpdateReadingProgress = (userId, readingInfo) => {
  return {
    type: UPDATE_READING_PROGRESS,
    payload: { userId, readingInfo }
  }
}

export const onRequestIssueReadingProgresses = (userId, issueId) => {
  return {
    type: REQUEST_ISSUE_READING_PROGRESSES,
    payload: { userId, issueId }
  }
}

export const onRequestIssueReadingProgressesSuccess = (
  issueId,
  readingProgresses
) => ({
  type: REQUEST_ISSUE_READING_PROGRESSES_SUCCESS,
  payload: { issueId, readingProgresses }
})

export const onResetReadingProgressesFetchingStatus = () => ({
  type: RESET_READING_PROGRESSES_FETCHING_STATUS
})
