import styled from "styled-components"

const SideTabMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${props => (props.isOpen ? "0" : "-320px")};
  z-index: 9000;
  transition: left ${props => props.s} ease-out;
  display: flex;
  flex-flow: column nowrap;
  width: 320px;
  height: 100vh;
  background-color: #ffffff;
  overflow-y: scroll;
  box-sizing: border-box;
  padding-bottom: 20px;
`

export default SideTabMenuWrapper
