import KonoApi from "../KonoApi"

class FetchAllK4lLibrariesApi extends KonoApi {
  constructor() {
    super(`/k4l/libraries`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchAllK4lLibrariesApi
