import {
  REQUEST_CURATION_CHANNEL,
  REQUEST_CURATION_CHANNEL_SUCCESS,
  REQUEST_CURATION_CHANNELS,
  REQUEST_CURATION_CHANNELS_SUCCESS,
  REQUEST_CURATION_CHANNEL_POSTS,
  REQUEST_CURATION_CHANNEL_POSTS_SUCCESS
} from "constants/actionTypes"

export const onRequestCurationChannel = (curationChannelId, onError) => {
  return {
    type: REQUEST_CURATION_CHANNEL,
    payload: { curationChannelId, onError }
  }
}

export const onRequestCurationChannelSuccess = curationChannel => {
  return {
    type: REQUEST_CURATION_CHANNEL_SUCCESS,
    payload: { curationChannel }
  }
}

export const onRequestCurationChannels = () => {
  return {
    type: REQUEST_CURATION_CHANNELS
  }
}

export const onRequestCurationChannelsSuccess = curationChannels => {
  return {
    type: REQUEST_CURATION_CHANNELS_SUCCESS,
    payload: { curationChannels }
  }
}

export const onRequestCurationChannelPosts = curationChannelId => {
  return {
    type: REQUEST_CURATION_CHANNEL_POSTS,
    payload: { curationChannelId }
  }
}

export const onRequestCurationChannelPostsSuccess = (
  curationChannelId,
  curationChannelPosts
) => {
  return {
    type: REQUEST_CURATION_CHANNEL_POSTS_SUCCESS,
    payload: {
      curationChannelId,
      curationChannelPosts
    }
  }
}
