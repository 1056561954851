import styled from "styled-components/macro"

const NavigationsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
`

export default NavigationsWrapper
