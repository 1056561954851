import { REQUEST_GIFTING_PLAN_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const formatGiftingPlan = plan => {
  return {
    id: plan.id,
    desktopImage: plan.desktop_image,
    mobileImage: plan.mobile_image,
    vipType: plan.vip_type,
    vipLength: plan.vip_length,
    amount: plan.amount
  }
}

const planArrayReducer = (arr, plan) => {
  arr[plan.id] = formatGiftingPlan(plan)
  return arr
}

const reducerMap = new Map([
  [
    REQUEST_GIFTING_PLAN_SUCCESS,
    (state, action) => {
      const giftingPlans = action.payload.giftingPlans
      const giftingPlansMap = giftingPlans.reduce(planArrayReducer, {})
      return fromJS(giftingPlansMap)
    }
  ]
])

const giftingPlans = handleActions(reducerMap, initialState)
export default giftingPlans
