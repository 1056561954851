import React from "react"
import styled from "styled-components/macro"
import media from "utilities/media"

const Table = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 4px;
  border: solid 1px #e6e6e6;
`

const Row = styled.div`
  width: calc(100% - 15px);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: solid 1px #e6e6e6;
  padding: 14px 0 14.5px 15px;

  ${media.mobile`
    flex-flow: column nowrap;
    align-items: flex-start;
    padding: 14px 0 10.5px 15px;
  `};

  &:last-child {
    border-bottom: none;
  }
`

const SpanCell = styled.div`
  width: 1px;
  flex-grow: 128;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #222222;

  ${media.mobile`
    width: 100%;
  `};
`

const ItemCell = styled.div`
  width: 1px;
  flex-grow: 222;
  font-size: 14px;
  line-height: 1.57;
  color: #222222;

  ${media.mobile`
    margin-top: 10px;
    width: 100%;
  `};
`

function BillingTable(props) {
  const { rowData } = props

  return (
    <Table>
      {rowData.map(d => (
        <Row key={d[0]}>
          <SpanCell>{d[0]}</SpanCell>
          <ItemCell>{d[1]}</ItemCell>
        </Row>
      ))}
    </Table>
  )
}

export default BillingTable
