import KonoApi from "../KonoApi"

class FetchBookmarksStatisticsApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/bookmarks/statistics`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchBookmarksStatisticsApi
