const prodEndPoint = "https://api.thekono.com/KPI2"

export const notInProd = ["staging", "development"].includes(
  process.env.REACT_APP_CONFIG_FILE
)

export const setProdEndPoint = func => {
  if (notInProd) {
    window.localStorage.setItem("endPoint", prodEndPoint)
    func()
  }
}

export const trySetApiBase = () => {
  window.apiBase = window.localStorage.getItem("endPoint")
    ? window.localStorage.getItem("endPoint")
    : window.apiBase
}

export const removeEndPoint = func => {
  if (notInProd) {
    window.localStorage.removeItem("endPoint")
    func()
  }
}

export const ifDbChangedToProd = () => {
  return window.localStorage.getItem("endPoint") === prodEndPoint
}
