import KonoApi from "../KonoApi"

class FetchCategoryGroupsApi extends KonoApi {
  constructor(data) {
    super(`/book_lists/${data.bookListId}/category_groups`)
  }

  call(params) {
    return this.axios.get(this.url, {
      params: { language: params.language }
    })
  }
}

export default FetchCategoryGroupsApi
