import { throttle, takeEvery } from "redux-saga/effects"
import { fromJS } from "immutable"
import {
  LOG_HOME_MY_FOLLOW_CLICK,
  LOG_HOME_RECOMMEND_MAGAZINE_CLICK,
  LOG_HOME_WEEKLY_ISSUE_CLICK,
  LOG_HOME_LATEST_ISSUE_CLICK,
  LOG_HOME_CURATION_CHANNEL_CLICK,
  LOG_HOME_CURATION_POST_CLICK,
  LOG_HOME_CURATION_POST_ARTICLE_CLICK,
  LOG_HOME_RECOMMEND_ARTICLE_CLICK,
  LOG_HOME_POPULAR_MAGAZINE_CLICK,
  LOG_HOME_POPULAR_ARTICLE_CLICK,
  LOG_HOME_MY_BOOKMARK_CLICK,
  LOG_ENTER_HOME
} from "constants/actionTypes"
import * as gtmEvents from "constants/trackingEvents"
import { selectStore } from "utilities/storeCheck"
import { gtmLogEvent } from "utilities/gtmDataLayer"
import { getTrackingVipStatus } from "utilities/vipPeriodMap"
import { fetchAndReturnVipPeriods } from "../vipPeriodsSaga"
import { fetchAndReturnFreeArticleQuota } from "../freeArticleQuotaSaga"
import { getReadingPlatform } from "utilities/osCheck"

function* getUserVipStatus() {
  const vipPeriods = yield fetchAndReturnVipPeriods()
  const freeArticleQuota = yield fetchAndReturnFreeArticleQuota()

  return getTrackingVipStatus(
    fromJS(vipPeriods || []),
    fromJS(freeArticleQuota || {})
  )
}

function* homeMyFollowClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()
  const { magazineId } = action.payload
  const issueId = yield selectStore("magazinesLatestIssues", magazineId)
  const issueName = yield selectStore("issues", issueId, "name")
  const magazineName = yield selectStore("issues", issueId, "magazineName")

  const eventParams = {
    issue_id: issueId,
    issue_name: issueName,
    title: magazineId,
    title_name: magazineName,
    reader_vip_status: readerVipStatus
  }
  gtmLogEvent(gtmEvents.HOME_OPEN_MY_FOLLOW_TITLE, eventParams)
}

export function* watchHomeMyFollowClickEvent() {
  yield throttle(1000, LOG_HOME_MY_FOLLOW_CLICK, homeMyFollowClickEvent)
}

function* homeRecommendMagazineClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()
  const { magazineId } = action.payload
  const issueId = yield selectStore("magazinesLatestIssues", magazineId)
  const issueName = yield selectStore("issues", issueId, "name")
  const magazineName = yield selectStore("issues", issueId, "magazineName")

  const eventParams = {
    issue_id: issueId,
    issue_name: issueName,
    title: magazineId,
    title_name: magazineName,
    reader_vip_status: readerVipStatus
  }
  gtmLogEvent(gtmEvents.HOME_OPEN_RECOMMEND_TITLE, eventParams)
}

export function* watchHomeRecommendMagazineClickEvent() {
  yield throttle(
    1000,
    LOG_HOME_RECOMMEND_MAGAZINE_CLICK,
    homeRecommendMagazineClickEvent
  )
}

function* homeWeeklyIssueClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()

  const { issueId } = action.payload
  const issueName = yield selectStore("issues", issueId, "name")
  const magazineId = yield selectStore("issues", issueId, "magazineId")
  const magazineName = yield selectStore("issues", issueId, "magazineName")
  const eventParams = {
    issue_id: issueId,
    issue_name: issueName,
    title: magazineId,
    title_name: magazineName,
    reader_vip_status: readerVipStatus
  }
  gtmLogEvent(gtmEvents.HOME_OPEN_WEEKLY_ISSUE, eventParams)
}

export function* watchHomeWeeklyIssueClickEvent() {
  yield throttle(1000, LOG_HOME_WEEKLY_ISSUE_CLICK, homeWeeklyIssueClickEvent)
}

function* homeLatestIssueClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()

  const { issueId } = action.payload
  const issueName = yield selectStore("issues", issueId, "name")
  const magazineId = yield selectStore("issues", issueId, "magazineId")
  const magazineName = yield selectStore("issues", issueId, "magazineName")
  const eventParams = {
    issue_id: issueId,
    issue_name: issueName,
    title: magazineId,
    title_name: magazineName,
    reader_vip_status: readerVipStatus
  }

  gtmLogEvent(gtmEvents.HOME_OPEN_LATEST_ISSUE, eventParams)
}

export function* watchHomeLatestIssueClickEvent() {
  yield throttle(1000, LOG_HOME_LATEST_ISSUE_CLICK, homeLatestIssueClickEvent)
}

function* homeCurationChannelClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()

  const { channelId } = action.payload
  const eventParams = {
    channel_id: channelId,
    reader_vip_status: readerVipStatus
  }
  gtmLogEvent(gtmEvents.HOME_OPEN_CURATION_CHANNEL, eventParams)
}

export function* watchHomeCurationChannelClickEvent() {
  yield throttle(
    1000,
    LOG_HOME_CURATION_CHANNEL_CLICK,
    homeCurationChannelClickEvent
  )
}

function* homeCurationPostClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()

  const { postId } = action.payload
  const channelId = yield selectStore(
    "curationPosts",
    postId,
    "curationChannelId"
  )
  const postTitle = yield selectStore("curationPosts", postId, "title")

  const eventParams = {
    channel_id: channelId,
    post_id: postId,
    post_title: postTitle,
    reader_vip_status: readerVipStatus
  }

  gtmLogEvent(gtmEvents.HOME_OPEN_CURATION_POST, eventParams)
}

export function* watchHomeCurationPostClickEvent() {
  yield throttle(1000, LOG_HOME_CURATION_POST_CLICK, homeCurationPostClickEvent)
}

function* homeCurationPostArticleClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()

  const { postId, articleId } = action.payload
  const channelId = yield selectStore(
    "curationPosts",
    postId,
    "curationChannelId"
  )
  const postTitle = yield selectStore("curationPosts", postId, "title")

  const articleTitle = yield selectStore("articles", articleId, "title")
  const issueId = yield selectStore("articles", articleId, "issueId")
  const issueName = yield selectStore("issues", issueId, "name")
  const magazineId = yield selectStore("issues", issueId, "magazineId")
  const magazineName = yield selectStore("issues", issueId, "magazineName")

  const eventParams = {
    channel_id: channelId,
    post_id: postId,
    post_title: postTitle,
    article_id: articleId,
    article_name: articleTitle,
    issue_id: issueId,
    issue_name: issueName,
    title: magazineId,
    title_name: magazineName,
    reader_vip_status: readerVipStatus
  }

  gtmLogEvent(gtmEvents.HOME_OPEN_CURATION_POST_ARTICLE, eventParams)
}

export function* watchHomeCurationPostArticleClickEvent() {
  yield throttle(
    1000,
    LOG_HOME_CURATION_POST_ARTICLE_CLICK,
    homeCurationPostArticleClickEvent
  )
}

function* homeRecommendArticleClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()

  const { articleId } = action.payload

  const articleTitle = yield selectStore("articles", articleId, "title")
  const issueId = yield selectStore("articles", articleId, "issueId")
  const issueName = yield selectStore("issues", issueId, "name")
  const magazineId = yield selectStore("issues", issueId, "magazineId")
  const magazineName = yield selectStore("issues", issueId, "magazineName")

  const eventParams = {
    article_id: articleId,
    article_name: articleTitle,
    issue_id: issueId,
    issue_name: issueName,
    title: magazineId,
    title_name: magazineName,
    reader_vip_status: readerVipStatus
  }

  gtmLogEvent(gtmEvents.HOME_OPEN_RECOMMEND_ARTICLE, eventParams)
}

export function* watchHomeRecommendArticleClickEvent() {
  yield throttle(
    1000,
    LOG_HOME_RECOMMEND_ARTICLE_CLICK,
    homeRecommendArticleClickEvent
  )
}

function* homePopularMagazineClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()

  const { magazineId } = action.payload
  const issueId = yield selectStore("magazinesLatestIssues", magazineId)
  const issueName = yield selectStore("issues", issueId, "name")
  const magazineName = yield selectStore("issues", issueId, "magazineName")

  const eventParams = {
    issue_id: issueId,
    issue_name: issueName,
    title: magazineId,
    title_name: magazineName,
    reader_vip_status: readerVipStatus
  }

  gtmLogEvent(gtmEvents.HOME_OPEN_POPULAR_TITLE, eventParams)
}

export function* watchHomePopularMagazineClickEvent() {
  yield throttle(
    1000,
    LOG_HOME_POPULAR_MAGAZINE_CLICK,
    homePopularMagazineClickEvent
  )
}

function* homePopularArticleClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()

  const { articleId } = action.payload
  const articleTitle = yield selectStore("articles", articleId, "title")
  const issueId = yield selectStore("articles", articleId, "issueId")
  const issueName = yield selectStore("issues", issueId, "name")
  const magazineId = yield selectStore("issues", issueId, "magazineId")
  const magazineName = yield selectStore("issues", issueId, "magazineName")

  const eventParams = {
    article_id: articleId,
    article_name: articleTitle,
    issue_id: issueId,
    issue_name: issueName,
    title: magazineId,
    title_name: magazineName,
    reader_vip_status: readerVipStatus
  }

  gtmLogEvent(gtmEvents.HOME_OPEN_POPULAR_ARTICLE, eventParams)
}

export function* watchHomePopularArticleClickEvent() {
  yield throttle(
    1000,
    LOG_HOME_POPULAR_ARTICLE_CLICK,
    homePopularArticleClickEvent
  )
}

function* homeMyBookmarkClickEvent(action) {
  const readerVipStatus = yield getUserVipStatus()

  const { articleId } = action.payload
  const articleTitle = yield selectStore("articles", articleId, "title")
  const issueId = yield selectStore("articles", articleId, "issueId")
  const magazineId = yield selectStore("issues", issueId, "magazineId")
  const magazineName = yield selectStore("issues", issueId, "magazineName")

  const eventParams = {
    article_id: articleId,
    article_name: articleTitle,
    title: magazineId,
    title_name: magazineName,
    reader_vip_status: readerVipStatus
  }

  gtmLogEvent(gtmEvents.HOME_OPEN_MY_BOOKMARK, eventParams)
}

export function* watchHomeMyBookmarkClickEvent() {
  yield throttle(1000, LOG_HOME_MY_BOOKMARK_CLICK, homeMyBookmarkClickEvent)
}

function* enterHomeEvent(action) {
  const readerVipStatus = yield getUserVipStatus()
  const { source } = action.payload
  const platform = getReadingPlatform()

  const eventParams = {
    reader_vip_status: readerVipStatus,
    source,
    platform
  }

  gtmLogEvent(gtmEvents.ENTER_HOME, eventParams)
}

export function* watchEnterHomeEvent() {
  yield takeEvery(LOG_ENTER_HOME, enterHomeEvent)
}
