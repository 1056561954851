import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs"
import { put, takeEvery, call, all } from "redux-saga/effects"
import {
  onRequestReadingProgressesSuccess,
  onDeleteReadingProgressSuccess,
  onDeleteReadingProgressError,
  onDeleteReadingProgressesSuccess,
  onDeleteReadingProgressesError,
  onRequestIssueReadingProgressesSuccess
} from "actions/readingProgresses"
import { onOpenReadingProgressPrompt } from "actions/readingProgressPrompts"
import {
  REQUEST_READING_PROGRESSES,
  DELETE_READING_PROGRESS,
  DELETE_READING_PROGRESSES,
  UPDATE_READING_PROGRESS,
  REQUEST_ISSUE_READING_PROGRESSES
} from "constants/actionTypes"
import { isStoreExpired } from "utilities/storeCheck"
import { READING_PROGRESS_PROMPTS_TTL } from "constants/ttl"
import FetchReadingProgressesApi from "apis/FetchReadingProgressesApi"
import DeleteReadingProgressApi from "apis/DeleteReadingProgressApi"
import DeleteReadingProgressesApi from "apis/DeleteReadingProgressesApi"
import FetchReadingProgressesConfigApi from "apis/FetchReadingProgressesConfigApi"
import FetchReadingRecordsConfigApi from "apis/FetchReadingRecordsConfigApi"
import ApiLock, { withLock } from "utilities/ApiLock"
import { onOpenToast } from "actions/toasts"
import toastTypes from "constants/toastTypes"

function* fetchReadingProgresses(action) {
  const { userId, limit } = action.payload

  try {
    const api = new FetchReadingProgressesApi({ userId })
    const response = yield call(api.call, { limit })
    yield put(onRequestReadingProgressesSuccess(response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestReadingProgresses() {
  yield takeEvery(
    REQUEST_READING_PROGRESSES,
    withLock(fetchReadingProgresses, true)
  )
}

function* deleteReadingProgress(action) {
  const { userId, issueId } = action.payload
  const lock = yield call(ApiLock.acquireLock, "change-reading-progress", 2000)

  try {
    const api = new DeleteReadingProgressApi({ userId, issueId })
    yield call(api.call)
    yield all([
      put(onDeleteReadingProgressSuccess(issueId)),
      put(onOpenToast(toastTypes.READING_PROGRESS_DELETED))
    ])
  } catch (e) {
    yield put(onDeleteReadingProgressError(issueId))
    console.log(e)
  } finally {
    lock.release()
  }
}

export function* watchDeleteReadingProgress() {
  yield takeEvery(DELETE_READING_PROGRESS, deleteReadingProgress)
}

function* deleteReadingProgresses(action) {
  const { userId } = action.payload
  const lock = yield call(ApiLock.acquireLock, "change-reading-progress", 2000)

  try {
    const api = new DeleteReadingProgressesApi({ userId })
    yield call(api.call)
    yield all([
      put(onDeleteReadingProgressesSuccess()),
      put(onOpenToast(toastTypes.READING_PROGRESS_DELETED))
    ])
  } catch (e) {
    yield put(onDeleteReadingProgressesError())
    console.log(e)
  } finally {
    lock.release()
  }
}

export function* watchDeleteReadingProgresses() {
  yield takeEvery(DELETE_READING_PROGRESSES, deleteReadingProgresses)
}

function* updateReadingProgress(action) {
  const { userId, readingInfo } = action.payload
  const lock = yield call(ApiLock.acquireLock, "change-reading-progress", 2000)

  try {
    const fetchReadingProgressesConfigApi = new FetchReadingProgressesConfigApi(
      { userId }
    )
    const response = yield call(fetchReadingProgressesConfigApi.call)
    const config = response.data

    const sqsClient = new SQSClient({
      credentials: {
        accessKeyId: config.access_key_id,
        secretAccessKey: config.secret_access_key,
        sessionToken: config.session_token
      },
      region: config.region
    })

    const readingProgressesMessage = {
      user_id: userId,
      issue_id: readingInfo.issueId,
      device_id: "web",
      read_format: readingInfo.readFormat,
      page_number:
        readingInfo.readFormat === "pdf" ? readingInfo.pageNumber : undefined,
      article_id: readingInfo.articleId,
      read_at: readingInfo.readAt
    }

    const sqsParams = {
      MessageBody: JSON.stringify(readingProgressesMessage),
      QueueUrl: config.queue_url
    }

    const sqsCommand = new SendMessageCommand(sqsParams)

    sqsClient
      .send(sqsCommand)
      .then(data => {})
      .catch(err => console.log(err))

    yield updateReadingRecords(action)
  } catch (e) {
    console.log(e)
  } finally {
    lock.release()
  }
}

export function* watchUpdateReadingProgress() {
  yield takeEvery(UPDATE_READING_PROGRESS, updateReadingProgress)
}

function* updateReadingRecords(action) {
  const { userId, readingInfo } = action.payload
  const fetchReadingRecordsConfigApi = new FetchReadingRecordsConfigApi({
    userId
  })

  const response = yield call(fetchReadingRecordsConfigApi.call)
  const config = response.data

  const sqsClient = new SQSClient({
    credentials: {
      accessKeyId: config.access_key_id,
      secretAccessKey: config.secret_access_key,
      sessionToken: config.session_token
    },
    region: config.region
  })

  const readingRecordsMessage = {
    user_id: userId,
    issue_id: readingInfo.issueId,
    article_id: readingInfo.articleId,
    read_at: readingInfo.readAt
  }

  const sqsParams = {
    MessageBody: JSON.stringify(readingRecordsMessage),
    QueueUrl: config.queue_url
  }

  const sqsCommand = new SendMessageCommand(sqsParams)

  sqsClient
    .send(sqsCommand)
    .then(data => {})
    .catch(err => console.log("Error", err))
}

function* fetchIssueReadingProgresses(action) {
  const { userId, issueId } = action.payload

  try {
    const isExpired = yield* isStoreExpired(
      READING_PROGRESS_PROMPTS_TTL,
      "readingProgressPrompts",
      issueId
    )
    if (!isExpired) {
      return
    }

    const api = new FetchReadingProgressesApi({ userId })
    const response = yield call(api.call, { issue_id: issueId })
    yield all([
      put(onRequestIssueReadingProgressesSuccess(issueId, response.data)),
      put(onOpenReadingProgressPrompt(issueId))
    ])
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestIssueReadingProgress() {
  yield takeEvery(
    REQUEST_ISSUE_READING_PROGRESSES,
    withLock(fetchIssueReadingProgresses, true)
  )
}
