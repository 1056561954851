import {
  REQUEST_SUBSCRIPTION_INFO,
  REQUEST_SUBSCRIPTION_INFO_SUCCESS
} from "constants/actionTypes"

export const onRequestSubscriptionInfo = (userId, throttling = false) => {
  return {
    type: REQUEST_SUBSCRIPTION_INFO,
    payload: { userId, throttling }
  }
}

export const onRequestSubscriptionInfoSuccess = info => {
  return {
    type: REQUEST_SUBSCRIPTION_INFO_SUCCESS,
    payload: { info }
  }
}
