// This index.js will be copied to src/ every execute. Care for the relative path.
// Same situation as robots.txt and favicon.ico. See scripts in package.json.
// These must be the first lines in src/index.js
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import TagManager from "react-gtm-module"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import { default as K4lApp } from "apps/k4l/components/App"
import "./index.css"
import configureStore from "./configureStore"
import { HelmetProvider } from "react-helmet-async"
import "./i18n"

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayerName: "gtmDataLayer"
}

TagManager.initialize(tagManagerArgs)

async function init() {
  const store = await configureStore()
  ReactDOM.render(
    <Provider store={store}>
      <HelmetProvider>
        <K4lApp />
      </HelmetProvider>
    </Provider>,
    document.getElementById("root")
  )

  serviceWorkerRegistration.register({
    onUpdate: registration => {
      const waitingServiceWorker = registration.waiting

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", event => {
          if (event.target.state === "activated") {
            window.location.reload()
          }
        })
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
      }
    }
  })
}

init()
