import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestSubscriptionInfoSuccess } from "actions/subscriptionInfo"
import { REQUEST_SUBSCRIPTION_INFO } from "constants/actionTypes"
import { SUBSCRIPTION_INFO_TTL } from "constants/ttl"
import SubscriptionInfoApi from "apis/SubscriptionInfoApi"
import {
  isStoreExisted,
  isStoreExpired,
  selectStore
} from "utilities/storeCheck"
import camelcaseKeys from "camelcase-keys"

function* fetchSubscriptionInfo(action) {
  try {
    const { userId, throttling } = action.payload

    if (throttling) {
      const isExisted = yield* isStoreExisted("subscriptionInfo")
      const isExpired = yield* isStoreExpired(
        SUBSCRIPTION_INFO_TTL,
        "subscriptionInfo"
      )
      if (isExisted && !isExpired) {
        return
      }
    }

    const api = new SubscriptionInfoApi({ userId })
    const response = yield call(api.call)
    yield put(onRequestSubscriptionInfoSuccess(response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestSubscriptionInfo() {
  yield takeEvery(REQUEST_SUBSCRIPTION_INFO, fetchSubscriptionInfo)
}

export function* fetchAndReturnSubscriptionInfo() {
  try {
    let subscriptionInfo = {}
    const isExisted = yield* isStoreExisted("subscriptionInfo")
    const isExpired = yield* isStoreExpired(
      SUBSCRIPTION_INFO_TTL,
      "subscriptionInfo"
    )
    if (isExisted && !isExpired) {
      subscriptionInfo = yield selectStore("subscriptionInfo")
      subscriptionInfo = subscriptionInfo.toJS()
    } else {
      const userId = yield* selectStore("user", "current", "id")
      if (!userId) return
      const subscriptionInfoApi = new SubscriptionInfoApi({ userId })
      const response = yield call(subscriptionInfoApi.call)
      subscriptionInfo = response.data
      yield put(onRequestSubscriptionInfoSuccess(subscriptionInfo))
      subscriptionInfo = camelcaseKeys(subscriptionInfo, { deep: true })
    }
    return subscriptionInfo
  } catch (e) {
    console.log(e)
  }
}
