import { CHANGE_STATE, CHANGE_STATE_VIA } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({
  current: "",
  currentParams: {},
  locationSearch: "",
  history: []
})

const reducerMap = new Map([
  [
    CHANGE_STATE,
    (state, action) => {
      const search = window.location.search
        ? window.location.search
        : state.get("locationSearch")
      return state
        .set("current", action.payload.state)
        .set("currentParams", action.payload.stateParams)
        .set("locationSearch", search)
        .set(
          "history",
          state
            .get("history")
            .push(
              fromJS({
                state: action.payload.state,
                params: action.payload.stateParams,
                url: action.payload.url
              })
            )
            .slice(-5)
        )
    }
  ],
  [
    CHANGE_STATE_VIA,
    (state, action) => {
      const via = action.payload.via
      const latestState = state.getIn(["history", -1])
      return latestState ? state.setIn(["history", -1, "via"], via) : state
    }
  ]
])

export default handleActions(reducerMap, initialState)
