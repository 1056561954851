import axios from "axios"
import KonoApi from "../KonoApi"

class AptgSendSmsApi extends KonoApi {
  constructor() {
    super(`/aptg_users/send_sms`)
  }

  call(params) {
    return axios.post(this.url, {
      phone_number: params.phoneNumber
    })
  }
}

export default AptgSendSmsApi
