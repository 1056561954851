import {
  UPDATE_FACEBOOK_STATUS,
  UPDATE_FACEBOOK_STATUS_SUCCESS,
  OPEN_PAGE_WIDGET_BAR,
  CLOSE_PAGE_WIDGET_BAR,
  TOGGLE_DEVICE_SEARCH_BAR,
  OPEN_ARTICLE_WIDGET_BAR,
  CLOSE_ARTICLE_WIDGET_BAR,
  TOGGLE_SIDE_TAB_MENU,
  CHANGE_STATE,
  CLOSE_SIDE_TAB_MENU,
  OPEN_READING_PROGRESS_PROMPT_TOAST,
  CLOSE_READING_PROGRESS_PROMPT_TOAST,
  SEND_SQS_MESSAGES_SUCCESS,
  TEMPORARY_LOGIN,
  SET_YEARLY_REPORT,
  CLOSE_YEARLY_REPORT,
  UPDATE_AUDIO_VOLUME,
  UPDATE_AUDIO_PLAYBACK,
  CLOSE_AUDIO_TOAST,
  CLOSE_TSTAR_VIP_TRANSFER_MODAL
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({
  facebookStatusTrigger: false,
  pageWidgetBar: { isOpen: true },
  deviceSearchBar: { isOpen: false },
  articleWidgetBar: { isOpen: true },
  sideTabMenu: { isOpen: false },
  readingProgressPromptToast: { isOpen: false },
  readingEventsConfig: {
    accessKeyId: undefined,
    secretAccessKey: undefined,
    region: undefined,
    sessionToken: undefined,
    expireAt: undefined,
    queueUrl: undefined
  },
  appView: { showHeader: true },
  yearlyReport: {
    hasReport: false,
    modalOpen: true,
    hasCalledReport: false
  },
  audio: {
    volume: 1,
    playback: 1,
    toast: true
  },
  tstarVipTransfer: {
    modalOpen: true
  }
})

const reducerMap = new Map([
  [
    UPDATE_FACEBOOK_STATUS,
    state => {
      return state.set("facebookStatusTrigger", true)
    }
  ],
  [
    UPDATE_FACEBOOK_STATUS_SUCCESS,
    state => {
      return state.set("facebookStatusTrigger", false)
    }
  ],
  [
    OPEN_PAGE_WIDGET_BAR,
    state => {
      return state.setIn(["pageWidgetBar", "isOpen"], true)
    }
  ],
  [
    CLOSE_PAGE_WIDGET_BAR,
    state => {
      return state.setIn(["pageWidgetBar", "isOpen"], false)
    }
  ],
  [
    TOGGLE_DEVICE_SEARCH_BAR,
    state => {
      return state.setIn(
        ["deviceSearchBar", "isOpen"],
        !state.getIn(["deviceSearchBar", "isOpen"])
      )
    }
  ],
  [
    OPEN_ARTICLE_WIDGET_BAR,
    state => {
      return state.setIn(["articleWidgetBar", "isOpen"], true)
    }
  ],
  [
    CLOSE_ARTICLE_WIDGET_BAR,
    state => {
      return state.setIn(["articleWidgetBar", "isOpen"], false)
    }
  ],
  [
    TOGGLE_SIDE_TAB_MENU,
    state => {
      return state.setIn(
        ["sideTabMenu", "isOpen"],
        !state.getIn(["sideTabMenu", "isOpen"])
      )
    }
  ],
  [
    CHANGE_STATE,
    state => {
      return state.setIn(["sideTabMenu", "isOpen"], false)
    }
  ],
  [
    CLOSE_SIDE_TAB_MENU,
    state => {
      return state.setIn(["sideTabMenu", "isOpen"], false)
    }
  ],
  [
    OPEN_READING_PROGRESS_PROMPT_TOAST,
    state => {
      return state.setIn(["readingProgressPromptToast", "isOpen"], true)
    }
  ],
  [
    CLOSE_READING_PROGRESS_PROMPT_TOAST,
    state => {
      return state.setIn(["readingProgressPromptToast", "isOpen"], false)
    }
  ],
  [
    SEND_SQS_MESSAGES_SUCCESS,
    (state, action) => {
      return state.set("readingEventsConfig", fromJS(action.payload.config))
    }
  ],
  [
    TEMPORARY_LOGIN,
    state => {
      return state.setIn(["appView", "showHeader"], false)
    }
  ],
  [
    SET_YEARLY_REPORT,
    (state, action) => {
      const { hasReport } = action.payload
      return state
        .setIn(["yearlyReport", "hasReport"], hasReport)
        .setIn(["yearlyReport", "hasCalledReport"], true)
    }
  ],
  [
    CLOSE_YEARLY_REPORT,
    state => {
      return state.setIn(["yearlyReport", "modalOpen"], false)
    }
  ],
  [
    UPDATE_AUDIO_VOLUME,
    (state, action) => {
      return state.setIn(["audio", "volume"], action.payload.volume)
    }
  ],
  [
    UPDATE_AUDIO_PLAYBACK,
    (state, action) => {
      return state.setIn(["audio", "playback"], action.payload.playback)
    }
  ],
  [
    CLOSE_AUDIO_TOAST,
    state => {
      return state.setIn(["audio", "toast"], false)
    }
  ],
  [
    CLOSE_TSTAR_VIP_TRANSFER_MODAL,
    state => {
      return state.setIn(["tstarVipTransfer", "modalOpen"], false)
    }
  ]
])

const general = handleActions(reducerMap, initialState)
export default general
