import styled from "styled-components/macro"
import media from "utilities/media"

const Subject = styled.div`
  width: 100%;
  line-height: 1.4em;
  color: #222222;
  font-weight: 500;
  ${media.desktop`
    font-size: 25px;
    margin-bottom: 15px;
  `};
  ${media.tablet`
    font-size: 18px;
    margin-bottom: 10px;
  `};
  ${media.mobile`
    font-size: 18px;
    margin-bottom: 10px;
  `};
`

export default Subject
