import { REQUEST_LATEST_ISSUES_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS([])

const reducerMap = new Map([
  [
    REQUEST_LATEST_ISSUES_SUCCESS,
    (state, action) => {
      const issues = action.payload.latestIssues
      return state.merge(issues.map(issue => issue.bid))
    }
  ]
])

export default handleActions(reducerMap, initialState)
