import React from "react"
import { useDispatch } from "react-redux"
import { branchDownloadAppLink } from "utilities/appLinks"
import { onLogDownloadApp } from "actions/trackingEvents"
import SideTabComponent from "components/container/SideTabComponent"
import { withTranslation } from "react-i18next"
import styled from "styled-components"

const SideTabRowExternalLink = styled.a`
  font-size: 16px;
  color: #222222;
  height: 48px;
  display: flex;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 16px;
`

function SideTabDownloadApp(props) {
  const dispatch = useDispatch()

  return (
    <SideTabComponent.SideTabDivWrapper noBottomBorder>
      <SideTabComponent.SideTabRow>
        <SideTabRowExternalLink
          href={branchDownloadAppLink()}
          onClick={() => {
            dispatch(onLogDownloadApp())
          }}
        >
          {props.t("DOWNLOAD_APP")}
        </SideTabRowExternalLink>
      </SideTabComponent.SideTabRow>
    </SideTabComponent.SideTabDivWrapper>
  )
}

export default withTranslation("container")(SideTabDownloadApp)
