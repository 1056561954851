import { put, takeEvery, takeLatest, call, all } from "redux-saga/effects"
import {
  onRequestBookmarksStatistics,
  onRequestBookmarksSuccess,
  onRequestBookmarksStatisticsSuccess,
  onCreateBookmarkSuccess,
  onCreateBookmarkError,
  onDeleteBookmarkSuccess,
  onDeleteBookmarkError
} from "actions/bookmarks"
import {
  onRequestBookmarkedArticlesSuccess,
  onRequestArticleSuccess
} from "actions/articles"
import { onOpenToast } from "actions/toasts"
import toastTypes from "constants/toastTypes"
import {
  REQUEST_BOOKMARKS,
  REQUEST_BOOKMARKS_STATISTICS,
  CREATE_BOOKMARK,
  DELETE_BOOKMARK
} from "constants/actionTypes"
import FetchBookmarkedArticlesApi from "apis/FetchBookmarkedArticlesApi"
import FetchBookmarksStatisticsApi from "apis/FetchBookmarksStatisticsApi"
import CreateBookmarkApi from "apis/CreateBookmarkApi"
import DeleteBookmarkApi from "apis/DeleteBookmarkApi"
import { selectStore } from "utilities/storeCheck"
import ApiLock, { withLock } from "utilities/ApiLock"

function simplifyBookmark(bookmark) {
  return {
    id: bookmark.id,
    article: { article_id: bookmark.article.article_id }
  }
}

function* fetchBookmarks(action) {
  const { userId, limit, offset } = action.payload

  try {
    const api = new FetchBookmarkedArticlesApi({ userId })
    const articles = yield* selectStore("bookmarks", "bookmarkedArticles")
    const storeCount = articles.count()
    let remainingToFetch = offset - storeCount
    let fetched = []
    let response, before

    if (storeCount && offset && remainingToFetch <= 0) {
      const article = articles.get(offset - 1)
      before = article ? article.get("bookmarkId") : null
      response = yield call(api.call, before, limit)
    } else if (limit + offset <= 100) {
      response = yield call(api.call, null, limit + offset)
    } else {
      before = storeCount ? articles.last().get("bookmarkId") : null
      response = yield call(api.call, before, 100)
      while (remainingToFetch > 0 && response.data.length) {
        yield put(
          onRequestBookmarksSuccess(response.data.map(simplifyBookmark))
        )
        response.data.forEach(bookmark => fetched.push(bookmark.id))
        before = fetched[fetched.length - 1]
        response = yield call(api.call, before, remainingToFetch)
        remainingToFetch -= response.data.length
      }

      if (response.data.length) {
        before = fetched[fetched.length - 1]
        response = yield call(api.call, before, limit)
      }
    }

    yield put(onRequestBookmarksSuccess(response.data))
    yield put(onRequestBookmarkedArticlesSuccess(response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestBookmarks() {
  yield takeEvery(REQUEST_BOOKMARKS, withLock(fetchBookmarks, true))
}

function* fetchBookmarksStatistics(action) {
  const { userId } = action.payload
  try {
    if (!ApiLock.getLock("change-bookmark").isLocked()) {
      const api = new FetchBookmarksStatisticsApi({ userId })
      let response = yield call(api.call)
      yield put(onRequestBookmarksStatisticsSuccess(response.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestBookmarksStatistics() {
  yield takeLatest(REQUEST_BOOKMARKS_STATISTICS, fetchBookmarksStatistics)
}

function* createBookmark(action) {
  const { userId, articleId } = action.payload
  const lock = yield call(ApiLock.acquireLock, "change-bookmark", 2000)

  try {
    const api = new CreateBookmarkApi({ userId, articleId })
    const response = yield call(api.call)
    if (response.data.length > 0) {
      yield all([
        put(onCreateBookmarkSuccess(response.data[0])),
        put(onOpenToast(toastTypes.BOOKMARKED))
      ])
      let data = response.data[0].article
      data.magazine = response.data[0].magazine
      yield put(onRequestArticleSuccess(data))
    }
  } catch (e) {
    yield put(onCreateBookmarkError(articleId))
    console.log(e)
  } finally {
    lock.release()
  }
  yield put(onRequestBookmarksStatistics(userId))
}

export function* watchCreateBookmark() {
  yield takeEvery(CREATE_BOOKMARK, createBookmark)
}

function* deleteBookmark(action) {
  const { userId, articleId } = action.payload
  const lock = yield call(ApiLock.acquireLock, "change-bookmark", 2000)

  try {
    const api = new DeleteBookmarkApi({ userId, articleId })
    yield call(api.call)
    yield all([
      put(onDeleteBookmarkSuccess(articleId)),
      put(onOpenToast(toastTypes.UNBOOKMARKED, { userId, articleId }))
    ])
  } catch (e) {
    yield put(onDeleteBookmarkError(articleId))
    console.log(e)
  } finally {
    lock.release()
  }
  yield put(onRequestBookmarksStatistics(userId))
}

export function* watchDeleteBookmark() {
  yield takeEvery(DELETE_BOOKMARK, deleteBookmark)
}
