import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { onAgreeCookie } from "actions/agreement"

import KonoUI from "components/presentational/KonoUI"
import { withTranslation } from "react-i18next"
import styled from "styled-components/macro"
import media from "utilities/media"

const FixBannerWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: ${props => (props.isOpen ? "0px" : "-500px")};
  background-color: #eee9e0;
  justify-content: center;
  align-items: space-between;
  z-index: 1500;
  transition: bottom 2.5s;
  ${props => (!props.isOpen ? "overflow: hidden" : "")};

  ${media.tablet`
    flex-flow: column nowrap;
    align-items: center;
    `};
  ${media.mobile`
    flex-flow: column wrap;
    align-items: center;
  `};
`

const ContentWrapper = styled.div`
  line-height: 1.58;
  color: #222222;

  ${media.desktop`
    width: 700px;
    margin: 27px 0;
    font-size: 12px;
  `};
  ${media.tablet`
    width: 460px;
    margin: 20px 0;
    font-size: 10px;
    text-align: center;
  `};
  ${media.mobile`
    width: 280px;
    margin: 20px 0;
    font-size: 10px;
    text-align: center;
  `};
`

const ButtonWrapper = styled.div`
  ${media.desktop`
    width: 220px;
    margin-top: 20px;
    margin-left: 20px;
  `};
  ${media.tablet`
    width: 460px;
    margin-bottom: 20px;
  `};
  ${media.mobile`
    width: 280px;
    margin-bottom: 20px;
  `};
`

const NoStyledLink = styled(Link)`
  color: inherit;
  text-decoration: underline;
`

const privacyHref = "/privacy"

const prolicyHref = "/policy"

function CookieBanner(props) {
  const dispatch = useDispatch()
  const agreeCookie = useSelector(state =>
    state.getIn(["agreement", "agreeCookie"])
  )
  const [display, setDisplay] = useState(!agreeCookie)

  useEffect(() => {
    if (agreeCookie) {
      setTimeout(() => setDisplay(false), 500)
    }
  }, [agreeCookie])

  // Use state.display so no need to render after it runs out of the screen
  return display ? (
    <FixBannerWrapper isOpen={!agreeCookie}>
      <ContentWrapper>
        {props.t("CONTENT_1")}
        <NoStyledLink to={privacyHref}>{props.t("PRIVACY")}</NoStyledLink>
        {props.t("CONTENT_2")}
        <NoStyledLink to={prolicyHref}>{props.t("POLICY")}</NoStyledLink>
        {props.t("CONTENT_3")}
      </ContentWrapper>
      <ButtonWrapper onClick={() => dispatch(onAgreeCookie())}>
        <KonoUI.Button
          color="#222222"
          backgroundColor="#fafafa"
          lgFontSize="13px"
          mdFontSize="12px"
          smFontSize="11px"
          mdHeight="44px"
          smHeight="35px"
        >
          {props.t("I_KNOW")}
        </KonoUI.Button>
      </ButtonWrapper>
    </FixBannerWrapper>
  ) : null
}

export default withTranslation("container")(CookieBanner)
