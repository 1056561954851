import { lazy } from "react"
import retry from "utilities/retry"

function lazyRetry(func) {
  return lazy(() => retry(() => func()))
}

const Home = lazyRetry(() => import("./Home"))
const Dashboard = lazyRetry(() => import("./Dashboard"))
const Landing = lazyRetry(() => import("./Landing"))
const Library = lazyRetry(() => import("./Library"))
const SingleCategory = lazyRetry(() => import("./SingleCategory"))
const SingleMagazine = lazyRetry(() => import("./SingleMagazine"))
const SingleIssue = lazyRetry(() => import("./SingleIssue"))
const About = lazyRetry(() => import("./About"))
const Jobs = lazyRetry(() => import("./Jobs"))
const News = lazyRetry(() => import("./News"))
const ContactUs = lazyRetry(() => import("./ContactUs"))
const KonoMagazineIntro = lazyRetry(() => import("./KonoMagazineIntro"))
const Search = lazyRetry(() => import("./Search"))
const MyFollow = lazyRetry(() => import("./MyFollow"))
const MyCollect = lazyRetry(() => import("./MyCollect"))
const WeeklyIssue = lazyRetry(() => import("./WeeklyIssue"))
const CurationPost = lazyRetry(() => import("./CurationPost"))
const CurationChannel = lazyRetry(() => import("./CurationChannel"))
const SignIn = lazyRetry(() => import("./SignIn"))
const SignUp = lazyRetry(() => import("./SignUp"))
const Oobe = lazyRetry(() => import("./Oobe"))
const RemindPassword = lazyRetry(() => import("./RemindPassword"))
const ResetPassword = lazyRetry(() => import("./ResetPassword"))
const ChangePassword = lazyRetry(() => import("./ChangePassword"))
const TStarIntro = lazyRetry(() => import("./TStarIntro"))
const TStarSuccess = lazyRetry(() => import("./TStarSuccess"))
const TStarBinding = lazyRetry(() => import("./TStarBinding"))
const TelecomIntro = lazyRetry(() => import("./TelecomIntro"))
const TelecomSuccess = lazyRetry(() => import("./TelecomSuccess"))
const TelecomBinding = lazyRetry(() => import("./TelecomBinding"))
const SingleArticle = lazyRetry(() => import("./SingleArticle"))
const Page = lazyRetry(() => import("./Page"))
const ThankYou = lazyRetry(() => import("./ThankYou"))
const GiftingWelcome = lazyRetry(() => import("./GiftingWelcome"))
const GiftingPlans = lazyRetry(() => import("./GiftingPlans"))
const Membership = lazyRetry(() => import("./Membership"))
const SmartoneSignUp = lazyRetry(() => import("./SmartoneSignUp"))
const Referee = lazyRetry(() => import("./Referee"))
const AptgTokenBinding = lazyRetry(() => import("./AptgTokenBinding"))
const TstarTokenBinding = lazyRetry(() => import("./TstarTokenBinding"))
const CancelVip = lazyRetry(() => import("./CancelVip"))
const SubscriptionManagement = lazyRetry(() =>
  import("./SubscriptionManagement")
)
const Recommend = lazyRetry(() => import("./Recommend"))
const NotFound = lazyRetry(() => import("./NotFound"))
const Privacy = lazyRetry(() => import("./Privacy"))
const Policy = lazyRetry(() => import("./Policy"))
const Unsubscribe = lazyRetry(() => import("./Unsubscribe"))
const RegistrationConfirmation = lazyRetry(() =>
  import("./RegistrationConfirmation")
)
const WechatAuth = lazyRetry(() => import("./WechatAuth"))
const KrsDocument = lazyRetry(() => import("./KrsDocument"))
const ChangePlan = lazyRetry(() => import("./ChangePlan"))
const YearlyReport = lazyRetry(() => import("./YearlyReport"))

const K4lSignUp = lazyRetry(() => import("./K4lSignUp"))
const K4lSignIn = lazyRetry(() => import("./K4lSignIn"))
const K4lLibraryRegion = lazyRetry(() => import("./K4lLibraryRegion"))
const K4lLibraryList = lazyRetry(() => import("./K4lLibraryList"))
const K4lFaq = lazyRetry(() => import("./K4lFaq"))
const ReadingProgress = lazyRetry(() => import("./ReadingProgress"))
const MagazineList = lazyRetry(() => import("./MagazineList"))
const TemporaryLogin = lazyRetry(() => import("./TemporaryLogin"))
const K4lLanding = lazyRetry(() => import("./K4lLanding"))
const K4lSharing = lazyRetry(() => import("./K4lSharing"))
const CreditCardPlans = lazyRetry(() => import("./CreditCardPlans"))
const Coupon = lazyRetry(() => import("./Coupon"))
const EmailConfirmation = lazyRetry(() => import("./EmailConfirmation"))
const Summit = lazyRetry(() => import("./Summit"))

const content = {
  Home,
  Dashboard,
  Landing,
  Library,
  SingleCategory,
  SingleMagazine,
  SingleIssue,
  About,
  Jobs,
  News,
  ContactUs,
  KonoMagazineIntro,
  Search,
  MyFollow,
  MyCollect,
  WeeklyIssue,
  CurationPost,
  CurationChannel,
  SignIn,
  SignUp,
  Oobe,
  RemindPassword,
  ResetPassword,
  ChangePassword,
  TStarIntro,
  TStarSuccess,
  TStarBinding,
  TelecomIntro,
  TelecomSuccess,
  TelecomBinding,
  SingleArticle,
  Page,
  ThankYou,
  GiftingWelcome,
  GiftingPlans,
  Membership,
  SmartoneSignUp,
  Referee,
  AptgTokenBinding,
  TstarTokenBinding,
  CancelVip,
  SubscriptionManagement,
  Recommend,
  NotFound,
  Privacy,
  Policy,
  Unsubscribe,
  RegistrationConfirmation,
  WechatAuth,
  KrsDocument,
  ChangePlan,
  YearlyReport,
  K4lSignUp,
  K4lSignIn,
  K4lLibraryRegion,
  K4lLibraryList,
  K4lFaq,
  ReadingProgress,
  MagazineList,
  TemporaryLogin,
  K4lLanding,
  K4lSharing,
  CreditCardPlans,
  Coupon,
  EmailConfirmation,
  Summit
}

export default content
