import {
  REQUEST_MAGAZINE_SUCCESS,
  REQUEST_MAGAZINES_SUCCESS,
  REQUEST_RECOMMENDED_MAGAZINES_SUCCESS
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const formatMagazine = magazine => {
  return {
    id: magazine.title,
    description: magazine.description,
    name: magazine.name,
    isBannedInChina: magazine.is_banned_in_china,
    available: magazine.available
  }
}

const arrayReducer = (acc, cur) => {
  acc[cur.title] = formatMagazine(cur)
  return acc
}

const reducerMap = new Map([
  [
    REQUEST_MAGAZINE_SUCCESS,
    (state, action) => {
      const { magazine } = action.payload
      const magazineObject = {
        id: magazine.title,
        description: magazine.description,
        name: magazine.name,
        isBannedInChina: magazine.is_banned_in_china,
        available: magazine.available
      }
      return state.set(magazine.title, fromJS(magazineObject))
    }
  ],
  [
    REQUEST_MAGAZINES_SUCCESS,
    (state, action) => {
      return state.merge(
        fromJS(action.payload.magazines.reduce(arrayReducer, {}))
      )
    }
  ],
  [
    REQUEST_RECOMMENDED_MAGAZINES_SUCCESS,
    (state, action) => {
      return state.merge(
        fromJS(action.payload.magazines.reduce(arrayReducer, {}))
      )
    }
  ]
])

export default handleActions(reducerMap, initialState)
