import Subject from "./components/Subject"
import FullScreenWidthContainer from "./components/FullScreenWidthContainer"
import ContentContainer from "./components/ContentContainer"
import ContentText from "./components/ContentText"
import TableContent from "./components/TableContent"
import FullScreenWidthBanner from "./components/FullScreenWidthBanner"
import Separator from "./components/Separator"
import Subtitle from "./components/Subtitle"
import HyperLink from "./components/HyperLink"
import FullWidthImage from "./components/FullWidthImage"
import InternalLink from "./components/InternalLink"
import Input from "./components/Input"
import Button from "./components/Button"
import ErrorMessage from "./components/ErrorMessage"
import KonoModal from "./components/KonoModal"
import SuccessMessage from "./components/SuccessMessage"
import MainTitle from "./components/MainTitle"
import Radio from "./components/Radio"
import SquareInput, {
  SquareInputCss,
  SquareInputNameCss
} from "./components/SquareInput"
import Checkbox from "./components/Checkbox"
import SelectionModal from "./components/SelectionModal"
import BillingTable from "./components/BillingTable"

const object = {
  Subject,
  FullScreenWidthContainer,
  ContentContainer,
  ContentText,
  TableContent,
  FullScreenWidthBanner,
  Separator,
  Subtitle,
  HyperLink,
  FullWidthImage,
  InternalLink,
  Input,
  Button,
  ErrorMessage,
  KonoModal,
  SuccessMessage,
  MainTitle,
  Radio,
  SquareInput,
  SquareInputCss,
  SquareInputNameCss,
  Checkbox,
  SelectionModal,
  BillingTable
}

export default object
