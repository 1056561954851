import KonoApi from "../KonoApi"

class FetchK4lLibraryApi extends KonoApi {
  constructor(data) {
    super(`/k4l/libraries/${data.libraryId}`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchK4lLibraryApi
