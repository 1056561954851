import {
  REQUEST_ISSUE_ARTICLES_SUCCESS,
  REQUEST_ARTICLE_SUCCESS,
  CREATE_BOOKMARK,
  CREATE_BOOKMARK_SUCCESS,
  CREATE_BOOKMARK_ERROR,
  DELETE_BOOKMARK,
  DELETE_BOOKMARK_SUCCESS,
  DELETE_BOOKMARK_ERROR,
  REQUEST_BOOKMARKED_ARTICLES_SUCCESS,
  REQUEST_CURATION_POST_ARTICLES_SUCCESS,
  REQUEST_RECOMMEND_ARTICLES_SUCCESS,
  REQUEST_CURRENT_FREE_ISSUE_SUCCESS,
  REQUEST_POPULAR_ARTICLES_SUCCESS,
  REQUEST_ARTICLE_RELEVANT_ARTICLES_SUCCESS,
  REQUEST_READING_PROGRESSES_SUCCESS
} from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({})

const formatArticle = article => {
  let formattedArticle = {
    id: article.article_id,
    issueId: article.bid,
    author: article.author,
    description: article.description,
    intro: article.intro,
    startPageNumber: article.page_number,
    endPageNumber: article.end_page,
    hasAudio: article.has_audio,
    hasVideo: article.has_video,
    hasPdf: article.has_pdf,
    hasFitReading: article.has_fit_reading,
    hasTranslation: article.has_translation,
    isHighlight: article.is_highlight,
    isFree: article.plan_type === 0,
    sequence: article.sequence,
    title: article.title,
    mainImage: {
      "1:1": {
        large: article.main_image["1:1"].large.url,
        medium: article.main_image["1:1"].medium.url,
        small: article.main_image["1:1"].small.url
      },
      "4:3": {
        large: article.main_image["4:3"].large.url,
        medium: article.main_image["4:3"].medium.url,
        small: article.main_image["4:3"].small.url
      },
      "9:5": {
        large: article.main_image["9:5"].large.url,
        medium: article.main_image["9:5"].medium.url,
        small: article.main_image["9:5"].small.url
      },
      original: {
        large: article.main_image.original.large.url,
        medium: article.main_image.original.medium.url,
        small: article.main_image.original.small.url
      }
    }
  }

  if (article.is_bookmark === true || article.is_bookmark === false) {
    formattedArticle["isBookmark"] = article.is_bookmark
  }
  return formattedArticle
}

const arrayReducer = (acc, cur) => {
  acc[cur.article_id] = formatArticle(cur)
  return acc
}

const reducerMap = new Map([
  [
    REQUEST_ISSUE_ARTICLES_SUCCESS,
    (state, action) => {
      const articles = action.payload.articles
      return state.mergeDeep(fromJS(articles.reduce(arrayReducer, {})))
    }
  ],
  [
    REQUEST_ARTICLE_SUCCESS,
    (state, action) => {
      const article = formatArticle(action.payload.article)
      return state.set(article.id, fromJS(article))
    }
  ],
  [
    CREATE_BOOKMARK,
    (state, action) => {
      return state.setIn([action.payload.articleId, "isBookmark"], true)
    }
  ],
  [
    CREATE_BOOKMARK_SUCCESS,
    (state, action) => {
      return state.setIn([action.payload.articleId, "isBookmark"], true)
    }
  ],
  [
    CREATE_BOOKMARK_ERROR,
    (state, action) => {
      return state.setIn([action.payload.articleId, "isBookmark"], false)
    }
  ],
  [
    DELETE_BOOKMARK,
    (state, action) => {
      return state.setIn([action.payload.articleId, "isBookmark"], false)
    }
  ],
  [
    DELETE_BOOKMARK_SUCCESS,
    (state, action) => {
      return state.setIn([action.payload.articleId, "isBookmark"], false)
    }
  ],
  [
    DELETE_BOOKMARK_ERROR,
    (state, action) => {
      return state.setIn([action.payload.articleId, "isBookmark"], true)
    }
  ],
  [
    REQUEST_BOOKMARKED_ARTICLES_SUCCESS,
    (state, action) => {
      const articles = action.payload.bookmarks.map(
        bookmark => bookmark.article
      )
      return state.mergeDeep(fromJS(articles.reduce(arrayReducer, {})))
    }
  ],
  [
    REQUEST_CURATION_POST_ARTICLES_SUCCESS,
    (state, action) => {
      const articles = action.payload.articles
      return state.mergeDeep(fromJS(articles.reduce(arrayReducer, {})))
    }
  ],
  [
    REQUEST_RECOMMEND_ARTICLES_SUCCESS,
    (state, action) => {
      const recommend = action.payload.recommendArticles
      const articles = recommend.map(recommend => recommend.article)
      return state.mergeDeep(fromJS(articles.reduce(arrayReducer, {})))
    }
  ],
  [
    REQUEST_CURRENT_FREE_ISSUE_SUCCESS,
    (state, action) => {
      const articles = action.payload.freeIssue.highlight_articles
      return state.mergeDeep(fromJS(articles.reduce(arrayReducer, {})))
    }
  ],
  [
    REQUEST_POPULAR_ARTICLES_SUCCESS,
    (state, action) => {
      const articles = action.payload.popularArticles
      return state.mergeDeep(fromJS(articles.reduce(arrayReducer, {})))
    }
  ],
  [
    REQUEST_ARTICLE_RELEVANT_ARTICLES_SUCCESS,
    (state, action) => {
      const articles = action.payload.relevantArticles
      return state.mergeDeep(fromJS(articles.reduce(arrayReducer, {})))
    }
  ],
  [
    REQUEST_READING_PROGRESSES_SUCCESS,
    (state, action) => {
      const articles = action.payload.readingProgresses.map(rp => rp.article)
      return state.mergeDeep(fromJS(articles.reduce(arrayReducer, {})))
    }
  ]
])

export default handleActions(reducerMap, initialState)
