import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import { REQUEST_CURATION_CHANNEL_POSTS_SUCCESS } from "constants/actionTypes"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    REQUEST_CURATION_CHANNEL_POSTS_SUCCESS,
    (state, action) => {
      const curationChannelId = action.payload.curationChannelId
      const curationChannelPosts = fromJS(
        action.payload.curationChannelPosts.map(post => post.id)
      )

      return state.set(curationChannelId, curationChannelPosts)
    }
  ]
])

export default handleActions(reducerMap, initialState)
