export const getMobileOS = () => {
  const userAgent = navigator.platform || navigator.vendor || window.opera
  if (/android|Linux armv/i.test(userAgent)) {
    return "Android"
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS"
  }
  return "unknown"
}

export const getReadingPlatform = () => {
  switch (getMobileOS()) {
    case "Android":
      return "android_web"
    case "iOS":
      return "ios_web"
    default:
      return "web"
  }
}
