import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components/macro"
import HeaderUI from "components/presentational/HeaderUI"

const StartWrapper = styled.div`
  display: flex;
  align-items: center;
`

export default function StartBlock(props) {
  const { t } = useTranslation("container")

  return (
    <StartWrapper>
      <HeaderUI.NavigationsText>{t("ALREADY_USER")}</HeaderUI.NavigationsText>
      <HeaderUI.LinkWrapper
        to="/library-region"
        padding="0 5px"
        skin="auth"
        hoverUnderline
      >
        <HeaderUI.NavigationsText skin="auth" fontWeight="500">
          {t("START_USE")}
        </HeaderUI.NavigationsText>
      </HeaderUI.LinkWrapper>
    </StartWrapper>
  )
}
