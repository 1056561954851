import { put, takeEvery, call } from "redux-saga/effects"
import { withLock } from "utilities/ApiLock"
import {
  onRequestMagazineSuccess,
  onRequestMagazineYearsSuccess,
  onRequestMagazineYearIssuesSuccess,
  onRequestMagazineIssuesSuccess,
  onRequestMagazinesSuccess,
  onRequestPopularMagazinesSuccess,
  onRequestRecommendedMagazinesSuccess
} from "actions/magazines"
import {
  REQUEST_MAGAZINE,
  REQUEST_MAGAZINE_YEARS,
  REQUEST_MAGAZINE_YEAR_ISSUES,
  REQUEST_MAGAZINE_ISSUES,
  REQUEST_MAGAZINES,
  REQUEST_POPULAR_MAGAZINES,
  REQUEST_RECOMMENDED_MAGAZINES
} from "constants/actionTypes"
import FetchMagazineApi from "apis/FetchMagazineApi"
import FetchMagazineYearsApi from "apis/FetchMagazineYearsApi"
import FetchMagazineYearIssuesApi from "apis/FetchMagazineYearIssuesApi"
import FetchMagazineIssuesApi from "apis/FetchMagazineIssuesApi"
import {
  isStoreExisted,
  isStoreExpired,
  selectStore
} from "utilities/storeCheck"
import {
  MAGAZINE_TTL,
  MAGAZINE_YEARS_TTL,
  MAGAZINE_YEAR_ISSUES_TTL,
  MAGAZINE_ISSUES_TTL,
  ALL_MAGAZINES_TTL,
  POPULAR_MAGAZINES_TTL
} from "constants/ttl"
import FetchAllMagazinesApi from "apis/FetchAllMagazinesApi"
import FetchPopularMagazinesApi from "apis/FetchPopularMagazinesApi"
import FetchRecommendedMagazinesApi from "apis/FetchRecommendedMagazinesApi"

function* fetchMagazine(action) {
  try {
    const { magazineId } = action.payload
    const isExisted = yield* isStoreExisted("magazines", magazineId)
    const isExpired = yield* isStoreExpired(
      MAGAZINE_TTL,
      "magazines",
      magazineId
    )
    if (isExisted && !isExpired) {
      return
    }
    const fetchMagazineApi = new FetchMagazineApi({
      magazineId
    })
    const response = yield call(fetchMagazineApi.call)
    const magazine = response.data
    yield put(onRequestMagazineSuccess(magazine))
  } catch (e) {
    const { onError } = action.payload
    onError ? onError(e) : console.log(e)
  }
}

export function* watchRequestMagazine() {
  yield takeEvery(REQUEST_MAGAZINE, withLock(fetchMagazine))
}

function* fetchMagazineYears(action) {
  try {
    const { magazineId } = action.payload
    const isExisted = yield* isStoreExisted("magazineYears", magazineId)
    const isExpired = yield* isStoreExpired(
      MAGAZINE_YEARS_TTL,
      "magazineYears",
      magazineId
    )
    if (isExisted && !isExpired) {
      return
    }
    const fetchMagazineYearsApi = new FetchMagazineYearsApi({
      magazineId
    })
    const response = yield call(fetchMagazineYearsApi.call)
    const magazineYears = response.data.map(item => {
      return item.year
    })
    yield put(onRequestMagazineYearsSuccess(magazineId, magazineYears))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestMagazineYears() {
  yield takeEvery(REQUEST_MAGAZINE_YEARS, withLock(fetchMagazineYears))
}

function* fetchMagazineYearIssues(action) {
  try {
    const { magazineId } = action.payload
    const year = action.payload.year
    const isExisted = yield* isStoreExisted(
      "magazineYearIssues",
      magazineId,
      year
    )
    const isExpired = yield* isStoreExpired(
      MAGAZINE_YEAR_ISSUES_TTL,
      "magazineYearIssues",
      magazineId,
      year
    )
    if (isExisted && !isExpired) {
      return
    }
    const fetchMagazineYearIssuesApi = new FetchMagazineYearIssuesApi({
      magazineId,
      year
    })
    const response = yield call(fetchMagazineYearIssuesApi.call)
    const issues = response.data
    yield put(onRequestMagazineYearIssuesSuccess(magazineId, year, issues))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestMagazineYearIssues() {
  yield takeEvery(
    REQUEST_MAGAZINE_YEAR_ISSUES,
    withLock(fetchMagazineYearIssues)
  )
}

function* fetchMagazineIssues(action) {
  try {
    const { magazineId } = action.payload
    const isExisted = yield* isStoreExisted("magazineIssues", magazineId)
    const isExpired = yield* isStoreExpired(
      MAGAZINE_ISSUES_TTL,
      "magazineIssues",
      magazineId
    )
    if (isExisted && !isExpired) {
      return
    }
    const fetchMagazineIssuesApi = new FetchMagazineIssuesApi({
      magazineId
    })
    const response = yield call(fetchMagazineIssuesApi.call)
    const issues = response.data
    yield put(onRequestMagazineIssuesSuccess(magazineId, issues))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestMagazineIssues() {
  yield takeEvery(REQUEST_MAGAZINE_ISSUES, withLock(fetchMagazineIssues))
}

function* fetchMagazines(action) {
  try {
    const { bookListId } = action.payload
    const isExisted = yield* isStoreExisted("allMagazines")
    const isExpired = yield* isStoreExpired(ALL_MAGAZINES_TTL, "allMagazines")
    if (isExisted && !isExpired) {
      return
    }
    const fetchAllMagazinesApi = new FetchAllMagazinesApi({ bookListId })
    const response = yield call(fetchAllMagazinesApi.call)
    const magazines = response.data
    yield put(onRequestMagazinesSuccess(magazines))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestMagazines() {
  yield takeEvery(REQUEST_MAGAZINES, withLock(fetchMagazines))
}

function* fetchPopularMagazines() {
  try {
    const isExisted = yield* isStoreExisted("popularMagazines")
    const isExpired = yield* isStoreExpired(
      POPULAR_MAGAZINES_TTL,
      "popularMagazines"
    )
    if (isExisted && !isExpired) {
      return
    }
    const country = yield* selectStore("clientInfo", "countryCode") || "TW"
    const fetchPopularMagazinesApi = new FetchPopularMagazinesApi()
    const response = yield call(fetchPopularMagazinesApi.call, {
      country,
      limit: 5
    })
    const magazines = response.data
    yield put(onRequestPopularMagazinesSuccess(magazines))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestPopularMagazines() {
  yield takeEvery(REQUEST_POPULAR_MAGAZINES, withLock(fetchPopularMagazines))
}

function* fetchRecommendedMagazines(action) {
  try {
    const { userId } = action.payload
    const isExisted = yield* isStoreExisted("recommendedMagazines")
    const isExpired = yield* isStoreExpired(
      MAGAZINE_TTL,
      "recommendedMagazines"
    )
    if (isExisted && !isExpired) {
      return
    }
    const fetchRecommendedMagazinesApi = new FetchRecommendedMagazinesApi({
      userId
    })
    const response = yield call(fetchRecommendedMagazinesApi.call)
    const magazines = response.data
    yield put(onRequestRecommendedMagazinesSuccess(magazines))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestRecommendedMagazines() {
  yield takeEvery(
    REQUEST_RECOMMENDED_MAGAZINES,
    withLock(fetchRecommendedMagazines)
  )
}
