import KonoApi from "../KonoApi"

class FetchCurationChannelApi extends KonoApi {
  constructor(data) {
    super(`/curation_channels/${data.curationChannelId}`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchCurationChannelApi
