import {
  OPEN_READING_PROGRESS_PROMPT_TOAST,
  CLOSE_READING_PROGRESS_PROMPT_TOAST
} from "constants/actionTypes"

export const onOpenReadingProgressPrompt = issueId => ({
  type: OPEN_READING_PROGRESS_PROMPT_TOAST,
  payload: { issueId }
})

export const onCloseReadingProgressPrompt = () => ({
  type: CLOSE_READING_PROGRESS_PROMPT_TOAST
})
