import { getNow } from "utilities/time"
import vipStatus from "constants/vipStatus"

export const paymentTypeDict = {
  0: vipStatus.BEFORE_AYCR,
  1: vipStatus.COUPON, // in use
  2: vipStatus.IOS_SUBSCRIPTION, // in use
  3: vipStatus.BRAINTREE_SUBSCRIPTION, // in use
  4: vipStatus.TRIAL,
  5: vipStatus.SAMSUNG,
  6: vipStatus.IBON, // in use
  7: vipStatus.REFERRAL, // in use
  8: vipStatus.PACKAGE_PLAN,
  9: vipStatus.LIBRARY, // kl only
  10: vipStatus.BRAINTREE_GRACE_PERIOD, // in use
  11: vipStatus.BRAINTREE_SUBSCRIPTION_TRAIL,
  12: vipStatus.ACHIEVEMENT_SIGN_UP, // in use
  13: vipStatus.ACHIEVEMENT_DOWNLOAD_APP,
  14: vipStatus.TSTAR, // in use
  15: vipStatus.ACHIEVEMENT_COME_BACK,
  16: vipStatus.SMARTONE, // in use
  17: vipStatus.REFEREE, // in use
  18: vipStatus.APTG, // in use
  19: vipStatus.WECHAT,
  20: vipStatus.ALI,
  21: vipStatus.KRS, // Kono@
  22: vipStatus.PAST_DUE_GRACE_PERIOD, // in use
  23: vipStatus.TRIAL_VIP, // in use,
  24: vipStatus.GOOGLE, // in use,
  25: vipStatus.VOUCHER // in use,
}

const autoRenewTypeExcludeBraintree = [2, 16, 24]

export const isAutoRenewExcludeBraintree = paymentType => {
  return autoRenewTypeExcludeBraintree.includes(paymentType)
}

export const isDoubleVip = (vipType, vipPeriods) => {
  const vipTypeIndex = Object.keys(paymentTypeDict).find(
    key => paymentTypeDict[key] === vipType
  )
  const now = getNow()
  let hasVipTyped = false
  let hasOthers = false
  vipPeriods.forEach(vipPeriod => {
    if (now >= vipPeriod.get("beginAt") && now <= vipPeriod.get("endAt")) {
      if (vipPeriod.get("paymentType") === vipTypeIndex) {
        hasVipTyped = true
      } else if (vipPeriod.get("paid")) {
        hasOthers = true
      }
    }
  })
  return hasVipTyped && hasOthers
}

export const findCurrentVip = vipPeriods => {
  if (!vipPeriods) return null

  return (
    vipPeriods.find(
      period =>
        period.get("beginAt") <= getNow() && period.get("endAt") >= getNow()
    ) || null
  )
}

export const getTrackingVipStatus = (vipPeriods, freeArticleQuota) => {
  const currentVip = findCurrentVip(vipPeriods)
  const quota = freeArticleQuota && freeArticleQuota.get("active")

  if (currentVip) {
    return paymentTypeDict[currentVip.get("paymentType")]
  } else {
    return quota ? vipStatus.HAMI : vipStatus.FREE
  }
}

export const currentVipPeriod = periods => {
  if (!periods) return null

  // search from the oldest, find the active vipPeriod
  // If multiple current vip periods are active, it will return the oldest one
  return periods.find(
    period =>
      period.get("beginAt") <= getNow() && period.get("endAt") >= getNow()
  )
}

export const futureVipPeriods = periods => {
  return periods && periods.filter(period => period.get("beginAt") > getNow())
}

export const pastVipPeriods = periods => {
  // return all past and active vip peiods including the current one
  return periods && periods.filter(period => period.get("beginAt") < getNow())
}
