import React from "react"
import { Link } from "react-router-dom"
import K4lLogo from "../K4lLogo"
import StartBlock from "../StartBlock"
import { ifDbChangedToProd } from "utilities/sandbox"
import styled from "styled-components/macro"
import media from "utilities/media"

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: auto 0;

  ${media.desktop`
    padding: 0 40px;
  `};

  ${media.tablet`
    padding: 0 10px 0 20px;
  `};

  ${media.mobile`
    padding: 0 10px 0 20px;
  `};
`

function OneRowHeader(props) {
  return (
    <RowWrapper>
      <Link to="/">
        <K4lLogo />
        {ifDbChangedToProd() ? "prod" : null}
      </Link>
      {props.isLanding ? <StartBlock /> : null}
    </RowWrapper>
  )
}

export default OneRowHeader
