import axios from "axios"

class FetchArticleTextApi {
  constructor(data) {
    this.url = data.url
    this.call = this.call.bind(this)
  }

  call() {
    return axios.get(this.url)
  }
}

export default FetchArticleTextApi
