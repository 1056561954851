import i18n from "i18next"

export const k4lHomeHref = () => {
  return ["ja-JP", "ko-KR"].includes(i18n.language)
    ? "/libraries/japanese"
    : "/libraries/chinese"
}

export const prodType = () => {
  return process.env.REACT_APP_PRODUCT
}

export const isAycr = () => {
  return process.env.REACT_APP_PRODUCT === "aycr"
}

export const isK4l = () => {
  return process.env.REACT_APP_PRODUCT === "k4l"
}

export const k4lSummitHref = "/summit"
