import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import { REQUEST_ARTICLE_RELEVANT_ARTICLES_SUCCESS } from "constants/actionTypes"

const initialState = fromJS({})

const reducerMap = new Map([
  [
    REQUEST_ARTICLE_RELEVANT_ARTICLES_SUCCESS,
    (state, action) => {
      const { relevantArticles, magazineIds, articleId } = action.payload
      const relevantArticleIds = relevantArticles
        .filter(article => magazineIds.includes(article.magazine.title))
        .map(article => article.article_id)
      return state.set(articleId, fromJS(relevantArticleIds))
    }
  ]
])

export default handleActions(reducerMap, initialState)
