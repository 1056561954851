const ipEmail = "ip_email"
const sso = "sso"
const cardNumberEmail = "card_number_email"
const cardNumberOnly = "card_number_only"
const emailDomain = "email_domain"

export const allValidatorType = [
  ipEmail,
  sso,
  cardNumberEmail,
  cardNumberOnly,
  emailDomain
]

export const signInOnlyType = [sso, cardNumberOnly, emailDomain]
