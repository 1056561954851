import { AGREE_COOKIE } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({
  agreeCookie: false
})

const reducerMap = new Map([
  [AGREE_COOKIE, state => state.set("agreeCookie", true)]
])

export default handleActions(reducerMap, initialState)
