import KonoApi from "../KonoApi"

class FetchBraintreeSubscriptionApi extends KonoApi {
  constructor(data) {
    super(`/users/${data.userId}/braintree_subscription`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchBraintreeSubscriptionApi
