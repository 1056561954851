import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestHamiReadCountSuccess } from "actions/hamiReadCount"
import { REQUEST_HAMI_READ_COUNT } from "constants/actionTypes"
import FetchHamiReadCountApi from "apis/FetchHamiReadCountApi"

function* fetchHamiReadCount(action) {
  try {
    const userId = action.payload.userId
    const fetchHamiReadCountApi = new FetchHamiReadCountApi({ userId })
    const response = yield call(fetchHamiReadCountApi.call)
    const count = response.data.result
    yield put(onRequestHamiReadCountSuccess(count))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestHamiReadCount() {
  yield takeEvery(REQUEST_HAMI_READ_COUNT, fetchHamiReadCount)
}
