import { REQUEST_INTRO_ARTICLES_SUCCESS } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import camelcaseKeys from "camelcase-keys"

const initialState = fromJS([])

const formatArticle = article => {
  return camelcaseKeys(
    {
      id: article.id,
      name: article.name,
      date: article.date,
      content: article.content.map(formatContent)
    },
    { deep: true }
  )
}

const formatContent = content => {
  const contentType = content[content.type]
  const contentValue =
    content.type === "image"
      ? {
          ...contentType[contentType.type],
          caption:
            contentType.caption.length > 0
              ? contentType.caption[0].plain_text
              : ""
        }
      : contentType.rich_text

  return {
    id: content.id,
    type: content.type,
    content: contentValue
  }
}

const reducerMap = new Map([
  [
    REQUEST_INTRO_ARTICLES_SUCCESS,
    (state, action) => {
      const articles = action.payload.articles
      return fromJS(articles.map(formatArticle))
    }
  ]
])

export default handleActions(reducerMap, initialState)
