import KonoApi from "../KonoApi"

class FetchEmployeesApi extends KonoApi {
  constructor() {
    super(`/employees`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchEmployeesApi
