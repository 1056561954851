import KonoApi from "../KonoApi"

class FetchArticleRelevantArticlesApi extends KonoApi {
  constructor() {
    super(`/relevant_articles`)
  }

  call(params) {
    return this.axios.get(this.url, {
      params: {
        article_id: params.articleId,
        userId: params.userId
      }
    })
  }
}

export default FetchArticleRelevantArticlesApi
