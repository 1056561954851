import { css } from "styled-components"

const media = {
  desktop: (...args) => css`
    @media (min-width: 1024px) {
      ${css(...args)};
    }
  `,

  tablet: (...args) => css`
    @media (min-width: 600px) and (max-width: 1023px) {
      ${css(...args)};
    }
  `,

  mobile: (...args) => css`
    @media (max-width: 599px) {
      ${css(...args)};
    }
  `
}

export default media

export function getMql(...features) {
  const mqs = features
    .map(feature => {
      let mediaQuery = ""
      switch (feature) {
        case "desktop":
          mediaQuery = "(min-width: 1024px)"
          break
        case "tablet":
          mediaQuery = "(min-width: 600px) and (max-width: 1023px)"
          break
        case "mobile":
          mediaQuery = "(max-width: 599px)"
          break
        default:
          break
      }
      return mediaQuery
    })
    .join(",")
  return window.matchMedia(mqs)
}

export function getDeviceWidth() {
  const mediaType = {
    desktop: "desktop",
    tablet: "tablet",
    mobile: "mobile"
  }
  let deviceWidth
  if (getMql(mediaType.desktop)) deviceWidth = mediaType.desktop
  else if (getMql(mediaType.tablet)) deviceWidth = mediaType.tablet
  else if (getMql(mediaType.mobile)) deviceWidth = mediaType.mobile

  return deviceWidth
}
