import { SET_K4L_ERROR } from "constants/actionTypes"
import { handleActions } from "redux-actions"
import { fromJS } from "immutable"

const initialState = fromJS({ error: false })

const reducerMap = new Map([
  [
    SET_K4L_ERROR,
    (state, action) => {
      return state.set("error", action.payload.boolean)
    }
  ]
])

const k4l = handleActions(reducerMap, initialState)

export default k4l
