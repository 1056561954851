import KonoApi from "../KonoApi"

class FetchBannersApi extends KonoApi {
  constructor() {
    super(`/campaign_banners`)
  }

  call(params) {
    const { platform, libraryId, userId, acceptWebP } = params
    return this.axios.get(this.url, {
      params: {
        platform,
        library_id: libraryId,
        user_id: userId,
        accept_webp: acceptWebP
      }
    })
  }
}

export default FetchBannersApi
