import { OPEN_TOAST, CLOSE_TOAST } from "constants/actionTypes"

export const onOpenToast = (type, args) => {
  return {
    type: OPEN_TOAST,
    payload: { type, args }
  }
}

export const onCloseToast = () => {
  return {
    type: CLOSE_TOAST
  }
}
