import React, { Component } from "react"
import SideTabComponent from "components/container/SideTabComponent"
import DropDownButton from "components/presentational/DropDownButton"
import SmoothCollapse from "react-smooth-collapse"
import earth from "./assets/images/earth.svg"
import earthDark from "./assets/images/earth-dark.svg"
import styled from "styled-components/macro"
import { withTranslation } from "react-i18next"

const SideTabRowStaticItem = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  color: #222222;
  height: 48px;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 9px;
`

const LanguageWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

const Earth = styled.div`
  display: block;
  background-image: url(${props => (props.dark ? earthDark : earth)});
  width: 37px;
  height: 37px;
  background-size: 37px 37px;
`

const SplitLine = styled.div`
  height: 16px;
  width: 0px;
  border-left: 1px solid;
  border-color: ${props => (props.dark ? "#656565" : "#c7c7c7")};
  margin: 0 14.5px 0 5.5px;
`

const SpaceMargin = styled.div`
  margin-right: 3px;
`

class SideTabLanguageSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isToggle: false
    }
    this.handleToggle = this.handleToggle.bind(this)
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this)
  }

  handleToggle() {
    this.setState(prevState => ({
      isToggle: !prevState.isToggle
    }))
  }

  handleChangeLanguage(language) {
    const { i18n } = this.props
    if (language !== i18n.language) i18n.changeLanguage(language)
    this.handleToggle()
  }

  renderItem() {
    return this.props.i18n.options.supportedLngs
      .filter(lng => lng !== "cimode")
      .map(language => (
        <SideTabComponent.SideTabSubMenuItem key={language}>
          <div onClick={() => this.handleChangeLanguage(language)}>
            {this.props.t(language)}
          </div>
        </SideTabComponent.SideTabSubMenuItem>
      ))
  }

  renderDropDown() {
    return (
      <SmoothCollapse expanded={this.state.isToggle}>
        <SideTabComponent.SideTabSubMenuWrapper>
          {this.renderItem()}
        </SideTabComponent.SideTabSubMenuWrapper>
      </SmoothCollapse>
    )
  }

  render() {
    return (
      <SideTabComponent.SideTabDivWrapper noBottomBorder bottom>
        <SideTabComponent.SideTabRow onClick={this.handleToggle}>
          <SideTabRowStaticItem>
            <LanguageWrapper>
              <Earth dark={this.props.dark} />
              <SplitLine dark={this.props.dark} />
              {this.props.t(this.props.i18n.language)}
              <SpaceMargin />
              <DropDownButton
                size="48px"
                mode="normal"
                isToggle={this.state.isToggle}
              />
            </LanguageWrapper>
          </SideTabRowStaticItem>
        </SideTabComponent.SideTabRow>
        {this.renderDropDown()}
      </SideTabComponent.SideTabDivWrapper>
    )
  }
}

export default withTranslation("language")(SideTabLanguageSelector)
