import { call, put, takeEvery } from "redux-saga/effects"
import {
  onLogInSuccess,
  onLogInFail,
  onSignUpSuccess,
  onSignUpFail,
  onSearchPlatformsSuccess,
  onRemindPasswordSuccess,
  onRemindPasswordError,
  onResetPasswordSuccess,
  onResetPasswordError,
  onUpdateUserSuccess,
  onRequestUserInfoSuccess,
  onRequestWechatAccessTokenFinish,
  onRequestUserPictureSuccess,
  onTemporaryLoginSuccess,
  onTemporaryLoginFail
} from "actions/authentication"
import {
  onLogSignIn,
  onLogSignUp,
  onLogTelecomBinding
} from "actions/trackingEvents"
import { onStartLoading, onEndLoading } from "actions/loading"
import {
  LOG_IN,
  REQUEST_USER_PLATFORMS,
  SIGN_UP,
  SIGN_UP_SMARTONE,
  REMIND_PASSWORD,
  RESET_PASSWORD,
  UPDATE_USER,
  REQUEST_USER_INFO,
  REQUEST_WECHAT_ACCESS_TOKEN,
  WECHAT_LOG_IN,
  K4L_LOG_IN,
  K4L_SIGN_UP,
  REQUEST_USER_PICTURE,
  TEMPORARY_LOGIN
} from "constants/actionTypes"
import LoginApi from "apis/LoginApi"
import { UNKNOWN_ERROR } from "constants/error"
import SignUpApi from "apis/SignUpApi"
import SignUpSmartoneApi from "apis/SignUpSmartoneApi"
import SearchPlatformsApi from "apis/SearchPlatformsApi"
import RemindPasswordApi from "apis/RemindPasswordApi"
import RemindLibraryUserPasswordApi from "apis/RemindLibraryUserPasswordApi"
import ResetPasswordApi from "apis/ResetPasswordApi"
import UpdateUserApi from "apis/UpdateUserApi"
import FetchUserInfoApi from "apis/FetchUserInfoApi"
import WechatAccessTokenApi from "apis/WechatAccessTokenApi"
import WechatLoginApi from "apis/WechatLoginApi"
import K4lLoginApi from "apis/K4lLoginApi"
import K4lSignUpApi from "apis/K4lSignUpApi"
import FetchUserPictureApi from "apis/FetchUserPictureApi"
import TemporaryLoginApi from "apis/TemporaryLoginApi"
import {
  LOG_IN_LOADING,
  SIGN_UP_LOADING,
  SMARTONE_SIGN_UP_LOADING,
  SEARCH_PLATFORM_LOADING,
  REMIND_PASSWORD_LOADING,
  RESET_PASSWORD_LOADING,
  REQUEST_USER_INFO_LOADING
} from "constants/loadingTypes"
import { getUtmParams } from "utilities/history"
import { selectStore } from "utilities/storeCheck"

function* login(action) {
  try {
    yield put(onStartLoading(LOG_IN_LOADING))
    const loginApi = new LoginApi()
    const locationSearch = yield selectStore("state", "locationSearch")
    const utmParams = getUtmParams(locationSearch)
    const params = { ...action.payload, utmParams }
    const response = yield call(loginApi.call, params)
    const user = response.data
    yield put(onEndLoading(LOG_IN_LOADING))
    yield put(onLogInSuccess(user))
    yield put(onLogSignIn(user))
  } catch (e) {
    console.log(e)
    yield put(onEndLoading(LOG_IN_LOADING))
    yield put(onLogInFail(e.response?.data.code || UNKNOWN_ERROR))
  }
}

export function* watchLogIn() {
  yield takeEvery(LOG_IN, login)
}

function* signUp(action) {
  try {
    yield put(onStartLoading(SIGN_UP_LOADING))
    const signUpApi = new SignUpApi()
    const locationSearch = yield selectStore("state", "locationSearch")
    const utmParams = getUtmParams(locationSearch)
    const params = { ...action.payload, utmParams }
    const response = yield call(signUpApi.call, params)
    const user = response.data
    yield put(onEndLoading(SIGN_UP_LOADING))
    yield put(onSignUpSuccess(user))
    yield put(onLogSignUp(user))
  } catch (e) {
    console.log(e)
    yield put(onEndLoading(SIGN_UP_LOADING))
    yield put(onSignUpFail(e.response?.data.code || UNKNOWN_ERROR))
  }
}

export function* watchSignUp() {
  yield takeEvery(SIGN_UP, signUp)
}

function* signUpSmartone(action) {
  try {
    yield put(onStartLoading(SMARTONE_SIGN_UP_LOADING))
    const signUpSmartoneApi = new SignUpSmartoneApi()
    const locationSearch = yield selectStore("state", "locationSearch")
    const utmParams = getUtmParams(locationSearch)
    const params = { ...action.payload, utmParams }
    const response = yield call(signUpSmartoneApi.call, params)
    const user = response.data

    yield put(onEndLoading(SMARTONE_SIGN_UP_LOADING))
    yield put(onSignUpSuccess(user))
    yield put(onLogTelecomBinding({ telecom: "smartone" }))
    yield put(onLogSignUp(user))
  } catch (e) {
    console.log(e)
    yield put(onEndLoading(SMARTONE_SIGN_UP_LOADING))
    yield put(onSignUpFail(e.response?.data.code || UNKNOWN_ERROR))
  }
}

export function* watchSignUpSmartone() {
  yield takeEvery(SIGN_UP_SMARTONE, signUpSmartone)
}

function* searchPlatforms(action) {
  try {
    yield put(onStartLoading(SEARCH_PLATFORM_LOADING))
    const searchPlatformsApi = new SearchPlatformsApi()
    const response = yield call(searchPlatformsApi.call, action.payload.email)
    const platforms = response.data
    yield put(onEndLoading(SEARCH_PLATFORM_LOADING))
    yield put(onSearchPlatformsSuccess(platforms))
  } catch (e) {
    yield put(onEndLoading(SEARCH_PLATFORM_LOADING))
    console.log(e)
  }
}

export function* watchSearchPlatforms() {
  yield takeEvery(REQUEST_USER_PLATFORMS, searchPlatforms)
}

function* remindPassword(action) {
  try {
    yield put(onStartLoading(REMIND_PASSWORD_LOADING))
    const { platform } = action.payload
    const remindPasswordApi = platform
      ? new RemindLibraryUserPasswordApi()
      : new RemindPasswordApi()
    yield call(remindPasswordApi.call, action.payload)
    yield put(onEndLoading(REMIND_PASSWORD_LOADING))
    yield put(onRemindPasswordSuccess())
  } catch (e) {
    yield put(onEndLoading(REMIND_PASSWORD_LOADING))
    yield put(onRemindPasswordError(`${e.response?.status}` || UNKNOWN_ERROR))
  }
}

export function* watchRemindPassword() {
  yield takeEvery(REMIND_PASSWORD, remindPassword)
}

function* resetPassword(action) {
  try {
    yield put(onStartLoading(RESET_PASSWORD_LOADING))
    const resetPasswordApi = new ResetPasswordApi()
    yield call(resetPasswordApi.call, action.payload)
    yield put(onEndLoading(RESET_PASSWORD_LOADING))
    yield put(onResetPasswordSuccess())
  } catch (e) {
    yield put(onEndLoading(RESET_PASSWORD_LOADING))
    yield put(onResetPasswordError(`${e.response?.status}` || UNKNOWN_ERROR))
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword)
}

function* updateUser(action) {
  try {
    const updateUserApi = new UpdateUserApi()
    const response = yield call(updateUserApi.call, action.payload)
    const user = response.data.user
    yield put(onUpdateUserSuccess(user))
  } catch (e) {
    console.log(e)
  }
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser)
}

function* fetchUserInfo(action) {
  try {
    const { userId } = action.payload
    yield put(onStartLoading(REQUEST_USER_INFO_LOADING))
    const fetchUserInfoApi = new FetchUserInfoApi()
    const response = yield call(fetchUserInfoApi.call, { userId })
    const user = response.data
    yield put(onRequestUserInfoSuccess(user))
    yield put(onEndLoading(REQUEST_USER_INFO_LOADING))
  } catch (e) {
    console.log(e)
    yield put(onEndLoading(REQUEST_USER_INFO_LOADING))
  }
}

export function* watchRequestUserInfo() {
  yield takeEvery(REQUEST_USER_INFO, fetchUserInfo)
}

function* fetchWechatAccessToken(action) {
  try {
    yield put(onStartLoading(LOG_IN_LOADING))
    const wechatAccessTokenApi = new WechatAccessTokenApi()
    const response = yield call(wechatAccessTokenApi.call, action.payload)
    const user = response.data
    yield put(onRequestWechatAccessTokenFinish(user))
    yield put(onEndLoading(LOG_IN_LOADING))
  } catch (e) {
    console.log(e)
    yield put(onEndLoading(LOG_IN_LOADING))
  }
}

export function* watchRequestWechatAccessToken() {
  yield takeEvery(REQUEST_WECHAT_ACCESS_TOKEN, fetchWechatAccessToken)
}

function* wechatLogin(action) {
  try {
    yield put(onStartLoading(LOG_IN_LOADING))
    const wechatLoginApi = new WechatLoginApi()
    const response = yield call(wechatLoginApi.call, action.payload)
    const user = response.data
    yield put(onLogInSuccess(user))
    yield put(onEndLoading(LOG_IN_LOADING))
  } catch (e) {
    yield put(onEndLoading(LOG_IN_LOADING))
    yield put(onLogInFail(e.response?.data.code || UNKNOWN_ERROR))
  }
}

export function* watchWechatLogIn() {
  yield takeEvery(WECHAT_LOG_IN, wechatLogin)
}

function* k4lLogin(action) {
  try {
    yield put(onStartLoading(LOG_IN_LOADING))
    const k4lLoginApi = new K4lLoginApi()
    const locationSearch = yield selectStore("state", "locationSearch")
    const utmParams = getUtmParams(locationSearch)
    const params = { ...action.payload, utmParams }
    const response = yield call(k4lLoginApi.call, params)
    const user = response.data
    yield put(onEndLoading(LOG_IN_LOADING))
    yield put(onLogInSuccess(user))
    yield put(onLogSignIn(user))
  } catch (e) {
    console.log(e)
    yield put(onEndLoading(LOG_IN_LOADING))
    yield put(onLogInFail(e.response?.data.error_code || UNKNOWN_ERROR))
  }
}

export function* watchK4lLogIn() {
  yield takeEvery(K4L_LOG_IN, k4lLogin)
}

function* k4lSignUp(action) {
  try {
    yield put(onStartLoading(SIGN_UP_LOADING))
    const { goSignIn, ...signParams } = action.payload
    const k4lSignUpApi = new K4lSignUpApi()
    const locationSearch = yield selectStore("state", "locationSearch")
    const utmParams = getUtmParams(locationSearch)
    const params = { ...signParams, utmParams }
    const response = yield call(k4lSignUpApi.call, params)
    const user = response.data
    yield put(onEndLoading(SIGN_UP_LOADING))
    yield put(onSignUpSuccess(user, goSignIn))
    yield put(onLogSignUp(user))
  } catch (e) {
    console.log(e)
    yield put(onEndLoading(SIGN_UP_LOADING))
    yield put(onSignUpFail(e.response?.data.error_code || UNKNOWN_ERROR))
  }
}

export function* watchK4lSignUp() {
  yield takeEvery(K4L_SIGN_UP, k4lSignUp)
}

function* fetchUserPicture(action) {
  try {
    const { userId, accessToken } = action.payload
    const fetchUserPictureApi = new FetchUserPictureApi({ userId })
    const response = yield call(fetchUserPictureApi.call, { accessToken })
    yield put(onRequestUserPictureSuccess(response.data.url))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestUserPicture() {
  yield takeEvery(REQUEST_USER_PICTURE, fetchUserPicture)
}

function* temporaryLogin(action) {
  try {
    const { loginToken } = action.payload
    const temporaryLoginApi = new TemporaryLoginApi()
    const response = yield call(temporaryLoginApi.call, { loginToken })
    yield put(onTemporaryLoginSuccess(response.data))
  } catch (e) {
    yield put(onTemporaryLoginFail(e.response.data.error_code))
    console.log(e)
  }
}

export function* watchTemporaryLogin() {
  yield takeEvery(TEMPORARY_LOGIN, temporaryLogin)
}
