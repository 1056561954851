import { put, takeEvery, call } from "redux-saga/effects"
import {
  onRequestMagazineYearReadingStatusesSuccess,
  onRequestIssueReadingStatusSuccess
} from "actions/readingStatuses"
import {
  REQUEST_MAGAZINE_YEAR_READING_STATUSES,
  REQUEST_ISSUE_READING_STATUS
} from "constants/actionTypes"
import FetchMagazineYearReadingStatusesApi from "apis/FetchMagazineYearReadingStatusesApi"
import FetchIssueReadingStatusApi from "apis/FetchIssueReadingStatusApi"
import { withLock } from "utilities/ApiLock"

function* fetchMagazineYearReadingStatuses(action) {
  const { userId, magazineId, year } = action.payload

  try {
    const api = new FetchMagazineYearReadingStatusesApi({ magazineId, year })
    const response = yield call(api.call, { userId })
    yield put(onRequestMagazineYearReadingStatusesSuccess(response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestMagazineYearReadingStatuses() {
  yield takeEvery(
    REQUEST_MAGAZINE_YEAR_READING_STATUSES,
    withLock(fetchMagazineYearReadingStatuses, true)
  )
}

function* fetchIssueReadingStatus(action) {
  const { userId, issueId } = action.payload

  try {
    const api = new FetchIssueReadingStatusApi({ issueId })
    const response = yield call(api.call, { userId })
    yield put(onRequestIssueReadingStatusSuccess(response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestIssueReadingStatus() {
  yield takeEvery(
    REQUEST_ISSUE_READING_STATUS,
    withLock(fetchIssueReadingStatus, true)
  )
}
