import styled from "styled-components/macro"
import media from "utilities/media"

const NavigationsTab = styled.div`
  width: 100%;
  height: 44px;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  align-items: center;

  ${media.desktop`display: none;`};
  ${media.tablet`display: flex;`};
  ${media.mobile`display: flex;`};
`

export default NavigationsTab
