import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import {
  REQUEST_CURATION_POST_SUCCESS,
  REQUEST_CURATION_CHANNEL_POSTS_SUCCESS
} from "constants/actionTypes"

const initialState = fromJS({})

const formatCurationPost = post => {
  return post.curation_articles.map(article => {
    return {
      id: article.article_id,
      weight: article.weight
    }
  })
}

const arrayReducer = (acc, cur) => {
  acc[cur.id] = formatCurationPost(cur)
  return acc
}

const reducerMap = new Map([
  [
    REQUEST_CURATION_POST_SUCCESS,
    (state, action) => {
      const curationPostId = action.payload.curationPost.id
      const articles = fromJS(formatCurationPost(action.payload.curationPost))
      return state.set(curationPostId, articles)
    }
  ],
  [
    REQUEST_CURATION_CHANNEL_POSTS_SUCCESS,
    (state, action) => {
      return state.merge(
        fromJS(action.payload.curationChannelPosts.reduce(arrayReducer, {}))
      )
    }
  ]
])

export default handleActions(reducerMap, initialState)
