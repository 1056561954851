import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestFollowedMagazines } from "actions/followedMagazines"
import { FOLLOW_MAGAZINE } from "constants/actionTypes"
import FollowMagazineApi from "apis/FollowMagazineApi"
import { onOpenToast } from "actions/toasts"
import toastTypes from "constants/toastTypes"
import { onUpdateTaskRecords } from "actions/taskRecords"
import { taskIds } from "constants/taskRecords"

function* followMagazine(action) {
  const { userId, magazineId } = action.payload

  try {
    const followMagazineApi = new FollowMagazineApi({
      userId,
      magazineId
    })
    yield call(followMagazineApi.call)

    yield put(onOpenToast(toastTypes.FOLLOWED, { magazineId }))
    yield put(onUpdateTaskRecords(userId, taskIds.FOLLOW_MAGAZINE))
  } catch (e) {
    yield put(onRequestFollowedMagazines(userId, true))
    console.log(e)
  }
}

export function* watchFollowMagazine() {
  yield takeEvery(FOLLOW_MAGAZINE, followMagazine)
}
