export const DEFAULT_SOURCE = "url"
export const LIBRARY_SOURCE = "library"
export const SEARCH_SOURCE = "search"
export const BANNER_SOURCE = "banner"
export const BOOKMARK_SOURCE = "bookmark"
export const WEEKLY_ISSUE_SOURCE = "feed.free_magazines"
export const CURATION_SOURCE = "curation"
export const RECOMMEND_SOURCE = "curation.recommend_article"
export const DASHBOARD_SOURCE = "dashboard"
export const RELATED_ARTICLE_SOURCE = "article.related"
export const RECENTLY_READ_SOURCE = "recently_read"
