import { handleActions } from "redux-actions"
import { fromJS } from "immutable"
import { REQUEST_HAMI_READ_COUNT_SUCCESS } from "constants/actionTypes"

const initialState = fromJS({})

const reduceMap = new Map([
  [
    REQUEST_HAMI_READ_COUNT_SUCCESS,
    (state, action) => {
      return fromJS({ count: action.payload.count })
    }
  ]
])

export default handleActions(reduceMap, initialState)
