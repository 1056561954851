import React from "react"
import { useSelector } from "react-redux"
import AppStoreUrl from "../../assets/images/download_App_store.svg"
import GooglePlayUrl from "../../assets/images/download_google_play.svg"
import {
  K4L_APP_STORE_LINK,
  K4L_GOOGLE_PLAY_LINK,
  K4lQRCode
} from "constants/appLinks"
import styled from "styled-components/macro"
import media from "utilities/media"

const AppIconsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  ${media.mobile`
    width: 100%;
    order: 2;
    margin-top: 20px;
  `}
`

const QRCodeWrapper = styled.div`
  margin-right: 10px;

  ${media.mobile`
    display: none;
  `}
`

const QRCode = styled.img.attrs({ alt: "QR code" })`
  display: block;
  width: auto;
  height: 90px;
`

const DownloadIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;

  ${media.mobile`
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  `}
`

const ImageLink = styled.a`
  text-decoration: none;
  display: block;

  ${media.mobile`
    width: 50%;
  `}
`

const DownloadIcon = styled.img.attrs({ alt: "download icon" })`
  display: block;
  height: auto;
  width: 120px;

  ${media.mobile`
    width: calc(100% - 15px);
    max-width: 160px;
  `}
`

export default function AppIcons(props) {
  const k4lCurrentLibrary = useSelector(state =>
    state.getIn(["k4lLibraries", "current"])
  )

  function renderDownloadIcon(href, imageSrc) {
    return (
      <ImageLink href={href} target="_blank" rel="noopener noreferrer">
        <DownloadIcon src={imageSrc} onClick={props.onLogDownloadApp} />
      </ImageLink>
    )
  }

  return (
    <AppIconsWrapper>
      <QRCodeWrapper>
        <QRCode
          src={
            k4lCurrentLibrary && k4lCurrentLibrary.get("qrCode")
              ? k4lCurrentLibrary.get("qrCode")
              : K4lQRCode
          }
        />
      </QRCodeWrapper>
      <DownloadIconContainer>
        {renderDownloadIcon(K4L_APP_STORE_LINK, AppStoreUrl)}
        {renderDownloadIcon(K4L_GOOGLE_PLAY_LINK, GooglePlayUrl)}
      </DownloadIconContainer>
    </AppIconsWrapper>
  )
}
