import styled from "styled-components/macro"
import media from "utilities/media"

const Radio = styled.div`
  width: 18px;
  height: 18px;
  position: relative;
  border-radius: 50%;
  border: 1px solid #222222;
  cursor: pointer;

  ${media.tablet`
      width: 16px;
      height: 16px;
      border: 1px solid #222222;
  `};

  ${media.mobile`
      width: 16px;
      height: 16px;
      border: 1px solid #222222;
  `};

  &::before {
    content: "";
    display: ${props => (props.selected ? "block" : "none")};
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #222222;

    ${media.tablet`
      width: 8px;
      height: 8px;
    `};
  }

  &:checked::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #222222;

    ${media.tablet`
      width: 8px;
      height: 8px;
    `};
  }
`

export default Radio
