import styled from "styled-components/macro"

const Text = styled.div`
  font-size: ${props => props.fontSize || "14px"};
  color: #222222;
  line-height: 1.3;
  font-weight: ${props => props.fontWeight || "normal"};
  white-space: pre-wrap;
  display: inline-block;
`

export default Text
