import React from "react"
import styled from "styled-components/macro"
import { Link } from "react-router-dom"
import media from "utilities/media"
import arrow from "./assets/images/arrow.svg"
import whiteArrow from "./assets/images/white-arrow.svg"

const BreadcrumbWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  word-break: break-all;
  background-color: ${props => (props.dark ? "rgba(0, 0, 0, 0.75)" : "")};

  ${media.desktop`
    margin-bottom: ${props => (props.dark ? "0" : "30px")};
    height: ${props => (props.dark ? "32px" : "")};
    padding: ${props => (props.dark ? "0px 15px" : "")};
  `};
  ${media.tablet`
    margin-bottom: 25px;
    display: ${props => (props.dark ? "none" : "")};
  `};
  ${media.mobile`
    margin-bottom: 20px;
    display: ${props => (props.dark ? "none" : "")};
  `};
`

const HyplerLinkWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  word-break: break-all;
  ${media.desktop`
    font-size: ${props => (props.dark ? "12px" : "15px")};
    margin-right: ${props => (props.dark ? "5px" : "10px")};
  `};
  ${media.tablet`
    font-size: 12px;
    margin-right: 7px;
  `};
  ${media.mobile`
    font-size: 10px;
    margin-right: 5px;
  `};
  ::after {
    content: "";
    background-image: url(${props => (props.dark ? whiteArrow : arrow)});
    display: "inline-block";
    ${media.desktop`
      width: 16px;
      height: 16px;
      background-size:16px 16px;
      margin-left: ${props => (props.dark ? "5px" : "10px")};
    `};
    ${media.tablet`
      width: 13px;
      height: 13px;
      background-size: 13px 13px;
      margin-left: 7px;
    `};
    ${media.mobile`
      width: 10px;
      height: 10px;
      background-size: 10px 10px;
      margin-left: 5px;
    `};
  }
  &:last-child:after {
    display: none;
  }
`
const HyplerLink = styled(Link)`
  color: ${props => (props.dark ? "#fafafa" : "#222222")};
  font-size: ${props => (props.dark ? "12px" : "15px")};
  ::after {
    content: "/";
    display: "inline-block";
    ${media.desktop`
      font-size: ${props => (props.dark ? "12px" : "15px")};
      margin: ${props => (props.dark ? "0px 3px" : "0px 6px")};
    `};
    ${media.tablet`
      font-size: 12px;
      margin: 0px 4.2px;
    `};
    ${media.mobile`
      font-size: 10px;
      margin: 0px 3px;
    `};
  }
  &:last-child:after {
    display: none;
  }
`

function Breadcrumb(props) {
  const { dark, maplist, onLogClick } = props

  function multiCases(element, index) {
    const result = element.map((ele, idx) => (
      <HyplerLink to={ele.url} key={idx} dark={dark}>
        {ele.name}
      </HyplerLink>
    ))
    return (
      <HyplerLinkWrapper dark={dark} key={index}>
        {result}
      </HyplerLinkWrapper>
    )
  }

  function mapIdAndUrl(maplist) {
    const result = maplist.map((ele, idx) =>
      Array.isArray(ele) ? (
        multiCases(ele, idx)
      ) : (
        <HyplerLinkWrapper dark={dark} key={idx}>
          <HyplerLink to={ele.url} dark={dark}>
            {ele.name}
          </HyplerLink>
        </HyplerLinkWrapper>
      )
    )
    return <React.Fragment>{result}</React.Fragment>
  }

  return Array.isArray(maplist) ? (
    <BreadcrumbWrapper dark={dark} onClick={onLogClick}>
      {mapIdAndUrl(maplist)}
    </BreadcrumbWrapper>
  ) : null
}

export default React.memo(Breadcrumb)
