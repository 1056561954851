import { REQUEST_NEWS, REQUEST_NEWS_SUCCESS } from "constants/actionTypes"

export const onRequestNews = () => {
  return {
    type: REQUEST_NEWS
  }
}

export const onRequestNewsSuccess = news => {
  return {
    type: REQUEST_NEWS_SUCCESS,
    payload: { news }
  }
}
