import React from "react"
import styled from "styled-components/macro"
import { BrowserRouter as Router } from "react-router-dom"
import ErrorBoundary from "components/container/ErrorBoundary"
import Routes from "./components/Routes"
import InitialApis from "./components/InitialApis"

const RouteWrapper = styled.div`
  flex-grow: 1;
  flex-direction: column;
  display: flex;
`

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <RouteWrapper>
          <InitialApis />
          <Routes />
        </RouteWrapper>
      </Router>
    </ErrorBoundary>
  )
}

export default App
