import { put, takeEvery, call } from "redux-saga/effects"
import { onRequestMagazineListSuccess } from "actions/magazineList"
import { REQUEST_MAGAZINE_LIST } from "constants/actionTypes"
import FetchMagazineListApi from "apis/FetchMagazineListApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { MAGAZINE_LIST_TTL } from "constants/ttl"

function* fetchMagazineList() {
  try {
    const isExisted = yield* isStoreExisted("magazineList")
    const isExpired = yield* isStoreExpired(MAGAZINE_LIST_TTL, "magazineList")
    if (isExisted && !isExpired) {
      return
    }
    const fetchMagazineListApi = new FetchMagazineListApi()
    const response = yield call(fetchMagazineListApi.call)
    const magazineList = response.data
    yield put(onRequestMagazineListSuccess(magazineList))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestMagazineList() {
  yield takeEvery(REQUEST_MAGAZINE_LIST, fetchMagazineList)
}
