export default function retry(func, retries = 5, interval = 500) {
  return new Promise((resolve, reject) => {
    func()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retries === 1) {
            reject(error)
          } else {
            retry(func, retries - 1, interval).then(resolve, reject)
          }
        }, interval)
      })
  })
}
