import React from "react"
import ReactModal from "react-modal"
import styled from "styled-components/macro"
import media from "utilities/media"

const MainWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 30px 40px;

  ${media.mobile`
    padding: 30px;
  `};
`

const Title = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: #222222;
`

const Description = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 1.43;
  color: #222222;
  margin-top: 10px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;

  ${media.desktop`
    flex-flow: row-reverse wrap;
  `};
  ${media.tablet`
    flex-flow: row-reverse wrap;
  `};
  ${media.mobile`
    flex-flow: column wrap;
  `};
`

const Button = styled.button`
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  outline: 0;

  ${media.mobile`
    width: 100%;
  `};
`

const MainButton = styled(Button)`
  font-weight: 500;
  background-color: #00a270;
  color: #ffffff;

  &:hover {
    background-color: #008159;
  }
`

const SecondaryButton = styled(Button)`
  background-color: #ffffff;
  color: #222222;

  &:hover {
    background-color: #f2f2f2;
  }

  ${media.desktop`
    margin-right: 10px;
  `};
  ${media.tablet`
    margin-right: 10px;
  `};
  ${media.mobile`
    border: 1px solid #c7c7c7;
    margin-top: 10px;
  `};
`

function SelectionModal(props) {
  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: props.zIndex || 9999
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "row wrap",
      backgroundColor: "#ffffff",
      border: "none",
      padding: "0",
      maxWidth: "460px",
      width: "calc(100vw - 100px)"
    }
  }

  return (
    <ReactModal
      isOpen={props.open}
      onAfterClose={props.onClose}
      onRequestClose={props.onOverlayClick}
      style={styles}
      ariaHideApp={false}
    >
      <MainWrapper>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
        <ButtonsWrapper>
          <MainButton onClick={props.onMainButtonClick}>
            {props.mainButton}
          </MainButton>
          {props.secondaryButton ? (
            <SecondaryButton onClick={props.onSecondaryButtonClick}>
              {props.secondaryButton}
            </SecondaryButton>
          ) : null}
        </ButtonsWrapper>
      </MainWrapper>
    </ReactModal>
  )
}

export default SelectionModal
