import { put, takeEvery, call } from "redux-saga/effects"
import { withLock } from "utilities/ApiLock"
import { onRequestCategorySuccess } from "actions/categories"
import { REQUEST_CATEGORY } from "constants/actionTypes"
import FetchCategoryApi from "apis/FetchCategoryApi"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import { CATEGORIES_TTL } from "constants/ttl"

function* fetchCategory(action) {
  try {
    const { bookListId } = action.payload
    let { categoryId } = action.payload
    if (!bookListId || !categoryId) return
    categoryId = categoryId.toString()

    const isExisted = yield* isStoreExisted("categories", categoryId)
    const isExpired = yield* isStoreExpired(
      CATEGORIES_TTL,
      "categories",
      categoryId
    )
    if (isExisted && !isExpired) {
      return
    }

    const fetchCategoryApi = new FetchCategoryApi({ categoryId })
    const response = yield call(fetchCategoryApi.call, { bookListId })
    const category = response.data
    yield put(onRequestCategorySuccess(category))
  } catch (e) {
    const { onError } = action.payload
    onError ? onError(e) : console.log(e)
  }
}

export function* watchRequestCategory() {
  yield takeEvery(REQUEST_CATEGORY, withLock(fetchCategory))
}
