import styled from "styled-components/macro"
import media from "utilities/media"

const ContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  padding: 50px 0px;
  ${media.desktop`
    width: 940px;
  `};
  ${media.tablet`
    width: 460px;
  `};
  ${media.mobile`
    width: calc(100vw - 40px);
  `};
`

export default ContentContainer
