import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs"
import { throttle, call } from "redux-saga/effects"
import { REPORT_ERROR } from "constants/actionTypes"
import FetchArticleReadEventsConfigApi from "apis/FetchArticleReadEventsConfigApi"
import { selectStore } from "utilities/storeCheck"

function* reportError(action) {
  const { url, agent, message, componentStack, stackFrames } = action.payload

  try {
    const api = new FetchArticleReadEventsConfigApi()
    const resp = yield call(api.call)
    const { access_key: accessKeyId, secret_key: secretAccessKey } = resp.data
    const region = process.env.REACT_APP_ERROR_REPORT_REGION

    const sqsClient = new SQSClient({
      credentials: {
        accessKeyId,
        secretAccessKey
      },
      region
    })

    const stage = process.env.REACT_APP_CONFIG_FILE
    const QueueUrl = process.env.REACT_APP_ERROR_REPORT_URL
    const currentUser = yield* selectStore("user", "current")
    const historyState = yield* selectStore("state", "history")
    const clientInfo = yield* selectStore("clientInfo")

    const MessageBody = JSON.stringify({
      env: stage,
      url: url,
      time: new Date().toISOString(),
      UserAgent: agent,
      ErrorMessage: message,
      ComponentStack: componentStack,
      StackFrames: stackFrames,
      currentUser: currentUser ? currentUser.toJS() : {},
      historyState: historyState.toJS(),
      clientInfo: clientInfo ? clientInfo.toJS() : {}
    })

    const sqsParams = {
      MessageBody,
      QueueUrl
    }

    const sqsCommand = new SendMessageCommand(sqsParams)

    sqsClient
      .send(sqsCommand)
      .then(data => console.log("data:", data))
      .catch(err => console.error("Send SQS message error! error:", err))
  } catch (error) {
    console.error(error)
  }
}

export function* watchReportError() {
  yield throttle(3000, REPORT_ERROR, reportError)
}
