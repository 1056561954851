import { put, takeEvery, call } from "redux-saga/effects"
import { isStoreExisted, isStoreExpired } from "utilities/storeCheck"
import {
  REQUEST_K4L_LIBRARIES,
  SET_CURRENT_LIBRARY_BY_ID
} from "constants/actionTypes"
import {
  onRequestK4lLibrariesSuccess,
  onSetCurrentLibrary
} from "actions/k4lLibraries"
import { onSetK4lError } from "actions/k4l"
import { K4L_ALL_LIBRARIES_TTL } from "constants/ttl"
import FetchAllK4lLibrariesApi from "apis/FetchAllK4lLibrariesApi"
import FetchK4lLibraryApi from "apis/FetchK4lLibraryApi"

function* fetchK4lLibraries() {
  try {
    const isExisted = yield* isStoreExisted("k4lLibraries", "all")
    const isExpired = yield* isStoreExpired(
      K4L_ALL_LIBRARIES_TTL,
      "k4lLibraries",
      "all"
    )
    if (isExisted && !isExpired) {
      return
    }

    const fetchAllK4lLibrariesApi = new FetchAllK4lLibrariesApi()
    const response = yield call(fetchAllK4lLibrariesApi.call)
    yield put(onRequestK4lLibrariesSuccess(response.data))
  } catch (e) {
    console.log(e)
  }
}

export function* watchRequestK4lLibraries() {
  yield takeEvery(REQUEST_K4L_LIBRARIES, fetchK4lLibraries)
}

function* setCurrentLibraryById(action) {
  try {
    const libraryId = action.payload.libraryId
    const fetchK4lLibraryApi = new FetchK4lLibraryApi({ libraryId })
    const response = yield call(fetchK4lLibraryApi.call)
    yield put(onSetCurrentLibrary(response.data))
  } catch (e) {
    console.log(e)
    yield put(onSetK4lError(true))
  }
}

export function* watchSetCurrentLibraryById() {
  yield takeEvery(SET_CURRENT_LIBRARY_BY_ID, setCurrentLibraryById)
}
