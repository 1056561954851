import KonoApi from "../KonoApi"

class FetchLatestIssueApi extends KonoApi {
  constructor(data) {
    super(`/titles/${data.magazineId}/current_issue`)
  }

  call() {
    return this.axios.get(this.url)
  }
}

export default FetchLatestIssueApi
